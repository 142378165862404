import { useSelector } from 'react-redux';
import React from 'react';
import { Redirect } from 'react-router';
import { ChildrenProps, ReduxState } from '../../types';
import AuthService from '../../services/authService';

function AuthGuard({ children, admittedRoles }: ChildrenProps
& { admittedRoles?: string[] }): JSX.Element {
  const account = useSelector((state: ReduxState) => state.account);
  if (!account.user) {
    return <Redirect to="/login" />;
  }
  AuthService.handleAuthentication();
  if (!AuthService.isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  // Si uno desea no tener un guard via user types, simplemente no definir user types
  if (admittedRoles && !admittedRoles.includes(account.user.userType)) {
    return <Redirect to="/sin-autorizacion" />;
  }

  return <>{children}</>;
}

export default AuthGuard;
