import { Box, Checkbox, ClassNameMap } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useHistory, useLocation, useParams } from 'react-router';
import CustomTable from '../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../components/General/EmptyTable';
import useStyles from '../../styles';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import { getProgramSectionsByProgramId, putProgramSections } from '../../../../../requests/api/students';
import { SectionTimeSlots } from '../../../../Section/SectionListView/types';
import Page from '../../../../../components/Layout/Page';
import SectionsAssociationHeader from './Components/SectionsAssociationHeader';
import { AssociationTableInformation, ProgramSpecificSectionResponse, TeacherResponse } from './types';

interface LocationState {
  sectionId: string;
}

const displayTimeSlotFormat = (array: SectionTimeSlots[]) => {
  const timeSlotsToJoin = array.map((elem, index) => (
    <React.Fragment key={index}>
      {`${elem.timeSlot.weekDay} ${elem.timeSlot.startTime.slice(0, 5)} hrs - ${elem.timeSlot.endTime.slice(0, 5)} hrs`}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));
  return timeSlotsToJoin;
};

const customTeachersView = (array: string[]) => {
  const teacherToShow = array.map((elem, index) => (
    <React.Fragment key={index}>
      {`${elem}`}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));
  return teacherToShow;
};

const checkboxUI = (
  classes: ClassNameMap<string>,
  sectionIdToCheck: string,
  checkedBox: string[],
  handleCheckboxChange: (sectionIdToCheck: string) => void,
) => (
  <Box>
    <Checkbox
      color='primary'
      checked={checkedBox.includes(sectionIdToCheck)}
      onChange={() => handleCheckboxChange(sectionIdToCheck)}
    />
  </Box>
);

const ASSOCIATIONS_SECTIONS_UI = [
  {
    label: 'ID', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Sede', key: 'locationName', valueType: 'string',
  },
  {
    label: 'Profesor', key: 'teacherName', valueType: 'custom', functionToApply: customTeachersView,
  },
  {
    label: 'Sala', key: 'classroom', valueType: 'string',
  },
  {
    label: 'Inscritos / Cupos', key: 'classroomCapacity', valueType: 'string',
  },
  {
    label: 'Horario', key: 'sectionTimeSlots', valueType: 'custom', functionToApply: displayTimeSlotFormat,
  },
  {
    label: 'Seleccionar una sección', key: 'checkbox', valueType: 'other',
  },
];

const SectionsAssociationsView = () => {
  const { id: studentId, programId } = useParams<{ id: string, programId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [allProgramSections, setAllProgramSections] = useState<ProgramSpecificSectionResponse[]>(
    [],
  );
  const [tableInformation, setTableInformation] = useState<AssociationTableInformation[]>([]);
  const [selectedSectionIdCheckbox, setSelectedSectionIdCheckbox] = useState<string[]>([]);
  const [searchedValue, setSearchedValue] = useState('');

  useEffect(() => {
    const getProgramAllSections = async () => {
      try {
        const { sections } = await getProgramSectionsByProgramId(programId, searchedValue);
        setAllProgramSections(sections);
        if (location.state && location.state.sectionId) {
          setSelectedSectionIdCheckbox([location.state.sectionId]);
        }
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getProgramAllSections();
  }, [searchedValue]);

  useEffect(() => {
    if (allProgramSections) {
      const auxAllProgramSectionsInfo = allProgramSections.map((
        elem,
      ) => ({
        mipreuId: elem.mipreuId,
        sectionId: elem.id,
        locationName: elem.location?.name,
        teacherName: elem.sectionTeachers && elem.sectionTeachers.length > 0
          ? elem.sectionTeachers.map((elemTeacher: TeacherResponse) => elemTeacher.teacher
            .user.teacherName)
          : [],
        classroom: elem.classroom ?? '',
        classroomCapacity: `${elem.studentsCount}/${elem.studentsCapacity}`,
        sectionTimeSlots: elem.sectionTimeSlots,
        checkbox: checkboxUI(classes, elem.id, selectedSectionIdCheckbox, handleCheckboxChange),
      }));
      setTableInformation(auxAllProgramSectionsInfo);
    }
  }, [selectedSectionIdCheckbox[0], allProgramSections]);

  const handleCheckboxChange = (sectionIdToChange: string) => {
    if (selectedSectionIdCheckbox.includes(sectionIdToChange)) {
      setSelectedSectionIdCheckbox([]);
      return;
    }

    setSelectedSectionIdCheckbox([sectionIdToChange]);
  };

  const handleUpsertProgramSection = async () => {
    if (selectedSectionIdCheckbox.length === 0) {
      enqueueSnackbar('Debes asignar al menos una sección al alumno para este programa', { variant: 'error' });
      return;
    }

    try {
      const response = await putProgramSections({
        studentId,
        programId,
        sectionId: selectedSectionIdCheckbox[0],
      });
      handleApiResponse(enqueueSnackbar, response, true);
      history.replace(`/alumnos/${studentId}`, { activeTab: 1 });
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  return (
    <Page
      title={'Alumnos | Asociar seccion'}
    >
      <SectionsAssociationHeader
        sectionToSearch={searchedValue}
        setSectionToSearch={setSearchedValue}
        handleUpsertProgramSection={handleUpsertProgramSection}
        isEditSection={Boolean(location.state && location.state.sectionId)}
      />
      <Box
        mt={3}
      >
        {tableInformation.length !== 0
          && <CustomTable
            headers={ASSOCIATIONS_SECTIONS_UI}
            data={{ values: tableInformation, count: tableInformation.length }}
          />}
        {tableInformation.length === 0 && <EmptyTable />}
      </Box>
    </Page>
  );
};

export default SectionsAssociationsView;
