import { Box, Typography } from '@mui/material';
import React from 'react';

const ErrorScreen = ({ error }: { error: string }) => (
  <Box display='flex' flexDirection='column' width='100%' height='100%' justifyContent='center' flex={1}>
    <Box display='flex' flexDirection='column' width='100%' height='100%' alignItems='center'>
      <Typography variant='h1' color='tertiary'>500 - Error de Servidor</Typography>
      {/* eslint-disable-next-line global-require */}
      <img src={require('../../assets/images/error_500.png')} alt='Cartoon of a monkey with a hammer to show error' />
      <Typography variant='h3' color='tertiary'>{error}</Typography>
    </Box>
  </Box>
);

export default ErrorScreen;
