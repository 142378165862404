import { validate } from 'rut.js';

type KeyTypeValidation = {
  error: boolean,
  message: string,
};

export const validateEmail = (email: string) => {
  const regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexp.test(email);
};

export const getKeyTypeValidation = (key: string, value: string | Date): KeyTypeValidation => {
  if (!key || !value) {
    return { error: false, message: '' };
  }
  if (typeof value === 'string' && key === 'rut') {
    if (!validate(value)) {
      return { error: true, message: 'El Rut ingresado no es válido' };
    }
  }
  if (typeof value === 'string' && key === 'email') {
    if (!validateEmail(value)) {
      return { error: true, message: 'El email ingresado no es válido' };
    }
  }
  return { error: false, message: '' };
};
