import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Typography,
  Grid,
  Button,
} from '@mui/material';

function Header({ isInsertRoute }: { isInsertRoute: boolean }) {
  const intl = useIntl();
  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          {isInsertRoute ? intl.messages['admin.upsert.header.title.editUser'] : intl.messages['admin.upsert.header.title.createUser']}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          component={RouterLink}
          to="/administracion/usuarios"
        >
          <FormattedMessage
            id='admin.upsert.header.cancel.button'
          />
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
