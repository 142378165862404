import React, { SyntheticEvent, useCallback } from 'react';
import { Typography, Box } from '@mui/material';

import { CustomAutocompleteTextField, CustomDateTextField, CustomTextField } from '../../../../../../components/General/CustomTextfields';
import { Installment } from '../../../types';

import useStyles from '../../../styles';

const bankOptions = [
  { id: 'BCI', name: 'BCI' },
  { id: 'Banco Bice', name: 'Banco Bice' },
  { id: 'Itau', name: 'Itau' },
  { id: 'Banco de Chile', name: 'Banco de Chile' },
  { id: 'Banco del Estado de Chile', name: 'Banco del Estado de Chile' },
  { id: 'Santander', name: 'Santander' },
  { id: 'Scotiabank', name: 'Scotiabank' },
  { id: 'Banco Security', name: 'Banco Security' },
  { id: 'Banco Falabella', name: 'Banco Falabella' },
  { id: 'Banco Ripley', name: 'Banco Ripley' },
  { id: 'Banco Consorcio', name: 'Banco Consorcio' },
  { id: 'Banco Corpbanca', name: 'Banco Corpbanca' },
  { id: 'Banco Internacional', name: 'Banco Internacional' },
  { id: 'Banco Paris', name: 'Banco Paris' },
  { id: 'Banco del desarrollo', name: 'Banco del desarrollo' },
  { id: 'Coopeuch', name: 'Coopeuch' },
  { id: 'HSBC Bank', name: 'HSBC Bank' },
  { id: 'Radobank', name: 'Radobank' },
];

type InstallmentComponentProps = {
  installment: Installment;
  setInstallment: (updatedInstallment: Installment) => void;
  installmentType: string;
  setUpdateInstallments?: (updateInstallments: (prevState: boolean) => boolean) => void;
};

const InstallmentComponent: React.FC<InstallmentComponentProps> = ({
  installment,
  setInstallment,
  installmentType,
  setUpdateInstallments,
}) => {
  const classes = useStyles();

  const handleFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | Date, source: string) => {
      if (!setUpdateInstallments) return;

      if (source === 'dueDate' && e instanceof Date) {
        const date = e;

        setInstallment({ ...installment, dueDate: date });
        setUpdateInstallments((prevState: boolean) => !prevState);
        return;
      }

      const event = e as React.ChangeEvent<HTMLInputElement>;
      let { value } = event.target;
      if (value === '' && (event.nativeEvent as InputEvent).inputType === 'deleteContentBackward') value = '0';

      setInstallment({ ...installment, [source]: value });

      if (source === 'amount') return;

      setUpdateInstallments((prevState: boolean) => !prevState);
    },
    [installment, setInstallment],
  );

  const handleSelectChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    const { name } = value as { id: string, name: string };

    setInstallment({ ...installment, [source]: name });
  },
  [installment, setInstallment]);

  return (
    <Box>
      <Box className={classes.contractViewContractDiscountContainer}>
        <Box className={classes.contractViewProgramsTitleContainer}>
          <Typography variant='h6' color='textPrimary' fontWeight='bold' mb={3} mt={2}>
            {installmentType} {installment.number}
          </Typography>
        </Box>
        <Box className={classes.contractViewContractDiscountContent}>
          <Box className={classes.contractViewContractGroupingContainer}>
            <Box className={classes.contractPaymentTextfieldsContainer}>
              <CustomAutocompleteTextField
                keyField='bank'
                name='Banco'
                onChangeFunction={handleSelectChange}
                selectFields={bankOptions}
                value={installment.bank}
                valueToRetrieveFromSelect="id"
                size='medium'
              />
            </Box>
            <Box className={classes.contractPaymentTextfieldsContainer}>
              <CustomTextField
                keyField='accountNumber'
                name='N° de cuenta'
                onChangeFunction={handleFieldChange}
                value={installment.accountNumber}
              />
            </Box>
            {installmentType === 'Cheque' ? (
              <Box className={classes.contractPaymentTextfieldsContainer}>
                <CustomTextField
                  keyField='documentNumber'
                  name='N° de documento'
                  onChangeFunction={handleFieldChange}
                  value={installment.documentNumber || ''}
                />
              </Box>
            ) : null}
            <Box className={classes.contractPaymentTextfieldsContainer}>
              <CustomDateTextField
                keyField='dueDate'
                name='Fecha de vencimiento'
                onChangeFunction={handleFieldChange}
                value={installment.dueDate}
              />
            </Box>
            <Box className={classes.contractPaymentTextfieldsContainer}>
              <CustomTextField
                keyField='amount'
                name='Valor'
                onChangeFunction={handleFieldChange}
                value={`${installment.amount}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InstallmentComponent;
