import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { ProgramContentData, ProgramData } from '../types';
import UpsertModal from '../../../../components/General/UpsertModal';

function UpsertProgramModal(
  {
    allItems,
    modalAddEdit,
    setModalAddEdit,
    handleCloseModal,
    modalValues,
    setModalValues,
    handleFieldChange,
    itemsUI,
    addFunction,
    editFunction,
  }: {
    allItems: { items: Array<ProgramData>, totalItems: number },
    modalAddEdit: { id: string, open: boolean },
    setModalAddEdit: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>,
    handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>) => void,
    modalValues: ProgramData,
    setModalValues: React.Dispatch<React.SetStateAction<ProgramData>>,
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File, source: string) => void,
    itemsUI: {
      label: string;
      key: string;
      showOnTable: boolean;
      showOnModal: boolean;
      typeField: string;
      selectFields?: object[];
      valueType: string;
    }[],
    addFunction: () => void,
    editFunction: (id: string) => void,
  },
) {
  const [programContents, setProgramContents] = useState<ProgramContentData[]>([]);
  const [specificProgramContent, setSpecificProgramContent] = useState({
    isBeingModified: false,
    id: '',
    programId: '',
    number: '',
    name: '',
    url: '',
  });
  const [upsertFlag, setUpsertFlag] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleProgramContentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    source: string,
    programContentId: string | undefined,
  ) => {
    if (programContentId) {
      const newProgramContents = programContents.map((content) => {
        if (content.id === programContentId) {
          return { ...content, [source]: e.target.value };
        }
        return content;
      });
      setProgramContents(newProgramContents);
    } else {
      setSpecificProgramContent((prevState) => ({ ...prevState, [source]: e.target.value }));
    }
  };

  useEffect(() => {
    if (upsertFlag && modalAddEdit.id) {
      editFunction(modalAddEdit.id);
      setUpsertFlag(false);
    } else if (upsertFlag) {
      addFunction();
      setUpsertFlag(false);
    }
  }, [upsertFlag, modalAddEdit.id]);

  useEffect(() => {
    if (modalAddEdit.id !== '') {
      const auxSelectedProgram = allItems.items
        .filter((elem) => elem.id === modalAddEdit.id);
      const selectedProgram = auxSelectedProgram[0];
      const auxProgramContents = selectedProgram.programContents ?? [];
      setModalValues({
        subjectId: selectedProgram.subjectId,
        subjectName: selectedProgram.subjectName,
        name: selectedProgram.name,
        level: selectedProgram.level,
        classYear: selectedProgram.classYear,
        startDate: selectedProgram.startDate,
        endDate: selectedProgram.endDate,
        fee: selectedProgram.fee,
        active: selectedProgram.active ? 'Activo' : 'Inactivo',
        programContents: auxProgramContents,
      });
      setProgramContents(auxProgramContents);
      setSpecificProgramContent({
        isBeingModified: false,
        id: '',
        programId: '',
        number: '',
        name: '',
        url: '',
      });
    }
  }, [modalAddEdit.id]);

  const handleAddingNewContent = () => {
    const userDefinedFields = ['number', 'name', 'url'];
    const addNewFieldCondition = Object.entries(specificProgramContent)
      .filter((elem) => typeof elem[1] !== 'boolean' && userDefinedFields.includes(elem[0]))
      .every((elem) => elem[1] !== '');

    if (addNewFieldCondition) {
      setProgramContents((prevState) => [...prevState, {
        id: `${Math.random()}`,
        programId: modalAddEdit.id || '',
        number: specificProgramContent.number,
        name: specificProgramContent.name,
        url: specificProgramContent.url,
      }]);
      setSpecificProgramContent({
        isBeingModified: true,
        id: '',
        programId: '',
        number: '',
        name: '',
        url: '',
      });
    } else {
      setSpecificProgramContent((prevState) => ({ ...prevState, isBeingModified: true }));
    }
  };

  const removeCurrentProgramContent = (programContentId: string) => {
    const auxProgramContents = programContents.filter((elem) => `${elem.id}` !== `${programContentId}`);
    setProgramContents(auxProgramContents);
  };

  const removeNewProgramContent = () => {
    setSpecificProgramContent({
      isBeingModified: false,
      id: '',
      programId: '',
      number: '',
      name: '',
      url: '',
    });
  };

  const programContentsUI = ({
    number, name, url, programContentId,
  }: { number: string, name: string, url: string, programContentId: string | undefined }) => {
    let deleteFunction;
    if (programContentId) {
      deleteFunction = () => removeCurrentProgramContent(programContentId);
    } else {
      deleteFunction = () => removeNewProgramContent();
    }
    return (
      <TableRow key={`row-item-content-${programContentId}`}>
        <TableCell>
          <TextField
            key={`number-${programContentId}`}
            name="Número"
            placeholder='Ingresa un número'
            value={number}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProgramContentChange(e, 'number', programContentId)}
          />
        </TableCell>
        <TableCell>
          <TextField
            key={`name-${programContentId}`}
            name="Nombre"
            value={name}
            placeholder='Ingresa un nombre'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProgramContentChange(e, 'name', programContentId)}
          />
        </TableCell>
        <TableCell>
          <TextField
            key={`url-${programContentId}`}
            name="Link"
            value={url}
            placeholder='Ingresa un enlace'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProgramContentChange(e, 'url', programContentId)}
          />
        </TableCell>
        <TableCell>
          <IconButton onClick={deleteFunction}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const allFieldsFilled = () => (
    programContents.every((elem) => `${elem.number}`.trim() !== '' && elem.name.trim() !== '' && elem.url.trim() !== '')
    && (!specificProgramContent.isBeingModified || (specificProgramContent.isBeingModified && (specificProgramContent.number.trim() !== '' && specificProgramContent.name.trim() !== '' && specificProgramContent.url.trim() !== '')))
  );

  const handleAddAProgram = () => {
    const areFieldsFilled = allFieldsFilled();
    if (!areFieldsFilled) {
      enqueueSnackbar('Rellene todos los contenidos de programa', {
        variant: 'error',
      });
    } else {
      if (specificProgramContent.isBeingModified) {
        const actualProgram = {
          number: specificProgramContent.number,
          name: specificProgramContent.name,
          url: specificProgramContent.url,
        };
        setModalValues((prevState) => ({
          ...prevState, programContents: [...programContents, actualProgram],
        }));
      } else {
        setModalValues((prevState) => ({
          ...prevState, programContents: [...programContents],
        }));
      }
      setUpsertFlag(true);
    }
  };

  const handleEditAProgram = () => {
    const areFieldsFilled = allFieldsFilled();
    if (!areFieldsFilled) {
      enqueueSnackbar('Falta rellenar todos los contenidos de programa', {
        variant: 'error',
      });
    } else {
      if (specificProgramContent.isBeingModified) {
        const actualProgram = {
          number: specificProgramContent.number,
          name: specificProgramContent.name,
          url: specificProgramContent.url,
        };

        setModalValues((prevState) => ({
          ...prevState, programContents: [...programContents, actualProgram],
        }));
      } else {
        setModalValues((prevState) => ({
          ...prevState, programContents: [...programContents],
        }));
      }

      setUpsertFlag(true);
    }
  };

  return (
    <UpsertModal<ProgramData>
      title={'programa'}
      modalAddEdit={modalAddEdit}
      setModalAddEdit={setModalAddEdit}
      handleCloseModal={handleCloseModal}
      modalValues={modalValues}
      handleFieldChange={handleFieldChange}
      itemsUI={itemsUI}
      handleAddFunction={handleAddAProgram}
      handleEditFunction={handleEditAProgram}
      >
      <Box>
        <Typography variant='h4' fontWeight='bold' mt={2} mb={3}>Videos</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Número</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Link</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {programContents.length > 0
              && programContents.sort((a, b) => parseInt(a.number, 10) - parseInt(b.number, 10))
                .map((elem) => programContentsUI({
                  number: elem.number,
                  name: elem.name,
                  url: elem.url,
                  programContentId: elem.id,
                }))
            }
            {specificProgramContent.isBeingModified && programContentsUI({
              number: specificProgramContent.number,
              name: specificProgramContent.name,
              url: specificProgramContent.url,
              programContentId: '',
            })}
          </TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <IconButton onClick={handleAddingNewContent}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </Table>
      </Box>
    </UpsertModal>
  );
}

export default UpsertProgramModal;
