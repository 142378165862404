import { Box, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../styles';
import EmptyTable from '../../../../components/General/EmptyTable';
import CustomTable from '../../../../components/General/CustomTable';

const MASSIVE_DETAIL_TEST_UI = [
  {
    label: 'Número', key: 'number', valueType: 'string',
  },
  {
    label: 'Nombre', key: 'testName', valueType: 'string',
  },
  {
    label: 'Fecha programada', key: 'deliverDate', valueType: 'shortDate',
  },
  {
    label: 'N Entregas', key: 'numberOfDeliveries', valueType: 'string',
  },
];

const TestsTable = ({ data }: {
  data: {
    count: number,
    values: {
      id: number,
      programId: number,
      testId: number,
      number: number,
      deliverDate: Date,
      numberOfDeliveries: number,
      testName: string
    }[]
  }
}) => {
  const classes = useStyles();

  return (
    <Box mt={3}>
      <Typography
        variant='h3'
        color='textPrimary'
        fontWeight='bold'
      >
        Guías
      </Typography>
      <Box className={classes.massiveDetailTableContainer}>
        {data.count !== 0
          && <CustomTable
            headers={MASSIVE_DETAIL_TEST_UI}
            data={{ count: data.count, values: data.values }}
          />}
        {data.count === 0 && <EmptyTable />}
      </Box>
    </Box>
  );
};

export default TestsTable;
