import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  ClassNameMap,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import Header from './Components/Header';
import useStyles from './styles';
import FeedbackMassiveModal from './Components/FeedbackMassiveModal';
import {
  deleteMassive,
  getMassivesPerPage,
  getQuestionsAnswersZip,
  postMassives,
} from '../../../requests/api/massive';
import DeleteModal from '../../../components/General/DeleteModal';
import { Program } from '../../Program/ProgramListView/types';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import { ReduxState } from '../../../types';
import { setMassivesFilters } from '../../../actions/filtersActions';

type Massive = {
  id: string,
  maxQuestions: number,
  minQuestions: number,
  feedbackCount: number,
  creationNumber: number,
  createdAt: Date,
  status: string,
  section: {
    id: string,
    mipreuId: string,
    program: {
      id: string,
      name: string,
    },
  },
  user: {
    id: string,
    name: string,
    lastname: string,
  }
};

const SeeAction = (
  classes: ClassNameMap<string>,
  massiveId: string,
  handleRedirection: (massiveId: string, programInfo: Record<string, Date | string>) => void,
  programInfo: Record<string, Date | string>,
) => (<Box>
  <IconButton onClick={() => handleRedirection(massiveId, programInfo)}>
    <RemoveRedEyeIcon className={classes.icons} />
  </IconButton>
</Box>);

const DownloadAction = (
  classes: ClassNameMap<string>,
  massiveId: string,
  handleDownload: (massiveId: string) => void,
) => (<Box>
  <IconButton onClick={() => handleDownload(massiveId)}>
    <DownloadIcon className={classes.icons} />
  </IconButton>
</Box>);

const DeleteAction = (
  classes: ClassNameMap<string>,
  massiveId: string,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box>
  <IconButton onClick={() => openDeleteModal({ id: massiveId, open: true })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>

</Box>);

const MASSIVE_UI = [
  {
    label: 'ID', key: 'id', typeField: 'text', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'ID Seccion', key: 'mipreuId', valueType: 'string',
  },
  {
    label: '# Masivo', key: 'creationNumber', valueType: 'string',
  },
  {
    label: 'Fecha creación', key: 'createdAt', valueType: 'shortDate',
  },
  {
    label: 'Número preguntas', key: 'numberOfQuestions', valueType: 'string',
  },
  {
    label: 'Feedbacks creados', key: 'numberOfFeedbacksCreated', valueType: 'string',
  },
  {
    label: 'Creado por', key: 'createdBy', valueType: 'string',
  },
  {
    label: 'Estado', key: 'status', valueType: 'string',
  },
  {
    label: 'Ver masivo', key: 'seeMassive', valueType: 'other',
  },
  {
    label: 'Descargar preguntas para impresión', key: 'downloadQuestions', valueType: 'other',
  },
  {
    label: 'Acciones', key: 'actions', valueType: 'other',
  },
];

const MASSIVE_MODAL_UI = [
  {
    label: 'Programa', key: 'programId', typeField: 'autocomplete', selectFields: [], valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Mínimo de preguntas', key: 'minQuestions', typeField: 'number',
  },
  {
    label: 'Máximo de preguntas', key: 'maxQuestions', typeField: 'number',
  },
];

function MassiveListView() {
  const classes = useStyles();
  const history = useHistory();
  const [allMassives, setAllMassives] = useState<{ count: number, values: Massive[] }>(
    { count: 0, values: [] },
  );
  const { enqueueSnackbar } = useSnackbar();
  const [modalAddMassive, setModalAddMassive] = useState({ id: '', open: false });
  const [modalDeleteMassive, setModalDeleteMassive] = useState({ id: '', open: false });
  const [showLoading, setShowLoading] = useState(true);
  const [updateTable, setUpdateTable] = useState(false);
  const [modalValues, setModalValues] = useState<{
    name?: string,
    minQuestions: number,
    maxQuestions: number | undefined,
    programId: string,
    selectedTestsIds: string[],
  }>({
    minQuestions: 0,
    maxQuestions: undefined,
    programId: '',
    selectedTestsIds: [],
  });
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { massivesFilters } = useSelector((state: ReduxState) => state.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllMassives = async () => {
      try {
        const { massives, totalMassives } = await getMassivesPerPage({
          page: massivesFilters.page,
          limit: massivesFilters.rowsPerPage,
          sectionIdFilter: massivesFilters.sectionId,
          programNameFilter: massivesFilters.programName === 'Todos' ? '' : massivesFilters.programName,
          sort: 'id|DESC',
        }, source.token);

        const auxMassives = massives.map((elem: Massive) => ({
          id: elem.id,
          programName: elem.section.program.name,
          mipreuId: elem.section.mipreuId,
          creationNumber: elem.creationNumber,
          createdAt: elem.createdAt,
          numberOfQuestions: `${elem.minQuestions} a ${elem.maxQuestions}`,
          numberOfFeedbacksCreated: elem.feedbackCount,
          createdBy: `${elem.user.name} ${elem.user.lastname}`,
          status: elem.status,
          seeMassive: SeeAction(
            classes,
            elem.id,
            handleRedirection,
            {
              minQuestions: `${elem.minQuestions}`,
              maxQuestions: `${elem.maxQuestions}`,
              creationDate: elem.createdAt,
              programName: `${elem.section.program.name} / ${elem.section.mipreuId}`,
              programId: elem.section.program.id,
              sectionId: elem.section.id,
            },
          ),
          downloadQuestions: downloadLoading
            ? <CircularProgress />
            : DownloadAction(classes, elem.id, handleDownload),
          actions: DeleteAction(classes, elem.id, setModalDeleteMassive),
        }));
        setAllMassives({ count: totalMassives, values: auxMassives });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setShowLoading(false);
      }
    };

    getAllMassives();

    return () => {
      source.cancel();
    };
  }, [
    massivesFilters.page,
    massivesFilters.rowsPerPage,
    updateTable,
    massivesFilters.sectionId,
    massivesFilters.programName,
    downloadLoading,
  ]);

  const handleCloseModal = () => {
    setModalValues({
      minQuestions: 0,
      maxQuestions: undefined,
      programId: '',
      selectedTestsIds: [],
    });
    setModalAddMassive({ id: '', open: false });
  };

  const handleDownload = async (massiveId: string) => {
    setDownloadLoading(true);
    try {
      const { massivesZip } = await getQuestionsAnswersZip(massiveId);

      const blob = new Blob([new Uint8Array(massivesZip.data)], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `masivo_nro_${massiveId}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string, value?: string | object | null,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (!(e instanceof File)) {
      const { value: targetValue } = (e as React.ChangeEvent<HTMLInputElement>).target;
      if (source === 'checkbox') {
        if (modalValues.selectedTestsIds.includes(targetValue)) {
          setModalValues(
            (prevState) => ({
              ...prevState,
              selectedTestsIds: prevState.selectedTestsIds
                .filter((elem) => elem !== targetValue),
            }),
          );
        } else {
          setModalValues(
            (prevState) => ({
              ...prevState,
              selectedTestsIds: [...prevState.selectedTestsIds, targetValue],
            }),
          );
        }
      } else if (source === 'programId') {
        setModalValues(
          (prevState) => ({ ...prevState, [source]: (value as Program)?.id ?? '' }),
        );
      } else {
        setModalValues(
          (prevState) => ({ ...prevState, [source]: (e.target as HTMLInputElement).value }),
        );
      }
    }
  };

  const handleAddFunction = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!modalValues.maxQuestions
      || Number(modalValues.maxQuestions) < Number(modalValues.minQuestions)) {
      enqueueSnackbar('Máximo de preguntas no puede estar vacío ni ser menor que mínimo de preguntas.', { variant: 'error' });
      return;
    }

    try {
      setLoading(true);
      const response = await postMassives({
        minQuestions: modalValues.minQuestions,
        maxQuestions: modalValues.maxQuestions,
        programId: modalValues.programId,
        selectedTestsIds: modalValues.selectedTestsIds,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal();
      setUpdateTable((prevState) => !prevState);
      setLoading(false);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const handleDeleteFunction = async (massiveId: string) => {
    try {
      const response = await deleteMassive(massiveId);
      handleApiResponse(enqueueSnackbar, response, true);
      handleDeleteCloseModal(setModalDeleteMassive);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleDeleteCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setUpdateTable(!updateTable);
  };

  const handleRedirection = (massiveId: string, programInfo: Record<string, string | Date>) => {
    history.push({
      pathname: `/masivos/${massiveId}`,
      state: {
        minQuestions: programInfo.minQuestions,
        maxQuestions: programInfo.maxQuestions,
        creationDate: programInfo.creationDate,
        programName: programInfo.programName,
        programId: programInfo.programId,
        sectionId: programInfo.sectionId,
      },
    });
  };

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (source === 'programName') {
      dispatch(setMassivesFilters({ ...massivesFilters, programName: (value as { name: string })?.name ?? 'Todos' }));
    } else {
      dispatch(setMassivesFilters({ ...massivesFilters, sectionId: (value as { id: string })?.id ?? '' }));
    }

    if (massivesFilters.page !== 0) {
      dispatch(setMassivesFilters({ ...massivesFilters, page: 0 }));
    }
  };

  return (
    <Page
      title="Masivos"
    >
      <Header
        setModalAddEdit={setModalAddMassive}
        filterValues={massivesFilters}
        filterChangeFunction={handleFilterChange}
        loading={showLoading}
      />
      {showLoading && <CircularProgressComponent />}
      {!showLoading && <Box className={classes.massiveListViewContainer}>
        <Box className={classes.massiveListViewTableContainer}>
          {allMassives.count !== 0
            && <CustomTable
              headers={MASSIVE_UI}
              data={allMassives}
              reduxTableParameters={massivesFilters}
              setReduxTableParameters={setMassivesFilters}
            />}
          {allMassives.count === 0 && <EmptyTable />}
        </Box>
        <FeedbackMassiveModal
          title={'masivo'}
          modalAddEdit={modalAddMassive}
          setModalAddEdit={setModalAddMassive}
          itemsUI={MASSIVE_MODAL_UI}
          handleCloseModal={handleCloseModal}
          modalValues={modalValues}
          handleFieldChange={handleFieldChange}
          handleAddFunction={handleAddFunction}
        />
        <DeleteModal
          deleteFunction={handleDeleteFunction}
          handleCloseModal={handleDeleteCloseModal}
          modalDelete={modalDeleteMassive}
          setModalDelete={setModalDeleteMassive}
          title='masivo'
        />
      </Box>}
    </Page>
  );
}

export default MassiveListView;
