import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.background.main,
    margin: '0 0 20px',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  studentName: {
    fontSize: 'clamp(20px, 1.3vw, 40px)',
    fontWeight: 700,
    lineHeight: '30.48px',
    color: '#2C82B3',
    textAlign: 'left',
    marginLeft: 0,
  },
  studentInfo: {
    marginLeft: '30px',
    fontSize: 'clamp(18px, 1vw, 38px)',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginTop: '5px',
      width: '100%',
    },
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  disabledIcons: {
    color: theme.palette.action.disabled,
    fontSize: '26px',
  },
}));

export default useStyles;
