import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modal: {
    maxHeight: '90%',
    maxWidth: '890px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalDelete: {
    maxHeight: '290px',
    maxWidth: '690px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitleAndIcon: {
    display: 'flex',
  },
  modalTextfieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modalIndividualTextfield: {
    display: 'flex',
    minWidth: '50%',
    margin: '15px 0px',
    padding: '0px 10px',
  },
  modalButtonsContainer: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'end',
  },
  modalDeleteBody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  modalDeleteButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  modalFileUploadLabel: {
    width: '100%',
    display: 'flex',
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
}));

export default useStyles;
