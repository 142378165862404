import { ProgramData, SubjectData } from '../../Program/ProgramListView/types';
import { LocationsData } from '../../Section/SectionListView/types';

export type Bomb = {
  id: string,
  startHability: number,
  endHability: number,
  bombTests: { bombTestId: number }[],
  location: {
    name: string
  },
  locationId: number
  program: {
    id: number
    name: string
    subject: { name: string }
    subjectId: number
  },
  programId: number
  createdAt: Date
  updatedAt: Date
};

const BOMBS_UI: ({
  label: string;
  disabled?: boolean;
  key: string;
  showOnTable: boolean;
  showOnModal: boolean;
  typeField: string;
  valueType: string;
  selectFields?: LocationsData[] | SubjectData[] | ProgramData[] | undefined;
  valueToRetrieveFromSelect?: string;
  keyNameInObjectSent?: string;
  functionToApply?: (array: any[]) => JSX.Element[];
})[] = [
  {
    label: 'Fecha', key: 'createdAt', showOnTable: true, showOnModal: true, typeField: 'date', valueType: 'shortDate',
  },
  {
    label: 'Sede', key: 'locationName', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: [], valueType: 'string', keyNameInObjectSent: 'locationId', valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Asignatura', key: 'subjectName', showOnTable: true, showOnModal: false, typeField: 'select', selectFields: [], valueType: 'string', disabled: false,
  },
  {
    label: 'Programa', key: 'programName', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: [], valueType: 'string', keyNameInObjectSent: 'programId', valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Habilidad desde', key: 'startHability', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Habilidad hasta', key: 'endHability', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Número de guías', key: 'testsNumber', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Acciones', key: 'actions', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
];

const BOMB_MODAL_UI = [
  {
    label: 'Asignatura', key: 'subjectId', typeField: 'select', selectFields: [], valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Programa', key: 'programId', typeField: 'autocomplete', selectFields: [], valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Habilidad desde', key: 'startHability', typeField: 'number',
  },
  {
    label: 'Habilidad hasta', key: 'endHability', typeField: 'number',
  },
];

export {
  BOMBS_UI,
  BOMB_MODAL_UI,
};
