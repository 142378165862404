import {
  Box, ClassNameMap, IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import { useHistory, useParams } from 'react-router';
import { History } from 'history';
import {
  HistoryEdu as HistoryEduIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  SaveAs as SaveAsIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import SummaryTable from './Components/SummaryTable';
import useStyles from '../../styles';
import TestsTable from './Components/TestsTable';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import { downloadTest, getTestsInformationByStudentId } from '../../../../../requests/api/students';
import {
  DetailsDataResponse, SUMMARY_TABLE_UI, SummaryDataResponse, TESTS_TABLE_UI,
} from './types';
import CircularProgressComponent from '../../../../../components/Loading/CircularProgressComponent';

const seeSolutionActions = (
  classes: ClassNameMap<string>,
  handleDownload: (solutionaryUrl: string) => Promise<void>,
  solutionaryUrl: string,
) => (<Box>
  <IconButton disabled={!solutionaryUrl} onClick={() => handleDownload(solutionaryUrl)}>
    <FileDownloadIcon className={solutionaryUrl ? classes.icons : classes.disabledIcons} />
  </IconButton>
</Box>);

const buttonActions = ({
  classes,
  status,
  testName,
  programName,
  history,
  studentId,
  testId,
  isActive,
  studentOnlineEnabled,
}: {
  classes: ClassNameMap<string>,
  status: string,
  testName: string,
  programName: string,
  history: History,
  studentId: string,
  testId: number,
  isActive: boolean,
  studentOnlineEnabled: boolean | undefined,
}) => {
  let icon;
  if (!studentOnlineEnabled) {
    icon = <RemoveRedEyeIcon className={
      status === 'answered' ? classes.icons : classes.disabledIcons} />;
  } else {
    switch (status) {
      case 'answered':
        icon = <RemoveRedEyeIcon className={
          status === 'answered' ? classes.icons : classes.disabledIcons} />;
        break;
      case 'unanswered':
        icon = <HistoryEduIcon className={isActive ? classes.icons : classes.disabledIcons} />;
        break;
      case 'saved':
        icon = <SaveAsIcon className={classes.icons} />;
        break;
      default:
        icon = <RemoveRedEyeIcon className={classes.icons} />;
    }
  }

  const onClickFunc = (status === 'unanswered' && !isActive) ? undefined : () => history.push({ pathname: `/alumnos/${studentId}/guia/${testId}`, state: { testName, programName } });

  return (<Box>
    <IconButton disabled={((status === 'unanswered' && !isActive) || (status !== 'answered' && !studentOnlineEnabled))} onClick={onClickFunc}>
      {icon}
    </IconButton>
  </Box>);
};

function TestsListView({ studentOnlineEnabled }: { studentOnlineEnabled: boolean | undefined }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const { id: studentId } = useParams<{ id: string; }>();
  const [summaryData, setSummaryData] = useState<{
    count: number;
    values: SummaryDataResponse[]
  }>({ count: 0, values: [] });
  const [detailsData, setDetailsData] = useState<{
    count: number;
    values: DetailsDataResponse[]
  }>({ count: 0, values: [] });

  // test filters
  const [searchValue, setSearchValue] = useState('');
  const [programId, setProgramId] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [allPrograms, setAllPrograms] = useState<{ id: string, name: string }[]>([]);
  const [filteredData, setFilteredData] = useState<{
    count: number;
    values: DetailsDataResponse[]
  }>({ count: 0, values: [] });

  const handleDownload = async (solutionaryUrl: string) => {
    try {
      const { signedUrl } = await downloadTest(solutionaryUrl);

      // Attempt to fetch the resource directly for a Blob download
      fetch(signedUrl)
        .then((response) => {
          // Check if the response is ok (status in the range 200-299)
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          // Create a Blob URL for the file
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', 'solucionario.pdf'); // Set a desired filename here
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(blobUrl); // Clean up Blob URL
        })
        .catch((err) => {
          // If there is an error fetching or downloading, log or handle it
          console.error('Download failed', err);
          // Fallback to the original method or another error handling approach
          const link = document.createElement('a');
          link.href = signedUrl;
          link.target = '_blank'; // This will attempt to open in a new tab as fallback
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllTestsInformationFromStudent = async () => {
      try {
        const { testsSummary, testsDetails } = await getTestsInformationByStudentId(
          studentId, source.token,
        );
        const formattedTestSummary = testsSummary.map((elem: typeof summaryData.values[0]) => ({
          ...elem,
          numberOfDeliveries: `${elem.numberOfDeliveries} (${((elem.numberOfDeliveries / elem.numberOfTests) * 100).toFixed(2)}%)`,
          correctAnswers: `${Math.round(elem.correctAnswers * 10000) / 100}%`,
          wrongAnswers: `${Math.round(elem.wrongAnswers * 10000) / 100}%`,
          omittedAnswers: `${Math.round(elem.omittedAnswers * 10000) / 100}%`,
        }));

        const formattedTestDetails = testsDetails.map((elem: typeof detailsData.values[0]) => ({
          ...elem,
          lastUpdated: elem.lastUpdated,
          seeSolution: seeSolutionActions(classes, handleDownload, elem.solutionaryUrl),
          actions: buttonActions({
            classes,
            status: elem.status,
            testName: elem.testName,
            programName: elem.programName,
            history,
            studentId,
            testId: elem.id,
            isActive: elem.isActive,
            studentOnlineEnabled,
          }),
        }));

        setSummaryData({ count: formattedTestSummary.length, values: formattedTestSummary });
        setDetailsData({ count: formattedTestDetails.length, values: formattedTestDetails });
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllTestsInformationFromStudent();
    return () => {
      source.cancel();
    };
  }, [studentId]);

  // useEffect to get set student programs from detailsData
  useEffect(() => {
    const getStudentPrograms = async () => {
      try {
        const programs = detailsData.values
          .map((v: DetailsDataResponse) => ({ id: v.programId, name: v.programName }))
          .reduce((acc: { id: string; name: string }[], current: { id: string; name: string }) => {
            const duplicate = acc.find(
              (item) => item.id === current.id,
            );
            if (!duplicate) {
              acc.push(current);
            }
            return acc;
          }, []);

        setAllPrograms(programs);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getStudentPrograms();
  }, [detailsData]);

  useEffect(() => {
    // filter data
    let filtered = detailsData;
    if (programId) {
      const filteredByProgram = detailsData.values.filter(
        (v: DetailsDataResponse) => v.programId === programId,
      );

      filtered = { count: filteredByProgram.length, values: filteredByProgram };
    }

    if (searchValue) {
      // filter by searchValue on test name
      const filteredBySearch = filtered.values.filter(
        (v: DetailsDataResponse) => v.testName.toLowerCase().includes(searchValue.toLowerCase()),
      );

      filtered = { count: filteredBySearch.length, values: filteredBySearch };
    }

    setFilteredData(filtered);
  }, [detailsData, programId, searchValue]);

  return (
    <>
      {showLoading
        ? <CircularProgressComponent />
        : <Box>
          <SummaryTable data={summaryData} headers={SUMMARY_TABLE_UI} />
          <TestsTable
            data={filteredData}
            headers={TESTS_TABLE_UI}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            programId={programId}
            setProgramId={setProgramId}
            allPrograms={allPrograms}
          />
        </Box>}
    </>
  );
}

export default TestsListView;
