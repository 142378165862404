import React, { SyntheticEvent, useEffect, useState } from 'react';
import UpsertModal from '../../../../components/General/UpsertModal';
import { RowData } from '../types';

const TITLE = 'apoderados';

function EditModal(
  {
    allItems,
    modalEdit,
    setModalEdit,
    handleCloseModal,
    modalValues,
    setModalValues,
    handleFieldChange,
    itemsUI,
    editFunction,
  }: {
    allItems: { items: Array<RowData>, totalItems: number },
    modalEdit: { id: string, open: boolean },
    setModalEdit: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>,
    handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>) => void,
    modalValues: RowData,
    setModalValues: React.Dispatch<React.SetStateAction<RowData>>,
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File, source: string) => void,
    itemsUI: {
      label: string;
      key: string;
      showOnTable: boolean;
      showOnModal: boolean;
      typeField: string;
      selectFields?: object[];
      valueType: string;
    }[],
    editFunction: (id: string) => void,
  },
) {
  const [upsertFlag, setUpsertFlag] = useState(false);

  useEffect(() => {
    if (upsertFlag && modalEdit.id) {
      editFunction(modalEdit.id);
      setUpsertFlag(false);
    }
  }, [upsertFlag, modalEdit.id]);

  useEffect(() => {
    if (modalEdit.id !== '') {
      const auxSelectedItem = allItems.items.filter((elem) => elem.id === modalEdit.id);
      const selectedItem = auxSelectedItem[0];
      setModalValues({
        rut: selectedItem.rut,
        phoneNumber: selectedItem.phoneNumber,
        name: selectedItem.name,
        lastname: selectedItem.lastname,
        email: selectedItem.email,
        birthDate: selectedItem.birthDate || '',
        nationality: selectedItem.nationality || '',
        maritalStatus: selectedItem.maritalStatus || '',
        profession: selectedItem.profession || '',
        address: selectedItem.address || '',
        commune: selectedItem.commune || '',
      });
    }
  }, [modalEdit.id]);

  return (
    <UpsertModal<RowData>
      title={TITLE}
      modalAddEdit={modalEdit}
      setModalAddEdit={setModalEdit}
      handleCloseModal={handleCloseModal}
      modalValues={modalValues}
      handleFieldChange={handleFieldChange}
      itemsUI={itemsUI}
      handleAddFunction={() => setUpsertFlag(true)}
      handleEditFunction={() => setUpsertFlag(true)}
    />
  );
}

export default EditModal;
