import { Box } from '@mui/material';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AverageTestScoreBySubject } from '../types';

export const ColumnBarChart = ({ averageTestScoreBySubject }: {
  averageTestScoreBySubject: AverageTestScoreBySubject,
}) => {
  const categories: { [subject: string]: boolean } = {};
  const seriesPresencial: number[] = [];
  const seriesOnline: number[] = [];
  const seriesGeneral: number[] = [];
  if (Object.keys(averageTestScoreBySubject).length) {
    Object.keys(averageTestScoreBySubject).forEach((key) => {
      const data = averageTestScoreBySubject[key];
      if (data) {
        categories[key] = true;
        seriesPresencial.push(data.inPerson);
        seriesOnline.push(data.online);
        seriesGeneral.push(data.general);
      }
    });
  }
  const tripleColumnChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Promedio ensayos',
      align: 'left',
    },
    xAxis: {
      categories: Object.keys(categories),
    },
    tooltip: {
      valueSuffix: '%',
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Puntaje Promedio (%)',
      },
    },
    series: [
      {
        name: 'Presencial',
        data: seriesPresencial,
      },
      {
        name: 'Online',
        data: seriesOnline,
      },
      {
        name: 'General',
        data: seriesGeneral,
      },
    ],
  };
  return (
    <Box style={{ width: '33%' }}>
      <HighchartsReact highcharts={Highcharts} options={tripleColumnChartOptions} />
    </Box>
  );
};
