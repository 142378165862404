import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Settings as SettingsIcon,
  School as SchoolIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import {
  Box,
  ClassNameMap,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import { getSubjects } from '../../../requests/api/subjects';
import DeleteModal from '../../../components/General/DeleteModal';
import useStyles from './styles';
import UpsertTestModal from './Modals/UpsertTestModal';
import { TESTS_UI, TestData } from './types';
import { SubjectData } from '../../Program/ProgramListView/types';
import {
  deleteTest, getOptimarkTests, getTestsPerPage, postCreateTest,
} from '../../../requests/api/test';
import { downloadTest } from '../../../requests/api/students';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import { setTestsFilters } from '../../../actions/filtersActions';
import { ReduxState } from '../../../types';

const buttonActions = (
  classes: ClassNameMap<string>,
  testId: string,
  goToQuestionSettings: ({ id }: { id: string }) => void,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box>
  <IconButton onClick={() => goToQuestionSettings({ id: testId })}>
    <SettingsIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => openDeleteModal({ id: testId, open: true })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

const seeSolutionActions = (
  classes: ClassNameMap<string>,
  handleDownload: (solutionaryUrl: string | undefined) => Promise<void>,
  solutionaryUrl: string | undefined,
) => (<Box>
  <IconButton disabled={!solutionaryUrl} onClick={() => handleDownload(solutionaryUrl)}>
    <FileDownloadIcon className={solutionaryUrl ? classes.icons : classes.disabledIcons} />
  </IconButton>
</Box>);

function TableListView() {
  const classes = useStyles();
  const history = useHistory();
  const [allTests, setAllTests] = useState({
    items: [],
    totalItems: 0,
  });
  const [subjectObject, setSubjectObject] = useState<SubjectData>();
  const [testsUI, setTestsUI] = useState(TESTS_UI);
  const [modalAddEditATest, setModalAddEditATest] = useState(
    { id: '', open: false },
  );
  const [modalDeleteATest, setModalDeleteATest] = useState({ id: '', open: false });
  const [modalValues, setModalValues] = useState<TestData>({
    id: '',
    subjectId: '',
    subjectName: '',
    name: '',
    type: '',
    optimarkId: '',
    optimarkTest: {
      name: '',
      id: '',
      questions: '',
    },
    numberOfQuestions: 0,
    questionsInfo: undefined,
  });
  const [allSubjects, setAllSubjects] = useState<SubjectData[]>([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState({
    tests: false,
    subjects: false,
    optimarkTests: false,
  });

  const { enqueueSnackbar } = useSnackbar();
  const { testsFilters } = useSelector((state: ReduxState) => state.filters);
  const dispatch = useDispatch();

  const handleDownload = async (solutionaryUrl: string | undefined) => {
    if (solutionaryUrl) {
      try {
        const { signedUrl } = await downloadTest(solutionaryUrl);

        // Creating link to download file
        const link = document.createElement('a');
        link.href = signedUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllSubjects = async () => {
      try {
        const { subjects } = await getSubjects(source.token);
        const auxTestsUI = testsUI.map((elem) => {
          if (elem.label === 'Asignatura') {
            elem.selectFields = subjects;
          }
          return elem;
        });
        setTestsUI(auxTestsUI);
        setAllSubjects(subjects.map((s: SubjectData) => ({ id: s.id, name: s.name })));
        setSubjectObject(subjects.reduce((
          acc: SubjectData,
          val: SubjectData,
        ) => ({ ...acc, [val.id]: val.name }), {}));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setHasLoaded((prevState) => ({ ...prevState, subjects: true }));
      }
    };

    getAllSubjects();
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllOptimarkTests = async () => {
      try {
        const { optimarkTests } = await getOptimarkTests(source.token);
        const auxTestsUI = testsUI.map((elem) => {
          if (elem.label === 'Guía Optimark') {
            elem.selectFields = optimarkTests;
          }
          return elem;
        });
        setTestsUI(auxTestsUI);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setHasLoaded((prevState) => ({ ...prevState, optimarkTests: true }));
      }
    };

    getAllOptimarkTests();
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllTests = async () => {
      try {
        const { tests, totalTests } = await getTestsPerPage({
          page: testsFilters.page, limit: testsFilters.rowsPerPage, searchValue: testsFilters.searchValue, sort: 'createdAt|DESC', subjectId: testsFilters.subjectId,
        }, source.token);

        const testsAndActions = tests.map((
          elem: TestData & { numberOfDeliveries: string, lastUpdated: Date },
        ) => ({
          ...elem,
          subjectName: subjectObject && subjectObject[elem.subjectId as keyof SubjectData],
          numberOfDeliveries: elem.numberOfDeliveries ?? '-',
          lastUpdated: elem.lastUpdated,
          seeSolution: seeSolutionActions(classes, handleDownload, elem.solutionaryUrl),
          actions: buttonActions(
            classes,
            elem.id!,
            () => { history.push(`/guias/${elem.id}`); },
            setModalDeleteATest,
          ),
        }));
        setAllTests({ items: testsAndActions, totalItems: totalTests });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setHasLoaded((prevState) => ({ ...prevState, tests: true }));
      }
    };

    getAllTests();
    return () => {
      source.cancel();
    };
  }, [
    testsFilters.page,
    testsFilters.rowsPerPage,
    testsFilters.searchValue,
    subjectObject,
    updateTable,
    testsFilters,
  ]);

  useEffect(() => {
    if (Object.values(hasLoaded).every((elem) => elem === true)) {
      setShowLoading(false);
    }
  }, [
    hasLoaded.optimarkTests, hasLoaded.subjects, hasLoaded.tests,
  ]);

  const handleCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setModalValues({
      id: '',
      subjectId: undefined,
      subjectName: '',
      name: '',
      type: '',
      optimarkId: '',
      optimarkTest: {
        name: '',
        id: '',
        questions: '',
      },
      numberOfQuestions: 0,
    });
    setUpdateTable(!updateTable);
  };

  const addATest = async () => {
    try {
      let auxSubjectId;
      if (subjectObject) {
        auxSubjectId = Object
          .entries(subjectObject)
          .filter((elem) => elem[1] === modalValues.subjectName);
      } else {
        throw Error('Asignaturas no estan definidas');
      }

      if (!modalValues.questionsInfo) {
        enqueueSnackbar('Debes subir un archivo.', {
          variant: 'warning',
        });
        return;
      }

      const response = await postCreateTest({
        subjectId: auxSubjectId[0] && auxSubjectId[0][0],
        name: modalValues.name as string,
        type: modalValues.type,
        optimarkTest: modalValues.optimarkTest,
        numberOfQuestions: modalValues.numberOfQuestions,
      }, modalValues.questionsInfo);
      handleApiResponse(enqueueSnackbar, response, true);
      if (response.success) {
        const newTestId = response.newTest.id;
        history.push(`/guias/${newTestId}`);
      }
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const deleteATest = async (testId: string) => {
    try {
      const response = await deleteTest(testId);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalDeleteATest);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date | File,
    source: string, value?: { name: string, questions: number, id: string } | null,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (e instanceof File) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (e) {
      if (value && source === 'optimarkTest') {
        setModalValues(
          (prevState) => ({
            ...prevState,
            optimarkTest: value,
            numberOfQuestions: value.questions,
            optimarkId: value.id,
          }),
        );
      } else {
        setModalValues(
          (prevState) => ({ ...prevState, [source]: (e.target as HTMLInputElement).value }),
        );
      }
    }
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTestsFilters({ ...testsFilters, searchValue: e.target.value }));
    if (testsFilters.page !== 0) {
      dispatch(setTestsFilters({ ...testsFilters, page: 0 }));
    }
  };

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (!value) {
      dispatch(setTestsFilters({ ...testsFilters, subjectId: '' }));
    } else {
      const localValue = value as { name: string, id: string };
      dispatch(setTestsFilters({ ...testsFilters, subjectId: localValue.id }));
    }

    if (testsFilters.page !== 0) {
      dispatch(setTestsFilters({ ...testsFilters, page: 0 }));
    }
  };

  return (
    <Page
      title="Guías"
    >
      <Header
        title='Guías'
        icon={<SchoolIcon />}
        buttons={!showLoading ? [
          { text: 'Crear guía', icon: <AddIcon />, onClick: () => setModalAddEditATest((prevState) => ({ ...prevState, open: true })) },
        ] : undefined}
        search={!showLoading ? { text: 'Buscar una guía...', value: testsFilters.searchValue, onChangeSearchValue: handleChangeSearchValue } : undefined}
        autocompletes={!showLoading ? [
          {
            name: 'Asignatura',
            value: testsFilters.subjectId,
            onChange: handleFilterChange,
            options: [{ name: 'Todas', id: '' }, ...allSubjects],
            keyField: 'subjectId',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
        ] : undefined}
      />
      {showLoading ? <CircularProgressComponent /> : <Box
        mt={3}
      >

        {allTests.totalItems !== 0
          && <CustomTable
            headers={testsUI.filter((elem: { showOnTable: boolean }) => elem.showOnTable)}
            data={{ values: allTests.items, count: allTests.totalItems }}
            reduxTableParameters={testsFilters}
            setReduxTableParameters={setTestsFilters}
          />}
        {allTests.totalItems === 0 && <EmptyTable />}
      </Box>}
      <UpsertTestModal
        modalAddEdit={modalAddEditATest}
        setModalAddEdit={setModalAddEditATest}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        setModalValues={setModalValues}
        handleFieldChange={handleFieldChange}
        itemsUI={testsUI}
        addFunction={addATest}
      />
      <DeleteModal
        title={'guía'}
        modalDelete={modalDeleteATest}
        setModalDelete={setModalDeleteATest}
        handleCloseModal={handleCloseModal}
        deleteFunction={deleteATest}
      />
    </Page>
  );
}

export default TableListView;
