import { RequestsPerPage } from '../types';
import axios from '../../utils/axios';
import { Program } from '../../views/Program/ProgramListView/types';

const getProgramsPerPage = async ({
  page, limit, searchValue, sort, subjectId, teacherId,
}: RequestsPerPage) => {
  let url = `/api/programs?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`;
  if (subjectId) {
    url += `&subjectId=${subjectId}`;
  }

  if (teacherId) {
    url += `&teacherId=${teacherId}`;
  }

  const response = await axios.get(url);

  return response.data;
};

const getProgramById = async (id: number | string) => {
  const response = await axios.get(`/api/programs/${id}`);

  return response.data;
};

const postCreateProgram = async ({
  subjectId,
  name,
  level,
  classYear,
  startDate,
  endDate,
  active,
  fee,
  programContents,
}: Program) => {
  const response = await axios.post('/api/programs', {
    subjectId,
    name,
    level,
    classYear,
    startDate,
    endDate,
    active,
    fee,
    programContents,
  });

  return response.data;
};

const putUpdateProgram = async ({
  id,
  subjectId,
  name,
  level,
  classYear,
  startDate,
  endDate,
  active,
  fee,
  programContents,
}: Program) => {
  const response = await axios.put(`/api/programs/${id}`, {
    subjectId,
    name,
    level,
    classYear,
    startDate,
    endDate,
    active,
    fee,
    programContents,
  });

  return response.data;
};

const deleteProgram = async (programId: string) => {
  const response = await axios.delete(`/api/programs/${programId}`);

  return response.data;
};

const getProgramTestsByProgramId = async (programId: number | string) => {
  const response = await axios.get(`/api/program-detail/tests/${programId}`);

  return response.data;
};

const getProgramTestOverallInfo = async (programId: number | string) => {
  const response = await axios.get(`/api/program-detail/information/${programId}`);

  return response.data;
};

const getQuestionAnswersSummary = async (programId: number | string) => {
  const response = await axios.get(`/api/program-detail/question-answers/${programId}`);

  return response.data;
};

const putProgramTests = async ({
  programId,
  testId,
  number,
  deliverDate,
  programTestId,
  isActive,
}: {
  programId: string,
  testId: string,
  number: string,
  deliverDate: Date | string,
  programTestId: string,
  isActive: boolean,
}) => {
  const response = await axios.put(`/api/program-detail/${programId}`, {
    programTestId,
    programId,
    testId,
    number,
    deliverDate,
    isActive,
  });

  return response.data;
};

const getTestsBySubjectId = async (subjectId: number | string) => {
  const response = await axios.get(`/api/program-detail/tests-by-subject/${subjectId}`);

  return response.data;
};

const deleteProgramTest = async (programTestId: string) => {
  const response = await axios.delete(`/api/program-detail/${programTestId}`);

  return response.data;
};

export {
  getProgramsPerPage,
  getProgramById,
  putUpdateProgram,
  postCreateProgram,
  deleteProgram,
  getProgramTestsByProgramId,
  getProgramTestOverallInfo,
  getQuestionAnswersSummary,
  putProgramTests,
  getTestsBySubjectId,
  deleteProgramTest,
};
