import {
  Box,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Person } from '@mui/icons-material';
import useStyles from '../../../styles';
import EmptyTable from '../../../../../../components/General/EmptyTable';
import { ASSISTANCE_OPTIONS_UI, AssistanceDetailObj } from '../types';
import { putStudentClasses } from '../../../../../../requests/api/classes';
import handleApiResponse from '../../../../../../utils/handleApiResponse';
import { ReduxState } from '../../../../../../types';
import { ADMINISTRATOR, TEACHER } from '../../../../../../utils/user_types';
import Header from '../../../../../../components/Layout/Header';

const AssistancesTable = ({
  data,
  programId,
  setProgramId,
  allPrograms,
  setUpateOnNewInformation,
}: {
  data: { count: number; values: AssistanceDetailObj[] },
  programId: string,
  setProgramId: (id: string) => void,
  allPrograms: { id: string, name: string }[],
  setUpateOnNewInformation: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const account = useSelector((state: ReduxState) => state.account);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    if (setTableParameters) {
      setTableParameters((prevState) => ({ ...prevState, page: newPage }));
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setTableParameters) {
      setTableParameters({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    }
  };

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (!value) {
      setProgramId('');
    } else {
      setProgramId((value as { name: string, id: string }).id);
    }
  };

  const handleAssistanceChange = async (
    event: React.ChangeEvent<HTMLInputElement>, row: AssistanceDetailObj,
  ) => {
    const assistance = event.target.value;
    try {
      const response = await putStudentClasses({
        studentClasses: [{
          studentClassId: row.studentClassId,
          studentId: row.studentId,
          studentName: row.studentName,
          studentLastname: row.studentLastname,
          studentRut: row.studentRut,
          teacherNames: row.teacherNames,
          classId: row.classId,
          attended: assistance !== 'absent',
          arrivedLate: assistance === 'late',
        }],
        classDetails: {
          date: row.date,
          program: row.programName!,
          section: row.mipreuId,
        },
      });
      if (response.success) {
        handleApiResponse(enqueueSnackbar, response, true);
        setUpateOnNewInformation((prevState) => !prevState);
      }
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const attendanceValue = (attended: boolean, arrivedLate: boolean) => {
    if (attended) {
      if (arrivedLate) {
        return 'late';
      }
      return 'present';
    }
    return 'absent';
  };

  const showSelectAssistance = account?.user
    && (account.user!.userType === ADMINISTRATOR
      || account.user!.userType === TEACHER);

  return (
    <Box className={classes.contractViewProgramsContainer}>
      <Header
        title='Asistencias'
        icon={<Person />}
        buttons={[]}
        autocompletes={[
          {
            name: 'Programa',
            value: programId,
            onChange: handleFilterChange,
            options: [{ name: 'Todos', id: '' }, ...allPrograms],
            keyField: 'programId',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
        ]}
      />
      {data.count !== 0
        && <Box sx={{ marginTop: '30px' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Programa</TableCell>
                  <TableCell>ID Sección</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Dia y Hora</TableCell>
                  <TableCell>Profesor</TableCell>
                  <TableCell>Asistencia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.values.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell>{row.programName}</TableCell>
                    <TableCell>{row.mipreuId}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell><span>{row.scheduledTime.weekDay}</span><br /><span>{`${row.scheduledTime.startTime.slice(0, 5)} hrs - ${row.scheduledTime.endTime.slice(0, 5)} hrs`}</span></TableCell>
                    <TableCell>{row.teacherNames.map((elem, index: number) => (
                      <React.Fragment key={index}>
                        {`${elem}`}
                        {index < row.teacherNames.length - 1 && <br />}
                      </React.Fragment>
                    ))}</TableCell>
                    <TableCell>
                      {showSelectAssistance
                        && <TextField
                          label="Asistencia"
                          select
                          defaultValue={'present'}
                          value={attendanceValue(row.attended, row.arrivedLate)}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => handleAssistanceChange(event, row)}
                          fullWidth
                          sx={{
                            maxWidth: 200,
                          }}
                        >
                          {ASSISTANCE_OPTIONS_UI.map((option) => (
                            <MenuItem key={option.name} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>}
                      {!showSelectAssistance
                        && (ASSISTANCE_OPTIONS_UI
                          .find((elem) => elem.value === attendanceValue(
                            row.attended, row.arrivedLate,
                          ))?.name || 'Presente')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    Total: {data.count}
                  </TableCell>
                  <TablePagination
                    count={data.count}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    page={tableParameters.page}
                    rowsPerPage={tableParameters.rowsPerPage}
                    labelRowsPerPage="Datos por página:"
                    labelDisplayedRows={() => { }}
                  />
                </TableRow>
              </TableFooter>
            </Table>

          </TableContainer>
        </Box>}
      {data.count === 0
        && <Box sx={{ marginTop: '30px' }}><EmptyTable /></Box>}
    </Box>
  );
};

export default AssistancesTable;
