import { Box, ClassNameMap, IconButton } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import { format } from 'rut.js';
import { useSelector } from 'react-redux';
import CustomTable from '../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../components/General/EmptyTable';
import CustomButton from '../../../../../components/General/CustomButton';
import AssociateRepresentativeModal from './Components/AssociateRepresentativeModal';
import {
  deleteStudentRepresentativeAssociation,
  getStudentRepresentativesByStudentId,
  putRepresentativeStudentAndAssociation,
} from '../../../../../requests/api/students';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import useStyles from '../../styles';

import {
  RELATION_DB_TO_UI_EQ,
  RELATION_TO_STUDENT_OPTIONS,
  RELATION_UI_TO_DB_EQ,
  StudentRepresentative,
  StudentRepresentativeData,
} from './types';
import DeleteModal from '../../../../../components/General/DeleteModal';
import UpsertRepresentativeModal from './Components/UpsertRepresentativeModal';
import { postCreateRepresentative } from '../../../../../requests/api/representatives';
import { ReduxState } from '../../../../../types';
import { COMMUNES, NATIONALITIES, MARITAL_STATUS } from '../../../../types';
import {
  ADMINISTRATOR,
  REPRESENTATIVE,
  STUDENT,
  TEACHER,
} from '../../../../../utils/user_types';
import CircularProgressComponent from '../../../../../components/Loading/CircularProgressComponent';

const excludedRoles = [TEACHER, STUDENT, REPRESENTATIVE];

const STUDENT_REPRESENTATIVE_UI = [
  {
    label: 'Nombre', key: 'name', valueType: 'string', showOnTable: true, showOnModal: true, typeField: 'text',
  },
  {
    label: 'Apellidos', key: 'lastname', valueType: 'string', showOnTable: true, showOnModal: true, typeField: 'text',
  },
  {
    label: 'Rut', key: 'rut', valueType: 'string', showOnTable: true, showOnModal: true, typeField: 'text',
  },
  {
    label: 'Teléfono', key: 'phoneNumber', valueType: 'string', showOnTable: true, showOnModal: true, typeField: 'text',
  },
  {
    label: 'Email', key: 'email', valueType: 'string', showOnTable: true, showOnModal: true, typeField: 'text',
  },
  {
    label: 'Parentesco', key: 'relationToStudent', valueType: 'string', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: RELATION_TO_STUDENT_OPTIONS,
  },
  {
    label: 'Fecha de nacimiento', key: 'birthDate', showOnTable: true, showOnModal: true, typeField: 'date', valueType: 'shortDate',
  },
  {
    label: 'Nacionalidad', key: 'nationality', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: NATIONALITIES,
  },
  {
    label: 'Estado civil', key: 'maritalStatus', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: MARITAL_STATUS,
  },
  {
    label: 'Profesión', key: 'profession', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Dirección', key: 'address', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Comuna', key: 'commune', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: COMMUNES,
  },
  {
    label: 'Acciones', key: 'actions', valueType: 'string', showOnTable: true, showOnModal: false, typeField: 'other', excludedRoles,
  },
];

const buttonActions = (
  classes: ClassNameMap<string>,
  studentRepresentativeId: string,
  representativeId: string,
  openEditModal: ({ open, id }: { open: boolean, id: string }) => void,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box>
  <IconButton onClick={() => openEditModal({ id: representativeId, open: true })}>
    <EditIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => openDeleteModal({ id: studentRepresentativeId, open: true })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

function RepresentativeView() {
  const classes = useStyles();
  const { id: studentId } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [allAssociatedRepresentatives, setAllAssociatedRepresentatives] = useState({
    items: [],
    totalItems: 0,
  });
  const [openAssociateModal, setOpenAssociateModal] = useState(false);
  const [modalEditAssociation, setModalEditAssociation] = useState({
    open: false,
    id: '',
  });
  const [modalDeleteAssociation, setModalDeleteAssociation] = useState({
    open: false,
    id: '',
  });
  const [modalValues, setModalValues] = useState<StudentRepresentativeData>({
    userId: '',
    rut: '',
    phoneNumber: '',
    name: '',
    lastname: '',
    email: '',
    relationToStudent: '',
    birthDate: '',
    nationality: '',
    maritalStatus: '',
    profession: '',
    address: '',
    commune: '',
  });
  const [updateTable, setUpdateTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  const account = useSelector((state: ReduxState) => state.account);
  const handleCloseAssociateModal = () => {
    setOpenAssociateModal(false);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllStudentRepresentatives = async () => {
      try {
        const { studentRepresentatives } = await getStudentRepresentativesByStudentId(
          studentId, source.token,
        );
        setAllAssociatedRepresentatives({
          items: studentRepresentatives.map((elem: StudentRepresentative) => ({
            id: elem.representativeId, // id from representative. Used to edit in modalEdit
            name: elem.representative.user.name,
            lastname: elem.representative.user.lastname,
            rut: format(elem.representative.rut),
            phoneNumber: elem.representative.phoneNumber,
            email: elem.representative.user.email,
            birthDate: elem.representative.birthDate || '',
            nationality: elem.representative.nationality || '',
            maritalStatus: elem.representative.maritalStatus || '',
            profession: elem.representative.profession || '',
            address: elem.representative.address || '',
            commune: elem.representative.commune || '',
            relationToStudent: RELATION_DB_TO_UI_EQ.get(elem.relationToStudent) as string,
            actions: buttonActions(
              classes,
              elem.id,
              elem.representativeId,
              setModalEditAssociation,
              setModalDeleteAssociation,
            ),
          })),
          totalItems: studentRepresentatives.length,
        });
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllStudentRepresentatives();
    return () => {
      source.cancel();
    };
  }, [updateTable, openAssociateModal, studentId]);

  const upsertRow = async (representativeId?: string) => {
    try {
      let response;
      if (representativeId) {
        response = await putRepresentativeStudentAndAssociation({
          studentId,
          representativeId,
          relationToStudent: RELATION_UI_TO_DB_EQ.get(modalValues.relationToStudent) as string,
          rut: modalValues.rut,
          phoneNumber: modalValues.phoneNumber,
          name: modalValues.name,
          lastname: modalValues.lastname,
          email: modalValues.email,
          birthDate: modalValues.birthDate,
          nationality: modalValues.nationality,
          maritalStatus: modalValues.maritalStatus,
          profession: modalValues.profession,
          address: modalValues.address,
          commune: modalValues.commune,
        });
      } else {
        response = await postCreateRepresentative({
          rut: modalValues.rut,
          phoneNumber: modalValues.phoneNumber,
          name: modalValues.name,
          lastname: modalValues.lastname,
          email: modalValues.email,
          birthDate: modalValues.birthDate,
          nationality: modalValues.nationality,
          maritalStatus: modalValues.maritalStatus,
          profession: modalValues.profession,
          address: modalValues.address,
          commune: modalValues.commune,
        });
      }

      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalEditAssociation);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const deleteRow = async (studentRepresentativeId: string) => {
    try {
      const response = await deleteStudentRepresentativeAssociation(studentRepresentativeId);
      handleApiResponse(enqueueSnackbar, response, true);
      setModalDeleteAssociation({ id: '', open: false });
      setUpdateTable(!updateTable);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File,
    source: string,
    value?: string | object | null,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (!(e instanceof File)) {
      let newValue: string | number | { id: number, name: string };
      if (source === 'commune' || source === 'maritalStatus' || source === 'nationality') {
        if (!value) {
          newValue = '';
          return;
        }
        const { name } = value as { id: number, name: string };
        newValue = name;
      } else {
        newValue = (e.target as HTMLInputElement).value;
      }

      setModalValues(
        (prevState) => ({ ...prevState, [source]: newValue }),
      );
    }
  };

  const handleCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setModalValues({
      userId: '',
      rut: '',
      phoneNumber: '',
      name: '',
      lastname: '',
      email: '',
      relationToStudent: '',
      birthDate: '',
      nationality: '',
      maritalStatus: '',
      profession: '',
      address: '',
      commune: '',
    });
    setUpdateTable(!updateTable);
  };

  return (
    <>
      {showLoading
        ? <CircularProgressComponent />
        : <>
          {(!excludedRoles.includes(account.user?.userType || ADMINISTRATOR))
            && <Box mt={3} width={'100%'} display="flex" justifyContent="end">
              <CustomButton
                colorType='tertiary'
                text='Asociar apoderado'
                icon={<AddIcon />}
                onClick={() => setOpenAssociateModal(true)}
              />
            </Box>
          }
          <Box
            mt={3}
          >
            {allAssociatedRepresentatives.totalItems !== 0
              && <CustomTable
                userType={account.user?.userType || ADMINISTRATOR}
                headers={STUDENT_REPRESENTATIVE_UI}
                data={{
                  values: allAssociatedRepresentatives.items,
                  count: allAssociatedRepresentatives.totalItems,
                }}
              />}
            {allAssociatedRepresentatives.totalItems === 0 && <EmptyTable />}
          </Box>
        </>}

      <AssociateRepresentativeModal
        representativesAlreadyAssociated={allAssociatedRepresentatives
          .items.map((elem: StudentRepresentative) => elem.id)}
        studentId={studentId}
        openAssociateModal={openAssociateModal}
        handleCloseAssociateModal={handleCloseAssociateModal}
        openAddModal={modalEditAssociation}
        setOpenAddModal={setModalEditAssociation}
      />
      <UpsertRepresentativeModal
        allItems={allAssociatedRepresentatives}
        modalEdit={modalEditAssociation}
        setModalEdit={setModalEditAssociation}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        setModalValues={setModalValues}
        handleFieldChange={handleFieldChange}
        itemsUI={STUDENT_REPRESENTATIVE_UI}
        upsertFunction={upsertRow}
      />
      <DeleteModal
        title={'asociación apoderado'}
        modalDelete={modalDeleteAssociation}
        setModalDelete={setModalDeleteAssociation}
        handleCloseModal={handleCloseModal}
        deleteFunction={deleteRow}
        message={'¿Estas seguro que quieres eliminar esta asociación con el apoderado?'}
      />
    </>
  );
}

export default RepresentativeView;
