import React from 'react';
import {
  Box, Card, CardContent, IconButton, Modal, Theme, Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CustomButton from './CustomButton';

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modal: {
    maxHeight: '920px',
    maxWidth: '890px',
    width: '100%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalDelete: {
    maxHeight: '290px',
    maxWidth: '690px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitleAndIcon: {
    display: 'flex',
  },
  modalDeleteBody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  modalDeleteButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
}));

function DeleteModal({
  title,
  modalDelete,
  setModalDelete,
  handleCloseModal,
  deleteFunction,
  message,
}: {
  title: string,
  modalDelete: { id: string, open: boolean },
  setModalDelete: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>,
  handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => void,
  deleteFunction: (id: string) => void,
  message?: string;
}) {
  const classes = useStyles();
  return (
    <Modal
      open={modalDelete.open}
      onClose={() => handleCloseModal(setModalDelete)}
    >
      <Box className={classes.modalContainer}>
        <Card className={`${classes.modal} ${classes.modalDelete}`}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon} >
                <DeleteIcon />
                <Typography
                  variant="h3"
                  fontWeight='bold'
                >
                  {`Eliminar ${title}`}
                </Typography>
              </Box>
              <IconButton onClick={() => handleCloseModal(setModalDelete)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.modalDeleteBody}>
              {message && <Typography
                variant='body1'
                textAlign='center'
              >
                {message}
              </Typography>}
              {!message
                && <>
                  <Typography
                    variant='body1'
                    fontWeight='bold'
                    textAlign='center'
                  >{`¿Estas seguro que quieres eliminar este ${title}?`}</Typography>
                  <Typography
                    variant='body1'
                    textAlign='center'
                  >
                    {`Una vez que presiones el botón azul, este ${title}
              será eliminado y no podrás recuperarlo.`}
                  </Typography>
                </>}

            </Box>
            <Box className={classes.modalDeleteButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={() => handleCloseModal(setModalDelete)} key={`CancelButtonDelete${title}`} icon={<CloseIcon />} />
              <CustomButton text='Eliminar' colorType='primary' onClick={() => deleteFunction(modalDelete.id)} key={`AcceptButtonDelete${title}`} icon={<DeleteIcon />} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default DeleteModal;
