import React, { useEffect, useState } from 'react';
import {
  Redirect, useHistory, useLocation, useParams,
} from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getStudentById, getStudentsFromRepresentative } from '../../../requests/api/students';
import StudentDetailHeader from './Components/StudentDetailHeader';
import { StudentData } from '../StudentListView/types';
import StudentDetailNavbar from './Components/StudentDetailNavbar';
import ContractListView from './Views/ContractView/ContractListView';
import SectionsListView from './Views/SectionsView/SectionsListView';
import FeedbacksListView from './Views/FeedbacksView/FeedbacksListView';
import AssistancesView from './Views/AssistancesView/AssistancesListView';
import TestsListView from './Views/TestsView/TestsListView';
import { ReduxState } from '../../../types';
import { REPRESENTATIVE, STUDENT, TEACHER } from '../../../utils/user_types';
import VideosListView from './Views/VideosView/VideosListView';
import RepresentativeView from './Views/RepresentativeView/RepresentativeView';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';

interface LocationState {
  activeTab: string;
  studentsUnderRepresentative: number[],
}

function StudentDetailView() {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [student, setStudent] = useState<StudentData>();
  const [activeTab, setActiveTab] = useState(0);
  const [updateStudentTrigger, setUpdateStudentTrigger] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [studentsFromRepresentatives, setStudentsFromRepresentatives] = useState<StudentData[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const account = useSelector((state: ReduxState) => state.account);
  const isStudent = account?.user && account.user!.userType === STUDENT;
  const isTeacher = account?.user && account.user!.userType === TEACHER;

  useEffect(() => {
    const getSpecificStudent = async () => {
      if (account.user && account.user.userType === REPRESENTATIVE) {
        try {
          const { students } = await getStudentsFromRepresentative(account.user.userTypeId);
          const allStudentInformation = students.map((elem: {
            student: StudentData
          }) => ({
            ...elem,
            name: elem.student.user!.name,
            lastname: elem.student.user!.lastname,
            email: elem.student.user!.email,
            phoneNumber: elem.student.phoneNumber,
            rut: elem.student.rut,
            onlineEnabled: elem.student.onlineEnabled,
            student: elem.student,
          }));
          setStudentsFromRepresentatives(allStudentInformation);
          setStudent(allStudentInformation.find((s: {
            studentId: number,
          }) => s.studentId.toString() === id));
        } catch (err) {
          const e = err as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false);
        }
      } else {
        try {
          const { student: relevantStudent } = await getStudentById(id);

          const allStudentInformation = {
            ...relevantStudent,
            name: relevantStudent.user && relevantStudent.user.name,
            lastname: relevantStudent.user && relevantStudent.user.lastname,
            email: relevantStudent.user && relevantStudent.user.email,
          };

          setStudent(allStudentInformation);
        } catch (err) {
          const e = err as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false);
        }
      }
      setShowLoading(false);
    };
    getSpecificStudent();
  }, [id, updateStudentTrigger]);

  useEffect(() => {
    if (location?.state?.activeTab) {
      setActiveTab(parseInt(location.state.activeTab, 10));
    }

    if (location.search) {
      // Redirection from the email to see assistances
      const queryParams = new URLSearchParams(location.search);
      const redirection = queryParams.get('redirection');
      if (redirection) {
        history.replace({ pathname: `/alumnos/${id}`, state: { activeTab: 4 } });
      }
    }
  }, [location]);

  useEffect(() => {
    if (isStudent || isTeacher) {
      setActiveTab(1);
    }
  }, [isStudent, isTeacher]);

  const tabToShow = () => {
    switch (activeTab) {
      case 0:
        if (!(isStudent || isTeacher)) {
          return <ContractListView />;
        }
        return <></>;
      case 1:
        return <SectionsListView
          studentName={(student && student.name) ?? ''}
          studentLastname={(student && student.lastname) ?? ''}
        />;
      case 2:
        return <TestsListView studentOnlineEnabled={student?.onlineEnabled} />;
      case 3:
        return <FeedbacksListView />;
      case 4:
        return <AssistancesView />;
      case 5:
        return <VideosListView />;
      case 6:
        return <RepresentativeView />;
      default:
        return <ContractListView />;
    }
  };

  //  Alumno no puede ver perfil de otros alumnos
  if (account.user && isStudent
    && account.user.userTypeId.toString() !== id) {
    return <Redirect to={`/alumnos/${account.user.userTypeId}`} />;
  }

  // Apoderado no puede ver perfil de otros alumnos que estan bajo su tutela
  if (account?.user
    && account.user!.userType === REPRESENTATIVE) {
    if (studentsFromRepresentatives.length === 0) {
      return <h1>No tienes alumnos asociados</h1>;
    }

    const auxStudentsIds = studentsFromRepresentatives
      .map((elem: StudentData) => Number(elem.studentId));

    if (!auxStudentsIds.includes(Number(id))) {
      return <Redirect to={`/alumnos/${auxStudentsIds[0]}`} />;
    }
  }

  return (
    <Page title="Alumnos">
      {showLoading
        ? <CircularProgressComponent />
        : <>
          {account.user!.userType !== REPRESENTATIVE && student && <StudentDetailHeader
            student={student}
            setUpdateStudentTrigger={setUpdateStudentTrigger}
            userType={(account?.user && account.user!.userType) ?? ''}
          />}
          {account.user!.userType === REPRESENTATIVE && student
            && studentsFromRepresentatives && studentsFromRepresentatives.length > 0
            && <StudentDetailHeader
              student={student}
              students={studentsFromRepresentatives}
              setUpdateStudentTrigger={setUpdateStudentTrigger}
              userType={(account?.user && account.user!.userType) ?? ''}
            />}
          <StudentDetailNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
          {tabToShow()}
        </>}
    </Page>
  );
}

export default StudentDetailView;
