import { CancelToken } from 'axios';
import { RequestsPerPage } from '../types';
import axios from '../../utils/axios';
import { StudentClass } from '../../views/Class/ClassListView/types';

const getClassesPerPage = async ({
  page, limit, searchValue, teacherId, registered, sectionId,
}: RequestsPerPage & {
  teacherId: string | undefined, sectionId: string | undefined
}, cancelToken?: CancelToken) => {
  let url = `/api/classes?page=${page}&limit=${limit}&searchValue=${searchValue}`;
  if (teacherId) {
    url += `&teacherId=${teacherId}`;
  }

  if (registered !== null) {
    url += `&registered=${registered}`;
  }

  if (sectionId) {
    url += `&sectionId=${sectionId}`;
  }

  const response = await axios.get(url, { cancelToken });

  return response.data;
};

const getClassByClassId = async ({ classId }: { classId: string }) => {
  const response = await axios.get(`/api/classes/${classId}`);

  return response.data;
};

const getStudentsInClassPerPage = async ({
  page, limit, classId, lastAssistanceUpdate,
}: RequestsPerPage & { classId: string, lastAssistanceUpdate: Date | undefined }) => {
  const response = await axios.get(`/api/student-classes?page=${page}&limit=${limit}&classId=${classId}&lastAssistanceUpdate=${lastAssistanceUpdate}`);

  return response.data;
};

const postStudentClasses = async ({ studentClasses, classDetails }: {
  studentClasses: StudentClass[],
  classDetails: {
    date: string,
    program: string,
    section: string,
  },
}) => {
  const response = await axios.post('/api/student-classes', {
    studentClasses,
    classDetails,
  });

  return response.data;
};

const putStudentClasses = async ({ studentClasses, classDetails }: {
  studentClasses: StudentClass[],
  classDetails: {
    date: string,
    program: string,
    section: string,
  },
}) => {
  const response = await axios.put('/api/student-classes', {
    studentClasses,
    classDetails,
  });

  return response.data;
};

const deleteAssistanceStudentClasses = async (classId: string) => {
  const response = await axios.delete(`/api/student-classes/${classId}`);

  return response.data;
};

export {
  getClassesPerPage,
  getClassByClassId,
  getStudentsInClassPerPage,
  postStudentClasses,
  putStudentClasses,
  deleteAssistanceStudentClasses,
};
