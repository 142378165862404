import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  ClassNameMap,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { PlayCircle as PlayCircleIcon, Search as SearchIcon } from '@mui/icons-material';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import CustomTable from '../../../../../components/General/CustomTable';
import useStyles from '../../styles';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import { getContentsByStudentId, getFeedbacksProgramsByStudentId } from '../../../../../requests/api/students';
import EmptyTable from '../../../../../components/General/EmptyTable';
import { CustomAutocompleteTextField } from '../../../../../components/General/CustomTextfields';
import CircularProgressComponent from '../../../../../components/Loading/CircularProgressComponent';

type StudentContents = {
  id: number;
  programId: number;
  program: {
    id: number;
    name: string;
    subject: {
      id: number;
      name: string;
    };
    programContents: {
      id: number;
      name: string;
      number: number;
      url: string;
    }[];
  };
};

type TableContent = {
  number: number;
  name: string;
  programName: string;
  subjectName: string;
  actions: JSX.Element
};

const VIDEOS_TABLE_UI = [
  {
    label: 'Número', key: 'number', valueType: 'string',
  },
  {
    label: 'Nombre', key: 'name', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Descargar contenidos', key: 'actions', valueType: 'other',
  },
];

const buttonActions = (
  classes: ClassNameMap<string>,
  urlToDownload: string,
) => (
  <Box>
    <a href={urlToDownload} download target="_blank" rel="noreferrer" >
      <IconButton aria-label="download">
        <PlayCircleIcon className={classes.icons} />
      </IconButton>
    </a>
  </Box>
);

function VideosListView() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [videoContent, setVideoContent] = useState<{ count: number, values: TableContent[] }>(
    { count: 0, values: [] },
  );
  const [allPrograms, setAllPrograms] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { id: studentId } = useParams<{ id: string; }>();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllInformationFromStudent = async () => {
      try {
        const { studentContents } = await getContentsByStudentId(
          studentId, searchValue, filterValue, source.token,
        );
        const auxStudentContents: Array<Array<TableContent>> = [];

        studentContents.forEach((stdProgramContent: StudentContents) => {
          if (stdProgramContent.program.programContents) {
            const auxContents = stdProgramContent.program.programContents.map((prgmContent) => ({
              number: prgmContent.number,
              name: prgmContent.name,
              programName: stdProgramContent.program.name,
              subjectName: stdProgramContent.program.subject.name,
              actions: buttonActions(classes, prgmContent.url),
            }));

            auxStudentContents.push(auxContents);
          }
        });
        setVideoContent({
          count: auxStudentContents.flat().length,
          values: auxStudentContents.flat(),
        });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      try {
        const { programs } = await getFeedbacksProgramsByStudentId(studentId);
        setAllPrograms(programs);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      setShowLoading(false);
    };

    getAllInformationFromStudent();
    return () => {
      source.cancel();
    };
  }, [searchValue, filterValue, studentId]);

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    setFilterValue((value as { id: number, name: string })?.id.toString() ?? '');
  };

  return (
    <>
      {showLoading
        ? <CircularProgressComponent />
        : <Box className={classes.videoListViewContainer}>
          <Box className={classes.videoListViewHeader}>
            <Box className={classes.videoListViewTextfieldContainer}>
              <CustomAutocompleteTextField
                keyField='programId'
                name='Filtrar por programa'
                onChangeFunction={handleFilterChange}
                selectFields={allPrograms}
                value={filterValue}
                valueToRetrieveFromSelect='id'
                size='small'
              />
            </Box>
            <Box className={classes.videoListViewTextfieldContainer}>
              <TextField
                size='small'
                InputProps={{ startAdornment: (<InputAdornment position='start'><SearchIcon color='primary' /></InputAdornment>) }}
                variant='outlined'
                placeholder={'Buscar contenido...'}
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(
                  e.target.value,
                )}
              />
            </Box>
          </Box>
          <Box className={classes.videoListViewTableContainer}>
            {videoContent.count !== 0
              && <CustomTable
                headers={VIDEOS_TABLE_UI}
                data={videoContent}
              />}
            {videoContent.count === 0 && <EmptyTable />}
          </Box>
        </Box>}

    </>

  );
}

export default VideosListView;
