import React from 'react';
import {
  Box,
  Hidden,
  Icon,
  Typography,
} from '@mui/material';
import {
  Print as PrintIcon,
  AutoStories as AutoStoriesIcon,
  LocalLibrary as LocalLibraryIcon,
} from '@mui/icons-material';

import useStyles from '../styles';

const NAVBAR_UI = [{
  icon: <PrintIcon />,
  title: 'Totales',
}, {
  icon: <AutoStoriesIcon />,
  title: 'Secciones',
}, {
  icon: <LocalLibraryIcon />,
  title: 'Alumnos',
}];

function BombDetailNavbar({
  activeTab,
  setActiveTab,
}: {
  activeTab: number,
  setActiveTab: React.Dispatch<React.SetStateAction<number>>,
}) {
  const classes = useStyles();

  const NavBarDetail = ({
    icon,
    title,
    index,
  }: {
    icon: JSX.Element,
    title: string,
    index: number,
  }) => {
    let relevantClassnameActive = null;
    if (activeTab === index) {
      relevantClassnameActive = classes.navbarDetailNavItemActive;
    } else {
      relevantClassnameActive = classes.navbarDetailNavItemInactive;
    }

    return (<Box
      className={`${classes.navBarDetailContainer} ${relevantClassnameActive}`}
      onClick={() => setActiveTab(index)}
    >
      <Icon>
        {icon}
      </Icon>
      <Hidden mdDown >
        <Typography
          variant='h5'
          fontWeight='bold'
          marginLeft='5px'
        >
          {title}
        </Typography>
      </Hidden>
    </Box>);
  };

  return (
    <Box className={classes.bombDetailNavbar}>
      {NAVBAR_UI.map((elem, index) => <NavBarDetail
        key={elem.title}
        title={elem.title}
        icon={elem.icon}
        index={index}
      />)}
    </Box>
  );
}

export default BombDetailNavbar;
