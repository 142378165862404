import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';

import handleApiResponse from '../../../../../utils/handleApiResponse';
import { getBombSkillsBySection } from '../../../../../requests/api/bombs';
import { getAllSections } from '../../../../../requests/api/sections';

import Header from '../../../../../components/Layout/Header';
import CustomTable from '../../../../../components/General/CustomTable';

import { SECTION_BOMBS_UI, SectionBombSkill } from './types';

function SectionsListView() {
  const { enqueueSnackbar } = useSnackbar();
  const { id: bombId } = useParams<{ id: string }>();

  const [sectionBombSkills, setSectionBombSkills] = useState<SectionBombSkill[]>([]);
  const [sectionId, setSectionId] = useState<string>('');
  const [allSections, setAllSections] = useState<{ id: string, name: string }[]>([]);

  useEffect(() => {
    const getSections = async () => {
      try {
        const { sections } = await getAllSections();

        setAllSections(sections);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getSections();
  }, []);

  useEffect(() => {
    const getBombSkills = async () => {
      try {
        const { sectionBombs } = await getBombSkillsBySection({ bombId, sectionId });

        setSectionBombSkills(sectionBombs);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getBombSkills();
  }, [bombId, sectionId]);

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (!value) {
      setSectionId('');
    } else {
      setSectionId((value as { name: string, id: string }).id);
    }
  };

  return (
    <Box mt={3}>
      <Header
        title=''
        buttons={[]}
        autocompletes={[
          {
            name: 'Sección',
            value: `${sectionId}`,
            onChange: handleFilterChange,
            options: [{ name: 'Todas', id: '' }, ...allSections],
            keyField: 'sectionId',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
        ]}
      />
      <Box mt={3}>
        <CustomTable
          headers={SECTION_BOMBS_UI}
          data={{ values: sectionBombSkills, count: sectionBombSkills.length }}
        />
      </Box>
    </Box>
  );
}

export default SectionsListView;
