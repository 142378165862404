import React from 'react';
import { ProgramData, SubjectData } from '../../Program/ProgramListView/types';

type TimeSlot = {
  id: string;
  startTime: string;
  endTime: string;
  weekDay: string;
};

type SpecificTimeSlot = {
  isBeingModified?: boolean;
  id: string;
  startTime: string;
  endTime: string;
  weekDay: string;
};

type Teacher = {
  id: string;
  userId: string;
  fullName: string;
  rut: string;
  phoneNumber: string;
  birthDate: Date;
  moduleFee: number;
  role: string;
  user?: { id: string; fullName: string; }
};

type DropdownTeacherInfo = {
  role: string;
  id: string;
  fullName?: string;
};

type SpecificDropdownTeacherInfo = DropdownTeacherInfo & {
  isBeingModified: boolean;
};

type LocationsData = {
  id: string;
  name: string;
  address?: string;
};

type SectionTimeSlots = {
  id: string;
  timeSlotId: string;
  sectionId: string;
  timeSlot: { id: string; weekDay: string; startTime: string; endTime: string; }
};

type SectionTeacher = {
  id: string;
  teacherId: string;
  sectionId: string;
  teacher: Teacher & { user: { id: string; fullName: string; name: string; lastname: string; } };
  comment: string;
};

type SectionData = {
  id?: string;
  name?: string;
  subjectId?: string | number;
  programId: string;
  locationId: string;
  program?: { name: string; subject: { id: string; name: string; } };
  location?: { name: string; },
  classroom: string;
  studentsCapacity: string;
  studentsCount?: string,
  mipreuId?: number,
  timeSlotIds?: Array<string>;
  sectionTimeSlots?: SectionTimeSlots[],
  teacherInfo?: DropdownTeacherInfo[];
  sectionTeachers?: SectionTeacher[],
};

type CustomSectionData = SectionData & {
  subjectName: string;
  programName: string;
  locationName: string;
  teacherName: string;
};

const customView = (array: (string | SectionTimeSlots)[]) => {
  const teacherToShow = array.map((elem, index) => {
    const displayText = typeof elem === 'string' ? elem : `${elem.timeSlot.weekDay} ${elem.timeSlot.startTime.slice(0, 5)} hrs - ${elem.timeSlot.endTime.slice(0, 5)} hrs`;
    return (
      <React.Fragment key={index}>
        {displayText}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    );
  });
  return teacherToShow;
};

const SECTIONS_UI: ({
  label: string;
  disabled?: boolean;
  key: string;
  showOnTable: boolean;
  showOnModal: boolean;
  typeField: string;
  valueType: string;
  selectFields?: LocationsData[] | SubjectData[] | ProgramData[] | Teacher[] | undefined;
  valueToRetrieveFromSelect?: string;
  keyNameInObjectSent?: string;
  functionToApply?: (array: (SectionTimeSlots | string)[]) => JSX.Element[];
})[] = [
  {
    label: 'ID', key: 'mipreuId', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Asignatura', key: 'subjectId', showOnTable: false, showOnModal: true, typeField: 'select', selectFields: [], valueType: 'string', disabled: false, valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Asignatura', key: 'subjectName', showOnTable: true, showOnModal: false, typeField: 'select', selectFields: [], valueType: 'string', disabled: false,
  },
  {
    label: 'Programa', key: 'programName', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: [], valueType: 'string', keyNameInObjectSent: 'programId', valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Sede', key: 'locationName', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: [], valueType: 'string', keyNameInObjectSent: 'locationId', valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Profesor', key: 'teacherName', showOnTable: true, showOnModal: false, typeField: 'select', selectFields: [], valueType: 'custom', functionToApply: customView,
  },
  {
    label: 'Sala', key: 'classroom', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Inscritos / Cupos', key: 'studentsCapacityStatus', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Cupos', key: 'studentsCapacity', showOnTable: false, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Horario', key: 'sectionTimeSlots', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'custom', functionToApply: customView,
  },
  {
    label: 'Acciones', key: 'actions', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
];

export {
  SECTIONS_UI,
  SectionData,
  TimeSlot,
  SpecificTimeSlot,
  LocationsData,
  CustomSectionData,
  Teacher,
  SectionTeacher,
  DropdownTeacherInfo,
  SpecificDropdownTeacherInfo,
  SectionTimeSlots,
};
