import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  colWidth: {
    width: '49.5%',
  },
  iconColWidth: {
    width: '1%',
  },
}));

export default useStyles;
