import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  homeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100vh',
    '& h1': {
      color: theme.palette.primary.main,
      fontWeight: '200',
      marginTop: '60px',
    },
    '& a': {
      color: theme.palette.primary.main,
      fontSize: '18px',
    },
    '& div': {
      minWidth: '280px',
    },
    '& img': {
      display: 'block',
      margin: 'auto',
    },
    '& .MuiTextField-root': {
      marginBottom: '10px',
    },
  },
  home: {
    color: 'white',
    '& a': {
      margin: '50px 10px',
      '&:link': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
      '&:visited': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
      '&:hover': {
        color: '#ccc',
        textDecoration: 'underline',
      },
    },
  },
  homeLink: {
    padding: '40px 10px',
    textAlign: 'center',
  },
  image: {
    maxWidth: '280px',
    maxHeight: '100%',
  },
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: '#22335E',
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '100px',
  },
}));

export default useStyles;
