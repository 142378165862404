import { Block } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

function EmptyTable() {
  return (
    <Paper>
      <Box
        minHeight={250}
        width="100%"
        minWidth={300}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Block fontSize='large' />
        <Typography variant='h2' ml={2} >
          No hay datos
        </Typography>
      </Box>
    </Paper>

  );
}

export default EmptyTable;
