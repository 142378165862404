import produce, { Draft } from 'immer';
import {
  INSTITUTION_LOGIN_REQUEST,
  INSTITUTION_LOGIN_SUCCESS,
  INSTITUTION_LOGOUT,
  INSTITUTION_SILENT_LOGIN,
} from '../actions/institutionAccountActions';
import { InstitutionAccountState, InstitutionAction } from '../types';

const initialState: InstitutionAccountState = {
  institutionUser: null,
};

const institutionAccountReducer = (
  state = initialState, action: InstitutionAction,
): InstitutionAccountState => {
  switch (action.type) {
    case INSTITUTION_LOGIN_REQUEST: {
      return produce(state, (draft: Draft<InstitutionAccountState>) => {
        // Ensure we clear current session
        draft.institutionUser = null;
      });
    }

    case INSTITUTION_LOGIN_SUCCESS: {
      const { institutionUser } = action.payload;

      return produce(state, (draft: Draft<InstitutionAccountState>) => {
        draft.institutionUser = institutionUser;
      });
    }

    case INSTITUTION_LOGOUT: {
      return produce(state, (draft: Draft<InstitutionAccountState>) => {
        draft.institutionUser = null;
      });
    }

    case INSTITUTION_SILENT_LOGIN: {
      const { institutionUser } = action.payload;
      return produce(state, (draft: Draft<InstitutionAccountState>) => {
        draft.institutionUser = institutionUser;
      });
    }

    default: {
      return state;
    }
  }
};

export default institutionAccountReducer;
