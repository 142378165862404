/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NavBar from './NavBar';
import TopBar from './TopBar';

import useStyles from './styles';
import { ChildrenProps, ReduxState } from '../../types';
import {
  ACADEMIC, ADMINISTRATOR, SECRETARY, TEACHER,
} from '../../utils/user_types';

function DashboardLayout({ children }: ChildrenProps) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { user } = useSelector((state: ReduxState) => state.account);
  const rolesWithNavbar = [TEACHER, ADMINISTRATOR, ACADEMIC, SECRETARY];

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      {user && rolesWithNavbar.includes(user!.userType) && <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />}
      <div className={(user && rolesWithNavbar.includes(user!.userType))
        ? classes.wrapper
        : classes.wrapperWithoutNavbar}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any,
};

export default DashboardLayout;
