import React from 'react';
import { matchPath } from 'react-router';
import {
  Avatar,
  Box,
  Container,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Page from '../../components/Layout/Page';
import Logo from '../../components/Layout/Logo';
import SetPasswordForm from './SetPasswordForm';

import useStyles from './styles';

function PasswordRecoveryView() {
  const classes = useStyles();

  const match = matchPath<{ token: string }>(window.location.pathname, {
    path: '/reset-password/:token',
    exact: true,
    strict: false,
  });

  const token = match?.params?.token || '';

  return (
    <Page
      className={classes.root}
      title="Password recovery"
    >
      <Container maxWidth="md">
        <Box
          mb={8}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Logo className={classes.logo} />
        </Box>
        <Card className={classes.card} >
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <AccountBoxIcon fontSize="large" />
            </Avatar>
            <Typography
              variant="h2"
              color='testPrimary'
            >
              Recuperación de contraseña
            </Typography>
            <Typography
              variant="subtitle1"
              color="textPrimary"
            >
              Ingresa tu nueva contraseña
            </Typography>
            <Box mt={3}>
              <SetPasswordForm token={token} />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default PasswordRecoveryView;
