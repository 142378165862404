import { Box, ClassNameMap, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Edit as EditIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import CustomTable from '../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../components/General/EmptyTable';
import useStyles from '../../styles';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import { getStudentSectionsByStudentId } from '../../../../../requests/api/students';
import { SectionTimeSlots } from '../../../../Section/SectionListView/types';
import { AllSectionsInformation, ProgramAllSectionResponse, TeacherResponse } from './types';
import { REPRESENTATIVE, STUDENT } from '../../../../../utils/user_types';
import { ReduxState } from '../../../../../types';
import CircularProgressComponent from '../../../../../components/Loading/CircularProgressComponent';

const displayTimeSlotFormat = (timeSlotsarray: SectionTimeSlots[]) => {
  const timeSlotsToJoin = timeSlotsarray.map((elem, index) => (
    <React.Fragment key={index}>
      {`${elem.timeSlot.startTime.slice(0, 5)} hrs - ${elem.timeSlot.endTime.slice(0, 5)} hrs`}
      {index < timeSlotsarray.length - 1 && <br />}
    </React.Fragment>
  ));
  return timeSlotsToJoin;
};

const customTeachersView = (teacherNamesArray: string[]) => {
  const teacherToShow = teacherNamesArray.map((elem, index) => (
    <React.Fragment key={index}>
      {`${elem}`}
      {index < teacherNamesArray.length - 1 && <br />}
    </React.Fragment>
  ));
  return teacherToShow;
};

const LIST_SECTIONS_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'ID sección', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Sede', key: 'locationName', valueType: 'string',
  },
  {
    label: 'Horario', key: 'schedule', valueType: 'custom', functionToApply: displayTimeSlotFormat,
  },
  {
    label: 'Profesor', key: 'teacherName', valueType: 'custom', functionToApply: customTeachersView,
  },
  {
    label: 'Acciones', key: 'actions', valueType: 'other',
  },
];

const buttonActions = (
  classes: ClassNameMap<string>,
  edit: boolean,
  handleSectionAssocation: () => void,
) => (<Box>
  <IconButton onClick={() => handleSectionAssocation()}>
    {edit ? <EditIcon className={classes.icons} /> : <AddIcon className={classes.icons} />}
  </IconButton>
</Box>);

const READ_ONLY_USERS = [STUDENT, REPRESENTATIVE];

function SectionsListView({ studentName, studentLastname }: {
  studentName: string,
  studentLastname: string,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { id: studentId } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [allSections, setAllSections] = useState<ProgramAllSectionResponse[]>();
  const [showLoading, setShowLoading] = useState(true);
  const [allSectionsInformation, setAllSectionsInformation] = useState<
  AllSectionsInformation[]>([]);

  const account = useSelector((state: ReduxState) => state.account);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllStudentSections = async () => {
      try {
        const { sections } = await getStudentSectionsByStudentId(studentId, source.token);
        setAllSections(sections);
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllStudentSections();
    return () => {
      source.cancel();
    };
  }, [studentId]);

  useEffect(() => {
    if (allSections) {
      const auxAllSectionsInfo = allSections.map((elem) => {
        const schedule = (elem.section && elem.section.sectionTimeSlots) ?? [];
        const route: {
          pathname: string, state: {
            programName: string;
            studentName: string;
            studentId: string;
            sectionId?: string;
          }
        } = {
          pathname: `/alumnos/${studentId}/programa/${elem.program.id}/asociar`,
          state: {
            programName: elem.program.name,
            studentName: `${studentName} ${studentLastname}`,
            studentId,
          },
        };
        if (elem.sectionId) {
          route.state.sectionId = elem.sectionId;
        }
        const handleAssociation = () => history.push(route);
        return {
          id: elem.id,
          mipreuId: elem.section?.mipreuId,
          subjectName: elem.program.subject.name,
          programName: elem.program.name,
          sectionId: elem.sectionId ?? '',
          schedule,
          locationName: elem.section?.location.name ?? '',
          teacherName: (elem.section?.sectionTeachers && elem.section?.sectionTeachers.length > 0)
            ? elem.section.sectionTeachers
              .map((elemTeacher: TeacherResponse) => elemTeacher.teacher.user.teacherName)
            : [],
          actions: !READ_ONLY_USERS.includes(account.user!.userType || STUDENT)
            && buttonActions(classes, !!elem.sectionId, handleAssociation),
        };
      });
      setAllSectionsInformation(auxAllSectionsInfo);
    }
  }, [allSections, studentName, studentLastname, studentId]);

  return (
    <>
      {showLoading
        ? <CircularProgressComponent />
        : <Box
          mt={3}
        >
          {allSectionsInformation.length !== 0
            && <CustomTable
              headers={!READ_ONLY_USERS.includes(account.user!.userType || STUDENT) ? LIST_SECTIONS_UI : LIST_SECTIONS_UI.filter((elem) => elem.label !== 'Acciones')}
              data={{ values: allSectionsInformation, count: allSectionsInformation.length }}
            />}
          {allSectionsInformation.length === 0 && <EmptyTable />}
        </Box>}

    </>

  );
}

SectionsListView.propTypes = {
  studentName: PropTypes.string.isRequired,
  studentLastname: PropTypes.string.isRequired,
};

export default SectionsListView;
