import React from 'react';
import { useHistory } from 'react-router';
import {
  Typography,
  Grid,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Check as CheckIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import CustomButton from '../../../../components/General/CustomButton';
import useStyles from '../../ClassListView/styles';

function ClassAssistanceHeader({
  mipreuId,
  programName,
  date,
  time,
  lastAssistanceUpdate,
  hasChanged,
  handleAssistance,
  handleDelete,
}: {
  mipreuId: string,
  programName: string,
  date: string,
  time: string,
  lastAssistanceUpdate: Date | undefined,
  hasChanged: boolean,
  handleAssistance: () => void,
  handleDelete: () => void,

}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        <Button
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          variant="h3"
          fontWeight='bold'
        >
          {`ID Sección: ${mipreuId}`}
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Programa: </strong><span>{programName}</span></>}
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Fecha: </strong><span>{date}</span></>}
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Hora: </strong><span>{time}</span></>}
        </Typography>
      </Grid>
      <Grid item display="flex" alignItems="center">
        {!lastAssistanceUpdate && <CustomButton
          key='confirm-assistance'
          icon={<CheckIcon />}
          text="Confirmar asistencia"
          colorType="tertiary"
          onClick={handleAssistance}
        />}
        {lastAssistanceUpdate && !hasChanged && <CustomButton
          key='confirm-assistance'
          icon={<DeleteIcon />}
          text="Eliminar asistencia"
          colorType="tertiary"
          onClick={handleDelete}
        />}
        {lastAssistanceUpdate && hasChanged && <CustomButton
          key='update-assistance'
          icon={<EditIcon />}
          text="Actualizar asistencia"
          colorType="tertiary"
          onClick={handleAssistance}
        />}
      </Grid>
    </Grid >
  );
}

export default ClassAssistanceHeader;
