import React, { SyntheticEvent, useEffect, useState } from 'react';
import { TeacherData } from '../types';
import UpsertModal from '../../../../components/General/UpsertModal';

function UpsertTeacherModal(
  {
    allItems,
    modalAddEdit,
    setModalAddEdit,
    handleCloseModal,
    modalValues,
    setModalValues,
    handleFieldChange,
    itemsUI,
    addFunction,
    editFunction,
  }: {
    allItems: { items: Array<TeacherData>, totalItems: number },
    modalAddEdit: { id: string, open: boolean },
    setModalAddEdit: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>,
    handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>) => void,
    modalValues: TeacherData,
    setModalValues: React.Dispatch<React.SetStateAction<TeacherData>>,
    handleFieldChange: (
      e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date | File,
      source: string) => void,
    itemsUI: {
      label: string;
      key: string;
      showOnTable: boolean;
      showOnModal: boolean;
      typeField: string;
      selectFields?: object[];
      valueType: string;
    }[],
    addFunction: () => void,
    editFunction: (id: string) => void,
  },
) {
  const [upsertFlag, setUpsertFlag] = useState(false);

  useEffect(() => {
    if (upsertFlag && modalAddEdit.id) {
      editFunction(modalAddEdit.id);
      setUpsertFlag(false);
    } else if (upsertFlag) {
      addFunction();
      setUpsertFlag(false);
    }
  }, [upsertFlag, modalAddEdit.id]);

  useEffect(() => {
    if (modalAddEdit.id !== '') {
      const auxSelectedTeacher = allItems.items
        .filter((elem) => elem.id === modalAddEdit.id);
      const selectedTeacher = auxSelectedTeacher[0];
      setModalValues({
        rut: selectedTeacher.rut,
        phoneNumber: selectedTeacher.phoneNumber,
        birthDate: selectedTeacher.birthDate,
        moduleFee: selectedTeacher.moduleFee,
        name: selectedTeacher.name,
        lastname: selectedTeacher.lastname,
        email: selectedTeacher.email,
      });
    }
  }, [modalAddEdit.id]);

  return (
    <UpsertModal<TeacherData>
      title={'profesor'}
      modalAddEdit={modalAddEdit}
      setModalAddEdit={setModalAddEdit}
      handleCloseModal={handleCloseModal}
      modalValues={modalValues}
      handleFieldChange={handleFieldChange}
      itemsUI={itemsUI}
      handleAddFunction={() => setUpsertFlag(true)}
      handleEditFunction={() => setUpsertFlag(true)}
    />
  );
}

export default UpsertTeacherModal;
