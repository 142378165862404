import React from 'react';
import {
  Box,
  Hidden,
  Icon,
  Typography,
} from '@mui/material';
import {
  AutoStories as AutoStoriesIcon,
  Description as DescriptionIcon,
  EditAttributes as EditAttributesIcon,
  FamilyRestroom as FamilyRestroomIcon,
  Feedback as FeedbackIcon,
  Person as PersonIcon,
  PlayCircle as PlayCircleIcon,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import useStyles from '../styles';
import { ReduxState } from '../../../../types';
import { STUDENT, TEACHER, REPRESENTATIVE } from '../../../../utils/user_types';

const NAVBAR_UI = [{
  icon: <DescriptionIcon />,
  title: 'Contrato',
},
{
  icon: <AutoStoriesIcon />,
  title: 'Secciones',
},
{
  icon: <EditAttributesIcon />,
  title: 'Guías',
},
{
  icon: <FeedbackIcon />,
  title: 'Feedbacks',
},
{
  icon: <PersonIcon />,
  title: 'Asistencias',
},
{
  icon: <PlayCircleIcon />,
  title: 'Videos',
},
{
  icon: <FamilyRestroomIcon />,
  title: 'Apoderados',
}];

function StudentDetailNavbar({
  activeTab,
  setActiveTab,
}: {
  activeTab: number,
  setActiveTab: React.Dispatch<React.SetStateAction<number>>,
}) {
  const classes = useStyles();
  const account = useSelector((state: ReduxState) => state.account);

  const NavBarDetail = ({
    icon,
    title,
    index,
  }: {
    icon: JSX.Element,
    title: string,
    index: number,
  }) => {
    let relevantClassnamePosition = null;
    if (index === 0) {
      relevantClassnamePosition = classes.navbarDetailFirstNavItem;
    } else if (index === 6) { // last element
      relevantClassnamePosition = classes.navbarDetailLastNavItem;
    } else {
      relevantClassnamePosition = classes.navbarDetailNavItem;
    }

    let relevantClassnameActive = null;
    if (activeTab === index) {
      relevantClassnameActive = classes.navbarDetailNavItemActive;
    } else {
      relevantClassnameActive = classes.navbarDetailNavItemInactive;
    }

    let hiddenStatus = null;

    // hide contracts from students and teachers
    if (account?.user
      && (account.user!.userType === STUDENT || account.user!.userType === TEACHER) && title === 'Contrato') {
      hiddenStatus = classes.navbarDetailNavItemHidden;
    }

    // hide videos and feedbacks from representatives
    if (account?.user
      && (account.user!.userType === REPRESENTATIVE) && (title === 'Videos' || title === 'Feedbacks')) {
      hiddenStatus = classes.navbarDetailNavItemHidden;
    }

    return (<Box
      className={`${classes.navBarDetailContainer} ${relevantClassnamePosition} ${relevantClassnameActive} ${hiddenStatus}`}
      onClick={() => setActiveTab(index)}
    >
      <Icon>
        {icon}
      </Icon>
      <Hidden mdDown >
        <Typography
          variant='h5'
          fontWeight='bold'
          marginLeft='5px'
        >
          {title}
        </Typography>
      </Hidden>
    </Box>);
  };

  return (
    <Box className={classes.studentDetailNavbar}>
      {NAVBAR_UI.map((elem, index) => <NavBarDetail
        key={elem.title}
        title={elem.title}
        icon={elem.icon}
        index={index}
      />)}
    </Box>
  );
}

export default StudentDetailNavbar;
