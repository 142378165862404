import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import handleApiResponse from '../../../../../utils/handleApiResponse';
import { getStudentTestsInformationBySectionId } from '../../../../../requests/api/sections';
import { StudentStatistics, TESTS_TABLE_UI, TestData } from './types';

import Page from '../../../../../components/Layout/Page';
import DetailHeader from '../DetailHeader';
import HeaderSummary from '../HeaderSummary';
import StudentTestsTable from './Components/StudentTestsTable';

interface LocationState {
  student: string;
  mipreuId: string;
}

function StudentTestsListView() {
  const { enqueueSnackbar } = useSnackbar();
  const { sectionId, studentId } = useParams<{ sectionId: string, studentId: string }>();
  const location = useLocation<LocationState>();
  const student = location.state ? location.state.student : 'S/I';
  const mipreuId = location.state ? location.state.mipreuId : '';

  const [statistics, setStatistics] = useState<StudentStatistics>({
    correctAnswersAverage: 0,
    omittedAnswersAverage: 0,
    wrongAnswersAverage: 0,
    testsSubmitted: '0',
  });
  const [testsData, setTestData] = useState<{ count: number; values: TestData[] }>({
    count: 0,
    values: [],
  });

  useEffect(() => {
    const getAllTestsInformationFromStudent = async () => {
      try {
        const { testsDetails } = await getStudentTestsInformationBySectionId(studentId, sectionId);
        const { generalStatistics, tests } = testsDetails;
        setTestData({ count: tests.length, values: tests });
        setStatistics(generalStatistics);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllTestsInformationFromStudent();
  }, []);

  return (
    <Page title="Alumno | Detalle guias">
      <DetailHeader mipreuId={mipreuId} student={student} view="guías" />
      <HeaderSummary width="60%" info={[
        { key: 'Entregas / Total', value: `${statistics.testsSubmitted}/${testsData.values.filter((t) => t.deliveryStatus === 'onDate').length}` },
        { key: 'Correctas', value: `${statistics.correctAnswersAverage}%` },
        { key: 'Incorrectas', value: `${statistics.wrongAnswersAverage}%` },
        { key: 'Omitidas', value: `${statistics.omittedAnswersAverage}%` },
      ]} />
      <StudentTestsTable data={testsData} headers={TESTS_TABLE_UI} />
    </Page>
  );
}

export default StudentTestsListView;
