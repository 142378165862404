import { Box } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { School } from '@mui/icons-material';
import useStyles from '../../../styles';
import CustomTable from '../../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../../components/General/EmptyTable';
import { DetailsDataResponse } from '../types';
import { ReduxState } from '../../../../../../types';
import { STUDENT } from '../../../../../../utils/user_types';
import Header from '../../../../../../components/Layout/Header';

const TestsTable = ({
  data, headers, searchValue, setSearchValue, programId, setProgramId, allPrograms,
}: {
  data: { count: number; values: DetailsDataResponse[] },
  headers: { label: string; key: string; valueType: string; }[]
  searchValue: string,
  setSearchValue: (value: string) => void,
  programId: string,
  setProgramId: (id: string) => void,
  allPrograms: { id: string, name: string }[],
}) => {
  const classes = useStyles();
  const { user } = useSelector((state: ReduxState) => state.account);

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (!value) {
      setProgramId('');
    } else {
      setProgramId((value as { name: string, id: string }).id);
    }
  };

  return (
    <Box className={classes.contractViewProgramsContainer}>
      <Header
        title='Guías'
        icon={<School />}
        buttons={[]}
        search={{ text: 'Buscar una guía...', value: searchValue, onChangeSearchValue: (e) => setSearchValue(e.target.value) }}
        autocompletes={[
          {
            name: 'Programa',
            value: programId,
            onChange: handleFilterChange,
            options: [{ name: 'Todos', id: '' }, ...allPrograms],
            keyField: 'programId',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
        ]}
      />
      <Box className={classes.contractViewProgramsTableContainer}>
        {data.count !== 0
          && <CustomTable
            headers={headers}
            data={data}
            userType={user?.userType || STUDENT} // STUDENT as a fallback
          />}
        {data.count === 0 && <EmptyTable />}
      </Box>
    </Box>
  );
};

export default TestsTable;
