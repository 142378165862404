import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import handleApiResponse from '../utils/handleApiResponse';

export const useAxiosFetch = ({
  url,
  keyToRetrieve,
}: {
  url: string,
  keyToRetrieve: string,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [allData, setAllData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(url, {
          signal: controller.signal,
        });
        setAllData(response.data[keyToRetrieve]);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setLoading(false);
      }
    };

    // considerar cambiar los requests a este custom hook,
    // asi puedo manejar la cancelacion de pedidos tambien
    const controller = new AbortController();
    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  return {
    allData, loading,
  };
};
