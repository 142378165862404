import { CancelToken } from 'axios';
import { RequestsPerPage } from '../types';
import axios from '../../utils/axios';
import { PostContractData, PutContractData, StudentData } from '../../views/Student/StudentListView/types';

const getStudentsPerPage = async ({
  page, limit, searchValue, sort,
}: RequestsPerPage) => {
  const response = await axios.get(`/api/students?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`);

  return response.data;
};

const getStudentById = async (studentId: string) => {
  const response = await axios.get(`/api/students/${studentId}`);

  return response.data;
};

const postCreateStudent = async ({
  rut,
  phoneNumber,
  name,
  lastname,
  email,
}: StudentData) => {
  const response = await axios.post('/api/students', {
    rut,
    phoneNumber,
    name,
    lastname,
    email,
  });

  return response.data;
};

const putUpdateStudent = async ({
  id,
  phoneNumber,
  name,
  lastname,
  rut,
  email,
  onlineEnabled,
}: {
  id: string,
  phoneNumber: string,
  name: string,
  lastname: string,
  rut: string,
  email: string,
  onlineEnabled: boolean,
}) => {
  const response = await axios.put(`/api/students/${id}`, {
    phoneNumber,
    name,
    lastname,
    rut,
    email,
    onlineEnabled,
  });

  return response.data;
};

const deleteStudent = async (studentId: string) => {
  const response = await axios.delete(`/api/students/${studentId}`);

  return response.data;
};

const restoreStudent = async (studentId: string) => {
  const response = await axios.put(`/api/students/${studentId}/restore`);

  return response.data;
};

const getStudentContractsByStudentId = async (studentId: string, cancelToken?: CancelToken) => {
  const response = await axios.get(`/api/students-contracts/${studentId}`, { cancelToken });

  return response.data;
};

const getContractById = async (contractId: string) => {
  const response = await axios.get(`/api/contracts/${contractId}`);

  return response.data;
};

const postContract = async ({
  studentId,
  startDate,
  endDate,
  tuition,
  tuitionDiscount,
  tuitionAgreement,
  tuitionPaymentType,
  feeDiscountType,
  feeDiscount,
  feeAdditionalDiscount,
  feePaymentType,
  feePaymentTypeDiscount,
  studentProgramInfo,
  comment,
  installments,
}: PostContractData) => {
  const response = await axios.post('/api/contracts', {
    studentId,
    startDate,
    endDate,
    tuition,
    tuitionDiscount,
    tuitionAgreement,
    tuitionPaymentType,
    feeDiscountType,
    feeDiscount,
    feeAdditionalDiscount,
    feePaymentType,
    feePaymentTypeDiscount,
    studentProgramInfo,
    comment,
    installments,
  });

  return response.data;
};

const putContract = async ({
  contractId,
  studentId,
  startDate,
  endDate,
  tuition,
  tuitionDiscount,
  tuitionAgreement,
  tuitionPaymentType,
  feeDiscountType,
  feeDiscount,
  feeAdditionalDiscount,
  feePaymentType,
  feePaymentTypeDiscount,
  studentProgramInfo,
  comment,
  installments,
}: PutContractData) => {
  const response = await axios.put(`/api/contracts/${contractId}`, {
    studentId,
    startDate,
    endDate,
    tuition,
    tuitionDiscount,
    tuitionAgreement,
    tuitionPaymentType,
    feeDiscountType,
    feeDiscount,
    feeAdditionalDiscount,
    feePaymentType,
    feePaymentTypeDiscount,
    studentProgramInfo,
    comment,
    installments,
  });

  return response.data;
};

const getStudentSectionsByStudentId = async (studentId: string, cancelToken?: CancelToken) => {
  const response = await axios.get(`/api/students-sections/${studentId}`, { cancelToken });

  return response.data;
};

const getProgramSectionsByProgramId = async (programId: string, searchValue: string) => {
  const response = await axios.get(`/api/students-program-sections/${programId}?searchValue=${searchValue}`);

  return response.data;
};

const putProgramSections = async ({
  studentId,
  programId,
  sectionId,
}: {
  studentId: string,
  programId: string,
  sectionId: string,
}) => {
  const response = await axios.put('/api/students-program-sections', {
    studentId,
    programId,
    sectionId,
  });

  return response.data;
};

const getTestsInformationByStudentId = async (studentId: string, cancelToken?: CancelToken) => {
  const response = await axios.get(`/api/students-tests/${studentId}`, { cancelToken });

  return response.data;
};

const getContentsByStudentId = async (
  studentId: string, searchValue: string, filterValue: string, cancelToken?: CancelToken,
) => {
  const response = await axios.get(`/api/student-contents/${studentId}?searchValue=${searchValue}&filterValue=${filterValue}`, { cancelToken });

  return response.data;
};

const getFeedbacksByStudentId = async (studentId: string, cancelToken?: CancelToken) => {
  const response = await axios.get(`/api/student-feedbacks/${studentId}`, { cancelToken });

  return response.data;
};

const getFeedbacksTestsByStudentId = async (studentId: string, programId: string) => {
  const response = await axios.get(`/api/student-feedbacks/student/${studentId}/program/${programId}`);

  return response.data;
};

const getFeedbacksProgramsByStudentId = async (studentId: string) => {
  const response = await axios.get(`/api/student-feedbacks/student/${studentId}/programs`);

  return response.data;
};

const postFeedbacks = async ({
  studentId,
  programId,
  minQuestions,
  maxQuestions,
  selectedTestsIds,
}: {
  studentId: string,
  programId: string,
  minQuestions: number,
  maxQuestions: number,
  selectedTestsIds: string[],
}) => {
  const response = await axios.post(`/api/student-feedbacks/${studentId}`, {
    minQuestions,
    maxQuestions,
    programId,
    selectedTestsIds,
  });

  return response.data;
};

const downloadTest = async (url: string) => {
  const response = await axios.post('/api/student-tests/download-test', { url });

  return response.data;
};

const getTestsQuestionsByStudentId = async (studentId: string, testId: string) => {
  const response = await axios.get(`/api/student-test-questions/student/${studentId}/test/${testId}`);

  return response.data;
};

const getAssistancesInformationByStudentId = async (
  studentId: string,
  cancelToken?: CancelToken,
) => {
  const response = await axios.get(`/api/student-assistances/student/${studentId}`, { cancelToken });

  return response.data;
};

const getStudentRepresentativesByStudentId = async (
  studentId: string,
  cancelToken: CancelToken,
) => {
  const response = await axios.get(`/api/student-representatives/${studentId}`, { cancelToken });

  return response.data;
};

const postRepresentativeStudentAssociation = async ({
  studentId,
  representativeId,
  relationToStudent,
}: {
  studentId: string,
  representativeId: string,
  relationToStudent: string,
}) => {
  const response = await axios.post('/api/student-representatives', {
    studentId,
    representativeId,
    relationToStudent,
  });

  return response.data;
};

const putRepresentativeStudentAndAssociation = async ({
  studentId,
  representativeId,
  relationToStudent,
  rut,
  phoneNumber,
  name,
  lastname,
  email,
  birthDate,
  nationality,
  maritalStatus,
  profession,
  address,
  commune,
}: {
  studentId: string,
  representativeId: string,
  relationToStudent: string,
  rut: string,
  phoneNumber: string,
  name: string,
  lastname: string,
  email: string,
  birthDate: Date | string,
  nationality: string,
  maritalStatus: string,
  profession: string,
  address: string,
  commune: string,
}) => {
  const response = await axios.put('/api/student-representatives', {
    studentId,
    representativeId,
    relationToStudent,
    rut,
    phoneNumber,
    name,
    lastname,
    email,
    birthDate,
    nationality,
    maritalStatus,
    profession,
    address,
    commune,
  });

  return response.data;
};

const deleteStudentRepresentativeAssociation = async (studentRepresentativeId: string) => {
  const response = await axios.delete(`/api/student-representatives/${studentRepresentativeId}`);

  return response.data;
};

const getStudentsFromRepresentative = async (representativeId: string) => {
  const response = await axios.get(`/api/student-representatives/representative/${representativeId}`);

  return response.data;
};

const postStudentAnswers = async ({
  studentId,
  testId,
  answers,
}: {
  studentId: string;
  testId: string;
  answers: {
    testQuestionId: string;
    questionNumber: string;
    answer: string;
  }[];
}) => {
  const response = await axios.post('/api/student-tests/submit-answers', {
    studentId,
    testId,
    answers,
  });

  return response.data;
};

const putStudentSavedAnswers = async ({
  studentId,
  testId,
  answers,
}: {
  studentId: string;
  testId: string;
  answers: {
    testQuestionId: string;
    questionNumber: string;
    answer: string;
  }[];
}) => {
  const response = await axios.put('/api/student-tests/update-answers', {
    studentId,
    testId,
    answers,
  });

  return response.data;
};

const putStudentOnlineStatus = async (studentId: string, onlineEnabled: boolean) => {
  const response = await axios.put('/api/students/online-enabled/status', {
    studentId,
    onlineEnabled,
  });

  return response.data;
};

const postGenerateContractDocument = async (
  { contractId, contractDocumentId }: { contractId: string, contractDocumentId: string },
) => {
  const response = await axios.post('/api/students/documents/generate', { contractId: contractId.toString(), contractDocumentId: contractDocumentId.toString() });

  return response.data;
};

const postDownloadDocument = async (url: string) => {
  const response = await axios.post('/api/students/documents/download', { url });

  return response.data;
};

const postSendDocument = async (
  studentId: string, contractDocumentId: string, documentUrl: string,
) => {
  const response = await axios.post('/api/students/documents/send', {
    studentId: studentId.toString(),
    contractDocumentId: contractDocumentId.toString(),
    documentUrl,
  });

  return response.data;
};

const deleteDocument = async (contractDocumentId: string) => {
  const response = await axios.delete(`/api/students/documents/delete/${contractDocumentId}`);

  return response.data;
};

const postUploadAnnexDocument = async (file: File, contractId: string) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
    formData.set('contractId', contractId);
  }
  const response = await axios.post('/api/students/documents/upload', formData);

  return response.data;
};

const studentsCSVDownload = async () => {
  const response = await axios.get('/api/download/students');

  return response.data;
};

const studentAssistancesCSVDownload = async () => {
  const response = await axios.get('/api/download/students-assistances');

  return response.data;
};

const studentTestsCSVDownload = async () => {
  const response = await axios.get('/api/download/students-tests');

  return response.data;
};

const studentTestsResultsCSVDownload = async () => {
  const response = await axios.get('/api/download/students-results');

  return response.data;
};

export {
  getStudentsPerPage,
  getStudentById,
  postCreateStudent,
  putUpdateStudent,
  deleteStudent,
  restoreStudent,
  getStudentContractsByStudentId,
  getContractById,
  postContract,
  putContract,
  getStudentSectionsByStudentId,
  getProgramSectionsByProgramId,
  putProgramSections,
  getTestsInformationByStudentId,
  getContentsByStudentId,
  getFeedbacksByStudentId,
  getFeedbacksTestsByStudentId,
  getFeedbacksProgramsByStudentId,
  postFeedbacks,
  downloadTest,
  getTestsQuestionsByStudentId,
  getAssistancesInformationByStudentId,
  getStudentRepresentativesByStudentId,
  postRepresentativeStudentAssociation,
  putRepresentativeStudentAndAssociation,
  deleteStudentRepresentativeAssociation,
  getStudentsFromRepresentative,
  postStudentAnswers,
  putStudentSavedAnswers,
  putStudentOnlineStatus,
  postGenerateContractDocument,
  postDownloadDocument,
  postSendDocument,
  deleteDocument,
  studentsCSVDownload,
  studentAssistancesCSVDownload,
  studentTestsCSVDownload,
  studentTestsResultsCSVDownload,
  postUploadAnnexDocument,
};
