import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.main,
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  massiveListViewContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  massiveListViewTableContainer: {
    marginTop: '30px',
  },
  buttonContainer: {
    minWidth: '150px',
    marginRight: '10px',
  },
  textfieldContainer: {
    minWidth: '250px',
    marginRight: '10px',
  },
}));

export default useStyles;
