import produce, { Draft } from 'immer';

import {
  CustomActionStudentContract, StudentContractState,
} from '../types';
import {
  SET_NEW_CONTRACT, RESET_CONTRACT, RESET_ADD_PROGRAM_MODAL_VALUES, SET_ADD_PROGRAM_MODAL_VALUES,
} from '../actions/studentContractActions';

const iniState: StudentContractState = {
  contract: null,
  contractAddProgramModal: {
    open: false,
    values: {
      subjectId: '',
      selectedProgramId: '',
      feeDiscount: '0',
      comment: '',
    },
  },
};

const studentContractReducer = (
  state = iniState,
  action: CustomActionStudentContract,
): StudentContractState => {
  switch (action.type) {
    case SET_NEW_CONTRACT: {
      return produce(state, (draft: Draft<StudentContractState>) => {
        const { contractData } = action.payload;
        if (!state.contract) {
          draft.contract = { [contractData.studentId]: contractData.studentContract };
        } else {
          draft.contract = {
            ...state.contract,
            [contractData.studentId]: contractData.studentContract,
          };
        }
      });
    }

    case RESET_CONTRACT: {
      const { studentId } = action.payload.contractData;
      return produce(state, (draft: Draft<StudentContractState>) => {
        if (state.contract && state.contract[studentId]) {
          const auxState = { ...state.contract };
          delete auxState[studentId];
          draft.contract = { ...auxState };
        }
      });
    }

    case SET_ADD_PROGRAM_MODAL_VALUES: {
      return produce(state, (draft: Draft<StudentContractState>) => {
        const { open, values } = action.payload.contractAddProgramModal;
        draft.contractAddProgramModal = {
          open, values,
        };
      });
    }

    case RESET_ADD_PROGRAM_MODAL_VALUES: {
      return produce(state, (draft: Draft<StudentContractState>) => {
        draft.contractAddProgramModal = {
          open: false,
          values: {
            subjectId: '',
            selectedProgramId: '',
            feeDiscount: '0',
            comment: '',
          },
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default studentContractReducer;
