import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  CastForEducation as CastForEducationIcon,
  Edit as EditIcon,
  GroupAdd as GroupAddIcon,
} from '@mui/icons-material';
import {
  Box, ClassNameMap, IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import useStyles from './styles';
import { getClassesPerPage } from '../../../requests/api/classes';
import { getDateOnlyFormat } from '../../../utils/helpers';
import { Class, SectionTeacher } from './types';
import { TEACHER } from '../../../utils/user_types';
import { ReduxState } from '../../../types';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import { setClassesAssistancesFilters } from '../../../actions/filtersActions';
import { getSectionsPerPage } from '../../../requests/api/sections';
import { SectionData } from '../../Section/SectionListView/types';

const customTeachersView = (array: string[]) => {
  const teacherToShow = array.map((elem, index) => (
    <React.Fragment key={index}>
      {`${elem}`}
      {index < array.length - 1 && <br />}
    </React.Fragment>
  ));
  return teacherToShow;
};

const customFunctionToApply = (dateInfo: Class) => [<React.Fragment key={dateInfo.id}>
  <span>{`${dateInfo.date} `}</span><br />{`${dateInfo.sectionTimeSlot.timeSlot.startTime.slice(0, 5)} hrs - ${dateInfo.sectionTimeSlot.timeSlot.endTime.slice(0, 5)} hrs`}
</React.Fragment>];

const customDateTime = (date: string, rowIndex: string) => {
  if (date) {
    return [<React.Fragment key={rowIndex}>
      <span>{`${getDateOnlyFormat(new Date(date), '/', true)} `}</span><br />{`${new Date(date).getHours()}:${new Date(date).getMinutes()} hrs`}
    </React.Fragment>];
  }

  return [<React.Fragment key={rowIndex}>-</React.Fragment >];
};

const CLASSES_UI = [{
  label: 'ID Seccion', key: 'mipreuId', valueType: 'string',
},
{
  label: 'Programa', key: 'programName', valueType: 'string',
},
{
  label: 'Sede', key: 'locationName', valueType: 'string',
},
{
  label: 'Profesor', key: 'professorName', valueType: 'custom', functionToApply: customTeachersView,
},
{
  label: 'Sala', key: 'classroom', valueType: 'string',
},
{
  label: 'Horario', key: 'scheduledTime', valueType: 'custom', functionToApply: customFunctionToApply,
},
{
  label: 'Asistencia', key: 'assistance', valueType: 'string',
},
{
  label: 'Ultima actualización asistencia', key: 'lastAssistanceUpdate', valueType: 'custom', functionToApply: customDateTime,
},
{
  label: 'Registro asistencia', key: 'actions', valueType: 'other',
}];

const buttonActions = (
  classes: ClassNameMap<string>,
  lastAssistanceUpdate: string | undefined,
  classId: string,
  handleRedirection: (classId: string) => void,
) => (<Box>
  <IconButton onClick={() => handleRedirection(classId)} >
    {!lastAssistanceUpdate && <GroupAddIcon className={classes.icons} />}
    {lastAssistanceUpdate && <EditIcon className={classes.icons} />}
  </IconButton>
</Box >);

function ClassListView() {
  const classes = useStyles();
  const history = useHistory();
  const [allClasses, setAllClasses] = useState({
    items: [],
    totalItems: 0,
  });
  const [showLoading, setShowLoading] = useState(true);
  const [sectionOptions, setSectionOptions] = useState<{ id: string, name: string }[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state: ReduxState) => state.account);
  const { classesAssistancesFilters } = useSelector((state: ReduxState) => state.filters);
  const dispatch = useDispatch();

  const isTeacher = account?.user && account.user!.userType === TEACHER;

  const handleRedirection = (classId: string) => {
    history.push({
      pathname: `/clases/${classId}`,
    });
  };

  // Clear filter values when leaving the view.
  useEffect(() => () => {
    dispatch(setClassesAssistancesFilters({
      registered: '',
      searchValue: '',
      page: 0,
      rowsPerPage: 5,
      Seccion: '',
    }));
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllClasses = async () => {
      let teacherId;
      if (isTeacher) {
        teacherId = account?.user?.userTypeId;
      }
      try {
        const { classes: studentClasses, totalClasses } = await getClassesPerPage({
          page: classesAssistancesFilters.page,
          limit: classesAssistancesFilters.rowsPerPage,
          searchValue: classesAssistancesFilters.searchValue,
          teacherId,
          sectionId: classesAssistancesFilters.Seccion,
          registered: classesAssistancesFilters.registered === '' ? null : classesAssistancesFilters.registered === 'true',
        }, source.token);

        const classesAndActions = studentClasses.map((elem: Class) => {
          const teachersName = elem.sectionTimeSlot.section?.sectionTeachers
            && elem.sectionTimeSlot.section?.sectionTeachers
              .map((sectionTeacher: SectionTeacher) => `${sectionTeacher.teacher.user.name} ${sectionTeacher.teacher.user.lastname}`);
          const assistanceToClass = elem.studentClasses ? `${elem.studentClasses.reduce((acc: number, val: { attended: boolean }) => acc + (val.attended ? 1 : 0), 0)}` : '-';
          const studentsInClass = elem.sectionTimeSlot.section.studentPrograms ? elem.sectionTimeSlot.section.studentPrograms.length : '-';

          return {
            ...elem,
            mipreuId: elem.sectionTimeSlot.section.mipreuId,
            programName: elem.sectionTimeSlot.section.program.name,
            locationName: elem.sectionTimeSlot.section.location.name,
            professorName: teachersName,
            scheduledTime: elem,
            assistance: `${assistanceToClass} / ${studentsInClass}`,
            actions: buttonActions(
              classes,
              elem.lastAssistanceUpdate,
              elem.id,
              handleRedirection,
            ),
          };
        });
        setAllClasses({ items: classesAndActions, totalItems: totalClasses });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setShowLoading(false);
      }
    };

    getAllClasses();

    const getSeccionValuesForFields = async () => {
      // Get sections
      try {
        const { sections } = await getSectionsPerPage({
          teacherId: account?.user?.userTypeId,
          page: 0,
          limit: 1000,
          searchValue: '',
          sort: 'name|ASC',
        });

        setSectionOptions(sections.map((p: SectionData) => ({ id: p.mipreuId, name: p.mipreuId })));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getSeccionValuesForFields();
    return () => {
      source.cancel();
    };
  }, [
    classesAssistancesFilters.page,
    classesAssistancesFilters.rowsPerPage,
    classesAssistancesFilters.searchValue,
    classesAssistancesFilters.registered,
    classesAssistancesFilters.Seccion,
  ]);

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (!value) {
      dispatch(setClassesAssistancesFilters({ ...classesAssistancesFilters, [source]: '' }));
    } else {
      dispatch(setClassesAssistancesFilters({
        ...classesAssistancesFilters,
        [source]: (value as { name: string, id: string }).id,
      }));
    }

    if (classesAssistancesFilters.page !== 0) {
      dispatch(setClassesAssistancesFilters({ ...classesAssistancesFilters, page: 0 }));
    }
  };

  return (
    <Page
      title="Clases"
    >
      <Header
        title='Asistencias'
        icon={<CastForEducationIcon />}
        autocompletes={!showLoading ? [
          {
            name: 'registered',
            value: classesAssistancesFilters.registered,
            options: [{ name: 'Todas', id: '' }, { name: 'Tomadas', id: 'true' }, { name: 'Por tomar', id: 'false' }],
            onChange: handleFilterChange,
            keyField: 'registered',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
          {
            name: 'Sección',
            value: classesAssistancesFilters.Seccion,
            options: [{ name: 'Todos', id: '' }, ...sectionOptions],
            onChange: handleFilterChange,
            keyField: 'Seccion',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
        ] : undefined}
      />
      {showLoading && <CircularProgressComponent />}
      {!showLoading && <Box
        mt={3}
      >
        {allClasses.totalItems !== 0
          && <CustomTable
            headers={CLASSES_UI}
            data={{ values: allClasses.items, count: allClasses.totalItems }}
            reduxTableParameters={classesAssistancesFilters}
            setReduxTableParameters={setClassesAssistancesFilters}
          />}
        {allClasses.totalItems === 0 && <EmptyTable />}
      </Box>}

    </Page>
  );
}

export default ClassListView;
