import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  sectionDetailTitleContainer: {
    display: 'flex',
    color: theme.palette.background.main,
  },
  sectionDetailHeaderBackButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
  sectionDetailAllInfoContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '30px',
  },
  sectionDetailInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  sectionDetailTypographyMainTitle: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    fontSize: theme.typography.h4.fontSize,
  },
  sectionDetailTypographyContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
    width: '33%',
  },
  sectionDetailTypographySectionTitle: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: theme.typography.h4.fontSize,
  },
  sectionDetailTypographyText: {
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.primary,
  },
  sectionDetailTypographyMultipleRowsContainer: {
    display: 'flex',
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.main,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  backButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
}));

export default useStyles;
