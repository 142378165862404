import React, { SyntheticEvent } from 'react';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  Switch,
  Theme,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CustomButton from './CustomButton';
import {
  CustomAutocompleteTextField,
  CustomDateTextField,
  CustomFileField,
  CustomNumericTextField,
  CustomSelectTextField,
  CustomTextField,
} from './CustomTextfields';

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modal: {
    maxHeight: '90%',
    maxWidth: '890px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalDelete: {
    maxHeight: '290px',
    maxWidth: '690px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitleAndIcon: {
    display: 'flex',
  },
  modalTextfieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modalIndividualTextfield: {
    display: 'flex',
    minWidth: '50%',
    margin: '15px 0px',
    padding: '0px 10px',
  },
  modalButtonsContainer: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'end',
  },
  modalDeleteBody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  modalDeleteButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  modalFileUploadLabel: {
    width: '100%',
    display: 'flex',
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  switchAndTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface NamedType {
  name?: string;
}

export default function UpsertModal<DataType extends NamedType>({
  children,
  title,
  modalAddEdit,
  setModalAddEdit,
  handleCloseModal,
  modalValues,
  handleFieldChange,
  itemsUI,
  handleAddFunction,
  handleEditFunction,
}: {
  children?: React.ReactNode,
  title: string,
  modalAddEdit: { id: string, open: boolean },
  setModalAddEdit: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>,
  handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => void,
  modalValues: DataType,
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date | File,
    source: string,
  ) => void,
  itemsUI: {
    label: string;
    key: string;
    showOnTable?: boolean;
    showOnModal?: boolean;
    typeField: string;
    selectFields?: object[];
    valueType?: string;
    valueToRetrieveFromSelect?: string;
    keyNameInObjectSent?: string;
    disabled?: boolean;
    switchLabels?: { on: string, off: string },
  }[],
  handleAddFunction: () => void,
  handleEditFunction: () => void,
}) {
  const classes = useStyles();
  return (
    <Modal
      open={modalAddEdit.open}
      onClose={() => handleCloseModal(setModalAddEdit)}
    >
      <Box className={classes.modalContainer}>
        <Card className={classes.modal}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon}>
                {modalAddEdit.id !== '' ? <EditIcon color='primary' /> : <AddIcon color='primary' />}
                <Typography variant='h4' color='primary' fontWeight='bold'>{modalAddEdit.id !== '' ? `Editar ${title}` : `Crear ${title}`}</Typography>
                {modalAddEdit.id !== '' && <Typography variant='h4' color='primary' ml={1}>{`- ${modalValues.name}`}</Typography>}
              </Box>

              <IconButton onClick={() => handleCloseModal(setModalAddEdit)}>
                <CloseIcon color='primary' />
              </IconButton>
            </Box>
            <Box className={classes.modalTextfieldsContainer}>
              {itemsUI
                .filter((elem) => elem.showOnModal)
                .map((elem) => {
                  let key: keyof DataType;
                  if (elem.keyNameInObjectSent) {
                    key = elem.keyNameInObjectSent as keyof DataType;
                  } else {
                    key = elem.key as keyof DataType;
                  }

                  return (
                    <Box key={key.toString()} className={classes.modalIndividualTextfield}>
                      {elem.typeField === 'select' && <CustomSelectTextField
                        disabled={elem.disabled}
                        valueToRetrieveFromSelect={elem.valueToRetrieveFromSelect}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key] as string}
                        onChangeFunction={handleFieldChange}
                        selectFields={elem.selectFields as Array<{ id: string; name: string; }>}
                      />}
                      {elem.typeField === 'autocomplete' && <CustomAutocompleteTextField
                        disabled={elem.disabled}
                        valueToRetrieveFromSelect={elem.valueToRetrieveFromSelect}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key] as string}
                        onChangeFunction={handleFieldChange}
                        selectFields={elem.selectFields as Array<{ id: string; name: string; }>}
                      />}
                      {elem.typeField === 'text' && <CustomTextField
                        disabled={elem.disabled}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key] as string}
                        onChangeFunction={handleFieldChange}
                      />}
                      {elem.typeField === 'date' && <CustomDateTextField
                        disabled={elem.disabled}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key] as Date}
                        onChangeFunction={handleFieldChange}
                      />}
                      {elem.typeField === 'file' && <CustomFileField
                        keyField={key.toString()}
                        value={modalValues[key] as File}
                        name={elem.label}
                        onChangeFunction={handleFieldChange}
                        labelClass={classes.modalFileUploadLabel}
                      />}
                      {elem.typeField === 'number' && <CustomNumericTextField
                        disabled={elem.disabled}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key] as string}
                        onChangeFunction={handleFieldChange}
                      />}
                      {elem.typeField === 'switch'
                        && <Box className={classes.switchAndTitleContainer}>
                          <Typography variant='h6' color='primary' fontWeight='bold'>{elem.label}</Typography>
                          <Box className={classes.switchContainer}>
                            <Typography variant='h6'>{elem.switchLabels?.off || 'Deshabilitada'}</Typography>
                            <Switch
                              checked={modalValues[key] as boolean}
                              onChange={(e) => handleFieldChange(e, key.toString())}
                            />
                            <Typography variant='h6'>{elem.switchLabels?.on || 'Habilitada'}</Typography>
                          </Box>
                        </Box>}
                    </Box>
                  );
                })}
            </Box>
            {children}
            <Box className={classes.modalButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={() => handleCloseModal(setModalAddEdit)} key={'CancelButtonAddProgram'} icon={<CloseIcon />} />
              {modalAddEdit.id !== ''
                ? <CustomButton text='Confirmar cambio' colorType='primary' onClick={handleEditFunction} key={`AcceptButtonEdit${title}`} icon={<CheckIcon />} />
                : <CustomButton text={`Crear ${title}`} colorType='primary' onClick={handleAddFunction} key={`AcceptButtonAdd${title}`} icon={<AddIcon />} />}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
