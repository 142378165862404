import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CustomDateTextField, CustomTextField } from '../../../../../../components/General/CustomTextfields';
import useStyles from '../../../styles';
import { ReduxState } from '../../../../../../types';
import { setNewContract } from '../../../../../../actions/studentContractActions';

function ContractUpsertHeader() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id: studentId, contractId } = useParams<{ id: string, contractId?: string }>();
  const dispatch = useDispatch();
  const { contract } = useSelector((state: ReduxState) => state.studentContract);

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | Date | File, source: string,
  ) => {
    if (contract && contract[studentId]) {
      if (source === 'startDate' && contract[studentId].endDate && (e as Date) > contract[studentId].endDate!) {
        enqueueSnackbar('Fecha inicial no puede ser mayor a fecha final', { variant: 'error' });
        return;
      }

      let auxContractInfo;
      if (source === 'comment') {
        auxContractInfo = {
          ...contract[studentId],
          [source]: (e as React.ChangeEvent<HTMLInputElement>).target.value,
        };
      } else {
        auxContractInfo = { ...contract[studentId], [source]: e };
      }

      dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    } else {
      dispatch(setNewContract({ studentId, studentContract: { [source]: e } }));
    }
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
    >
      <Grid item className={classes.contractUpsertHeaderTitleContainer}>
        <Button
          className={classes.contractUpsertHeaderBackButton}
          onClick={() => history.replace(`/alumnos/${studentId}`, { activeTab: 0 })}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          ml={1}
          variant="h3"
          fontWeight='bold'
          display='flex'
          alignItems='center'
        >
          {contractId ? 'Editar contrato' : 'Crear nuevo contrato'}
        </Typography>
      </Grid>
      <Grid item display="flex" alignItems="center" >
        <Grid item marginRight='30px' >
          <CustomTextField
            keyField='comment'
            name='Comentario'
            onChangeFunction={handleFieldChange}
            value={(contract && contract[studentId] && contract[studentId].comment) ?? ''}
          />
        </Grid>
        <Grid item marginRight='30px' >
          <CustomDateTextField
            keyField='startDate'
            name='Fecha inicio del contrato'
            onChangeFunction={handleFieldChange}
            value={(contract && contract[studentId] && contract[studentId].startDate) ?? new Date()}
          />
        </Grid>
        <Grid item>
          <CustomDateTextField
            keyField='endDate'
            name='Fecha fin del contrato'
            onChangeFunction={handleFieldChange}
            value={(contract && contract[studentId] && contract[studentId].endDate)
              ?? new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContractUpsertHeader;
