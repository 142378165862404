import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { THEMES } from '../../../constants';

const headerMinHeight = '84px';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    height: 45,
    width: 45,
  },
  popover: {
    width: 200,
  },
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT ? {
      backgroundColor: theme.palette.background.default,
    } : {}),
    ...(theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {}),
  },
  toolbar: {
    minHeight: headerMinHeight,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '0px',
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.background.main,
      justifyContent: 'start',
      paddingLeft: '0px',
    },
  },
  logo: {
    maxWidth: '300px',
    maxHeight: '60px',
  },
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popoverSettings: {
    width: 320,
    padding: theme.spacing(2),
  },
  icon: {
    filter: theme.settings.filter,
  },
  accountContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: headerMinHeight,
    minWidth: '300px',
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'end',
      width: '100%',
    },
  },
}));

export default useStyles;
