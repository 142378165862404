import React, {
  useRef,
  useState,
} from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { ChevronDown } from 'react-feather';
import { persistorFlush } from '../../../store';

import { logout } from '../../../actions/accountActions';

import useStyles from './styles';
import { ReduxState } from '../../../types';

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { institutionUrlExtension } = useParams<{ institutionUrlExtension: string }>();

  const account = useSelector((state: ReduxState) => state.account);
  const institutionAccount = useSelector((state: ReduxState) => state.institutionAccount);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      dispatch(logout());
      await persistorFlush();
      if (institutionUrlExtension) {
        history.push(`/institucion/${institutionUrlExtension}/login`);
      } else {
        history.push('/');
      }
    } catch (error) {
      enqueueSnackbar('No se pudo cerrar sesión.', {
        variant: 'error',
      });
    }
  };

  return <>
    <Box
      display="flex"
      alignItems="center"
      ref={ref}
    >
      <Avatar
        alt="User"
        className={classes.avatar}
      />
      <Hidden mdDown>
        <Typography
          variant="h4"
          margin={'0px 1rem'}
        >
          {`${account.user?.name || (`${institutionAccount.institutionUser?.name} ${institutionAccount.institutionUser?.lastname}`)}`}
        </Typography>
      </Hidden>
      <IconButton
        component={ButtonBase}
        onClick={handleOpen}
      >
        <ChevronDown />
      </IconButton>
    </Box>
    <Menu
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      keepMounted
      PaperProps={{ className: classes.popover }}
      anchorEl={ref.current}
      open={isOpen}
    >
      {/* <MenuItem
        component={RouterLink}
        to="/"
      >
        <FormattedMessage id='navigation.topbar.account.account' />
      </MenuItem> */}
      <MenuItem onClick={handleLogout}>
        <FormattedMessage id='navigation.topbar.account.closeSession' />
      </MenuItem>
    </Menu>
  </>;
}

export default Account;
