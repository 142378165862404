import {
  AnyAction,
  applyMiddleware, createStore, Dispatch, Middleware,
} from 'redux';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';
import { ENABLE_REDUX_LOGGER } from '../config';
import { CombinedStateType, ReduxState } from '../types';
import AppActions from '../actions/appActions';

interface FlushFunction {
  (): Promise<void>;
}

const persistConfig: PersistConfig<CombinedStateType> = {
  key: 'root',
  storage,
  // blacklist: ['account'],
  // whitelist: []
};

let flush: FlushFunction;

const loggerMiddleware = createLogger();

export function configureStore(preloadedState: ReduxState = {
  account: { user: null },
  studentContract: {
    contract: null,
    contractAddProgramModal: {
      open: false,
      values: {
        feeDiscount: '0',
        subjectId: '',
        selectedProgramId: '',
        comment: '',
      },
    },
  },
  filters: {
    sectionFilters: {
      Programa: '',
      Asignatura: '',
      rowsPerPage: 5,
      page: 0,
      Seccion: '',
    },
    programsFilters: {
      subjectId: '',
      rowsPerPage: 5,
      page: 0,
      searchValue: '',
    },
    testsFilters: {
      subjectId: '',
      rowsPerPage: 5,
      page: 0,
      searchValue: '',
    },
    classesAssistancesFilters: {
      registered: '',
      searchValue: '',
      page: 0,
      rowsPerPage: 5,
      Seccion: '',
    },
    massivesFilters: {
      sectionId: '',
      programName: 'Todos',
      searchValue: '',
      page: 0,
      rowsPerPage: 5,
    },
  },
  form: {},
  institutionAccount: { institutionUser: null },
  institutionUserTest: { userTimer: null },
  _persist: { version: -1, rehydrated: false },
}) {
  const middlewares: Middleware[] = [thunkMiddleware];

  if (ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware as Middleware<object, any, Dispatch<AnyAction>>);
  }

  const thunkMiddlewareTyped: ThunkMiddleware<ReduxState, AppActions> = thunkMiddleware;
  middlewares.push(thunkMiddlewareTyped);

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares),
  );

  // const enhancers = [middlewareEnhancer];
  // const composedEnhancers = compose(...enhancers);

  const pReducer = persistReducer(persistConfig, rootReducer);

  // Before we used composedEnhancers instead of middlewareEnhancer
  const store = createStore(pReducer, preloadedState, middlewareEnhancer);
  const persistor = persistStore(store);
  flush = persistor.flush;

  return { store, persistor };
}

export const persistorFlush = (): Promise<void> => flush();
