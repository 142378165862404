import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from '@mui/icons-material';
import {
  Box,
  ClassNameMap,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import { format } from 'rut.js';
import { useSelector } from 'react-redux';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import {
  deleteTeacher,
  getTeachersPerPage,
  postCreateTeacher,
  putUpdateTeacher,
} from '../../../requests/api/teachers';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import useStyles from './styles';
import { TEACHERS_UI, TeacherData } from './types';
import DeleteModal from '../../../components/General/DeleteModal';
import UpsertTeacherModal from './Modals/UpsertTeacherModal';
import { SECRETARY } from '../../../utils/user_types';
import { ReduxState } from '../../../types';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';

const buttonActions = (
  classes: ClassNameMap<string>,
  teacherId: string,
  openEditModal: ({ open, id }: { open: boolean, id: string }) => void,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box>
  <IconButton onClick={() => openEditModal({ id: teacherId, open: true })}>
    <EditIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => openDeleteModal({ id: teacherId, open: true })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

function TeacherListView() {
  const classes = useStyles();
  const [allTeachers, setAllTeachers] = useState({
    items: [],
    totalItems: 0,
  });
  const [modalAddEditATeacher, setModalAddEditATeacher] = useState(
    { id: '', open: false },
  );
  const [modalDeleteATeacher, setModalDeleteATeacher] = useState({ id: '', open: false });
  const [modalValues, setModalValues] = useState<TeacherData>({
    userId: '',
    rut: '',
    phoneNumber: '',
    birthDate: new Date(),
    moduleFee: '',
    name: '',
    lastname: '',
    email: '',
  });
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [searchValue, setSearchValue] = useState('');
  const [updateTable, setUpdateTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state: ReduxState) => state.account);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllTeachers = async () => {
      try {
        const { teachers, totalTeachers } = await getTeachersPerPage({
          page: tableParameters.page, limit: tableParameters.rowsPerPage, searchValue, sort: 'id|DESC',
        }, source.token);

        const teachersAndActions = teachers.map((elem: TeacherData) => ({
          ...elem,
          name: elem.user && elem.user.name,
          lastname: elem.user && elem.user.lastname,
          email: elem.user && elem.user.email,
          rut: format(elem.rut),
          actions: buttonActions(
            classes,
            elem.id!,
            setModalAddEditATeacher,
            setModalDeleteATeacher,
          ),
        }));
        setAllTeachers({ items: teachersAndActions, totalItems: totalTeachers });
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllTeachers();

    return () => {
      source.cancel();
    };
  }, [tableParameters.page, tableParameters.rowsPerPage, searchValue, updateTable]);

  const handleCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setModalValues({
      userId: '',
      rut: '',
      phoneNumber: '',
      birthDate: new Date(),
      moduleFee: '',
      name: '',
      lastname: '',
      email: '',
    });
    setUpdateTable(!updateTable);
  };

  const addATeacher = async () => {
    try {
      const response = await postCreateTeacher({
        rut: modalValues.rut,
        phoneNumber: modalValues.phoneNumber,
        birthDate: modalValues.birthDate,
        moduleFee: modalValues.moduleFee,
        name: modalValues.name,
        lastname: modalValues.lastname,
        email: modalValues.email,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalAddEditATeacher);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const editATeacher = async (teacherId: string) => {
    try {
      const response = await putUpdateTeacher({
        id: teacherId,
        rut: modalValues.rut,
        birthDate: modalValues.birthDate,
        phoneNumber: modalValues.phoneNumber,
        moduleFee: modalValues.moduleFee,
        name: modalValues.name,
        lastname: modalValues.lastname,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalAddEditATeacher);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const deleteATeacher = async (teacherId: string) => {
    try {
      const response = await deleteTeacher(teacherId);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalDeleteATeacher);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File, source: string,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (!(e instanceof File)) {
      setModalValues(
        (prevState) => ({ ...prevState, [source]: (e.target as HTMLInputElement).value }),
      );
    }
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue((e.target as HTMLInputElement).value);
    if (tableParameters.page !== 0) {
      setTableParameters((prevState) => ({ ...prevState, page: 0 }));
    }
  };

  return (
    <Page
      title="Profesores"
    >
      <Header
        title='Profesores'
        icon={<SupervisorAccountIcon />}
        buttons={(account && account.user && account.user.userType !== SECRETARY && !showLoading)
          ? [{ text: 'Crear profesor', icon: <AddIcon />, onClick: () => setModalAddEditATeacher((prevState) => ({ ...prevState, open: true })) }] : undefined}
        search={!showLoading ? { text: 'Buscar profesor...', value: searchValue, onChangeSearchValue: handleChangeSearchValue } : undefined}
      />
      {showLoading ? <CircularProgressComponent /> : <Box
        mt={3}
      >
        {allTeachers.totalItems !== 0
          && <CustomTable
            headers={TEACHERS_UI.filter((elem) => elem.showOnTable)}
            data={{ values: allTeachers.items, count: allTeachers.totalItems }}
            userType={(account && account.user && account.user.userType) ?? undefined}
            tableParameters={tableParameters}
            setTableParameters={setTableParameters}
          />}
        {allTeachers.totalItems === 0 && <EmptyTable />}
      </Box>}
      <UpsertTeacherModal
        allItems={allTeachers}
        modalAddEdit={modalAddEditATeacher}
        setModalAddEdit={setModalAddEditATeacher}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        setModalValues={setModalValues}
        handleFieldChange={handleFieldChange}
        itemsUI={TEACHERS_UI}
        addFunction={addATeacher}
        editFunction={editATeacher}
      />
      <DeleteModal
        title="profesor"
        modalDelete={modalDeleteATeacher}
        setModalDelete={setModalDeleteATeacher}
        handleCloseModal={handleCloseModal}
        deleteFunction={deleteATeacher}
      />
    </Page>
  );
}

export default TeacherListView;
