import React, { SyntheticEvent } from 'react';
import {
  Divider, Typography, Box, Checkbox,
} from '@mui/material';

import { formatCcy } from '../../../../../../utils/helpers';
import { CustomAutocompleteTextField, CustomTextField } from '../../../../../../components/General/CustomTextfields';

import useStyles from '../../../styles';

type Field = {
  keyField: string,
  fieldType: string,
  name: string,
  value: string,
  options?: { id:string, name: string }[],
  disabled?: boolean,
  noApply?: boolean,
};

type ContractSectionProps = {
  title: string,
  fields: Field[],
  values: { valueWithDiscounts: number, valueWithoutDiscounts: number, discount: boolean },
  handleFieldChange: ((e: React.ChangeEvent<HTMLInputElement> | Date, source: string) => void),
  handleSelectChange?: (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => void,
  disabledProgramsDiscount?: boolean,
  handleDisableDiscount?: (disabled: boolean, discountName: string) => void,
};

function ContractSection({
  title,
  fields,
  values,
  handleFieldChange,
  handleSelectChange,
  disabledProgramsDiscount,
  handleDisableDiscount,
}: ContractSectionProps) {
  const classes = useStyles();

  return (
    <Box className={classes.contractViewContractDiscountContainer}>
      <Box className={classes.contractViewProgramsTitleContainer}>
        <Typography variant='h3' color='textPrimary' fontWeight='bold' mb={4}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.contractTextFieldsContainer}>
        <Box className={classes.contractViewContractGroupingContainer}>
          {fields.map((field) => (
            <Box
            key={field.keyField}
            className={classes.contractViewContractDiscountTextfieldContainer}
            >
              {field.fieldType === 'select' && handleSelectChange !== undefined && (
                <CustomAutocompleteTextField
                  keyField={field.keyField}
                  name={field.name}
                  selectFields={field.options || []}
                  value={field.value}
                  valueToRetrieveFromSelect='id'
                  size='medium'
                  onChangeFunction={handleSelectChange}
                />
              )}
              {field.fieldType === 'textInput' && (
                <CustomTextField
                  keyField={field.keyField}
                  name={field.name}
                  value={field.value}
                  disabled={field.disabled}
                  onChangeFunction={handleFieldChange}
                  className={field.keyField === 'programsDiscount' && field.value !== '0' && (field.noApply || disabledProgramsDiscount) ? classes.lineThroughTextField : ''}
                  inputMessage={field.keyField === 'programsDiscount' && field.value !== '0' && field.noApply ? 'Descuento no aplicado por descuento por convenio' : ''}
                />
              )}
              {field.keyField === 'programsDiscount' && field.value !== '0' && !field.noApply && handleDisableDiscount ? (
                <Box className={classes.discountCheckbox}>
                <Checkbox
                  checked={disabledProgramsDiscount}
                  onChange={(e) => handleDisableDiscount(e.target.checked, 'disabledProgramsDiscount')}
                />
                <Typography>{disabledProgramsDiscount ? 'Activar' : 'Desactivar'} descuento</Typography>
              </Box>
              ) : null}
            </Box>
          ))}
        </Box>
        <Box className={classes.contractViewContractGroupingContainer}>
          <Box className={classes.contractViewContractDiscountIndividualTypography}>
            <Typography fontWeight='bold' variant='h5' color='textPrimary'>
              Valor {title.toLowerCase()}:
            </Typography>
            {values?.discount && values.valueWithoutDiscounts ? (
              <Typography variant='h6' color='textPrimary'>
                <s>{formatCcy(values.valueWithoutDiscounts, '.')}</s>
              </Typography>
            ) : null}
            <Typography variant='h5' color='textPrimary'>
              {formatCcy(values.valueWithDiscounts, '.')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginTop: '30px' }} />
    </Box>
  );
}

export default ContractSection;
