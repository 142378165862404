/* eslint-disable no-console */
import _ from 'lodash';
import {
  colors, createTheme, responsiveFontSizes, Shadows, ThemeOptions,
} from '@mui/material';
import typography from './typography';
import { strongShadows } from './shadows';
import { THEMES } from '../constants';
import { ContextSettings } from '../types';

declare module '@mui/material/styles' {
  interface Theme {
    name: string;
    logo: {
      filter: string;
      filterLoad: string;
    };
    settings: {
      filter: string;
    };
  }

  interface ThemeOptions {
    name?: string;
    overrides?: object;
    logo?: {
      filter?: string;
      filterLoad?: string;
    };
    settings?: {
      filter?: string;
    };
  }

  interface PaletteColorOptions {
    white?: string;
    main?: string;
    button?: string;
    red?: string;
  }

  interface TypeBackground {
    default: string;
    main?: string,
    dark?: string;
    paper: string;
    gray?: string;
    transition?: string;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    tertiary?: string;
  }

  interface PaletteColor {
    white?: string;
    button?: string;
    red?: string;
  }
}

const baseConfig: ThemeOptions = {
  direction: 'ltr',
  typography: {
    ...typography,
    fontFamily: 'Montserrat, sans-serif',
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

const primaryColor = '#2c82b3';

const themeConfigs: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            '&.MuiDayCalendar-weekDayLabel': {
              color: 'black',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          noOptions: {
            color: 'black', // Replace #yourDesiredColor with the actual color code
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: primaryColor,
            '& .MuiTableCell-root': {
              color: 'white',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: 'center',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: primaryColor,
            color: 'white',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#a8a8a8',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: primaryColor,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            borderColor: '#5bc2d2',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: primaryColor,
            '& .MuiSelect-select': {
              color: 'white',
            },
            '& .MuiTableCell-root': {
              padding: '0px',
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            margin: '0px',
          },
          actions: {
            '& .MuiButtonBase-root': {
              color: 'white',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'gray',
          },
        },
      },
      // MuiOutlinedInput: {
      //   styleOverrides: {
      //     input: {
      //       color: primaryColor,
      //     },
      //   },
      // },
      // MuiSelect: {
      //   styleOverrides: {
      //     select: {
      //       color: 'red',
      //     },
      //   },
      // },
      // MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       color: 'red',
      //     },
      //     input: {
      //       color: 'red',
      //       '&::placeholder': {
      //         opacity: 1,
      //         color: primaryColor,
      //       },
      //     },
      //   },
      // },
    },
    // overrides: {
    //   MuiInputBase: {
    //     input: {
    //       '&::placeholder': {
    //         opacity: 1,
    //         color: colors.blueGrey[600],
    //       },
    //     },
    //   },
    // },
    palette: {
      mode: 'light',
      action: {
        active: '#f2ac32',
      },
      background: {
        default: colors.common.white,
        main: primaryColor,
        dark: '#f4f6f8',
        paper: '#f4f6f8',
        gray: '#e2e2e2',
      },
      primary: {
        main: primaryColor,
        white: '#FFFFFF',
        red: '#e94745',
        button: '#e94745',
      },
      secondary: {
        main: '#5bc2d2',
      },
      text: {
        primary: '#706e6f',
        secondary: '#ffffff',
        tertiary: primaryColor,
      },
    },
    logo: {
      filter: 'brightness(0) invert(100%)',
      filterLoad: 'invert(100%)',
    },
    settings: {
      filter: '',
    },
    shadows: strongShadows as Shadows,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
        transition: '#ffffff',
      },
      primary: {
        main: '#002D3B',
        white: '#FFFFFF',
      },
      secondary: {
        button: '#003E6B',
        main: '#e6e5e8',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    logo: {
      filter: '',
      filterLoad: '',
    },
    settings: {
      filter: 'invert(100%)',
    },
    shadows: strongShadows as Shadows,
  },
];

export function createCustomTheme(settings: ContextSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
}) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction },
    ),
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
