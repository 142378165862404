import {
  Box, Card, CardContent, IconButton, Modal, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Add as AddIcon, Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../../../components/General/CustomButton';
import CustomTable from '../../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../../components/General/EmptyTable';
import useStyles from '../../../styles';
import { CustomNumericTextField, CustomSelectTextField, CustomTextField } from '../../../../../../components/General/CustomTextfields';
import { ProgramData, SubjectData } from '../../../../../Program/ProgramListView/types';
import { getPrograms } from '../../../../../../requests/api/sections';
import { getSubjects } from '../../../../../../requests/api/subjects';
import handleApiResponse from '../../../../../../utils/handleApiResponse';
import { ReduxState } from '../../../../../../types';
import { resetAddProgramModalValues, setAddProgramModalValues, setNewContract } from '../../../../../../actions/studentContractActions';
import { formatCcy } from '../../../../../../utils/helpers';
import { StudentProgramTableData } from '../../../types';

type ProgramInfo = {
  id: string; name: string; fee: string; startDate: string; endDate: string;
};

type ModalSelectValues = {
  subjectValues: Array<{ id: string; name: string; }>;
  programValues: ProgramInfo[];
};

function Programs({
  headers,
  data,
}: {
  headers: { label: string, key: string, valueType: string, }[]
  data: { values: StudentProgramTableData[], count: number },
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id: studentId } = useParams<{ id: string }>();
  const location = useLocation();
  const {
    contract,
    contractAddProgramModal,
  } = useSelector((state: ReduxState) => state.studentContract);
  const [modalSelectValues, setModalSelectValues] = useState<ModalSelectValues>({
    subjectValues: [],
    programValues: [],
  });

  useEffect(() => {
    // I need to do it on a separate call, as I need ALL the information,
    // not only the one available for the created sections
    const getSubjectsForFields = async () => {
      let subjectsInfo: { id: string; name: string; }[];

      // Get subjects
      try {
        const { subjects } = await getSubjects();
        subjectsInfo = subjects.map((elem: SubjectData) => ({ id: elem.id, name: elem.name }));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      setModalSelectValues((prevState) => ({ ...prevState, subjectValues: subjectsInfo }));
    };

    getSubjectsForFields();
  }, []);

  useEffect(() => {
    const getProgramValuesForFields = async () => {
      let programInfo: ProgramInfo[];
      // Get programs
      if (contractAddProgramModal && contractAddProgramModal.values
        && contractAddProgramModal.values.subjectId) {
        try {
          const { programs } = await getPrograms(contractAddProgramModal.values.subjectId);
          programInfo = programs.map((elem: ProgramData) => ({
            id: elem.id,
            name: elem.name,
            fee: elem.fee,
            startDate: elem.startDate ? elem.startDate.toString() : '',
            endDate: elem.endDate ? elem.endDate.toString() : '',
          }));

          if (contractAddProgramModal.values.subjectId) {
            setModalSelectValues((prevState) => ({ ...prevState, programValues: programInfo }));
          }
        } catch (err) {
          const e = err as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false);
        }
      }
    };

    getProgramValuesForFields();
  }, [contractAddProgramModal && contractAddProgramModal.values
    && contractAddProgramModal.values.subjectId]);

  const handleAddProgramChange = (
    e: React.ChangeEvent<HTMLInputElement> | Date | File, source: string,
  ) => {
    const { value } = (e as React.ChangeEvent<HTMLInputElement>).target;
    if (source === 'feeDiscount') {
      const finalValue = `${Number(value)}`;
      dispatch(setAddProgramModalValues({
        ...contractAddProgramModal,
        values: {
          ...contractAddProgramModal.values,
          [source]: finalValue,
        },
      }));
    } else {
      dispatch(setAddProgramModalValues({
        ...contractAddProgramModal,
        values: {
          ...contractAddProgramModal.values,
          [source]: value,
        },
      }));
    }
  };

  const handleCloseModal = () => {
    dispatch(resetAddProgramModalValues());
  };

  const handleAddEditProgram = () => {
    const programIdToAdd = contractAddProgramModal.values.selectedProgramId;
    const subjectInfo = modalSelectValues.subjectValues
      .filter((elem) => elem.id === contractAddProgramModal.values.subjectId);
    let programFee;
    let programName;
    let programStartDate;
    let programEndDate;
    modalSelectValues.programValues.forEach((elem) => {
      if (elem.id === contractAddProgramModal.values.selectedProgramId) {
        programFee = elem.fee;
        programName = elem.name;
        programStartDate = elem.startDate;
        programEndDate = elem.endDate;
      }
    });

    if (contractAddProgramModal.values.selectedProgramIdToEdit) {
      const filteredStudentPrograms = contract![studentId].selectedStudentPrograms!
        .filter((elem) => elem.programId !== contractAddProgramModal
          .values.selectedProgramIdToEdit);
      const auxContractInfo = {
        ...contract![studentId],
        selectedStudentPrograms: [...filteredStudentPrograms, {
          programId: programIdToAdd,
          subjectId: subjectInfo[0].id,
          subjectName: subjectInfo[0].name,
          programName: programName ?? '',
          programFee: programFee ?? '',
          programFeeDiscount: contractAddProgramModal.values.feeDiscount ?? 0,
          comment: contractAddProgramModal.values.comment,
          programStartDate: programStartDate ?? '',
          programEndDate: programEndDate ?? '',
        }],
      };
      dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    } else if (contract && contract[studentId]) {
      if (contract[studentId].selectedStudentPrograms) {
        if (!contract[studentId].selectedStudentPrograms!
          .some((elem) => elem.programId === contractAddProgramModal.values.selectedProgramId)) {
          const auxContractInfo = {
            ...contract[studentId],
            selectedStudentPrograms: [...contract[studentId].selectedStudentPrograms!, {
              programId: programIdToAdd,
              subjectId: subjectInfo[0].id,
              subjectName: subjectInfo[0].name,
              programName: programName ?? '',
              programFee: programFee ?? '',
              programFeeDiscount: contractAddProgramModal.values.feeDiscount ?? 0,
              comment: contractAddProgramModal.values.comment,
              programStartDate: programStartDate ?? '',
              programEndDate: programEndDate ?? '',
            }],
          };
          dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
        } else {
          enqueueSnackbar('Programa ya ha sido agregado. Editar la opcion existente', { variant: 'error' });
        }
      } else {
        const auxContractInfo = {
          ...contract[studentId],
          selectedStudentPrograms: [{
            programId: programIdToAdd,
            subjectId: subjectInfo[0].id,
            subjectName: subjectInfo[0].name,
            programName: programName ?? '',
            programFee: programFee ?? '',
            programFeeDiscount: contractAddProgramModal.values.feeDiscount ?? 0,
            comment: contractAddProgramModal.values.comment,
            programStartDate: programStartDate ?? '',
            programEndDate: programEndDate ?? '',
          }],
        };
        dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
      }
    } else {
      dispatch(setNewContract({
        studentId,
        studentContract: {
          selectedStudentPrograms: [{
            programId: programIdToAdd,
            subjectId: subjectInfo[0].id,
            subjectName: subjectInfo[0].name,
            programName: programName ?? '',
            programFee: programFee ?? '',
            programFeeDiscount: contractAddProgramModal.values.feeDiscount ?? 0,
            comment: contractAddProgramModal.values.comment,
            programStartDate: programStartDate ?? '',
            programEndDate: programEndDate ?? '',
          }],
        },
      }));
    }
    dispatch(resetAddProgramModalValues());
  };

  const isEditProgram = Boolean(contractAddProgramModal && contractAddProgramModal.values
    && contractAddProgramModal.values?.selectedProgramIdToEdit);

  return (
    <Box className={classes.contractViewProgramsContainer}>
      <Box className={classes.contractViewProgramsTitleButtonContainer}>
        <Box className={classes.contractViewProgramsTitleContainer}>
          <Typography
            variant='h3'
            color='textPrimary'
            fontWeight='bold'
          >
            Programas
          </Typography>
        </Box>
        <Box className={classes.contractViewProgramsButtonContainer}>
          {location.pathname.includes('contrato') && <CustomButton
            colorType='tertiary'
            text='Agregar programa'
            icon={<AddIcon />}
            onClick={() => dispatch(setAddProgramModalValues({
              open: true,
            }))}
          />}

        </Box>
      </Box>
      <Box className={classes.contractViewProgramsTableContainer}>
        {data.count !== 0
          && <CustomTable
            headers={headers}
            data={data}
          />}
        {data.count === 0 && <EmptyTable />}
      </Box>
      <Modal
        open={contractAddProgramModal.open}
        onClose={handleCloseModal}
      >
        <Box className={classes.modalContainer}>
          <Card className={classes.modal}>
            <CardContent className={classes.modalContent}>
              <Box className={classes.modalTitleContainer}>
                <Box className={classes.modalTitleAndIcon}>
                  {isEditProgram ? <EditIcon color='primary' /> : <AddIcon color='primary' />}

                  <Typography variant='h4' color='primary' fontWeight='bold'>{isEditProgram ? 'Editar programa' : 'Agregar programa'} </Typography>
                </Box>
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon color='primary' />
                </IconButton>
              </Box>
              <Box className={classes.modalTextfieldsContainer}>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomSelectTextField
                    disabled={isEditProgram}
                    keyField={'subjectId'}
                    name={'Asignatura'}
                    value={(contractAddProgramModal && contractAddProgramModal.values
                      && contractAddProgramModal.values.subjectId)
                      ? modalSelectValues.subjectValues.filter((elem) => elem.id === contractAddProgramModal.values.subjectId)[0].id : ''}
                    onChangeFunction={handleAddProgramChange}
                    selectFields={modalSelectValues.subjectValues}
                    valueToRetrieveFromSelect='id'
                  />
                </Box>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomSelectTextField
                    disabled={isEditProgram}
                    keyField={'selectedProgramId'}
                    name={'Programa'}
                    value={(contractAddProgramModal && contractAddProgramModal.values && contractAddProgramModal.values.selectedProgramId) ?? ''}
                    onChangeFunction={handleAddProgramChange}
                    selectFields={modalSelectValues.programValues}
                    valueToRetrieveFromSelect='id'
                  />
                </Box>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomNumericTextField
                    keyField={'feeDiscount'}
                    name={'Monto descuento arancel del programa'}
                    value={(contractAddProgramModal && contractAddProgramModal.values && contractAddProgramModal.values.feeDiscount) ?? '0'}
                    onChangeFunction={handleAddProgramChange}
                  />
                </Box>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomTextField
                    keyField={'comment'}
                    name={'Comentario (opcional)'}
                    value={(contractAddProgramModal && contractAddProgramModal.values && contractAddProgramModal.values.comment) ?? ''}
                    onChangeFunction={handleAddProgramChange}
                  />
                </Box>
                <Box className={classes.modalIndividualTextfield} flexDirection='column'>
                  <Box className={classes.modalIndividualTextfieldTypographyPrice}>
                    <Typography variant='h4' color='primary' fontWeight='bold'>Precio lista arancel: </Typography>
                    <Typography variant='h4' color='textBody'>{contractAddProgramModal?.values?.selectedProgramId
                      ? formatCcy(modalSelectValues.programValues.find((elem) => elem.id === contractAddProgramModal.values.selectedProgramId)?.fee ?? '0', '.')
                      : '$ 0'
                    }</Typography>
                  </Box>
                  <Box className={classes.modalIndividualTextfieldTypographyPrice}>
                    <Typography variant='h4' color='primary' fontWeight='bold'>Precio descuento arancel: </Typography>
                    <Typography variant='h4' color='textBody'>{contractAddProgramModal?.values?.selectedProgramId
                      ? formatCcy((parseInt(modalSelectValues.programValues.find((elem) => elem.id === contractAddProgramModal.values.selectedProgramId)?.fee ?? '0', 10) - (parseInt(contractAddProgramModal?.values?.feeDiscount ?? 0, 10))), '.')
                      : '$ 0'}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.modalButtonsContainer}>
                <CustomButton text='Cancelar' colorType='tertiary' onClick={handleCloseModal} key={'CancelButtonAddProgram'} icon={<CloseIcon />} />
                <CustomButton text={isEditProgram ? 'Editar programa' : 'Agregar programa'} colorType='primary' onClick={handleAddEditProgram} key={'AcceptButtonAddProgram'} icon={<AddIcon />} />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
}

export default Programs;
