import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.main,
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  massiveDetailTableContainer: {
    marginTop: '15px',
  },
}));

export default useStyles;
