import { User } from '../types';

export interface ClassNameProps {
  className?: string;
}

export interface MatchParams {
  id: string;
}

export interface ListProps {
  className?: string;
  users: User[];
  page: string;
  limit: string;
  sort: string;
  query: string;
  countUsers: number;
  toggleSearch: boolean;
  setQuery: (query: string) => void;
  setPage: (page: string) => void;
  setLimit: (limit: string) => void;
  setSort: (sort: string) => void;
  setToggleSearch: (toggleSearch: boolean) => void;
}

export const NATIONALITIES = [
  { id: 'Alemán', name: 'Alemán' },
  { id: 'Americano', name: 'Americano' },
  { id: 'Andorrano', name: 'Andorrano' },
  { id: 'Angolano', name: 'Angolano' },
  { id: 'Antiguano', name: 'Antiguano' },
  { id: 'Árabe', name: 'Árabe' },
  { id: 'Argelino', name: 'Argelino' },
  { id: 'Argentino', name: 'Argentino' },
  { id: 'Arménio', name: 'Arménio' },
  { id: 'Australiano', name: 'Australiano' },
  { id: 'Austríaco', name: 'Austríaco' },
  { id: 'Bahamense', name: 'Bahamense' },
  { id: 'Bangladés', name: 'Bangladés' },
  { id: 'Barbadiano', name: 'Barbadiano' },
  { id: 'Baremita', name: 'Baremita' },
  { id: 'Bielorrusso', name: 'Bielorrusso' },
  { id: 'Belga', name: 'Belga' },
  { id: 'Belizenho', name: 'Belizenho' },
  { id: 'Beninense', name: 'Beninense' },
  { id: 'Boliviano', name: 'Boliviano' },
  { id: 'Bósnio', name: 'Bósnio' },
  { id: 'Bechuano', name: 'Bechuano' },
  { id: 'Brasileiro', name: 'Brasileiro' },
  { id: 'Británico', name: 'Británico' },
  { id: 'Bruneano', name: 'Bruneano' },
  { id: 'Búlgaro', name: 'Búlgaro' },
  { id: 'Burquinense', name: 'Burquinense' },
  { id: 'Burundés', name: 'Burundés' },
  { id: 'Butanense', name: 'Butanense' },
  { id: 'Cabo-verdiano', name: 'Cabo-verdiano' },
  { id: 'Camaronense', name: 'Camaronense' },
  { id: 'Cambojano', name: 'Cambojano' },
  { id: 'Canadense', name: 'Canadense' },
  { id: 'Chadiano', name: 'Chadiano' },
  { id: 'Chinés', name: 'Chinés' },
  { id: 'Chileno', name: 'Chileno' },
  { id: 'Colombiano', name: 'Colombiano' },
  { id: 'Comoriano', name: 'Comoriano' },
  { id: 'Costa-marfinense', name: 'Costa-marfinense' },
  { id: 'Costa-riqueño', name: 'Costa-riqueño' },
  { id: 'Croata', name: 'Croata' },
  { id: 'Cubano', name: 'Cubano' },
  { id: 'Cipriota', name: 'Cipriota' },
  { id: 'Congolense', name: 'Congolense' },
  { id: 'Dinamarqués', name: 'Dinamarqués' },
  { id: 'Dominicano', name: 'Dominicano' },
  { id: 'Equatoriano', name: 'Equatoriano' },
  { id: 'Egípcio', name: 'Egípcio' },
  { id: 'Galés', name: 'Galés' },
  { id: 'Iemenita', name: 'Iemenita' },
  { id: 'Inglés', name: 'Inglés' },
  { id: 'Estoniano', name: 'Estoniano' },
  { id: 'Fijiano', name: 'Fijiano' },
  { id: 'Finlandés', name: 'Finlandés' },
  { id: 'Francés', name: 'Francés' },
  { id: 'Gabonense', name: 'Gabonense' },
  { id: 'Gambiano', name: 'Gambiano' },
  { id: 'Geórgico', name: 'Geórgico' },
  { id: 'Granadino', name: 'Granadino' },
  { id: 'Griego', name: 'Griego' },
  { id: 'Guatemalteco', name: 'Guatemalteco' },
  { id: 'Guineano', name: 'Guineano' },
  { id: 'Guineense', name: 'Guineense' },
  { id: 'Guianense', name: 'Guianense' },
  { id: 'Haitiano', name: 'Haitiano' },
  { id: 'Holandés', name: 'Holandés' },
  { id: 'Hondureño', name: 'Hondureño' },
  { id: 'Húngaro', name: 'Húngaro' },
  { id: 'Islandés', name: 'Islandés' },
  { id: 'Indiano', name: 'Indiano' },
  { id: 'Indonésio', name: 'Indonésio' },
  { id: 'Iraniano', name: 'Iraniano' },
  { id: 'Irlandés', name: 'Irlandés' },
  { id: 'Israelita', name: 'Israelita' },
  { id: 'Italiano', name: 'Italiano' },
  { id: 'Jamaicano', name: 'Jamaicano' },
  { id: 'Japonés', name: 'Japonés' },
  { id: 'Keniano', name: 'Keniano' },
  { id: 'Kuwaitiano', name: 'Kuwaitiano' },
  { id: 'Laosiano', name: 'Laosiano' },
  { id: 'Letoniano', name: 'Letoniano' },
  { id: 'Libanés', name: 'Libanés' },
  { id: 'Liberiano', name: 'Liberiano' },
  { id: 'Lituano', name: 'Lituano' },
  { id: 'Luxemburgués', name: 'Luxemburgués' },
  { id: 'Líbio', name: 'Líbio' },
  { id: 'Madagascarense', name: 'Madagascarense' },
  { id: 'Maldivo', name: 'Maldivo' },
  { id: 'Maltés', name: 'Maltés' },
  { id: 'Mauriciano', name: 'Mauriciano' },
  { id: 'Mauritano', name: 'Mauritano' },
  { id: 'Mexicano', name: 'Mexicano' },
  { id: 'Marroquino', name: 'Marroquino' },
  { id: 'Moldávio', name: 'Moldávio' },
  { id: 'Mongol', name: 'Mongol' },
  { id: 'Montenegrino', name: 'Montenegrino' },
  { id: 'Mozambiqueño', name: 'Mozambiqueño' },
  { id: 'Birmanés', name: 'Birmanés' },
  { id: 'Namibiano', name: 'Namibiano' },
  { id: 'Nauruano', name: 'Nauruano' },
  { id: 'Nepalés', name: 'Nepalés' },
  { id: 'Neozelandés', name: 'Neozelandés' },
  { id: 'Nicaraguense', name: 'Nicaraguense' },
  { id: 'Nigeriano', name: 'Nigeriano' },
  { id: 'Norte-coreano', name: 'Norte-coreano' },
  { id: 'Omanense', name: 'Omanense' },
  { id: 'Palestino', name: 'Palestino' },
  { id: 'Paquistanés', name: 'Paquistanés' },
  { id: 'Panameño', name: 'Panameño' },
  { id: 'Papú', name: 'Papú' },
  { id: 'Paraguayo', name: 'Paraguayo' },
  { id: 'Peruano', name: 'Peruano' },
  { id: 'Filipino', name: 'Filipino' },
  { id: 'Polonés', name: 'Polonés' },
  { id: 'Portugués', name: 'Portugués' },
  { id: 'Catarense', name: 'Catarense' },
  { id: 'Ruso', name: 'Ruso' },
  { id: 'Samoano', name: 'Samoano' },
  { id: 'Escocés', name: 'Escocés' },
  { id: 'Salvadoreño', name: 'Salvadoreño' },
  { id: 'Senegalés', name: 'Senegalés' },
  { id: 'Serbio', name: 'Serbio' },
  { id: 'Seychellense', name: 'Seychellense' },
  { id: 'Serra-leonés', name: 'Serra-leonés' },
  { id: 'Singapurense', name: 'Singapurense' },
  { id: 'Eslovaco', name: 'Eslovaco' },
  { id: 'Somali', name: 'Somali' },
  { id: 'Sur-africano', name: 'Sur-africano' },
  { id: 'Coreano', name: 'Coreano' },
  { id: 'Español', name: 'Español' },
  { id: 'Srilankés', name: 'Srilankés' },
  { id: 'Sudanés', name: 'Sudanés' },
  { id: 'Surinamés', name: 'Surinamés' },
  { id: 'Sueco', name: 'Sueco' },
  { id: 'Suízo', name: 'Suízo' },
  { id: 'Sírio', name: 'Sírio' },
  { id: 'Tanzaniano', name: 'Tanzaniano' },
  { id: 'Tailandés', name: 'Tailandés' },
  { id: 'Togolés', name: 'Togolés' },
  { id: 'Tonganés', name: 'Tonganés' },
  { id: 'Trinitense', name: 'Trinitense' },
  { id: 'Tunisiano', name: 'Tunisiano' },
  { id: 'Turco', name: 'Turco' },
  { id: 'Ucraniano', name: 'Ucraniano' },
  { id: 'Ugandés', name: 'Ugandés' },
  { id: 'Uruguayo', name: 'Uruguayo' },
  { id: 'Uzbeque', name: 'Uzbeque' },
  { id: 'Venezuelano', name: 'Venezuelano' },
  { id: 'Vietnamita', name: 'Vietnamita' },
  { id: 'Zambiano', name: 'Zambiano' },
  { id: 'Zimbabueano', name: 'Zimbabueano' },
];

export const COMMUNES = [
  { id: 'Arica', name: 'Arica' },
  { id: 'Camarones', name: 'Camarones' },
  { id: 'General Lagos', name: 'General Lagos' },
  { id: 'Putre', name: 'Putre' },
  { id: 'Alto Hospicio', name: 'Alto Hospicio' },
  { id: 'Camiña', name: 'Camiña' },
  { id: 'Colchane', name: 'Colchane' },
  { id: 'Huara', name: 'Huara' },
  { id: 'Iquique', name: 'Iquique' },
  { id: 'Pica', name: 'Pica' },
  { id: 'Pozo Almonte', name: 'Pozo Almonte' },
  { id: 'Antofagasta', name: 'Antofagasta' },
  { id: 'Calama', name: 'Calama' },
  { id: 'María Elena', name: 'María Elena' },
  { id: 'Mejillones', name: 'Mejillones' },
  { id: 'Ollagüe', name: 'Ollagüe' },
  { id: 'San Pedro de Atacama', name: 'San Pedro de Atacama' },
  { id: 'Sierra Gorda', name: 'Sierra Gorda' },
  { id: 'Taltal', name: 'Taltal' },
  { id: 'Tocopilla', name: 'Tocopilla' },
  { id: 'Alto del Carmen', name: 'Alto del Carmen' },
  { id: 'Caldera', name: 'Caldera' },
  { id: 'Chañaral', name: 'Chañaral' },
  { id: 'Copiapó', name: 'Copiapó' },
  { id: 'Diego de Almagro', name: 'Diego de Almagro' },
  { id: 'Freirina', name: 'Freirina' },
  { id: 'Huasco', name: 'Huasco' },
  { id: 'Tierra Amarilla', name: 'Tierra Amarilla' },
  { id: 'Vallenar', name: 'Vallenar' },
  { id: 'Andacollo', name: 'Andacollo' },
  { id: 'Canela', name: 'Canela' },
  { id: 'Combarbalá', name: 'Combarbalá' },
  { id: 'Coquimbo', name: 'Coquimbo' },
  { id: 'Illapel', name: 'Illapel' },
  { id: 'La Higuera', name: 'La Higuera' },
  { id: 'La Serena', name: 'La Serena' },
  { id: 'Los Vilos', name: 'Los Vilos' },
  { id: 'Monte Patria', name: 'Monte Patria' },
  { id: 'Ovalle', name: 'Ovalle' },
  { id: 'Paiguano', name: 'Paiguano' },
  { id: 'Punitaqui', name: 'Punitaqui' },
  { id: 'Río Hurtado', name: 'Río Hurtado' },
  { id: 'Salamanca', name: 'Salamanca' },
  { id: 'Vicuña', name: 'Vicuña' },
  { id: 'Algarrobo', name: 'Algarrobo' },
  { id: 'Cabildo', name: 'Cabildo' },
  { id: 'Calera', name: 'Calera' },
  { id: 'Calle Larga', name: 'Calle Larga' },
  { id: 'Cartagena', name: 'Cartagena' },
  { id: 'Casablanca', name: 'Casablanca' },
  { id: 'Catemu', name: 'Catemu' },
  { id: 'Concón', name: 'Concón' },
  { id: 'El Quisco', name: 'El Quisco' },
  { id: 'El Tabo', name: 'El Tabo' },
  { id: 'Hijuelas', name: 'Hijuelas' },
  { id: 'Isla de Pascua', name: 'Isla de Pascua' },
  { id: 'Juan Fernández', name: 'Juan Fernández' },
  { id: 'La Cruz', name: 'La Cruz' },
  { id: 'La Ligua', name: 'La Ligua' },
  { id: 'Limache', name: 'Limache' },
  { id: 'Llaillay', name: 'Llaillay' },
  { id: 'Los Andes', name: 'Los Andes' },
  { id: 'Nogales', name: 'Nogales' },
  { id: 'Olmué', name: 'Olmué' },
  { id: 'Panquehue', name: 'Panquehue' },
  { id: 'Papudo', name: 'Papudo' },
  { id: 'Petorca', name: 'Petorca' },
  { id: 'Puchuncaví', name: 'Puchuncaví' },
  { id: 'Putaendo', name: 'Putaendo' },
  { id: 'Quillota', name: 'Quillota' },
  { id: 'Quilpué', name: 'Quilpué' },
  { id: 'Quintero', name: 'Quintero' },
  { id: 'Rinconada', name: 'Rinconada' },
  { id: 'San Antonio', name: 'San Antonio' },
  { id: 'San Esteban', name: 'San Esteban' },
  { id: 'San Felipe', name: 'San Felipe' },
  { id: 'Santa María', name: 'Santa María' },
  { id: 'Santo Domingo', name: 'Santo Domingo' },
  { id: 'Valparaíso', name: 'Valparaíso' },
  { id: 'Villa Alemana', name: 'Villa Alemana' },
  { id: 'Viña del Mar', name: 'Viña del Mar' },
  { id: 'Zapallar', name: 'Zapallar' },
  { id: 'Alhué', name: 'Alhué' },
  { id: 'Buin', name: 'Buin' },
  { id: 'Calera de Tango', name: 'Calera de Tango' },
  { id: 'Cerrillos', name: 'Cerrillos' },
  { id: 'Cerro Navia', name: 'Cerro Navia' },
  { id: 'Colina', name: 'Colina' },
  { id: 'Conchalí', name: 'Conchalí' },
  { id: 'Curacaví', name: 'Curacaví' },
  { id: 'El Bosque', name: 'El Bosque' },
  { id: 'El Monte', name: 'El Monte' },
  { id: 'Estación Central', name: 'Estación Central' },
  { id: 'Huechuraba', name: 'Huechuraba' },
  { id: 'Independencia', name: 'Independencia' },
  { id: 'Isla de Maipo', name: 'Isla de Maipo' },
  { id: 'La Cisterna', name: 'La Cisterna' },
  { id: 'La Florida', name: 'La Florida' },
  { id: 'La Granja', name: 'La Granja' },
  { id: 'La Pintana', name: 'La Pintana' },
  { id: 'La Reina', name: 'La Reina' },
  { id: 'Lampa', name: 'Lampa' },
  { id: 'Las Condes', name: 'Las Condes' },
  { id: 'Lo Barnechea', name: 'Lo Barnechea' },
  { id: 'Lo Espejo', name: 'Lo Espejo' },
  { id: 'Lo Prado', name: 'Lo Prado' },
  { id: 'Macul', name: 'Macul' },
  { id: 'Maipú', name: 'Maipú' },
  { id: 'María Pinto', name: 'María Pinto' },
  { id: 'Melipilla', name: 'Melipilla' },
  { id: 'Ñuñoa', name: 'Ñuñoa' },
  { id: 'Padre Hurtado', name: 'Padre Hurtado' },
  { id: 'Paine', name: 'Paine' },
  { id: 'Pedro Aguirre Cerda', name: 'Pedro Aguirre Cerda' },
  { id: 'Peñaflor', name: 'Peñaflor' },
  { id: 'Peñalolén', name: 'Peñalolén' },
  { id: 'Pirque', name: 'Pirque' },
  { id: 'Providencia', name: 'Providencia' },
  { id: 'Pudahuel', name: 'Pudahuel' },
  { id: 'Puente Alto', name: 'Puente Alto' },
  { id: 'Quilicura', name: 'Quilicura' },
  { id: 'Quinta Normal', name: 'Quinta Normal' },
  { id: 'Recoleta', name: 'Recoleta' },
  { id: 'Renca', name: 'Renca' },
  { id: 'San Bernardo', name: 'San Bernardo' },
  { id: 'San Joaquín', name: 'San Joaquín' },
  { id: 'San José de Maipo', name: 'San José de Maipo' },
  { id: 'San Miguel', name: 'San Miguel' },
  { id: 'San Pedro', name: 'San Pedro' },
  { id: 'San Ramón', name: 'San Ramón' },
  { id: 'Santiago', name: 'Santiago' },
  { id: 'Talagante', name: 'Talagante' },
  { id: 'Tiltil', name: 'Tiltil' },
  { id: 'Vitacura', name: 'Vitacura' },
  { id: 'Chimbarongo', name: 'Chimbarongo' },
  { id: 'Chépica', name: 'Chépica' },
  { id: 'Codegua', name: 'Codegua' },
  { id: 'Coinco', name: 'Coinco' },
  { id: 'Coltauco', name: 'Coltauco' },
  { id: 'Doñihue', name: 'Doñihue' },
  { id: 'Graneros', name: 'Graneros' },
  { id: 'La Estrella', name: 'La Estrella' },
  { id: 'Las Cabras', name: 'Las Cabras' },
  { id: 'Litueche', name: 'Litueche' },
  { id: 'Lolol', name: 'Lolol' },
  { id: 'Machalí', name: 'Machalí' },
  { id: 'Malloa', name: 'Malloa' },
  { id: 'Marchihue', name: 'Marchihue' },
  { id: 'Nancagua', name: 'Nancagua' },
  { id: 'Navidad', name: 'Navidad' },
  { id: 'Olivar', name: 'Olivar' },
  { id: 'Mostazal', name: 'Mostazal' },
  { id: 'Palmilla', name: 'Palmilla' },
  { id: 'Paredones', name: 'Paredones' },
  { id: 'Peralillo', name: 'Peralillo' },
  { id: 'Peumo', name: 'Peumo' },
  { id: 'Pichidegua', name: 'Pichidegua' },
  { id: 'Pichilemu', name: 'Pichilemu' },
  { id: 'Placilla', name: 'Placilla' },
  { id: 'Pumanque', name: 'Pumanque' },
  { id: 'Quinta de Tilcoco', name: 'Quinta de Tilcoco' },
  { id: 'Rancagua', name: 'Rancagua' },
  { id: 'Rengo', name: 'Rengo' },
  { id: 'Requínoa', name: 'Requínoa' },
  { id: 'San Fernando', name: 'San Fernando' },
  { id: 'San Vicente', name: 'San Vicente' },
  { id: 'Santa Cruz', name: 'Santa Cruz' },
  { id: 'Cauquenes', name: 'Cauquenes' },
  { id: 'Chanco', name: 'Chanco' },
  { id: 'Colbún', name: 'Colbún' },
  { id: 'Constitución', name: 'Constitución' },
  { id: 'Curepto', name: 'Curepto' },
  { id: 'Curicó', name: 'Curicó' },
  { id: 'Empedrado', name: 'Empedrado' },
  { id: 'Hualañé', name: 'Hualañé' },
  { id: 'Licantén', name: 'Licantén' },
  { id: 'Linares', name: 'Linares' },
  { id: 'Longaví', name: 'Longaví' },
  { id: 'Maule', name: 'Maule' },
  { id: 'Molina', name: 'Molina' },
  { id: 'Parral', name: 'Parral' },
  { id: 'Pelarco', name: 'Pelarco' },
  { id: 'Pelluhue', name: 'Pelluhue' },
  { id: 'Pencahue', name: 'Pencahue' },
  { id: 'Rauco', name: 'Rauco' },
  { id: 'Retiro', name: 'Retiro' },
  { id: 'Romeral', name: 'Romeral' },
  { id: 'Río Claro', name: 'Río Claro' },
  { id: 'Sagrada Familia', name: 'Sagrada Familia' },
  { id: 'San Clemente', name: 'San Clemente' },
  { id: 'San Javier de Loncomilla', name: 'San Javier de Loncomilla' },
  { id: 'San Rafael', name: 'San Rafael' },
  { id: 'Talca', name: 'Talca' },
  { id: 'Teno', name: 'Teno' },
  { id: 'Vichuquén', name: 'Vichuquén' },
  { id: 'Villa Alegre', name: 'Villa Alegre' },
  { id: 'Yerbas Buenas', name: 'Yerbas Buenas' },
  { id: 'Bulnes', name: 'Bulnes' },
  { id: 'Chillán Viejo', name: 'Chillán Viejo' },
  { id: 'Chillán', name: 'Chillán' },
  { id: 'Cobquecura', name: 'Cobquecura' },
  { id: 'Coelemu', name: 'Coelemu' },
  { id: 'Coihueco', name: 'Coihueco' },
  { id: 'El Carmen', name: 'El Carmen' },
  { id: 'Ninhue', name: 'Ninhue' },
  { id: 'Ñiquén', name: 'Ñiquén' },
  { id: 'Pemuco', name: 'Pemuco' },
  { id: 'Pinto', name: 'Pinto' },
  { id: 'Portezuelo', name: 'Portezuelo' },
  { id: 'Quillón', name: 'Quillón' },
  { id: 'Quirihue', name: 'Quirihue' },
  { id: 'Ránquil', name: 'Ránquil' },
  { id: 'San Carlos', name: 'San Carlos' },
  { id: 'San Fabián', name: 'San Fabián' },
  { id: 'San Ignacio', name: 'San Ignacio' },
  { id: 'San Nicolás', name: 'San Nicolás' },
  { id: 'Treguaco', name: 'Treguaco' },
  { id: 'Yungay', name: 'Yungay' },
  { id: 'Alto Biobío', name: 'Alto Biobío' },
  { id: 'Antuco', name: 'Antuco' },
  { id: 'Arauco', name: 'Arauco' },
  { id: 'Cabrero', name: 'Cabrero' },
  { id: 'Cañete', name: 'Cañete' },
  { id: 'Chiguayante', name: 'Chiguayante' },
  { id: 'Concepción', name: 'Concepción' },
  { id: 'Contulmo', name: 'Contulmo' },
  { id: 'Coronel', name: 'Coronel' },
  { id: 'Curanilahue', name: 'Curanilahue' },
  { id: 'Florida', name: 'Florida' },
  { id: 'Hualpén', name: 'Hualpén' },
  { id: 'Hualqui', name: 'Hualqui' },
  { id: 'Laja', name: 'Laja' },
  { id: 'Lebu', name: 'Lebu' },
  { id: 'Los Álamos', name: 'Los Álamos' },
  { id: 'Los Ángeles', name: 'Los Ángeles' },
  { id: 'Lota', name: 'Lota' },
  { id: 'Mulchén', name: 'Mulchén' },
  { id: 'Nacimiento', name: 'Nacimiento' },
  { id: 'Negrete', name: 'Negrete' },
  { id: 'Penco', name: 'Penco' },
  { id: 'Quilaco', name: 'Quilaco' },
  { id: 'Quilleco', name: 'Quilleco' },
  { id: 'San Pedro de la Paz', name: 'San Pedro de la Paz' },
  { id: 'San Rosendo', name: 'San Rosendo' },
  { id: 'Santa Bárbara', name: 'Santa Bárbara' },
  { id: 'Santa Juana', name: 'Santa Juana' },
  { id: 'Talcahuano', name: 'Talcahuano' },
  { id: 'Tirúa', name: 'Tirúa' },
  { id: 'Tomé', name: 'Tomé' },
  { id: 'Tucapel', name: 'Tucapel' },
  { id: 'Yumbel', name: 'Yumbel' },
  { id: 'Angol', name: 'Angol' },
  { id: 'Carahue', name: 'Carahue' },
  { id: 'Cholchol', name: 'Cholchol' },
  { id: 'Collipulli', name: 'Collipulli' },
  { id: 'Cunco', name: 'Cunco' },
  { id: 'Curacautín', name: 'Curacautín' },
  { id: 'Curarrehue', name: 'Curarrehue' },
  { id: 'Ercilla', name: 'Ercilla' },
  { id: 'Freire', name: 'Freire' },
  { id: 'Galvarino', name: 'Galvarino' },
  { id: 'Gorbea', name: 'Gorbea' },
  { id: 'Lautaro', name: 'Lautaro' },
  { id: 'Loncoche', name: 'Loncoche' },
  { id: 'Lonquimay', name: 'Lonquimay' },
  { id: 'Los Sauces', name: 'Los Sauces' },
  { id: 'Lumaco', name: 'Lumaco' },
  { id: 'Melipeuco', name: 'Melipeuco' },
  { id: 'Nueva Imperial', name: 'Nueva Imperial' },
  { id: 'Padre las Casas', name: 'Padre las Casas' },
  { id: 'Perquenco', name: 'Perquenco' },
  { id: 'Pitrufquén', name: 'Pitrufquén' },
  { id: 'Pucón', name: 'Pucón' },
  { id: 'Purén', name: 'Purén' },
  { id: 'Renaico', name: 'Renaico' },
  { id: 'Saavedra', name: 'Saavedra' },
  { id: 'Temuco', name: 'Temuco' },
  { id: 'Teodoro Schmidt', name: 'Teodoro Schmidt' },
  { id: 'Toltén', name: 'Toltén' },
  { id: 'Traiguén', name: 'Traiguén' },
  { id: 'Victoria', name: 'Victoria' },
  { id: 'Vilcún', name: 'Vilcún' },
  { id: 'Villarrica', name: 'Villarrica' },
  { id: 'Corral', name: 'Corral' },
  { id: 'Futrono', name: 'Futrono' },
  { id: 'La Unión', name: 'La Unión' },
  { id: 'Lago Ranco', name: 'Lago Ranco' },
  { id: 'Lanco', name: 'Lanco' },
  { id: 'Los Lagos', name: 'Los Lagos' },
  { id: 'Mariquina', name: 'Mariquina' },
  { id: 'Máfil', name: 'Máfil' },
  { id: 'Paillaco', name: 'Paillaco' },
  { id: 'Panguipulli', name: 'Panguipulli' },
  { id: 'Río Bueno', name: 'Río Bueno' },
  { id: 'Valdivia', name: 'Valdivia' },
  { id: 'Ancud', name: 'Ancud' },
  { id: 'Calbuco', name: 'Calbuco' },
  { id: 'Castro', name: 'Castro' },
  { id: 'Chaitén', name: 'Chaitén' },
  { id: 'Chonchi', name: 'Chonchi' },
  { id: 'Cochamó', name: 'Cochamó' },
  { id: 'Curaco de Vélez', name: 'Curaco de Vélez' },
  { id: 'Dalcahue', name: 'Dalcahue' },
  { id: 'Fresia', name: 'Fresia' },
  { id: 'Frutillar', name: 'Frutillar' },
  { id: 'Futaleufú', name: 'Futaleufú' },
  { id: 'Hualaihué', name: 'Hualaihué' },
  { id: 'Llanquihue', name: 'Llanquihue' },
  { id: 'Los Muermos', name: 'Los Muermos' },
  { id: 'Maullín', name: 'Maullín' },
  { id: 'Osorno', name: 'Osorno' },
  { id: 'Palena', name: 'Palena' },
  { id: 'Puerto Montt', name: 'Puerto Montt' },
  { id: 'Puerto Octay', name: 'Puerto Octay' },
  { id: 'Puerto Varas', name: 'Puerto Varas' },
  { id: 'Puqueldón', name: 'Puqueldón' },
  { id: 'Purranque', name: 'Purranque' },
  { id: 'Puyehue', name: 'Puyehue' },
  { id: 'Queilén', name: 'Queilén' },
  { id: 'Quellón', name: 'Quellón' },
  { id: 'Quemchi', name: 'Quemchi' },
  { id: 'Quinchao', name: 'Quinchao' },
  { id: 'Río Negro', name: 'Río Negro' },
  { id: 'San Juan de la Costa', name: 'San Juan de la Costa' },
  { id: 'San Pablo', name: 'San Pablo' },
  { id: 'Aysén', name: 'Aysén' },
  { id: 'Chile Chico', name: 'Chile Chico' },
  { id: 'Cisnes', name: 'Cisnes' },
  { id: 'Cochrane', name: 'Cochrane' },
  { id: 'Coyhaique', name: 'Coyhaique' },
  { id: 'Guaitecas', name: 'Guaitecas' },
  { id: 'Lago Verde', name: 'Lago Verde' },
  { id: 'O’Higgins', name: 'O’Higgins' },
  { id: 'Río Ibáñez', name: 'Río Ibáñez' },
  { id: 'Tortel', name: 'Tortel' },
  { id: 'Antártica', name: 'Antártica' },
  { id: 'Cabo de Hornos', name: 'Cabo de Hornos' },
  { id: 'Laguna Blanca', name: 'Laguna Blanca' },
  { id: 'Natales', name: 'Natales' },
  { id: 'Porvenir', name: 'Porvenir' },
  { id: 'Primavera', name: 'Primavera' },
  { id: 'Punta Arenas', name: 'Punta Arenas' },
  { id: 'Río Verde', name: 'Río Verde' },
  { id: 'San Gregorio', name: 'San Gregorio' },
  { id: 'Timaukel', name: 'Timaukel' },
  { id: 'Torres del Paine', name: 'Torres del Paine' },
];

export const MARITAL_STATUS = [
  { id: 'Soltero/a', name: 'Soltero/a' },
  { id: 'Casado/a', name: 'Casado/a' },
  { id: 'Viudo/a', name: 'Viudo/a' },
  { id: 'Divorciado/a', name: 'Divorciado/a' },
];
