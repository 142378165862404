import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { History, Location } from 'history';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { Box, ClassNameMap, IconButton } from '@mui/material';
import { RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';

import { getStudentsInSection } from '../../../requests/api/sections';
import handleApiResponse from '../../../utils/handleApiResponse';

import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import EmptyTable from '../../../components/General/EmptyTable';
import SectionDetailHeader from './Components/SectionDetailHeader';
import SectionDetailInformation from './Components/SectionDetailInformation';

import useStyles from './styles';

const SECTION_DETAIL_UI = [
  {
    label: 'Rut', key: 'rut', valueType: 'string',
  },
  {
    label: 'Nombre', key: 'name', valueType: 'string',
  },
  {
    label: 'Apellido', key: 'lastname', valueType: 'string',
  },
  {
    label: 'Email', key: 'email', valueType: 'string',
  },
  {
    label: '% Asistencia', key: 'attendanceAverage', valueType: 'string',
  },
  {
    label: '% Atrasos', key: 'latenessAverage', valueType: 'string',
  },
  {
    label: 'Entregas / Total', key: 'submittedTests', valueType: 'string',
  },
  {
    label: '% Correctas', key: 'correctAnswers', valueType: 'string',
  },
  {
    label: '% Incorrectas', key: 'wrongAnswers', valueType: 'string',
  },
  {
    label: '% Omitidas', key: 'omittedAnswers', valueType: 'string',
  },
  {
    label: 'Ver asistencia', key: 'attendance', valueType: 'other',
  },
  {
    label: 'Ver guías', key: 'tests', valueType: 'other',
  },
];

type StudentsSectionData = {
  id: string,
  attend: string,
  absent: string,
  arrivedLate: string,
  testsAnswered: string,
  correctAnswers: string,
  wrongAnswers: string,
  omittedAnswers: string,
  student: {
    id: string,
    rut: string,
    user: {
      name: string,
      lastname: string,
      email: string,
    },
  }
};

interface LocationState {
  mipreuId: string,
}
const viewButton = (
  classes: ClassNameMap<string>,
  studentId: string,
  studentName: string,
  handleView: (studentId: string, studentName: string) => void,
) => (
  <IconButton onClick={() => handleView(studentId, studentName)}>
    <RemoveRedEyeIcon className={classes.icons} />
  </IconButton>
);

const calculatePercentage = (
  value: number, total: number,
) => (total === 0 ? 0 : Math.round((value * 100) / total));

const generateStudentUI = (
  student: StudentsSectionData,
  testsNumber: string,
  history: History,
  sectionId: string,
  location: Location<LocationState>,
  classes: ClassNameMap<string>,
) => {
  const totalClasses = (
    Number(student.attend) + Number(student.arrivedLate) + Number(student.absent)
  );
  const totalAnswers = (
    Number(student.correctAnswers) + Number(student.wrongAnswers) + Number(student.omittedAnswers)
  );

  const attendanceAverage = calculatePercentage(Number(student.attend), totalClasses);
  const latenessAverage = calculatePercentage(Number(student.arrivedLate), totalClasses);
  const submittedTests = `${student.testsAnswered} / ${testsNumber}`;
  const correctAnswers = calculatePercentage(Number(student.correctAnswers), totalAnswers);
  const wrongAnswers = calculatePercentage(Number(student.wrongAnswers), totalAnswers);
  const omittedAnswers = calculatePercentage(Number(student.omittedAnswers), totalAnswers);

  const handleViewTests = (studentId: string, studentName: string) => {
    history.push({
      pathname: `/secciones/${sectionId}/alumno/${studentId}/guias`,
      state: { student: studentName, mipreuId: location.state.mipreuId },
    });
  };

  const handleViewAssistances = (studentId: string, studentName: string) => {
    history.push({
      pathname: `/secciones/${sectionId}/alumno/${studentId}/asistencia`,
      state: { student: studentName, mipreuId: location.state.mipreuId },
    });
  };

  return {
    rut: student.student.rut,
    name: student.student.user.name,
    lastname: student.student.user.lastname,
    email: student.student.user.email,
    attendanceAverage,
    latenessAverage,
    submittedTests,
    correctAnswers,
    wrongAnswers,
    omittedAnswers,
    attendance: viewButton(classes, student.student.id, `${student.student.user.name} ${student.student.user.lastname}`, handleViewAssistances),
    tests: viewButton(classes, student.student.id, `${student.student.user.name} ${student.student.user.lastname}`, handleViewTests),
  };
};

const SectionDetailView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id: sectionId }: { id: string } = useParams();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [studentsUI, setStudentsUI] = useState([]);

  useEffect(() => {
    const getStudentsFromSection = async () => {
      try {
        const { studentsWithStatistics } = await getStudentsInSection(sectionId);
        const { students, testsNumber } = studentsWithStatistics;

        const studentsFormatted = students.map((
          elem: StudentsSectionData,
        ) => generateStudentUI(elem, testsNumber, history, sectionId, location, classes));

        setStudentsUI(studentsFormatted);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getStudentsFromSection();
  }, []);

  return (
    <Page
      title='Sección | Detalles'
    >
      <SectionDetailHeader mipreuId={location.state.mipreuId}/>
      <SectionDetailInformation sectionId={sectionId} />
      <Box mt={4}>
        {studentsUI.length > 0 && <CustomTable
          headers={SECTION_DETAIL_UI}
          data={{ values: studentsUI, count: studentsUI.length }}
        />}
        {studentsUI.length === 0 && <EmptyTable />}
      </Box>

    </Page>
  );
};

export default SectionDetailView;
