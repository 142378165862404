import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { useParams } from 'react-router';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import handleApiResponse from '../../../../utils/handleApiResponse';
import {
  CustomAutocompleteTextField,
  CustomNumericTextField,
  CustomSelectTextField,
  CustomTextField,
} from '../../../../components/General/CustomTextfields';
import CustomButton from '../../../../components/General/CustomButton';
import { getPrograms, getTests } from '../../../../requests/api/massive';

type ProgramTest = {
  deliverDate: Date,
  number: number,
  numberOfDeliveries: number,
  test: {
    id: string,
    name: string,
  }
};
const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modal: {
    maxHeight: '90%',
    maxWidth: '890px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalDelete: {
    maxHeight: '290px',
    maxWidth: '690px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitleAndIcon: {
    display: 'flex',
  },
  modalTextfieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modalIndividualTextfield: {
    display: 'flex',
    minWidth: '50%',
    margin: '15px 0px',
    padding: '0px 10px',
  },
  modalButtonsContainer: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'end',
  },
  modalDeleteBody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  modalDeleteButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  modalFileUploadLabel: {
    width: '100%',
    display: 'flex',
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
}));

export default function FeedbackMassiveModal({
  title,
  modalAddEdit,
  setModalAddEdit,
  handleCloseModal,
  modalValues,
  handleFieldChange,
  itemsUI,
  handleAddFunction,
}: {
  title: string,
  modalAddEdit: { id: string, open: boolean },
  setModalAddEdit: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>,
  handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => void,
  modalValues: {
    name?: string,
    minQuestions: number,
    maxQuestions: number | undefined,
    programId: string,
    selectedTestsIds: string[],
  },
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string, value?: string | object | null,
  ) => void,
  itemsUI: {
    label: string;
    key: string;
    showOnTable?: boolean;
    showOnModal?: boolean;
    typeField: string;
    selectFields?: object[];
    valueType?: string;
    valueToRetrieveFromSelect?: string;
    keyNameInObjectSent?: string;
    disabled?: boolean;
  }[],
  handleAddFunction: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void,
}) {
  const classes = useStyles();
  const { id: studentId } = useParams<{ id: string; }>();
  const { enqueueSnackbar } = useSnackbar();
  const [modalUI, setModalUI] = useState(itemsUI);
  const [allMassives, setAllMassives] = useState({ count: 0, values: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getMassiveInformation = async () => {
      // Getting the progams and questions associated with the program
      try {
        const { programs } = await getPrograms();
        setModalUI(itemsUI.map((elem) => {
          if (elem.label === 'Programa') {
            elem.selectFields = programs;
          }
          return elem;
        }));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (modalAddEdit.open) {
      getMassiveInformation();
    }
  }, [modalAddEdit.open]);

  useEffect(() => {
    const getAllTestsInformation = async () => {
      // Getting the progams and questions associated with the program
      try {
        const { tests } = await getTests(
          modalValues.programId !== '' ? modalValues.programId : '0',
        );
        setAllMassives({ count: tests.length, values: tests });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (modalAddEdit.open) {
      getAllTestsInformation();
    }
  }, [modalValues.programId, studentId, modalAddEdit.open]);

  return (
    <Modal
      open={modalAddEdit.open}
      onClose={() => handleCloseModal(setModalAddEdit)}
    >
      <Box className={classes.modalContainer}>
        <Card className={classes.modal}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon}>
                {modalAddEdit.id !== '' ? <EditIcon color='primary' /> : <AddIcon color='primary' />}
                <Typography variant='h3' color='primary' fontWeight='bold'>{modalAddEdit.id !== '' ? `Editar ${title}` : `Crear ${title}`}</Typography>
                {modalAddEdit.id !== '' && <Typography variant='h4' color='primary' ml={1}>{`- ${modalValues.name}`}</Typography>}
              </Box>

              <IconButton onClick={() => handleCloseModal(setModalAddEdit)}>
                <CloseIcon color='primary' />
              </IconButton>
            </Box>
            <Box className={classes.modalTextfieldsContainer}>
              {modalUI
                .map((elem) => {
                  let key;
                  if (elem.keyNameInObjectSent) {
                    key = elem.keyNameInObjectSent;
                  } else {
                    key = elem.key;
                  }

                  return (
                    <Box key={key.toString()} className={classes.modalIndividualTextfield}>
                      {elem.typeField === 'select' && <CustomSelectTextField
                        disabled={elem.disabled}
                        valueToRetrieveFromSelect={elem.valueToRetrieveFromSelect}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key as keyof typeof modalValues] as string}
                        onChangeFunction={handleFieldChange}
                        selectFields={elem.selectFields as Array<{ id: string; name: string; }>}
                      />}
                      {elem.typeField === 'text' && <CustomTextField
                        disabled={elem.disabled}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key as keyof typeof modalValues] as string}
                        onChangeFunction={handleFieldChange}
                      />}
                      {elem.typeField === 'number' && <CustomNumericTextField
                        disabled={elem.disabled}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key as keyof typeof modalValues] as string}
                        onChangeFunction={handleFieldChange}
                      />}
                      {elem.typeField === 'autocomplete' && <CustomAutocompleteTextField
                        disabled={elem.disabled}
                        valueToRetrieveFromSelect={elem.valueToRetrieveFromSelect}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key as keyof typeof modalValues] as string}
                        onChangeFunction={handleFieldChange}
                        selectFields={elem.selectFields as Array<{ id: string; name: string; }>}
                      />}
                    </Box>
                  );
                })}
            </Box>
            <Typography variant='h3' color='textPrimary' fontWeight='bold' mt={3} mb={3}>Guías</Typography>
            {modalValues.programId !== ''
              && <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Número</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Fecha programada</TableCell>
                      <TableCell>N entregas</TableCell>
                      <TableCell>Seleccionar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allMassives.values.map((row: ProgramTest, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.number}</TableCell>
                        <TableCell>{row.test.name}</TableCell>
                        <TableCell>{row.deliverDate}</TableCell>
                        <TableCell>{row.numberOfDeliveries}</TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={modalValues.selectedTestsIds.includes(`${row.test.id}`)}
                            onChange={(e) => {
                              e.target.value = row.test.id;
                              handleFieldChange(e, 'checkbox');
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
            {modalValues.programId === ''
              && <Typography
                fontWeight='bold'
              >Ningún programa se ha seleccionado
              </Typography>}
            <Box className={classes.modalButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={() => handleCloseModal(setModalAddEdit)} key={'CancelButtonAddProgram'} icon={<CloseIcon />} />
              {<CustomButton
                text={`Crear ${title}`}
                colorType='primary'
                onClick={() => handleAddFunction(setLoading)}
                key={`AcceptButtonAdd${title}`}
                icon={<AddIcon />}
                loading={loading}
              />}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
