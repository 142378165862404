import produce, { Draft } from 'immer';

import { InstitutionAction, InstitutionUserTestState } from '../types';
import {
  SET_INSTITUTION_USER_TEST_TIMER,
} from '../actions/institutionUserTestActions';

const initialState: InstitutionUserTestState = {
  userTimer: null,
};

const institutionUserTestReducer = (
  state = initialState, action: InstitutionAction,
): InstitutionUserTestState => {
  switch (action.type) {
    case SET_INSTITUTION_USER_TEST_TIMER: {
      return produce(state, (draft: Draft<InstitutionUserTestState>) => {
        draft.userTimer = Date.now();
      });
    }

    default: {
      return state;
    }
  }
};

export default institutionUserTestReducer;
