import React, { SyntheticEvent, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from '@mui/icons-material';
import {
  Box,
  ClassNameMap,
  IconButton,
} from '@mui/material';

import { useHistory } from 'react-router';
import { deleteBomb, getBombsPerPage, postBomb } from '../../../requests/api/bombs';
import handleApiResponse from '../../../utils/handleApiResponse';

import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import EmptyTable from '../../../components/General/EmptyTable';
import AddBombModal from '../Components/AddBombModal';
import DeleteModal from '../../../components/General/DeleteModal';

import { BOMBS_UI, BOMB_MODAL_UI, Bomb } from './types';
import { Program } from '../../Program/ProgramListView/types';

import useStyles from './styles';

const buttonActions = (
  classes: ClassNameMap<string>,
  bombId: string,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
  handleRedirectBombDetail: (bombId: string) => void,
) => (
  <Box sx={{ display: 'flex' }}>
    <IconButton onClick={() => handleRedirectBombDetail(bombId)}>
      <RemoveRedEyeIcon className={classes.icons} />
    </IconButton>
    <IconButton onClick={() => openDeleteModal({ id: bombId, open: true })}>
      <DeleteIcon className={classes.icons} />
    </IconButton>
  </Box>
);

function BombListView() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [allBombs, setAllBombs] = useState({
    items: [],
    totalItems: 0,
  });
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [searchValue, setSearchValue] = useState('');
  const [modalAddBomb, setModalAddBomb] = useState({ id: '', open: false });
  const [modalDeleteBomb, setModalDeleteBomb] = useState({ id: '', open: false });
  const [updateTable, setUpdateTable] = useState(false);
  const [modalValues, setModalValues] = useState<{
    startHability: number,
    endHability: number,
    programId: string,
    subjectId: string,
    selectedTestsIds: string[],
  }>({
    startHability: 1,
    endHability: 1,
    programId: '',
    subjectId: '',
    selectedTestsIds: [],
  });

  const handleRedirectBombDetail = (bombId: string) => {
    history.push({ pathname: `/bombas/${bombId}` });
  };

  useEffect(() => {
    const getAllBombs = async () => {
      try {
        const { bombs, totalBombs } = await getBombsPerPage({
          page: tableParameters.page, limit: tableParameters.rowsPerPage, searchValue, sort: 'programName|DESC',
        });

        const bombsAndActions = bombs.map((elem: Bomb) => ({
          ...elem,
          subjectId: elem.program && elem.program.subjectId,
          subjectName: elem.program && elem.program.subject.name,
          programName: elem.program && elem.program.name,
          locationName: elem.location && elem.location.name,
          testsNumber: elem.bombTests.length,
          actions: buttonActions(classes, elem.id, setModalDeleteBomb, handleRedirectBombDetail),
        }));
        setAllBombs({ items: bombsAndActions, totalItems: totalBombs });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllBombs();
  }, [tableParameters.page, tableParameters.rowsPerPage, searchValue, updateTable]);

  const handleCloseModal = () => {
    setModalValues({
      startHability: 1,
      endHability: 1,
      programId: '',
      subjectId: '',
      selectedTestsIds: [],
    });
    setModalAddBomb({ id: '', open: false });
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string, value?: string | object | null,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
      return;
    }

    if (e instanceof File) {
      return;
    }

    const { value: targetValue } = (e as React.ChangeEvent<HTMLInputElement>).target;

    if (source === 'checkbox') {
      setModalValues((prevState) => ({
        ...prevState,
        selectedTestsIds: prevState.selectedTestsIds.includes(targetValue)
          ? prevState.selectedTestsIds.filter((elem) => elem !== targetValue)
          : [...prevState.selectedTestsIds, targetValue],
      }));
    } else if (source === 'programId') {
      setModalValues(
        (prevState) => ({ ...prevState, [source]: (value as Program)?.id ?? '' }),
      );
    } else {
      const v = (e.target as HTMLInputElement).value;
      if ((source === 'startHability' || source === 'endHability') && Number(v) > 100) {
        enqueueSnackbar('El valor de las habilidades tiene un máximo de 100.', { variant: 'error' });
        return;
      }
      setModalValues(
        (prevState) => ({ ...prevState, [source]: (e.target as HTMLInputElement).value }),
      );
    }
  };

  const handleAddBomb = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (modalValues.startHability < 1 || modalValues.startHability > 100
      || modalValues.endHability < 1 || modalValues.endHability > 100) {
      enqueueSnackbar('El valor de las habilidades tiene un mínimo de 1 y un máximo de 100.', { variant: 'error' });
      return;
    }

    if (Number(modalValues.startHability) > Number(modalValues.endHability)) {
      enqueueSnackbar('El valor de la habilidad desde debe ser menor al de la habilidad hasta.', { variant: 'error' });
      return;
    }

    try {
      setLoading(true);
      const response = await postBomb({
        startHability: modalValues.startHability,
        endHability: modalValues.endHability,
        programId: modalValues.programId,
        selectedTestsIds: modalValues.selectedTestsIds,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal();
      setUpdateTable((prevState) => !prevState);
      setLoading(false);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  const handleDeleteFunction = async (bombId: string) => {
    try {
      const response = await deleteBomb(bombId);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseDeleteModal(setModalDeleteBomb);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleCloseDeleteModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setUpdateTable(!updateTable);
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Page title="Bombas">
      <Header
        title='Bombas'
        icon={(
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.65234 22.8008C6.56901 22.8008 4.79818 22.0633 3.33984 20.5883C1.88151 19.1133 1.15234 17.3341 1.15234 15.2508C1.15234 13.1674 1.87318 11.4049 3.31484 9.96328C4.75651 8.52161 6.51901 7.80078 8.60234 7.80078H8.92734L9.60234 6.62578C9.80234 6.25911 10.1023 6.02161 10.5023 5.91328C10.9023 5.80495 11.2857 5.85911 11.6523 6.07578L12.4023 6.50078L12.5273 6.30078C12.9107 5.58411 13.5107 5.11745 14.3273 4.90078C15.144 4.68411 15.9107 4.78411 16.6273 5.20078L17.5023 5.70078L16.5023 7.42578L15.6273 6.92578C15.394 6.79245 15.1398 6.76328 14.8648 6.83828C14.5898 6.91328 14.3857 7.06745 14.2523 7.30078L14.1273 7.50078L15.1273 8.07578C15.4773 8.27578 15.7065 8.57578 15.8148 8.97578C15.9232 9.37578 15.8773 9.75078 15.6773 10.1008L15.0023 11.3008C15.3857 11.9008 15.6732 12.5383 15.8648 13.2133C16.0565 13.8883 16.1523 14.5841 16.1523 15.3008C16.1523 17.3841 15.4232 19.1549 13.9648 20.6133C12.5065 22.0716 10.7357 22.8008 8.65234 22.8008ZM20.0023 8.80078V6.80078H23.0023V8.80078H20.0023ZM14.5023 3.30078V0.300781H16.5023V3.30078H14.5023ZM19.3773 5.32578L17.9773 3.92578L20.1023 1.80078L21.5023 3.20078L19.3773 5.32578Z" fill="#2C82B3"/>
          </svg>
        )}
        buttons={[{ text: 'Crear bomba', icon: <AddIcon />, onClick: () => setModalAddBomb((prevState) => ({ ...prevState, open: true })) }]}
        search={{ text: 'Filtrar', value: searchValue, onChangeSearchValue: handleChangeSearchValue }}
      />
      <Box mt={3}>
        {allBombs.totalItems !== 0
          && <CustomTable
            headers={BOMBS_UI.filter((elem) => elem.showOnTable)}
            data={{ values: allBombs.items, count: allBombs.totalItems }}
            tableParameters={tableParameters}
            setTableParameters={setTableParameters}
          />}
        {allBombs.totalItems === 0 && <EmptyTable />}
      </Box>
      <AddBombModal
        title={'bomba'}
        modalAddEdit={modalAddBomb}
        setModalAddEdit={setModalAddBomb}
        itemsUI={BOMB_MODAL_UI}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        handleFieldChange={handleFieldChange}
        handleAddFunction={handleAddBomb}
      />
      <DeleteModal
        deleteFunction={handleDeleteFunction}
        handleCloseModal={handleCloseDeleteModal}
        modalDelete={modalDeleteBomb}
        setModalDelete={setModalDeleteBomb}
        title='bomba'
      />
    </Page>
  );
}

export default BombListView;
