export const STUDENT_BOMBS_UI = [
  {
    label: 'Rut', key: 'rut', valueType: 'string', showOnTable: true, typeField: 'text',
  },
  {
    label: 'Nombre', key: 'name', valueType: 'string', showOnTable: true, typeField: 'text',
  },
  {
    label: 'Apellido', key: 'lastname', valueType: 'string', showOnTable: true, typeField: 'text',
  },
  {
    label: 'ID Sección', key: 'mipreuId', valueType: 'string', showOnTable: true, typeField: 'text',
  },
  {
    label: 'Habilidad 1', key: 'firstBombSkill', valueType: 'string', showOnTable: true, typeField: 'text',
  },
  {
    label: 'Habilidad 2', key: 'secondBombSkill', valueType: 'string', showOnTable: true, typeField: 'text',
  },
  {
    label: 'Habilidad 3', key: 'thirdBombSkill', valueType: 'string', showOnTable: true, typeField: 'text',
  },
];

export type StudentBombSkill = {
  mipreuId: number;
  rut: string;
  name: string;
  lastname: string;
  firstBombSkill: number | null;
  secondBombSkill: number | null;
  thirdBombSkill: number | null;
};
