import axios from '../../utils/axios';

const loginInstitution = async (
  institutionId: number, user: string, password: string,
) => {
  const response = await axios.post(`/api/institution/login/${institutionId}`, {
    user,
    password,
  });

  return response.data;
};

const saveInstitutionUserInfo = async (
  institutionUser: {
    id: number | null,
    name: string | null,
    lastname: string | null,
    email: string | null,
    phoneNumber: string | null,
    school: string | null,
    schoolYear: string | null,
    preferredUniversity: string | null,
    preferredCarrer: string | null,
  },
) => {
  const response = await axios.post('/api/institution-user', {
    user: institutionUser,
  });

  return response.data;
};

const getInstitutionUserById = async (institutionUserId: number | string) => {
  const response = await axios.get(`/api/institution-users/${institutionUserId}`);

  return response.data;
};

const getInstitutionSessionStudentsPerPage = async (
  {
    institutionSessionId,
    page,
    limit,
    sort,
  }: {
    institutionSessionId: string,
    page: number,
    limit: number,
    sort: string,
  },
) => {
  const response = await axios.get(`/api/institution-users/students/institution-session/${institutionSessionId}?page=${page}&limit=${limit}&sort=${sort}`);

  return response.data;
};

const bulkUpsertSessionStudents = async (file: File, institutionSessionId: string) => {
  const form = new FormData();

  if (file) {
    form.append('file', file);
  }
  const response = await axios.post(`/api/institution-users/students/institution-session/${institutionSessionId}/creation-by-excel`, form);

  return response.data;
};

export {
  loginInstitution,
  saveInstitutionUserInfo,
  getInstitutionUserById,
  getInstitutionSessionStudentsPerPage,
  bulkUpsertSessionStudents,
};
