import { AxiosError } from 'axios';
import {
  OptionsObject, SnackbarKey, SnackbarMessage, VariantType,
} from 'notistack';
import React from 'react';
import { User } from '../types';
import { DEFAULT_API_ERROR_MESSAGE } from './errors';

interface ResponseData {
  data?: {
    user: User;
  };
  message: string;
  success?: boolean;
  response?: {
    data: {
      errorMessage?: string;
    };
  }
}

const handleApiResponse = (
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  response: ResponseData | AxiosError,
  successSnackbar: boolean,
  variant: VariantType | null = null,
  action: React.ReactNode | null = null,
) => {
  if (successSnackbar) {
    enqueueSnackbar(response.message, {
      variant: variant || 'success',
      action,
    });
  } else if (response.response) {
    // TODO - Check which approach is better. Front uses errorResponse to parse errorMessage
    // Here, as we only get the try catch in the front, we do not parse it.
    const messageToShow = response.response.data?.message
      || DEFAULT_API_ERROR_MESSAGE;
    enqueueSnackbar(messageToShow, {
      variant: variant || 'error',
      action,
    });
  } else if (response.message) {
    enqueueSnackbar(response.message, {
      variant: variant || 'error',
      action,
    });
  }
};

export default handleApiResponse;
