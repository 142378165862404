import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Typography,
  Box,
} from '@mui/material';

import useStyles from './styles';

function Header(
  {
    testName,
    competence,
  }: {
    testName: string,
    competence: string,
  },
) {
  const classes = useStyles();

  return (
    <Container className={classes.titleContainer}>
      <Box>
        <Typography
          variant="h4"
          className={classes.testName}
        >
          {testName} - {competence}
        </Typography>
        <Typography
          variant="body1"
          className={classes.testName}
        >
          Ensayo contestado
        </Typography>
      </Box>
    </Container>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
