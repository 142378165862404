import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import handleApiResponse from '../../../../../utils/handleApiResponse';
import { getStudentAssistanceInformationBySectionId } from '../../../../../requests/api/sections';
import { putStudentClasses } from '../../../../../requests/api/classes';
import { StudentAssistanceStatistics, AssistanceDataSectionTeacher, AssistanceData } from './types';

import Page from '../../../../../components/Layout/Page';
import DetailHeader from '../DetailHeader';
import HeaderSummary from '../HeaderSummary';
import StudentAssistancesTable from './Components/StudentAssistancesTable';

interface LocationState {
  student: string;
  mipreuId: string;
}

function StudentAssistancesListView() {
  const { enqueueSnackbar } = useSnackbar();
  const { sectionId, studentId } = useParams<{ sectionId: string, studentId: string }>();
  const location = useLocation<LocationState>();
  const student = location.state ? location.state.student : 'S/I';
  const mipreuId = location.state ? location.state.mipreuId : '';

  const [statistics, setStatistics] = useState<StudentAssistanceStatistics>({
    arrivedLateAverage: 0,
    assistance: '0',
  });
  const [classesData, setClassesData] = useState<{ count: number; values: AssistanceData[] }>({
    count: 0,
    values: [],
  });

  useEffect(() => {
    const getAllAssistanceInformationFromStudent = async () => {
      try {
        const {
          generalStatistics, assistancesDetail,
        } = await getStudentAssistanceInformationBySectionId(studentId, sectionId);

        setClassesData({ count: assistancesDetail.length, values: assistancesDetail });
        setStatistics(generalStatistics);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllAssistanceInformationFromStudent();
  }, []);

  const handleAssistanceChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    elem: AssistanceData,
  ) => {
    try {
      const updatedAssistance = event.target.value;
      const updatedClassesData = {
        ...classesData,
        values: classesData.values.map((item) => (item.studentClassId === elem.studentClassId
          ? { ...item, assistance: updatedAssistance }
          : item)),
      };

      setClassesData(updatedClassesData);

      const response = await putStudentClasses({
        studentClasses: [{
          studentClassId: `${elem.studentClassId}`,
          studentId: `${elem.studentId}`,
          studentName: elem.studentName,
          studentLastname: elem.studentLastName,
          studentRut: elem.studentRut,
          classId: `${elem.classId}`,
          teacherNames: elem.class.sectionTimeSlot.section.sectionTeachers.map((st: AssistanceDataSectionTeacher) => `${st.teacher.user.name} ${st.teacher.user.last}`),
          attended: event.target.value === 'present' || event.target.value === 'late',
          arrivedLate: event.target.value === 'late',
        }],
        classDetails: {
          date: `${elem.date}`,
          program: elem.programName,
          section: elem.mipreuId,
        },
      });

      if (response.success) handleApiResponse(enqueueSnackbar, response, true);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  return (
    <Page title="Alumno | Detalle asistencia">
      <DetailHeader mipreuId={mipreuId} student={student} view="asistencia" />
      <HeaderSummary width="25%" info={[
        { key: 'Asistencia', value: `${statistics.assistance}/${classesData.count}` },
        { key: '% atrasos', value: `${statistics.arrivedLateAverage || 0}%` },
      ]} />
      <StudentAssistancesTable data={classesData} handleAssistanceChange={handleAssistanceChange} />
    </Page>
  );
}

export default StudentAssistancesListView;
