import React, { SyntheticEvent } from 'react';
import { TestData } from '../types';
import UpsertModal from '../../../../components/General/UpsertModal';

function UpsertTestModal(
  {
    modalAddEdit,
    setModalAddEdit,
    handleCloseModal,
    modalValues,
    handleFieldChange,
    itemsUI,
    addFunction,
  }: {
    modalAddEdit: { id: string, open: boolean },
    setModalAddEdit: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>,
    handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>) => void,
    modalValues: TestData,
    setModalValues: React.Dispatch<React.SetStateAction<TestData>>,
    handleFieldChange: (
      e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date | File,
      source: string) => void,
    itemsUI: {
      label: string;
      key: string;
      showOnTable: boolean;
      showOnModal: boolean;
      typeField: string;
      selectFields?: object[];
      valueType: string;
    }[],
    addFunction: () => void,
  },
) {
  return (
    <UpsertModal<TestData>
      title={'guía'}
      modalAddEdit={modalAddEdit}
      setModalAddEdit={setModalAddEdit}
      handleCloseModal={handleCloseModal}
      modalValues={modalValues}
      handleFieldChange={handleFieldChange}
      itemsUI={itemsUI}
      handleAddFunction={addFunction}
      handleEditFunction={() => {}}
    />
  );
}

export default UpsertTestModal;
