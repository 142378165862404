type TestData = {
  id: string,
  subjectId?: string,
  subjectName?: string,
  name: string,
  type: string,
  optimarkId: string,
  optimarkTest: {
    name: string,
    id: string | number,
    questions: string | number,
  },
  numberOfQuestions: number,
  questionsInfo?: File,
  solutionaryUrl?: string,
};

type PostTestData = {
  subjectId?: string,
  subjectName?: string,
  name: string,
  type: string,
  optimarkTest: {
    name: string,
    id: string | number,
  },
  numberOfQuestions: number,
  questionsInfo?: File,
};

type PutTestName = {
  name: string,
};

const TESTS_UI = [
  {
    label: 'Nombre', key: 'name', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Asignatura', key: 'subjectName', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: [], valueType: 'string',
  },
  {
    label: 'Tipo',
    key: 'type',
    showOnTable: true,
    showOnModal: true,
    typeField: 'select',
    selectFields: [
      { id: 'Guía', name: 'Guía' }, { id: 'Ensayo', name: 'Ensayo' }, { id: 'Evaluación', name: 'Evaluación' },
    ],
    valueType: 'string',
  },
  {
    label: 'Número de preguntas', key: 'numberOfQuestions', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'number', disabled: true,
  },
  {
    label: 'Guía Optimark', key: 'optimarkTest', showOnTable: false, showOnModal: true, typeField: 'autocomplete', selectFields: [], valueType: 'string',
  },
  {
    label: 'Guía Optimark', key: 'optimarkName', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Planilla de preguntas', key: 'questionsInfo', showOnTable: false, showOnModal: true, typeField: 'file', valueType: 'file',
  },
  {
    label: 'Ver solucionario', key: 'seeSolution', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
  {
    label: 'Número de entregas', key: 'numberOfDeliveries', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Fecha actualización respuestas', key: 'lastUpdated', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'shortDate',
  },
  {
    label: 'Acciones', key: 'actions', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
];

export {
  TestData,
  PostTestData,
  PutTestName,
  TESTS_UI,
};
