import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';
import { History } from 'history';
import Page from '../../../components/Layout/Page';
import Header from '../../../components/Layout/Header';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import CustomTable from '../../../components/General/CustomTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import { getInstitutionSessionSummaryInfo } from '../../../requests/api/institutionTests';
import { ColumnBarChart } from './Component/ColumnBarChart';
import { PieChart } from './Component/PieChart';
import { StackBarChart } from './Component/StackBarChart';
import useStyles from '../styles';
import { AverageTestScoreBySubject, TestsByCompletion, TestsBySubjectAndLocation } from './types';
import { ReduxState } from '../../../types';

const INSTITUTIONS_SESSION_RESULTS_UI = [
  {
    label: 'Nombre Ensayo', key: 'testName', valueType: 'string',
  },
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Nº de alumnos', key: 'numberOfStudents', valueType: 'string',
  },
  {
    label: 'Puntaje Promedio', key: 'averageScore', valueType: 'string',
  },
  {
    label: 'Ver ensayo', key: 'seeTest', valueType: 'string',
  }];

const oneButtonAction = (
  buttonToDisplay: JSX.Element,
  history: History,
  redirectUrl: string,
  name: string,
  enabledButton: boolean,
) => (<Box display='flex' alignItems='center' justifyContent='center'>
  <IconButton
    onClick={() => history.push({ pathname: redirectUrl, state: { name } })}
    disabled={!enabledButton}
  >
    {buttonToDisplay}
  </IconButton>
</Box>);

const initialGraphsInfo = {
  testsBySubjectAndLocation: {
    LENGUAJE: { online: 0, inPerson: 0 },
    MATEMATICA: { online: 0, inPerson: 0 },
  },
  testsByCompletion: {
    notCompleted: 0,
    completedLenguaje: 0,
    completedMatematicas: 0,
    completedBoth: 0,
  },
  averageTestScoreBySubject: {
    LENGUAJE: { online: 0, inPerson: 0, general: 0 },
    MATEMATICA: { online: 0, inPerson: 0, general: 0 },
  },
  totalStudents: 0,
};

interface LocationState {
  name: string,
}

const InstitutionSessionResultsView = () => {
  const {
    institutionId,
    institutionSessionId,
    institutionUrlExtension,
  } = useParams<{
    institutionId: string, institutionSessionId: string, institutionUrlExtension?: string
  }>();
  const history = useHistory();
  const classes = useStyles();
  const { state } = useLocation<LocationState>();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoading, setShowLoading] = useState(true);
  const [graphsInfo, setGraphsInfo] = useState<{
    testsBySubjectAndLocation: TestsBySubjectAndLocation,
    testsByCompletion: TestsByCompletion,
    averageTestScoreBySubject: AverageTestScoreBySubject,
    totalStudents: number,
  }>(initialGraphsInfo);
  const [allStudentsSummary, setAllStudentsSummary] = useState<{
    totalItems: number,
    items: {
      testName: string,
      subjectName: string,
      numberOfStudents: number,
      averageScore: string,
      seeTest: JSX.Element,
    }[],
  }>({
    totalItems: 0,
    items: [],
  });
  const { institutionAccount } = useSelector((storeState: ReduxState) => storeState);
  const institutionAdmin = Boolean(institutionAccount.institutionUser?.id);

  useEffect(() => {
    const getSessionSummaryInformation = async () => {
      try {
        const {
          testsBySubjectAndLocation,
          testsByCompletion,
          averageTestScoreBySubject,
          totalUsers,
        } = await getInstitutionSessionSummaryInfo({ institutionSessionId });

        // const auxTableData = ['MATEMATICA', 'LENGUAJE'].map((elem) => {
        const auxTableData = Object.keys(testsBySubjectAndLocation).map((elem) => {
          const enabledButton = Boolean(
            testsBySubjectAndLocation[elem].online + testsBySubjectAndLocation[elem].inPerson,
          );

          let seeTestUrl = `/instituciones/${institutionId}/jornada/${institutionSessionId}/resultados-ensayo/${testsBySubjectAndLocation[elem].id}`;
          if (institutionAdmin) {
            seeTestUrl = `/institucion/${institutionUrlExtension}/administracion/jornada/${institutionSessionId}/resultados-ensayo/${testsBySubjectAndLocation[elem].id}`;
          }
          return {
            testName: testsBySubjectAndLocation[elem].testName,
            subjectName: elem,
            numberOfStudents: testsBySubjectAndLocation[elem].inPerson
              + testsBySubjectAndLocation[elem].online,
            averageScore: `${Math.round(averageTestScoreBySubject[elem].general)}`,
            seeTest: oneButtonAction(
              <RemoveRedEyeIcon
                className={enabledButton ? classes.icons : classes.iconsDisabled }
              />,
              history,
              seeTestUrl,
              testsBySubjectAndLocation[elem].testName,
              enabledButton,
            ),
          };
        });
        setGraphsInfo({
          testsBySubjectAndLocation,
          testsByCompletion,
          averageTestScoreBySubject,
          totalStudents: totalUsers,
        });
        setAllStudentsSummary({ items: auxTableData, totalItems: auxTableData.length });
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getSessionSummaryInformation();
  }, []);

  return (
    <Page
      title="Instituciones | Resultados"
    >
      <Header
        title={`Resultados ${state?.name || 'Resultados'}`}
        goBack
      />
      {showLoading && <CircularProgressComponent />}
      {!showLoading && <Box
        mt={3}
      >
        {(graphsInfo?.totalStudents && allStudentsSummary.totalItems !== 0) ? <>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StackBarChart testsBySubjectAndLocation={graphsInfo.testsBySubjectAndLocation} />
            <PieChart
              totalStudents={graphsInfo?.totalStudents}
              testsByCompletion={graphsInfo.testsByCompletion} />
            <ColumnBarChart averageTestScoreBySubject={graphsInfo.averageTestScoreBySubject} />
          </Box>
          <Box mt={4}>
            <CustomTable
              headers={INSTITUTIONS_SESSION_RESULTS_UI}
              data={{
                values: allStudentsSummary.items,
                count: allStudentsSummary.totalItems,
              }}
            />
          </Box>
        </> : <Typography variant='h1'>No hay alumnos inscritos en esta jornada</Typography>}

      </Box>}
    </Page>
  );
};

export default InstitutionSessionResultsView;
