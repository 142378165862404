import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Add as AddIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Delete as DeleteIcon,
  LocalLibrary as LocalLibraryIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
} from '@mui/icons-material';
import {
  Box,
  ClassNameMap,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { Redirect, useHistory } from 'react-router';
import { format } from 'rut.js';
import { useSelector } from 'react-redux';
import { History } from 'history';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import useStyles from './styles';
import {
  StudentData,
  STUDENTS_UI,
} from './types';
import {
  deleteStudent,
  getStudentsFromRepresentative,
  getStudentsPerPage,
  postCreateStudent,
  putStudentOnlineStatus,
  restoreStudent,
} from '../../../requests/api/students';
import InsertStudentModal from './Modals/InsertStudentModal';
import { ReduxState } from '../../../types';
import { REPRESENTATIVE, STUDENT, TEACHER } from '../../../utils/user_types';
import DeleteModal from '../../../components/General/DeleteModal';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import RestoreModal from './Modals/RestoreModal';

const buttonActions = (
  classes: ClassNameMap<string>,
  studentId: string,
  history: History,
) => (<Box>
  <IconButton onClick={() => history.push(`/alumnos/${studentId}`)}>
    <ArrowForwardIosIcon className={classes.icons} />
  </IconButton>
</Box>);

const buttonDelete = (
  classes: ClassNameMap<string>,
  studentId: string,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box>
  <IconButton onClick={() => openDeleteModal({ id: studentId, open: true })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

const buttonRestore = (
  classes: ClassNameMap<string>,
  studentId: string,
  openRestoreModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box>
  <IconButton onClick={() => openRestoreModal({ id: studentId, open: true })}>
    <RestoreFromTrashIcon className={classes.icons} />
  </IconButton>
</Box>);

const switchActions = (
  studentId: string,
  onlineEnabled: boolean,
  onSwitchChange: (e: React.ChangeEvent<HTMLInputElement>, studentId: string) => Promise<void>,
  deleted: boolean | undefined,
) => (<Box key={studentId} display='flex' alignItems='center' justifyContent='center'>
  <Typography variant='h6'>No</Typography>
  <Switch
    disabled={deleted}
    checked={onlineEnabled}
    onChange={(e) => onSwitchChange(e, studentId)}
  />
  <Typography variant='h6'>Si</Typography>
</Box>);

const rolesToRedirect = [STUDENT, REPRESENTATIVE, TEACHER];

function SudentListView() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state: ReduxState) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [showLoading, setShowLoading] = useState(true);
  const [allStudents, setAllStudents] = useState({
    items: [],
    totalItems: 0,
  });
  const [modalAddAStudent, setModalAddAStudent] = useState(
    { id: '', open: false },
  );
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const [searchValue, setSearchValue] = useState('');
  const [updateTable, setUpdateTable] = useState(false);
  const [modalValues, setModalValues] = useState<StudentData>({
    userId: '',
    rut: '',
    phoneNumber: '',
    name: '',
    lastname: '',
    email: '',
    onlineEnabled: false,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState({ id: '', open: false });
  const [openRestoreModal, setOpenRestoreModal] = useState({ id: '', open: false });
  const [redirectState, setRedirectState] = useState<{
    path: undefined | string, data: undefined | number[],
  }>({ path: undefined, data: undefined });

  useEffect(() => {
    const checkRoleAndFetch = async () => {
      if (user && rolesToRedirect.includes(user.userType)) {
        if (user.userType === STUDENT) {
          setRedirectState({ path: `/alumnos/${user.userTypeId}`, data: undefined });
        } else if (user.userType === REPRESENTATIVE) {
          try {
            const { students } = await getStudentsFromRepresentative(user.userTypeId);
            setRedirectState({
              path: `/alumnos/${students[0].studentId}`,
              data: students.map((elem: { studentId: number }) => elem.studentId),
            });
          } catch (err) {
            const e = err as AxiosError;
            handleApiResponse(enqueueSnackbar, e, false);
          }
        } else if (user.userType === TEACHER) {
          setRedirectState({ path: '/secciones', data: undefined });
        }
      }
    };

    checkRoleAndFetch();
  }, [user]);

  useEffect(() => {
    const getAllStudents = async () => {
      try {
        const { students, totalStudents } = await getStudentsPerPage({
          page: tableParameters.page, limit: tableParameters.rowsPerPage, searchValue, sort: 'name|ASC',
        });

        const studentsAndActions = students.map((elem: StudentData) => ({
          ...elem,
          name: elem.user && elem.user.name,
          lastname: elem.user && elem.user.lastname,
          email: elem.user && elem.user.email,
          rut: format(elem.rut),
          onlineEnabled: switchActions(
            elem.id!,
            elem.onlineEnabled,
            onSwitchChange,
            elem.deleted,
          ),
          actions: elem.deleted
            ? buttonRestore(
              classes,
              elem.id!,
              setOpenRestoreModal,
            ) : buttonDelete(
              classes,
              elem.id!,
              setOpenDeleteModal,
            ),
          seeMore: buttonActions(
            classes,
            elem.id!,
            history,
          ),
          rowStyles: elem.deleted ? classes.disableTableCells : null,
        }));
        setAllStudents({ items: studentsAndActions, totalItems: totalStudents });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setShowLoading(false);
      }
    };

    if (user && !rolesToRedirect.includes(user.userType)) {
      getAllStudents();
    }
  }, [tableParameters.page, tableParameters.rowsPerPage, searchValue, updateTable]);

  const handleCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setModalValues({
      userId: '',
      rut: '',
      phoneNumber: '',
      name: '',
      lastname: '',
      email: '',
      onlineEnabled: false,
    });
    setUpdateTable(!updateTable);
  };

  const addAStudent = async () => {
    try {
      const response = await postCreateStudent({
        rut: modalValues.rut,
        phoneNumber: modalValues.phoneNumber,
        name: modalValues.name,
        lastname: modalValues.lastname,
        email: modalValues.email,
        onlineEnabled: modalValues.onlineEnabled,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalAddAStudent);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const onSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>, studentId: string) => {
    const onlineEnabled = e.target.checked;
    try {
      const response = await putStudentOnlineStatus(studentId, onlineEnabled);
      handleApiResponse(enqueueSnackbar, response, true);
      setUpdateTable(!updateTable);
    } catch (error) {
      const err = error as AxiosError;
      handleApiResponse(enqueueSnackbar, err, false);
    }
  };

  const handleFieldChange = (e:
  React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> |
  Date | File, source: string) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (!(e instanceof File)) {
      if (source === 'onlineEnabled') {
        setModalValues((prevState) => ({
          ...prevState,
          [source]: (e.target as HTMLInputElement).checked,
        }));
      } else {
        setModalValues(
          (prevState) => ({ ...prevState, [source]: (e.target as HTMLInputElement).value }),
        );
      }
    }
  };

  const handleDeleteFunction = async (studentId: string) => {
    try {
      const response = await deleteStudent(studentId);
      handleApiResponse(enqueueSnackbar, response, true);
      handleDeleteCloseModal(setOpenDeleteModal);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleDeleteCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setUpdateTable(!updateTable);
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue((e.target as HTMLInputElement).value);
    if (tableParameters.page !== 0) {
      setTableParameters((prevState) => ({ ...prevState, page: 0 }));
    }
  };

  const handleRestoreFunction = async (studentId: string) => {
    try {
      const response = await restoreStudent(studentId);
      handleApiResponse(enqueueSnackbar, response, true);
      handleRestoreCloseModal(setOpenRestoreModal);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleRestoreCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setUpdateTable(!updateTable);
  };

  if (redirectState.path) {
    return <Redirect to={{
      pathname: redirectState.path,
    }} />;
  }

  return (
    <Page
      title="Alumnos"
    >
      <Header
        title='Alumnos'
        icon={<LocalLibraryIcon />}
        buttons={!showLoading ? [
          { text: 'Crear alumno', icon: <AddIcon />, onClick: () => setModalAddAStudent((prevState) => ({ ...prevState, open: true })) },
        ] : undefined}
        search={!showLoading ? { text: 'Buscar alumno...', value: searchValue, onChangeSearchValue: handleChangeSearchValue } : undefined}
      />
      {showLoading ? <CircularProgressComponent /> : <Box
        mt={3}
      >
        {allStudents.totalItems !== 0
          && <CustomTable
            headers={STUDENTS_UI.filter((elem) => elem.showOnTable)}
            data={{ values: allStudents.items, count: allStudents.totalItems }}
            tableParameters={tableParameters}
            setTableParameters={setTableParameters}
          />}
        {allStudents.totalItems === 0 && <EmptyTable />}
      </Box>}
      <InsertStudentModal
        modalAdd={modalAddAStudent}
        setModalAdd={setModalAddAStudent}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        handleFieldChange={handleFieldChange}
        itemsUI={STUDENTS_UI}
        addFunction={addAStudent}
      />
      <DeleteModal
        deleteFunction={handleDeleteFunction}
        handleCloseModal={handleDeleteCloseModal}
        modalDelete={openDeleteModal}
        setModalDelete={setOpenDeleteModal}
        title='alumno'
      />
      <RestoreModal
        restoreFunction={handleRestoreFunction}
        handleCloseModal={handleRestoreCloseModal}
        modalRestore={openRestoreModal}
        setModalRestore={setOpenRestoreModal}
      />
    </Page>
  );
}

export default SudentListView;
