import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import {
  Check as CheckIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import handleApiResponse from '../../utils/handleApiResponse';
import {
  getInstitutionSessionUserTestResult,
  postDownloadTestPDF,
} from '../../requests/api/institutionTests';

import {
  InstitutionSessionUserTestResult,
  INSTITUTION_USER_TEST_RESULT,
  QuestionRows,
  QuestionRow,
} from './types';

import CircularProgressComponent from '../../components/Loading/CircularProgressComponent';
import Page from '../../components/Layout/Page';
import Header from './header';

import useStyles from './styles';
import CustomButton from '../../components/General/CustomButton';
import CustomTable from '../../components/General/CustomTable';

function InstitutionUserTest() {
  const {
    institutionSessionUserTestId,
  } = useParams<{
    institutionSessionUserTestId: string
  }>();

  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [downloadLoading, setDownloadLoading] = useState({
    test: false,
    solution: false,
  });
  const [testResult, setTestResult] = useState<InstitutionSessionUserTestResult>({
    test: {
      name: '',
      durationMinutes: 0,
      pdfUrl: '',
      solutionUrl: '',
      subject: {
        name: '',
      },
      answers: {
        correct: 0,
        incorrect: 0,
        omitted: 0,
      },
      questions: {
        rows: [],
        count: 0,
      },
    },
    competence: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      try {
        const response = await getInstitutionSessionUserTestResult(institutionSessionUserTestId);
        setTestResult(response);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
      setShowLoading(false);
    })();
  }, []);

  const handleDownload = async (urlToDownload: string, name: string) => {
    setDownloadLoading((prev) => ({ ...prev, [name]: true }));
    try {
      const { signedUrl } = await postDownloadTestPDF(urlToDownload);

      const link = document.createElement('a');
      link.href = signedUrl;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
    setDownloadLoading((prev) => ({ ...prev, [name]: false }));
  };

  const formatRows = (rows: QuestionRows) => {
    const newRows = rows.map((r) => {
      const newRow: QuestionRow & {
        rowStyles?: any,
        solutionButton?: JSX.Element,
      } = { ...r };
      if (newRow.incorrect) newRow.rowStyles = classes.incorrectRow;
      if (newRow.omitted || newRow.correctAnswer === 'PILOTO') newRow.rowStyles = classes.omittedRow;
      if (newRow.solution) {
        newRow.solutionButton = (
          <Button
            type="button"
            onClick={() => handleDownload(newRow.solution, `solucion_${newRow.number}`)}
          >
            <DownloadIcon />
          </Button>
        );
      }
      return newRow;
    });
    return newRows;
  };

  const renderTest = () => {
    if (!testResult.test.name) {
      return (
        <Box style={{ textAlign: 'center', marginTop: '20px', fontSize: '20px' }}>
          No se ha encontrado el test
        </Box>
      );
    }
    return (
      <Box className={classes.testContainer}>
        <Header
          testName={testResult.test.name}
          competence={testResult.competence.toUpperCase()}
        />
        <Box mt={3} className={classes.answersWrapper}>
          <Box className={classes.answerCont}>
            <Typography className={classes.answerType}>Respuestas correctas:</Typography>
            <Typography className={classes.answerNumber}>
              {testResult.test.answers.correct}
            </Typography>
          </Box>
          <Box className={classes.answerCont}>
            <Typography className={classes.answerType}>Respuestas incorrectas:</Typography>
            <Typography className={classes.answerNumber}>
              {testResult.test.answers.incorrect}
            </Typography>
          </Box>
          <Box className={classes.answerCont}>
            <Typography className={classes.answerType}>Respuestas omitidas:</Typography>
            <Typography className={classes.answerNumber}>
              {testResult.test.answers.omitted}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.tableCont}>
          <CustomTable
            headers={INSTITUTION_USER_TEST_RESULT}
            data={{
              values: formatRows(testResult.test.questions.rows),
              count: testResult.test.questions.count,
            }}
          />
        </Box>
        <Box className={classes.buttonsContainer}>
          <CustomButton
            text={'Descargar ensayo'}
            loading={downloadLoading.test}
            colorType='primary'
            onClick={() => handleDownload(testResult.test.pdfUrl, 'test')}
            icon={<DownloadIcon />}
          />
          <CustomButton
            text={'Descargar solucionario'}
            loading={downloadLoading.solution}
            colorType='primary'
            onClick={() => handleDownload(testResult.test.solutionUrl, 'solution')}
            icon={<DownloadIcon />}
          />
          <CustomButton
            text={'Terminar y volver'}
            colorType='primary'
            onClick={() => history.goBack()}
            icon={<CheckIcon />}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Page title="Resultado Ensayo">
      {showLoading
        ? <CircularProgressComponent />
        : renderTest()
      }
    </Page>
  );
}

export default InstitutionUserTest;
