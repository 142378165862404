import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const CircularProgressComponent = () => (
  <Box
    mt={3}
    display='flex'
    justifyContent='center'
    alignItems='center'
    height="100%"
    flex={1}
  ><CircularProgress size="5rem" />
  </Box>
);

export default CircularProgressComponent;
