import produce, { Draft } from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
} from '../actions/accountActions';
import { AccountState, CustomAction } from '../types';

const initialState: AccountState = {
  user: null,
};

const accountReducer = (state = initialState, action: CustomAction): AccountState => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft: Draft<AccountState>) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { userData } = action.payload;

      return produce(state, (draft: Draft<AccountState>) => {
        draft.user = userData;
      });
    }

    case LOGOUT: {
      return produce(state, (draft: Draft<AccountState>) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { userData } = action.payload;
      return produce(state, (draft: Draft<AccountState>) => {
        draft.user = userData;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, (draft: Draft<AccountState>) => {
        draft.user = user;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
