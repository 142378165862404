import React from 'react';
import { Box, Typography } from '@mui/material';

type HeaderInfo = { key: string; value: string; }[];

const HeaderSummary = ({
  info, width,
}: { info: HeaderInfo, width: string }) => (
    <Box mt={2} mb={2} display='flex' width={width} justifyContent="space-between" alignItems="center">
      {info.map(({ key, value }: { key: string; value: string; }, index: number) => (
        <Typography key={index} variant="h5">
          <strong>{key}: </strong><br/><span>{value}</span>
        </Typography>
      ))}
    </Box>
);

export default HeaderSummary;
