import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.main,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  responsiveTypography: {
    marginLeft: '30px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginTop: '5px',
      width: '100%',
    },
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  disabledIcons: {
    color: theme.palette.action.disabled,
    fontSize: '26px',
  },
  backButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
  textBodyColor: {
    color: theme.palette.text.primary,
  },
  studentDetailNavbar: {
    height: '60px',
    width: '100%',
    display: 'flex',
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.primary.main,
    marginTop: '35px',
  },
  navBarDetailContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    borderRadius: '5px 5px 0px 0px',
    width: '14.29%',
    textAlign: 'center',
    padding: '0px 12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  navbarDetailFirstNavItem: {
    marginRight: '4px',
  },
  navbarDetailLastNavItem: {
    marginLeft: '4px',
  },
  navbarDetailNavItem: {
    margin: '0px 4px',
  },
  navbarDetailNavItemActive: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  navbarDetailNavItemInactive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.gray,
  },
  navbarDetailNavItemHidden: {
    display: 'none',
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modal: {
    maxHeight: '90%',
    maxWidth: '890px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalDelete: {
    maxHeight: '290px',
    maxWidth: '690px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitleAndIcon: {
    display: 'flex',
  },
  modalTextfieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  modalIndividualTextfield: {
    display: 'flex',
    minWidth: '50%',
    margin: '15px 0px',
    padding: '0px 10px',
  },
  modalButtonsContainer: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'end',
  },
  modalIndividualTextfieldTypographyPrice: {
    display: 'flex',
  },

  // ContractView Styles
  contractViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: '25px',
  },
  contractViewHeaderContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  contractViewHeaderSelectTextfieldContainer: {
    width: '300px',
  },
  contractViewButtonsContainer: {
    display: 'flex',
  },
  contractViewDetailsHeaderContainer: {
    marginTop: '30px',
  },
  contractViewDetailsHeaderContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contractViewDetailsHeaderIndividualTypography: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 5px',
  },
  // Also used in TestsView/Components/:bothTables
  contractViewProgramsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    flexDirection: 'column',
  },
  // Also used in TestsView/Components/:bothTables
  contractViewProgramsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  // Also used in TestsView/Components/:bothTables
  contractViewProgramsTitleButtonContainer: {
    display: 'flex',
  },
  contractViewProgramsButtonContainer: {
    flex: 1,
    justifyContent: 'end',
    display: 'flex',
  },
  // Also used in TestsView/Components/:bothTables
  contractViewProgramsTableContainer: {
    marginTop: '30px',
  },
  contractViewContractDocumentsTitleButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contractUpsertHeaderTitleContainer: {
    display: 'flex',
    color: theme.palette.background.main,
  },
  contractUpsertHeaderBackButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
  contractUpsertButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '30px',
  },
  contractViewContractDiscountContainer: {
    width: '100%',
    marginTop: '30px',
  },
  contractViewContractDiscountContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contractViewContractDiscountIndividualTypography: {
    display: 'flex',
    flexDirection: 'column',
  },
  contractTextFieldsContainer: {
    display: 'grid',
    gridTemplateColumns: '85% 15%',
  },
  contractFinalValue: {
    display: 'flex',
    flexDirection: 'column',
    color: '#2C82B3',
  },
  contractViewContractGroupingContainer: {
    display: 'flex',
  },
  contractViewContractDiscountTextfieldContainer: {
    marginRight: '20px',
    width: '20vw',
  },
  contractPaymentTextfieldsContainer: {
    marginRight: '20px',
    width: '15vw',
    maxWidth: '230px',
  },

  paymentMethodTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  discountCheckbox: {
    display: 'flex',
    alignItems: 'center',
    color: '#706e6f',
  },

  // Video List View Styles
  // Also used at FeedbackListView
  videoListViewContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  // Also used at FeedbackListView
  videoListViewHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    marginTop: '30px',
  },
  // Also used at FeedbackListView
  videoListViewTableContainer: {
    marginTop: '30px',
  },
  videoListViewTextfieldContainer: {
    width: '250px',
    marginLeft: '10px',
  },
  autocompleteAddRepresentative: {
    color: theme.palette.primary.main,
  },
  switchAndTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '10px',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  lineThroughTextField: {
    textDecoration: 'line-through',
    textDecorationColor: 'rgba(0, 0, 0, 0.38)',
  },
}));

export default useStyles;
