import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: '14px',
    },
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkStyleFormat: {
    color: 'blue',
    textDecoration: 'underline',
    display: 'inline-flex',
    fontSize: '24px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const linkStyleFormat = {
  color: 'blue',
  textDecoration: 'underline',
  display: 'inline-flex',
  fontSize: '24px',
};

export default useStyles;
export { linkStyleFormat };
