import { RequestsBombSkills, RequestsPerPage } from '../types';
import axios from '../../utils/axios';

const getBombsPerPage = async ({
  page, limit, searchValue,
}: RequestsPerPage) => {
  const url = `/api/bombs?page=${page}&limit=${limit}&searchValue=${searchValue}`;

  const response = await axios.get(url);
  return response.data;
};

const postBomb = async ({
  startHability,
  endHability,
  programId,
  selectedTestsIds,
}: {
  startHability: number,
  endHability: number,
  programId: string,
  selectedTestsIds: string[],
}) => {
  const response = await axios.post('/api/bombs', {
    startHability,
    endHability,
    programId,
    selectedTestsIds,
  });

  return response.data;
};

const deleteBomb = async (bombId: string) => {
  const response = await axios.delete(`/api/bombs/${bombId}`);

  return response.data;
};

const getBombById = async (bombId: string) => {
  const response = await axios.get(`/api/bombs/${bombId}`);

  return response.data;
};

const getBombSkillsTotals = async (bombId: string) => {
  const response = await axios.get(`/api/bombs/skills/${bombId}`);

  return response.data;
};

const getBombSkillsBySection = async ({
  bombId,
  sectionId,
}: RequestsBombSkills) => {
  const response = await axios.get(`/api/bombs/section/skills/${bombId}?sectionId=${sectionId}`);

  return response.data;
};

const getBombSkillsByStudent = async ({
  bombId,
  sectionId,
}: RequestsBombSkills) => {
  const response = await axios.get(`/api/bombs/student/skills/${bombId}?sectionId=${sectionId}`);

  return response.data;
};

const getPrograms = async (subjectId: string | number) => {
  const response = await axios.get(`/api/bombs/get/programs/${subjectId}`);

  return response.data;
};

const getSubjects = async () => {
  const response = await axios.get('/api/bombs/get/subjects');

  return response.data;
};

const getTests = async (programId: string) => {
  const response = await axios.get(`/api/bombs/get/tests/${programId}`);

  return response.data;
};

export {
  getBombsPerPage,
  getBombById,
  getBombSkillsTotals,
  getBombSkillsBySection,
  getBombSkillsByStudent,
  postBomb,
  deleteBomb,
  getPrograms,
  getSubjects,
  getTests,
};
