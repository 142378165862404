import {
  Box,
  ClassNameMap,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Download as DownloadIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { format } from 'rut.js';
import useStyles from '../styles';
import EmptyTable from '../../../../components/General/EmptyTable';
import CustomTable from '../../../../components/General/CustomTable';
import handleApiResponse from '../../../../utils/handleApiResponse';
import { downloadTest } from '../../../../requests/api/students';

const MASSIVE_DETAIL_STUDENT_UI = [
  {
    label: 'Rut', key: 'rut', valueType: 'string',
  },
  {
    label: 'Nombre alumno', key: 'studentName', valueType: 'string',
  },
  {
    label: 'Estado', key: 'status', valueType: 'string',
  },
  {
    label: 'Comentario', key: 'comment', valueType: 'string',
  },
  {
    label: 'Número de preguntas', key: 'numberOfQuestions', valueType: 'string',
  },
  {
    label: 'Descargar preguntas', key: 'questionDownloadUI', valueType: 'other',
  },
  {
    label: 'Descargar respuestas', key: 'answerDownloadUI', valueType: 'other',
  },
];

type StudentInfo = {
  feedbackId: number;
  massiveId: number;
  rut: string;
  studentName: string;
  status: 'Creado' | 'En proceso';
  comment?: string;
  numberOfQuestions: number;
  questionsPdfUrl?: string;
  solutionsPdfUrl?: string;
};

const DownloadAction = (
  classes: ClassNameMap<string>,
  urlToDownload: string | undefined,
  handleDownload: (urlToDownload: string) => Promise<void>,
) => (<Box>
  <IconButton disabled={!urlToDownload} onClick={() => handleDownload(urlToDownload!)}>
    <DownloadIcon className={urlToDownload ? classes.icons : classes.disabledIcons} />
  </IconButton>
</Box>);

const StudentsTable = ({ data }: {
  data: {
    count: number,
    values: StudentInfo[]
  }
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [localData, setLocalData] = useState<{
    count: number, values: StudentInfo[]
  }>({ count: 0, values: [] });

  const handleDownload = async (urlToDownload: string) => {
    try {
      const { signedUrl } = await downloadTest(urlToDownload);

      // Creating link to download file
      const link = document.createElement('a');
      link.href = signedUrl;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  useEffect(() => {
    const auxLocalData = data.values.map((elem) => ({
      ...elem,
      rut: format(elem.rut),
      questionDownloadUI: DownloadAction(classes, elem.questionsPdfUrl, handleDownload),
      answerDownloadUI: DownloadAction(classes, elem.solutionsPdfUrl, handleDownload),
    }));

    setLocalData({ ...data, values: auxLocalData });
  }, [data.count]);

  return (
    <Box mt={3}>
      <Typography
        variant='h3'
        color='textPrimary'
        fontWeight='bold'
      >
        Alumnos
      </Typography>
      <Box className={classes.massiveDetailTableContainer}>
        {data.count !== 0
          && <CustomTable
            headers={MASSIVE_DETAIL_STUDENT_UI}
            data={{ count: localData.count, values: localData.values }}
          />}
        {data.count === 0 && <EmptyTable />}
      </Box>
    </Box>
  );
};

export default StudentsTable;
