// Not a service, but manager of LocalStorage of institutionUserTest (redux persistent is
// not working) this is the workaround

const getTestTimerInformation = () => {
  const auxTestTimer = localStorage.getItem('testTimer');
  if (auxTestTimer) {
    return parseInt(auxTestTimer, 10);
  }
  return null;
};

const setTestTimerInformation = () => {
  localStorage.setItem('testTimer', Date.now().toString());
};

const getUserTestAnswers = () => {
  const auxTestUserAnswers = localStorage.getItem('userTestAnswers');
  if (auxTestUserAnswers) {
    return JSON.parse(auxTestUserAnswers);
  }
  return null;
};

const setUserTestAnswers = (userAnswers: Record<string,
{
  answer: string | undefined, markedForRevision: boolean,
}>) => {
  localStorage.setItem('userTestAnswers', JSON.stringify(userAnswers));
};

const getTestInProgress = () => {
  const testInProgress = localStorage.getItem('testInProgress');
  if (testInProgress) {
    return JSON.parse(testInProgress);
  }
  return null;
};

const setTestInProgress = (testInProgress:
{
  institutionSessionUserTestId: string, institutionUrlExtension: string,
}) => {
  localStorage.setItem('testInProgress', JSON.stringify(testInProgress));
};

const resetInstitutionUserTestInfo = () => {
  localStorage.removeItem('testTimer');
  localStorage.removeItem('userTestAnswers');
  localStorage.removeItem('testInProgress');
};

export default {
  getTestTimerInformation,
  setTestTimerInformation,
  getUserTestAnswers,
  setUserTestAnswers,
  getTestInProgress,
  setTestInProgress,
  resetInstitutionUserTestInfo,
};
