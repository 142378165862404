import React, { SyntheticEvent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  SvgIcon,
  Box,
} from '@mui/material';

import {
  Add as AddIcon,
  FileOpen as FileOpenIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import CustomButton from '../../../../components/General/CustomButton';
import { CustomAutocompleteTextField } from '../../../../components/General/CustomTextfields';
import handleApiResponse from '../../../../utils/handleApiResponse';
import { getSectionsAndPrograms } from '../../../../requests/api/massive';
import useStyles from '../styles';

function Header({
  setModalAddEdit, filterValues, filterChangeFunction, loading,
}: {
  setModalAddEdit: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>,
  filterValues: { programName: string, sectionId: string, },
  filterChangeFunction: (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null) => void,
  loading: boolean,
}) {
  const classes = useStyles();
  const [filtersInformation, setFilterInformation] = useState({ programs: [], sections: [] });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getFiltersInformation = async () => {
      try {
        const { programs, sections } = await getSectionsAndPrograms(source.token);
        setFilterInformation({ programs, sections });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getFiltersInformation();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        <SvgIcon >
          <FileOpenIcon />
        </SvgIcon>
        <Typography
          ml={1}
          variant="h3"
          fontWeight='bold'
        >
          Masivos
        </Typography>
      </Grid>
      {!loading ? <Grid item display="flex" alignItems="center">
        <Box className={classes.buttonContainer}>
          <CustomButton
            key={'button-massive'}
            icon={<AddIcon />}
            text={'Crear masivos'}
            colorType="tertiary"
            onClick={() => setModalAddEdit((prevState) => ({ ...prevState, open: true }))}
          />
        </Box>
        <Box className={classes.textfieldContainer}>
          <CustomAutocompleteTextField
            keyField='programName'
            name='Filtrar por programa'
            onChangeFunction={filterChangeFunction}
            selectFields={[{ name: 'Todos', id: '' }, ...filtersInformation.programs]}
            value={filterValues.programName}
            size='small'
          />
        </Box>
        <Box className={classes.textfieldContainer}>
          <CustomAutocompleteTextField
            keyField='sectionId'
            name='Filtrar por ID de sección'
            onChangeFunction={filterChangeFunction}
            selectFields={[{ name: 'Todas', id: '' }, ...filtersInformation.sections]}
            value={filterValues.sectionId}
            valueToRetrieveFromSelect='id'
            size='small'
          />
        </Box>
      </Grid> : undefined}
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
