import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';

import { ChildrenProps, ReduxState } from '../../types';

function InstitutionTestInProgressGuard({ children, admittedRoles }: ChildrenProps
& { admittedRoles?: string[] }): JSX.Element {
  const account = useSelector((state: ReduxState) => state.institutionAccount);
  const { institutionUrlExtension } = useParams<{ institutionUrlExtension: string }>();

  if (!account.institutionUser) {
    return <Redirect to={`/institucion/${institutionUrlExtension}/login`} />;
  }

  if (admittedRoles && !admittedRoles.includes(account.institutionUser.role)) {
    return <Redirect to={`/institucion/${institutionUrlExtension}/login`} />;
  }

  return <>{children}</>;
}

export default InstitutionTestInProgressGuard;
