import React from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Button, Grid, Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useStyles from '../../styles';

function DetailHeader(
  { mipreuId, student, view }: { mipreuId: string, student: string, view: string },
) {
  const classes = useStyles();
  const history = useHistory();
  const { sectionId } = useParams<{ sectionId: string }>();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        <Button
        className={classes.backButton}
          onClick={() => history.replace(`/secciones/${sectionId}`, { mipreuId })}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography variant="h4">
          <b>Ver {view} -</b> {student} / ID Sección: {mipreuId}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DetailHeader;
