import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { getTimeSlots } from '../../../../requests/api/sections';
import { SpecificTimeSlot, TimeSlot } from '../types';
import handleApiResponse from '../../../../utils/handleApiResponse';
import useStyles from '../styles';

type TimeSlotComponentProps = {
  assignedTimeSlots: TimeSlot[],
  setAssignedTimeSlots: React.Dispatch<React.SetStateAction<Array<TimeSlot>>>,
  specificTimeSlot: SpecificTimeSlot,
  setSpecificTimeSlot: React.Dispatch<React.SetStateAction<SpecificTimeSlot>>,
};

function CustomTimeSlotComponent({
  assignedTimeSlots,
  setAssignedTimeSlots,
  specificTimeSlot,
  setSpecificTimeSlot,
}: TimeSlotComponentProps) {
  // Unlike program contents, here I am not creating timeSlots,
  // I am just selecting them and associating them afterwards
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [timeSlotToShow, setTimeSlotToShow] = useState<TimeSlot[]>([]);

  const removeCurrentTimeSlot = (timeSlotId: string) => {
    const auxAssignedTimeSlots = assignedTimeSlots.filter((elem) => `${elem.id}` !== `${timeSlotId}`);
    setAssignedTimeSlots(auxAssignedTimeSlots);
  };

  useEffect(() => {
    const getAllTimeSlots = async () => {
      try {
        const { timeSlots } = await getTimeSlots();
        setTimeSlotToShow(timeSlots);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllTimeSlots();
  }, []);

  useEffect(() => {
    if (timeSlotToShow && timeSlotToShow.length > 0) {
      const timeSlotsObject: {
        [id: string]: {
          startTime: string;
          endTime: string;
          weekDay: string;
        }
      } = {};
      timeSlotToShow.forEach((elem: TimeSlot) => {
        timeSlotsObject[elem.id] = {
          startTime: elem.startTime,
          endTime: elem.endTime,
          weekDay: elem.weekDay,
        };
      });
      const auxSelectedTimeSlots = assignedTimeSlots.map((elem) => ({
        id: elem.id,
        startTime: timeSlotsObject[elem.id].startTime,
        endTime: timeSlotsObject[elem.id].endTime,
        weekDay: timeSlotsObject[elem.id].weekDay,
      }));
      setAssignedTimeSlots(auxSelectedTimeSlots);
    }
  }, [timeSlotToShow]);

  const removeNewTimeSlot = () => {
    setSpecificTimeSlot({
      isBeingModified: false,
      id: '',
      startTime: '',
      endTime: '',
      weekDay: '',
    });
  };

  const handleAddingNewTimeSlot = () => {
    const userDefinedFields = ['weekDay', 'startTime', 'endTime'];
    const addNewFieldCondition = Object.entries(specificTimeSlot)
      .filter((elem) => typeof elem[1] !== 'boolean' && userDefinedFields.includes(elem[0]))
      .every((elem) => elem[1] !== '');

    if (addNewFieldCondition) {
      setAssignedTimeSlots((prevState) => [...prevState, {
        id: specificTimeSlot.id,
        startTime: specificTimeSlot.startTime,
        endTime: specificTimeSlot.endTime,
        weekDay: specificTimeSlot.weekDay,
      }]);
      setSpecificTimeSlot({
        isBeingModified: true,
        id: '',
        startTime: '',
        endTime: '',
        weekDay: '',
      });
    } else {
      setSpecificTimeSlot((prevState) => ({ ...prevState, isBeingModified: true }));
    }
  };

  const handleTimeSlotChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    source: string,
  ) => {
    if (source === 'weekDay') {
      setSpecificTimeSlot((prevState) => ({ ...prevState, weekDay: e.target.value }));
    } else {
      if (assignedTimeSlots.some((elem) => elem.id.toString() === e.target.value)) {
        enqueueSnackbar('No puede tener la misma franja horaria para la misma seccion', { variant: 'error' });
        return;
      }

      // Get the times from the id
      const auxTimeSlot = timeSlotToShow.filter((elem) => `${elem.id}` === e.target.value);
      setSpecificTimeSlot((prevState) => ({
        ...prevState,
        id: auxTimeSlot[0].id,
        startTime: auxTimeSlot[0].startTime,
        endTime: auxTimeSlot[0].endTime,
      }));
    }
  };

  const timeSlotsUI = ({
    weekDay, id,
  }: { id: string; weekDay: string }) => {
    let deleteFunction;
    const isTimeSlotIdAlreadySelected = assignedTimeSlots.some((elem) => elem.id === id);
    if (isTimeSlotIdAlreadySelected) {
      deleteFunction = () => removeCurrentTimeSlot(id);
    } else {
      deleteFunction = () => removeNewTimeSlot();
    }
    return (
      <TableRow key={`row-item-time-slot-${id}`}>
        <TableCell>
          <TextField
            disabled={isTimeSlotIdAlreadySelected}
            key={`Dia-${id}`}
            select
            fullWidth
            name="Dia"
            value={weekDay}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTimeSlotChange(e, 'weekDay')}
          >
            {[...new Set(timeSlotToShow.map((ts) => ts.weekDay))].map((wd) => (
              <MenuItem key={`seleccion-dia-${id}`} value={wd}>
                {`${wd}`}
              </MenuItem>))}
          </TextField>
        </TableCell>
        <TableCell>
          <TextField
            disabled={isTimeSlotIdAlreadySelected}
            key={`Horario-${id}`}
            select
            fullWidth
            name="Horario"
            value={`${id}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTimeSlotChange(e, 'time')}
          >
            {timeSlotToShow
              .filter((elem) => elem.weekDay === weekDay)
              .map((option) => (
                <MenuItem key={`seleccion-tiempo-${option.id}`} value={`${option.id}`}>
                  {`${option.startTime} - ${option.endTime}`}
                </MenuItem>))}
          </TextField>
        </TableCell>
        <TableCell >
          <IconButton onClick={deleteFunction}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Typography variant='h4' fontWeight='bold' mt={2} mb={3}>Horarios</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.colWidth}>Día</TableCell>
            <TableCell className={classes.colWidth}>Horario</TableCell>
            <TableCell className={classes.iconColWidth} />
          </TableRow>
        </TableHead>
        <TableBody>
          {assignedTimeSlots.length > 0
            && assignedTimeSlots.map((elem: { id: string; weekDay: string }) => timeSlotsUI({
              id: elem.id,
              weekDay: elem.weekDay,
            }))
          }
          {specificTimeSlot.isBeingModified && timeSlotsUI({
            id: specificTimeSlot.id ?? '',
            weekDay: specificTimeSlot.weekDay,
          })}
          <TableRow>
            <TableCell colSpan={3}>
              <IconButton onClick={handleAddingNewTimeSlot}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default CustomTimeSlotComponent;
