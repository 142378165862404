import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AxiosError } from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  Edit as EditIcon,
  Search as SearchIcon,
  Slash as SlashIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import getInitials from '../../../../utils/getInitials';
import handleApiResponse from '../../../../utils/handleApiResponse';

import useStyles from './styles';
import { deleteUsers } from '../../../../requests/api/users';
import { ReduxState, User } from '../../../../types';
import { ListProps } from '../../../types';

interface UserListProps extends ListProps {
  users: User[]
}

function Results({
  users,
  query,
  setQuery,
  page,
  setPage,
  limit,
  setLimit,
  sort,
  setSort,
  toggleSearch,
  setToggleSearch,
  countUsers,
}: UserListProps) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const intl = useIntl();
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [currentUsers, setCurrentUsers] = useState(users);
  const [numOfUsers, setNumOfUsers] = useState(countUsers);

  const sortOptions = [
    {
      value: 'updated_at|DESC',
      label: intl.messages['admin.listView.results.sort.filter.newestFirst'],
    },
    {
      value: 'updated_at|ASC',
      label: intl.messages['admin.listView.results.sort.filter.oldestFirst'],
    },
  ];

  useEffect(() => { setSort(sortOptions[0].value); }, []);

  useEffect(() => {
    setCurrentUsers(users || []);
    setNumOfUsers(countUsers);
  }, [users, countUsers]);

  // const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   event.persist();
  //   setQuery(event.target.value);
  // };

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllUsers = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const emptySelection: number[] = [];
    setSelectedUsers(event.target.checked
      ? currentUsers.map((user) => user.id)
      : emptySelection);
  };

  const handleSelectOneUser = (
    userId: number,
  ) => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelected) => [...prevSelected, userId]);
    } else {
      setSelectedUsers((prevSelected) => prevSelected.filter((id) => id !== userId));
    }
  };

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number,
  ) => {
    setPage(newPage.toString());
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(event.target.value);
  };

  const enableBulkOperations = selectedUsers.length > 0;
  const selectedSomeUsers = selectedUsers.length > 0 && selectedUsers.length < currentUsers.length;
  const selectedAllUsers = selectedUsers.length === currentUsers.length;
  const account = useSelector((state: ReduxState) => state.account);

  const deleteSelectedUsers = async () => {
    if (selectedUsers.includes(account.user ? account.user.id : -1)) {
      const data = {
        message: intl.messages['admin.listView.results.error.cannotDeleteSameAccount'] as string,
        success: false,
      };
      handleApiResponse(enqueueSnackbar, data, false, 'error');
    } else {
      try {
        const response = await deleteUsers(selectedUsers);
        handleApiResponse(enqueueSnackbar, response, true);
        setCurrentUsers(currentUsers.filter((e) => (!selectedUsers.includes(e.id))));
        setSelectedUsers([]);
        if ((currentUsers.length - 1) === 0) {
          setPage('0');
        }
        setNumOfUsers((prevValue) => prevValue - 1);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    }
  };

  return (
    <Card>
      <Box
        p={2}
        minHeight={56}
        display="flex"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={intl.messages['admin.listView.results.search.placeholder'] as string}
            value={query}
            variant="outlined"
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setToggleSearch(!toggleSearch)}
          >
            <FormattedMessage
              id='admin.listView.results.search.button'
            />
          </Button>
        </Box>

        <Box>
          <TextField
            label={intl.messages['admin.listView.results.sort.filter.title']}
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>

      </Box>
      {currentUsers.length > 0
        ? <>
          {enableBulkOperations && (
            <div className={classes.bulkOperations}>
              <div className={classes.bulkActions}>
                <Checkbox
                  checked={selectedAllUsers}
                  indeterminate={selectedSomeUsers}
                  onChange={handleSelectAllUsers}
                />
                <Button
                  variant="outlined"
                  className={classes.bulkAction}
                  onClick={() => deleteSelectedUsers()}
                >
                  <FormattedMessage
                    id='admin.listView.results.delete.button'
                  />
                </Button>
              </div>
            </div>
          )}
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedAllUsers}
                        indeterminate={selectedSomeUsers}
                        onChange={handleSelectAllUsers}
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        id='admin.listView.results.table.column.name'
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        id='admin.listView.results.table.column.userType'
                      />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedMessage
                        id='admin.listView.results.table.column.actions'
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUsers.map((user) => {
                    const isUserSelected = selectedUsers.includes(user.id);

                    return (
                      <TableRow
                        hover
                        key={user.id}
                        selected={isUserSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isUserSelected}
                            onChange={() => handleSelectOneUser(user.id)}
                            value={isUserSelected}
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                          >
                            <Avatar
                              className={classes.avatar}
                            >
                              {getInitials(user.name)}
                            </Avatar>
                            <div>
                              <Typography
                                color="inherit"
                                variant="h6"
                              >
                                {user.name} {' '} {user.lastname}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                              >
                                {user.email}
                              </Typography>
                            </div>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {user.userType}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            component={RouterLink}
                            to={`/administracion/usuarios/${user.id}/editar`}
                            size="large">
                            <SvgIcon fontSize="small">
                              <EditIcon />
                            </SvgIcon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={numOfUsers}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={Number(page)}
            rowsPerPage={Number(limit)}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={intl.messages['admin.listView.results.table.footer.rowsPerPage']}
          />
        </>
        : <Box className={classes.noDataDiv}>
          <SvgIcon className={classes.iconContainer}>
            <SlashIcon />
          </SvgIcon>
          <Box>
            <Typography
              variant="h1"
            >
              <FormattedMessage
                id='admin.listView.results.table.noData'
              />
            </Typography>
          </Box>
        </Box>
      }

    </Card>
  );
}

export default Results;
