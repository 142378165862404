import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import Page from '../../components/Layout/Page';
import useStyles, { linkStyleFormat } from './styles';
import { ReduxState } from '../../types';
import fallbackRoutes from '../../constants/fallbackRoutes';
import { logout } from '../../actions/accountActions';
import { persistorFlush } from '../../store';

function Unauthorized() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state: ReduxState) => state.account.user);

  const fallBackRoute = user?.userType ? fallbackRoutes[user.userType] : '/login';

  const handleLogout = async () => {
    try {
      dispatch(logout());
      await persistorFlush();
      history.push('/');
    } catch (error) {
      enqueueSnackbar('No se pudo cerrar sesión.', {
        variant: 'error',
      });
    }
  };

  return (
    <Page
      className={classes.root}
      title={intl.messages['noAuthorization.title'] as string}
    >
      <Box className={classes.mainDiv}>
        {/* eslint-disable-next-line global-require */}
        <img src={require('../../assets/images/error_401.jpg')} width={800} />
        <Box
          maxWidth={800}
          mt={2}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            align='justify'
          >
            <FormattedMessage id='noAuthorization.firstMessage'/><Link
              to={fallBackRoute} style={linkStyleFormat}><FormattedMessage id='noAuthorization.here'/></Link><FormattedMessage id='noAuthorization.secondMessage'/><span className={classes.linkStyleFormat} onClick={handleLogout}>
              <FormattedMessage id='noAuthorization.here'/></span><FormattedMessage id='noAuthorization.thirdMessage'/>
          </Typography>
        </Box>
      </Box>
    </Page>
  );
}

export default Unauthorized;
