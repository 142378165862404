import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DownloadIcon from '@mui/icons-material/Download';
import MessageIcon from '@mui/icons-material/Forum';

import handleApiResponse from '../../utils/handleApiResponse';
import { formatTime } from '../../utils/helpers';
import { getInstitutionSessionUserTestInfo, postDownloadTestPDF } from '../../requests/api/institutionTests';

import { InstitutionSessionUserTest } from './types';

import CircularProgressComponent from '../../components/Loading/CircularProgressComponent';
import Page from '../../components/Layout/Page';
import InstitutionUserTestDetailHeader from './InstitutionUserTestDetailHeader';

import useStyles from './styles';
import CustomButton from '../../components/General/CustomButton';

function InstitutionUserTest() {
  const { institutionUrlExtension, institutionSessionUserTestId } = useParams<{
    institutionUrlExtension: string, institutionSessionUserTestId: string
  }>();
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [institutionTest, setInstitutionTest] = useState<InstitutionSessionUserTest>({
    test: {
      name: '',
      durationMinutes: 0,
      pdfUrl: '',
      subject: {
        name: '',
      },
    },
    competence: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const loadInstitutionSessionUserTests = async () => {
      try {
        const {
          institutionSessionUserTest: institutionTestInfo,
        } = await getInstitutionSessionUserTestInfo(institutionSessionUserTestId);

        setInstitutionTest({
          ...institutionTestInfo,
          competence: institutionTestInfo.test.subject.name === 'MATEMATICA' ? 'Competencia Matemática' : 'Competencia Lectora',
        });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
      setShowLoading(false);
    };
    loadInstitutionSessionUserTests();
  }, [institutionUrlExtension, institutionSessionUserTestId]);

  const handleDownload = async (urlToDownload: string) => {
    setDownloadLoading(true);
    try {
      const { signedUrl } = await postDownloadTestPDF(urlToDownload);

      const link = document.createElement('a');
      link.href = signedUrl;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
    setDownloadLoading(false);
  };

  return (
    <Page title="Ensayo">
      {showLoading
        ? <CircularProgressComponent />
        : <Box className={classes.testContainer}>
            <InstitutionUserTestDetailHeader
              testName={institutionTest.test.name}
              competence={institutionTest.competence.toUpperCase()}
            />
            <Box mt={3} className={classes.instructionsBox}>
              <Typography>Estimado(a) Estudiante.</Typography>
              <Typography>
                <b>Lee atentamente las siguientes instrucciones antes de iniciar los Ensayos:</b>
              </Typography>
              <ul className={classes.list}>
                <li>Los ensayos se pueden responder <b>solo una vez.</b></li>
                <li>Para una mejor experiencia recomendamos rendir los ensayos en un computador
                  de escritorio.</li>
                <li>Los ensayos de <b>{institutionTest.competence} </b>
                  tienen 65 preguntas un tiempo de prueba de
                  <b> {formatTime(institutionTest.test.durationMinutes)}.</b></li>
                <li>Si lo deseas, puedes descargar e imprimir previamente los ensayos que vas a
                  rendir para que trabajes con mayor comodidad.</li>
                <li>Cuando hayas terminado, presiona la opción &quot;Entregar&quot; y obtendrás tu
                  corrección automáticamente.</li>
                <li>Una vez transcurrido el tiempo límite de cada prueba, tu ensayo se entregará
                  automáticamente y no podrás seguir respondiendo.</li>
                <li>Si lo deseas, puedes corregir inmediatamente las preguntas erróneas a través
                  de la visualización de un solucionario.</li>
                <li>Si tienes problemas durante la realización de los ensayos puedes escribir al
                  <b> chat en línea</b> (Whatsapp disponible al pie de página).</li>
              </ul>
              <Typography>Esperamos que tengas una buena experiencia de ensayo.</Typography>
              <Typography mt={2}>
                Universidad Adolfo Ibáñez - Preuniversitario Cárez y Córdova.
              </Typography>
            </Box>
            <Box className={classes.buttonsContainer}>
              <CustomButton text={'Hacer en otro momento'} colorType='primary' onClick={() => history.goBack()} icon={<ArrowBackIosIcon />} />
              <CustomButton text={'Descargar material imprimible'} loading={downloadLoading} colorType='primary' onClick={() => handleDownload(institutionTest.test.pdfUrl)} icon={<DownloadIcon />} />
              <CustomButton text={'Contestar'} colorType='primary' onClick={() => history.replace(`/institucion/${institutionUrlExtension}/ensayos/${institutionSessionUserTestId}/evaluacion`)} icon={<MessageIcon />} />
            </Box>
          </Box>
        }
    </Page>
  );
}

export default InstitutionUserTest;
