import { StudentContract } from '../types';

export const SET_NEW_CONTRACT = '@student-contract/new-contract';
export const RESET_CONTRACT = '@student-contract/reset-contract';
export const SET_ADD_PROGRAM_MODAL_VALUES = '@student-contract/set-add-program-modal-values';
export const RESET_ADD_PROGRAM_MODAL_VALUES = '@student-contract/reset-add-program-modal-values';

export const setNewContract = (contractData: {
  studentId: string; studentContract: StudentContract
}) => ({
  type: SET_NEW_CONTRACT,
  payload: {
    contractData,
  },
});

export const resetContract = (contractData: { studentId: string }) => ({
  type: RESET_CONTRACT,
  payload: {
    contractData,
  },
});

export const setAddProgramModalValues = (contractAddProgramModal: {
  open: boolean;
  values?: {
    subjectId: string;
    selectedProgramId: string;
    feeDiscount: string;
    comment: string,
    selectedProgramIdToEdit?: string;
  }
}) => ({
  type: SET_ADD_PROGRAM_MODAL_VALUES,
  payload: { contractAddProgramModal },
});

export const resetAddProgramModalValues = () => ({
  type: RESET_ADD_PROGRAM_MODAL_VALUES,
});
