import { CancelToken } from 'axios';
import axios from '../../utils/axios';

const getMassivesPerPage = async ({
  page, limit, sectionIdFilter, programNameFilter, sort,
}: {
  page: number | string;
  limit: number | string;
  sectionIdFilter: string;
  programNameFilter: string;
  sort: string;
}, cancelToken?: CancelToken) => {
  const response = await axios.get(`/api/massives?page=${page}&limit=${limit}&sectionIdFilter=${sectionIdFilter}&programNameFilter=${programNameFilter}&sort=${sort}`, { cancelToken });

  return response.data;
};

const postMassives = async ({
  programId,
  minQuestions,
  maxQuestions,
  selectedTestsIds,
}: {
  programId: string,
  minQuestions: number,
  maxQuestions: number,
  selectedTestsIds: string[],
}) => {
  const response = await axios.post('/api/massives', {
    programId,
    minQuestions,
    maxQuestions,
    selectedTestsIds,
  });

  return response.data;
};

const deleteMassive = async (massiveId: string) => {
  const response = await axios.delete(`/api/massives/${massiveId}`);

  return response.data;
};

const getPrograms = async () => {
  const response = await axios.get('/api/massives/get/programs');

  return response.data;
};

const getTests = async (programId: string) => {
  const response = await axios.get(`/api/massives/get/tests/${programId}`);

  return response.data;
};

const getSectionsAndPrograms = async (cancelToken?: CancelToken) => {
  const response = await axios.get('/api/massives/get/filters-information', { cancelToken });

  return response.data;
};

const getQuestionsAnswersZip = async (massiveId: string) => {
  const response = await axios.get(`/api/massives/download-questions-anwsers/${massiveId}`);

  return response.data;
};

export {
  getMassivesPerPage,
  postMassives,
  deleteMassive,
  getPrograms,
  getTests,
  getSectionsAndPrograms,
  getQuestionsAnswersZip,
};
