import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';
import { Redirect } from 'react-router-dom';
import { AxiosError } from 'axios';

import { useIntl, FormattedMessage } from 'react-intl';
import { editUserData } from '../../../../requests/api/users';

import handleApiResponse from '../../../../utils/handleApiResponse';
import { User } from '../../../../types';

import useStyles from './styles';
import { postRegister } from '../../../../requests/api/auth';

interface UserEditProps {
  user: User | null,
}

function UserUpsertForm({ user }: UserEditProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const classes = useStyles();
  const intl = useIntl();

  if (submitSuccess) {
    return <Redirect to="/administracion/usuarios" />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: user?.name || '',
        lastname: user?.lastname || '',
        userType: user?.userType || '',
        email: user?.email || '',
        active: user?.active || false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required(intl.messages['admin.upsert.form.validation.enterName'] as string),
        lastname: Yup.string().max(255).required(intl.messages['admin.upsert.form.validation.enterLastname'] as string),
        userType: Yup.string().required(intl.messages['admin.upsert.form.validation.enterUserType'] as string),
        active: Yup.boolean(),
      })}
      onSubmit={async (values, {
        // setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            name: values.name,
            email: values.email,
            lastname: values.lastname,
            userType: values.userType,
            active: values.active,
          };

          let response = null;
          if (user?.id) {
            data.active = values.active;
            response = await editUserData({
              userId: user.id,
              newData: data,
            });
          } else {
            response = await postRegister(data);
          }

          handleApiResponse(enqueueSnackbar, response, true);
          setSubmitSuccess(true);
          setStatus({ success: true });
          setSubmitting(false);
        } catch (error) {
          const e = error as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false, 'error', <Button href="/administracion/usuarios"><FormattedMessage id='admin.upsert.form.error.goBackToUsers.actionButton'/></Button>);
          setStatus({ success: false });
          // setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={classes.formContainer}
          onSubmit={handleSubmit}
        >

          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={intl.messages['admin.upsert.form.textField.name'] }
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label={intl.messages['admin.upsert.form.textField.lastname'] }
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label={intl.messages['admin.upsert.form.textField.email'] }
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                    disabled={!!user?.id}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label={intl.messages['admin.upsert.form.textField.userType'] }
                    name="userType"
                    onChange={handleChange}
                    select
                    value={values.userType}
                    variant="outlined"
                  >
                    <MenuItem
                      value="administrator"
                    >
                      <FormattedMessage id='admin.upsert.form.textField.userType.option.administrator'/>
                    </MenuItem>
                    <MenuItem
                      value="student"
                    >
                      <FormattedMessage id='admin.upsert.form.textField.userType.option.student'/>
                    </MenuItem>
                    <MenuItem
                      value="teacher"
                    >
                      <FormattedMessage id='admin.upsert.form.textField.userType.option.teacher'/>
                    </MenuItem>
                    <MenuItem
                      value="academic"
                    >
                      <FormattedMessage id='admin.upsert.form.textField.userType.option.academic'/>
                    </MenuItem>
                    <MenuItem
                      value="parent"
                    >
                      <FormattedMessage id='admin.upsert.form.textField.userType.option.parent'/>
                    </MenuItem>
                  </TextField>
                </Grid>
                {user?.id && <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label={intl.messages['admin.upsert.form.textField.state'] }
                    name="active"
                    onChange={handleChange}
                    select
                    value={values.active}
                    variant="outlined"
                  >
                    <MenuItem
                      value='true'
                    >
                      <FormattedMessage id='admin.upsert.form.textField.state.option.active' />
                    </MenuItem>
                    <MenuItem
                      value='false'
                    >
                      <FormattedMessage id='admin.upsert.form.textField.state.option.inactive' />
                    </MenuItem>
                  </TextField>
                </Grid>}
                <Grid item />
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id='admin.upsert.form.card.save.button'/>
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

export default UserUpsertForm;
