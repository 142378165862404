import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  LocalLibrary as LocalLibraryIcon,
} from '@mui/icons-material';
import {
  Box,
  ClassNameMap,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { format } from 'rut.js';

import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import {
  deleteRepresentative,
  getRepresentativesPerPage,
  postCreateRepresentative,
  putUpdateRepresentative,
} from '../../../requests/api/representatives';

import useStyles from './styles';
import {
  RowData,
  ITEMS_UI,
  ITEMS_EDIT_UI,
} from './types';

import InsertModal from './Modals/InsertModal';
import DeleteModal from '../../../components/General/DeleteModal';
import EditModal from './Modals/EditModal';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';

const TITLE = 'Apoderados';
const TITLE_DELETE = 'apoderado';
const CREATE_TEXT = 'Crear apoderado';
const FIND_TEXT = 'Buscar apoderado...';

const buttonActions = (
  classes: ClassNameMap<string>,
  id: string,
  openAddEditModal: ({ open, id }: { open: boolean, id: string }) => void,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box>
  <IconButton onClick={() => openAddEditModal({ id, open: true })}>
    <EditIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => openDeleteModal({ id, open: true })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

function ListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [allRows, setAllRows] = useState({ items: [], totalItems: 0 });
  const [modalAddRow, setModalAddRow] = useState({ id: '', open: false });
  const [modalEditRow, setModalEditRow] = useState({ id: '', open: false });
  const [modalDeleteARow, setModalDeleteARow] = useState({ id: '', open: false });
  const [tableParameters, setTableParameters] = useState({ page: 0, rowsPerPage: 5 });
  const [searchValue, setSearchValue] = useState('');
  const [updateTable, setUpdateTable] = useState(false);
  const [modalValues, setModalValues] = useState<RowData>({
    userId: '',
    rut: '',
    phoneNumber: '',
    name: '',
    lastname: '',
    email: '',
    birthDate: '',
    nationality: '',
    maritalStatus: '',
    profession: '',
    address: '',
    commune: '',
  });

  useEffect(() => {
    const getRows = async () => {
      try {
        const { rows, count } = await getRepresentativesPerPage({
          page: tableParameters.page,
          limit: tableParameters.rowsPerPage,
          searchValue,
          sort: 'name|ASC',
        });

        const rowsAndActions = rows.map((elem: RowData) => ({
          ...elem,
          name: elem.user && elem.user.name,
          lastname: elem.user && elem.user.lastname,
          email: elem.user && elem.user.email,
          rut: format(elem.rut),
          actions: buttonActions(
            classes,
            elem.id!,
            setModalEditRow,
            setModalDeleteARow,
          ),
        }));
        setAllRows({ items: rowsAndActions, totalItems: count });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setLoading(false);
      }
    };

    getRows();
  }, [
    tableParameters.page,
    tableParameters.rowsPerPage,
    searchValue,
    updateTable,
  ]);

  const handleCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setModalValues({
      userId: '',
      rut: '',
      phoneNumber: '',
      name: '',
      lastname: '',
      email: '',
      birthDate: '',
      nationality: '',
      maritalStatus: '',
      profession: '',
      address: '',
      commune: '',
    });
    setUpdateTable(!updateTable);
  };

  const addRow = async () => {
    try {
      const response = await postCreateRepresentative({
        rut: modalValues.rut,
        phoneNumber: modalValues.phoneNumber,
        name: modalValues.name,
        lastname: modalValues.lastname,
        email: modalValues.email,
        birthDate: modalValues.birthDate,
        nationality: modalValues.nationality,
        maritalStatus: modalValues.maritalStatus,
        profession: modalValues.profession,
        address: modalValues.address,
        commune: modalValues.commune,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalAddRow);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const editRow = async (id: string) => {
    try {
      const response = await putUpdateRepresentative({
        id,
        rut: modalValues.rut,
        phoneNumber: modalValues.phoneNumber,
        name: modalValues.name,
        lastname: modalValues.lastname,
        birthDate: modalValues.birthDate,
        nationality: modalValues.nationality,
        maritalStatus: modalValues.maritalStatus,
        profession: modalValues.profession,
        address: modalValues.address,
        commune: modalValues.commune,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalEditRow);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const deleteRow = async (teacherId: string) => {
    try {
      const response = await deleteRepresentative(teacherId);
      handleApiResponse(enqueueSnackbar, response, true);
      setModalDeleteARow({ id: '', open: false });
      setUpdateTable(!updateTable);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File,
    source: string,
    value?: string | object | null,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (!(e instanceof File)) {
      let newValue: string | number | { id: number, name: string };
      if (source === 'commune' || source === 'maritalStatus' || source === 'nationality') {
        if (!value) {
          newValue = '';
          return;
        }
        const { name } = value as { id: number, name: string };
        newValue = name;
      } else {
        newValue = (e.target as HTMLInputElement).value;
      }

      setModalValues(
        (prevState) => ({ ...prevState, [source]: newValue }),
      );
    }
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue((e.target as HTMLInputElement).value);
    if (tableParameters.page !== 0) {
      setTableParameters((prevState) => ({ ...prevState, page: 0 }));
    }
  };

  return (
    <Page
      title={TITLE}
    >
      <Header
        title={TITLE}
        icon={<LocalLibraryIcon />}
        buttons={!loading ? [
          {
            text: CREATE_TEXT,
            icon: <AddIcon />,
            onClick: () => setModalAddRow((prevState) => ({ ...prevState, open: true })),
          },
        ] : undefined}
        search={!loading ? {
          text: FIND_TEXT,
          value: searchValue,
          onChangeSearchValue: handleChangeSearchValue,
        } : undefined}
      />
      <Box
        mt={3}
      >
        {loading ? (
          <CircularProgressComponent />

        ) : (
          <>
            {allRows.totalItems !== 0
              && <CustomTable
                headers={ITEMS_UI.filter((elem) => elem.showOnTable)}
                data={{ values: allRows.items, count: allRows.totalItems }}
                tableParameters={tableParameters}
                setTableParameters={setTableParameters}
              />}
            {allRows.totalItems === 0 && <EmptyTable />}
          </>
        )}
      </Box>
      <InsertModal
        modalAdd={modalAddRow}
        setModalAdd={setModalAddRow}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        handleFieldChange={handleFieldChange}
        itemsUI={ITEMS_UI}
        addFunction={addRow}
      />
      <EditModal
        allItems={allRows}
        modalEdit={modalEditRow}
        setModalEdit={setModalEditRow}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        setModalValues={setModalValues}
        handleFieldChange={handleFieldChange}
        itemsUI={ITEMS_EDIT_UI}
        editFunction={editRow}
      />
      <DeleteModal
        title={TITLE_DELETE}
        modalDelete={modalDeleteARow}
        setModalDelete={setModalDeleteARow}
        handleCloseModal={handleCloseModal}
        deleteFunction={deleteRow}
      />
    </Page>
  );
}

export default ListView;
