export const SUMMARY_TABLE_UI = [
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Número de clases', key: 'numberOfClasses', valueType: 'string',
  },
  {
    label: 'Asistencias', key: 'attended', valueType: 'string',
  },
  {
    label: 'Atrasos', key: 'arrivedLate', valueType: 'string',
  },
  {
    label: 'Inasistencias', key: 'absent', valueType: 'string',
  },
];

export const ASSISTANCE_OPTIONS_UI = [{ name: 'Presente', value: 'present' }, { name: 'Ausente', value: 'absent' }, { name: 'Atrasado', value: 'late' }];

export type AssistanceSummaryObj = {
  programName: string;
  numberOfClasses: number;
  attended: number | string;
  arrivedLate: number | string;
  absent: number | string;
};

export type AssistanceDetailObj = {
  studentClassId: string;
  programId: string;
  classId: string;
  studentId: string;
  studentName: string;
  studentLastname: string;
  studentRut: string;
  programName: string;
  mipreuId: string;
  sectionId: string;
  date: string;
  scheduledTime: {
    weekDay: string;
    startTime: string;
    endTime: string;
  };
  teacherNames: string[];
  attended: boolean;
  arrivedLate: boolean;
};
