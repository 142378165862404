import { SECRETARY } from '../../../utils/user_types';

type UserData = {
  name: string;
  lastname: string;
  email: string;
};

type TeacherData = {
  id?: string;
  userId?: string;
  rut: string;
  phoneNumber: string;
  birthDate: Date;
  moduleFee: string;
  name: string;
  lastname: string;
  email?: string;
  user?: UserData;
};

const TEACHERS_UI = [
  {
    label: 'Nombre', key: 'name', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Apellido', key: 'lastname', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Rut', key: 'rut', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Teléfono', key: 'phoneNumber', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Email', key: 'email', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Cumpleaños', key: 'birthDate', showOnTable: true, showOnModal: true, typeField: 'date', valueType: 'date',
  },
  {
    label: 'Valor módulo', key: 'moduleFee', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'ccy', excludedRoles: [SECRETARY],
  },
  {
    label: 'Acciones', key: 'actions', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string', excludedRoles: [SECRETARY],
  },
];

export {
  TeacherData,
  TEACHERS_UI,
};
