import React, { FunctionComponent, HTMLAttributes } from 'react';

const Logo: FunctionComponent<HTMLAttributes<HTMLImageElement>> = (
  props: HTMLAttributes<HTMLImageElement>,
) => (
  <img
    alt="Logo"
    src="/img/carez-cordova-logo.png"
    {...props}
  />
);

export default Logo;
