import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';

import { getBombSkillsTotals } from '../../../../../requests/api/bombs';
import handleApiResponse from '../../../../../utils/handleApiResponse';

import { BOMB_TOTALS_UI, BombSkills } from './types';
import CustomTable from '../../../../../components/General/CustomTable';

function TotalsListView() {
  const { enqueueSnackbar } = useSnackbar();
  const { id: bombId } = useParams<{ id: string }>();

  const [bombSkillsTotals, setBombSkillsTotals] = useState<BombSkills[]>([]);

  useEffect(() => {
    const getBombSkills = async () => {
      try {
        const { bombSkills } = await getBombSkillsTotals(bombId);

        setBombSkillsTotals(bombSkills);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getBombSkills();
  }, [bombId]);

  return (
    <Box mt={3}>
      <CustomTable
        headers={BOMB_TOTALS_UI}
        data={{ values: bombSkillsTotals, count: bombSkillsTotals.length }}
      />
    </Box>
  );
}

export default TotalsListView;
