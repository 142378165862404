import React, { SyntheticEvent } from 'react';
import {
  Divider, Typography, Box, Checkbox,
} from '@mui/material';

import { Installment } from '../../../types';
import { formatCcy } from '../../../../../../utils/helpers';

import { CustomAutocompleteTextField, CustomSelectTextField } from '../../../../../../components/General/CustomTextfields';
import InstallmentComponent from './Installment';

import useStyles from '../../../styles';

const installmentsOptions = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
  { id: '9', name: '9' },
  { id: '10', name: '10' },
  { id: '11', name: '11' },
  { id: '12', name: '12' },
];

type Field = { keyField: string, name: string };

type ContractPaymentMethodSectionProps = {
  title: string,
  options: { id: string, name: string }[],
  fields: Field[],
  selectedValue: string,
  showInstallments?: boolean,
  installments?: Installment[],
  setInstallments?: (updatedInstallments: Installment[]) => void,
  installmentsNumber?: number,
  values: { valueWithoutDiscounts: number, discount: boolean, valueWithDiscounts: number },
  payment: string,
  applyDiscount?: boolean,
  final?: boolean,
  setUpdateInstallments?: (updateInstallments: (prevState: boolean) => boolean) => void,
  handleFieldChange?: ((e: React.ChangeEvent<HTMLInputElement> | Date, source: string) => void),
  handleSelectChange: (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => void,
  disabledDiscount?: boolean,
  handleDisableDiscount?: (disabled: boolean, discountName: string) => void,
};

function ContractPaymentMethodSection({
  title,
  options,
  fields,
  handleSelectChange,
  selectedValue,
  showInstallments,
  installments,
  setInstallments,
  installmentsNumber,
  values,
  payment,
  applyDiscount,
  final,
  setUpdateInstallments,
  disabledDiscount,
  handleDisableDiscount,
}: ContractPaymentMethodSectionProps) {
  const classes = useStyles();
  return (
    <Box className={classes.contractViewContractDiscountContainer}>
      <Box className={classes.contractTextFieldsContainer}>
        <Box className={classes.contractViewProgramsTitleContainer}>
          <Typography variant='h3' color='textPrimary' fontWeight='bold' mb={4}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.contractViewContractGroupingContainer}>
          <Box className={classes.contractFinalValue}>
            <Typography fontWeight='bold' variant='h5'>
              Valor final {payment}:
            </Typography>
            {!final && values?.discount && values.valueWithoutDiscounts && !disabledDiscount ? (
              <Typography variant='h6'>
                <s>{formatCcy(values.valueWithoutDiscounts, '.')}</s><span style={{ fontSize: '11px' }}> (3% de descuento)</span>
              </Typography>
            ) : null}
            <Typography variant='h5'>
              {formatCcy(values.valueWithDiscounts, '.')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.contractViewContractDiscountContent}>
        <Box className={classes.contractViewContractGroupingContainer}>
          {fields.map((field, index) => (
            <Box key={index} className={classes.contractPaymentTextfieldsContainer}>
              <CustomAutocompleteTextField
                keyField={field.keyField}
                name={field.name}
                onChangeFunction={handleSelectChange}
                selectFields={options}
                value={selectedValue}
                valueToRetrieveFromSelect="id"
                size='medium'
              />
            </Box>
          ))}
          {showInstallments ? (
            <Box className={classes.contractPaymentTextfieldsContainer}>
              <CustomSelectTextField
                keyField='installments'
                name='N° de cuotas'
                value={`${installmentsNumber}`}
                onChangeFunction={handleSelectChange}
                selectFields={installmentsOptions}
                valueToRetrieveFromSelect='id'
                renderValue={() => (
                  <Box>
                    {!showInstallments ? '' : `${installmentsNumber
                      ? `${installmentsNumber} cuota${installmentsNumber && installmentsNumber > 1 ? 's' : ' '}${applyDiscount ? '(3% descuento sobre total)' : ''}`
                      : ''}`}
                  </Box>
                )}
                disabled={!showInstallments}
              />
            </Box>
          ) : null}
          {(values?.discount || disabledDiscount) && handleDisableDiscount ? (
            <Box className={classes.discountCheckbox}>
              <Checkbox
                checked={disabledDiscount}
                onChange={(e) => handleDisableDiscount(e.target.checked, 'disabledDiscount')}
              />
              <Typography>{disabledDiscount ? 'Activar' : 'Desactivar'} descuento</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      {installments && setInstallments && (
        <Box className={classes.contractViewContractDiscountContainer}>
          <Box className={classes.contractViewContractDiscountContainer}>
            {installments.map((installment, index) => (
              <InstallmentComponent
                key={index}
                installment={installment}
                installmentType={selectedValue === 'check' ? 'Cheque' : 'Mensualidad'}
                setUpdateInstallments={setUpdateInstallments}
                setInstallment={(updatedInstallment) => {
                  const updatedInstallments = installments.map(
                    (c, i) => (i === index ? updatedInstallment : c),
                  );
                  setInstallments(updatedInstallments);
                }}
              />
            ))}
          </Box>
        </Box>
      )}
      <Divider sx={{ marginTop: '30px' }} />
    </Box>
  );
}

export default ContractPaymentMethodSection;
