import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import {
  Typography,
  Grid,
  Button,
  Modal,
  Box,
  Card,
  CardContent,
  IconButton,
  TextField,
  MenuItem,
  Switch,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Edit as EditIcon, Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { format } from 'rut.js';
import CustomButton from '../../../../components/General/CustomButton';
import { StudentData } from '../../StudentListView/types';
import useStyles from '../styles';
import { CustomTextField } from '../../../../components/General/CustomTextfields';
import handleApiResponse from '../../../../utils/handleApiResponse';
import { putUpdateStudent } from '../../../../requests/api/students';
import { REPRESENTATIVE, STUDENT } from '../../../../utils/user_types';

type StudentDetailHeaderProps = {
  student: StudentData;
  students?: StudentData[];
  setUpdateStudentTrigger: React.Dispatch<React.SetStateAction<boolean>>
  userType: string;
};

function StudentDetailHeader({
  student,
  students,
  setUpdateStudentTrigger,
  userType,
}: StudentDetailHeaderProps) {
  const classes = useStyles();
  const history = useHistory();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [studentToModify, setStudentToModify] = useState(student);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setStudentToModify(student);
  }, [student]);

  const handleCloseModal = () => {
    setStudentToModify(student);
    setOpenEditModal(false);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | Date, source: string,
  ) => {
    if (e instanceof Date) {
      setStudentToModify((prevState) => ({ ...prevState, [source]: e }));
    } else if (!(e instanceof File)) {
      setStudentToModify((prevState) => ({ ...prevState, [source]: e.target.value }));
    }
  };

  const handleEditStudent = async () => {
    if (studentToModify.email.trim() === '' || studentToModify.phoneNumber.trim() === '' || studentToModify.name.trim() === '' || studentToModify.lastname.trim() === '' || studentToModify.rut.trim() === '') {
      enqueueSnackbar('Campos no pueden estar vacíos', {
        variant: 'error',
      });
      return;
    }

    try {
      const response = await putUpdateStudent({
        id: student.id!,
        phoneNumber: studentToModify.phoneNumber,
        name: studentToModify.name,
        lastname: studentToModify.lastname,
        rut: studentToModify.rut,
        email: studentToModify.email,
        onlineEnabled: studentToModify.onlineEnabled,
      });

      setUpdateStudentTrigger((prevState) => !prevState);
      handleApiResponse(enqueueSnackbar, response, true);
      setOpenEditModal(false);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleStudentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    history.replace(`/alumnos/${e.target.value}`);
  };

  const showSelect = students && students.length > 1;

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        {userType !== STUDENT && userType !== REPRESENTATIVE && <Button
          className={classes.backButton}
          onClick={() => history.replace('/alumnos')}
        >
          <ArrowBackIosIcon />
        </Button>}
        {students && students.length > 1
          && <TextField
            fullWidth
            value={studentToModify.studentId}
            variant='standard'
            select
            onChange={handleStudentChange}
            size='medium'
          >
            {students.map((st: StudentData) => (
              <MenuItem key={st.studentId} value={st.studentId}>
                {`${st.name} ${st.lastname}`}
              </MenuItem>
            ))}

          </TextField>}
        {!showSelect && <Typography
          variant="h3"
          fontWeight='bold'
        >
          {`${studentToModify.name} ${studentToModify.lastname}`}
        </Typography>}
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Rut: </strong><span style={{ whiteSpace: 'nowrap' }}>{format(studentToModify.rut)}</span></>}
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Teléfono: </strong><span>{studentToModify.phoneNumber}</span></>}
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Email: </strong><span>{studentToModify.email}</span></>}
        </Typography>
      </Grid>
      {userType !== STUDENT && userType !== REPRESENTATIVE && <Grid item display="flex" alignItems="center">
        <CustomButton
          key='edit-student-info'
          icon={<EditIcon />}
          text="Editar info del alumno"
          colorType="tertiary"
          onClick={() => setOpenEditModal(true)}
        />
      </Grid>}

      <Modal
        open={openEditModal}
        onClose={handleCloseModal}
      >
        <Box className={classes.modalContainer}>
          <Card className={classes.modal}>
            <CardContent className={classes.modalContent}>
              <Box className={classes.modalTitleContainer}>
                <Box className={classes.modalTitleAndIcon}>
                  <EditIcon color='primary' />
                  <Typography variant='h4' color='primary' fontWeight='bold'>Editar Alumno</Typography>
                  <Typography variant='h4' color='primary' ml={1}>{`- ${studentToModify.name}`}</Typography>
                </Box>
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon color='primary' />
                </IconButton>
              </Box>
              <Box className={classes.modalTextfieldsContainer}>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomTextField
                    keyField={'name'}
                    name={'Nombre'}
                    value={studentToModify.name}
                    onChangeFunction={handleFieldChange}
                  />
                </Box>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomTextField
                    keyField={'lastname'}
                    name={'Apellidos'}
                    value={studentToModify.lastname}
                    onChangeFunction={handleFieldChange}
                  />
                </Box>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomTextField
                    keyField={'rut'}
                    name={'Rut'}
                    value={studentToModify.rut}
                    onChangeFunction={handleFieldChange}
                  />
                </Box>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomTextField
                    keyField={'phoneNumber'}
                    name={'Teléfono'}
                    value={studentToModify.phoneNumber}
                    onChangeFunction={handleFieldChange}
                  />
                </Box>
                <Box className={classes.modalIndividualTextfield}>
                  <CustomTextField
                    keyField={'email'}
                    name={'Email'}
                    value={studentToModify.email}
                    onChangeFunction={handleFieldChange}
                  />
                </Box>
                <Box className={classes.switchAndTitleContainer}>
                  <Typography variant='h6' color='primary' fontWeight='bold'>¿Guía Online?</Typography>
                  <Box className={classes.switchContainer}>
                    <Typography variant='h6'>Deshabilitado</Typography>
                    <Switch
                      checked={studentToModify.onlineEnabled}
                      onChange={(e) => setStudentToModify((prevState) => ({
                        ...prevState,
                        onlineEnabled: e.target.checked,
                      }))}
                    />
                    <Typography variant='h6'>Habilitado</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.modalButtonsContainer}>
                <CustomButton text='Cancelar' colorType='tertiary' onClick={handleCloseModal} key={'CancelButtonAddProgram'} icon={<CloseIcon />} />
                <CustomButton text='Confirmar cambio' colorType='primary' onClick={handleEditStudent} key={'AcceptButtonEditStudent'} icon={<CheckIcon />} />
              </Box>
            </CardContent>
          </Card >
        </Box >
      </Modal >
    </Grid >
  );
}

StudentDetailHeader.propTypes = {
  className: PropTypes.string,
};

export default StudentDetailHeader;
