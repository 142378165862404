export const TEACHERS_TABLE_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Sede', key: 'locationName', valueType: 'string',
  },
  {
    label: 'Sección', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Profesor', key: 'teacherName', valueType: 'string',
  },
];

export const STUDENTS_TABLE_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Sede', key: 'locationName', valueType: 'string',
  },
  {
    label: 'Sección', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Profesor', key: 'teacherName', valueType: 'string',
  },
  {
    label: 'Alumno', key: 'student', valueType: 'string',
  },
];

export const ASSISTANCES_TABLE_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Sede', key: 'locationName', valueType: 'string',
  },
  {
    label: 'Sección', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Profesor', key: 'teacherName', valueType: 'string',
  },
  {
    label: 'Alumno', key: 'student', valueType: 'string',
  },
  {
    label: 'Número de clases', key: 'numberOfClasses', valueType: 'string',
  },
  {
    label: 'Asistencias', key: 'attended', valueType: 'string',
  },
  {
    label: 'Atrasos', key: 'arrivedLate', valueType: 'string',
  },
  {
    label: 'Ausencias', key: 'absent', valueType: 'string',
  },
];

export const SUBMITTED_TABLE_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Sede', key: 'locationName', valueType: 'string',
  },
  {
    label: 'Sección', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Profesor', key: 'teacherName', valueType: 'string',
  },
  {
    label: 'Alumno', key: 'student', valueType: 'string',
  },
  {
    label: 'Número de guías', key: 'numberOfTests', valueType: 'string',
  },
  {
    label: 'Entregas', key: 'testsSubmitted', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas correctas', key: 'correctAnswersAverage', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas incorrectas', key: 'wrongAnswersAverage', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas omitidas', key: 'omittedAnswersAverage', valueType: 'string',
  },
];

export const RESULTS_TABLE_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Sede', key: 'locationName', valueType: 'string',
  },
  {
    label: 'Sección', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Profesor', key: 'teacherName', valueType: 'string',
  },
  {
    label: 'Alumno', key: 'student', valueType: 'string',
  },
  {
    label: 'Tipo de guía', key: 'testType', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas correctas', key: 'correctAnswersAverage', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas incorrectas', key: 'wrongAnswersAverage', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas omitidas', key: 'omittedAnswersAverage', valueType: 'string',
  },
];

export const TESTS_TABLE_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Sede', key: 'locationName', valueType: 'string',
  },
  {
    label: 'Sección', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Profesor', key: 'teacherName', valueType: 'string',
  },
  {
    label: 'Guía', key: 'testName', valueType: 'string',
  },
  {
    label: 'Tipo de guía', key: 'testType', valueType: 'string',
  },
  {
    label: 'Porcentaje de entregas', key: 'submittedTestsAverage', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas correctas', key: 'correctAnswersAverage', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas incorrectas', key: 'wrongAnswersAverage', valueType: 'string',
  },
  {
    label: 'Porcentaje de respuestas omitidas', key: 'omittedAnswersAverage', valueType: 'string',
  },
];

export const exampleValues = [{
  subjectName: 'Matemáticas',
  programName: 'Matemática M1 Intensivo Egresado Segundo Semestre',
  locationName: 'Escuela Militar',
  mipreuId: '530',
  teacherName: 'Rodrigo Costas',
  student: 'Pedro Pablo Sarabia López',
  numberOfClasses: '12',
  attended: '9',
  arrivedLate: '1',
  absent: '2',
  numberOfTests: '15',
  testsSubmitted: '13',
  correctAnswersAverage: '80%',
  wrongAnswersAverage: '10%',
  omittedAnswersAverage: '10%',
  submittedTestsAverage: '90%',
  testName: 'Modelo Cuadrático',
  testType: 'Guía',
}, {
  subjectName: '...',
  programName: '...',
  locationName: '...',
  mipreuId: '...',
  teacherName: '...',
  student: '...',
  numberOfClasses: '...',
  attended: '...',
  arrivedLate: '...',
  absent: '...',
  numberOfTests: '...',
  testsSubmitted: '...',
  correctAnswersAverage: '...',
  wrongAnswersAverage: '...',
  omittedAnswersAverage: '...',
  submittedTestsAverage: '...',
  testName: '...',
  testType: '...',
}];
