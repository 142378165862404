import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import {
  Send as SendIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import DisplayInfoComponent from './DisplayInfoComponent';
import { parseSecondsToTime } from '../../../../utils/helpers';
import handleApiResponse from '../../../../utils/handleApiResponse';
import CustomButton from '../../../../components/General/CustomButton';
import CustomModal from '../../../../components/General/CustomModal';
import LocalTestInformation from '../../../../services/institutionUserTestService';
import { postDownloadTestPDF } from '../../../../requests/api/institutionTests';
import useStyles from '../../styles';

const HeaderInformation = ({
  userAnswers, handleDeliverTest, durationMinutes, testPdfUrl,
}: {
  userAnswers: { [key: string]: { answer: string | undefined, markedForRevision: boolean } },
  handleDeliverTest: () => void,
  durationMinutes: number,
  testPdfUrl: string,
}) => {
  const classes = useStyles();
  const numOfQuestions = Object.keys(userAnswers).length;
  const [endTest, setEndTest] = useState(false);
  const [timer, setTimer] = useState(0);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const startTimer = useCallback(({ startTime }: Record<string, number>) => {
    const timerId = setInterval(() => {
      const startTimeInSeconds = startTime / 1000;
      const durationMinutesInSeconds = durationMinutes * 60;
      const timeRemaining = Math
        .round(durationMinutesInSeconds - (Date.now() / 1000 - startTimeInSeconds));

      if (timeRemaining <= 0) {
        handleDeliverTest();
      } else {
        setTimer(timeRemaining);
      }
    }, 1000);
    return timerId;
  }, []);

  const handleDownload = async (urlToDownload: string) => {
    setDownloadLoading(true);
    try {
      const { signedUrl } = await postDownloadTestPDF(urlToDownload);

      const link = document.createElement('a');
      link.href = signedUrl;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
    setDownloadLoading(false);
  };

  useEffect(() => {
    const userTimer = LocalTestInformation.getTestTimerInformation();

    if (!userTimer) {
      LocalTestInformation.setTestTimerInformation();
    }

    const timerId = startTimer({
      startTime: userTimer || Date.now(),
    });

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <Grid item mt={2} display='flex' justifyContent='space-between'>
      <Box display='flex' justifyContent='space-between' className={classes.testInformation}>
        <DisplayInfoComponent title={'Tiempo restante:'} subTitle={parseSecondsToTime(timer)} />
        <DisplayInfoComponent title={'Respondidas:'} subTitle={`${Object.values(userAnswers).reduce((acc, val) => acc + ((val?.answer) ? 1 : 0), 0)}/${numOfQuestions}`} />
        <DisplayInfoComponent title={'Por revisar:'} subTitle={`${Object.values(userAnswers).reduce((acc, val) => acc + (val?.markedForRevision ? 1 : 0), 0)}/${numOfQuestions}`} />
      </Box>
      <Box display='flex' flexDirection={window.innerWidth > 1400 ? 'row' : 'column'} alignItems='center' justifyContent="flex-end">
        <CustomButton text={'Descargar material'} loading={downloadLoading} colorType='primary' size="groupButtons" onClick={() => handleDownload(testPdfUrl)} icon={<DownloadIcon />} />
        <CustomButton text={'Finalizar ensayo'} colorType='tertiary' size="groupButtons" onClick={() => setEndTest(true)} icon={<SendIcon />} />
      </Box>
      <CustomModal
        title="¿Finalizar ensayo?"
        message="Confirma para enviar tus respuestas. Recuerda que no podrás regresar a esta vista una vez confirmes."
        modal={endTest}
        setModal={setEndTest}
        confirmFunction={handleDeliverTest}
      />
    </Grid>
  );
};

export default HeaderInformation;
