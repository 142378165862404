const INSTITUTIONS_STUDENTS_UI = [
  {
    label: 'RUT', key: 'rut', valueType: 'string',
  },
  {
    label: 'Nombre', key: 'name', valueType: 'string',
  },
  {
    label: 'Apellidos', key: 'lastname', valueType: 'other',
  },
  {
    label: 'Correo', key: 'email', valueType: 'other',
  },
  {
    label: 'Teléfono', key: 'phoneNumber', valueType: 'other',
  },
  {
    label: 'Colegio', key: 'school', valueType: 'other',
  },
  {
    label: 'Curso', key: 'schoolYear', valueType: 'other',
  },
];

type InstitutionSessionUser = {
  id: number,
  institutionUser: InstitutionUser,
};
interface InstitutionUser {
  id: number,
  role: string,
  rut: string,
  password: string,
  name: string | null,
  lastname: string | null,
  email: string | null,
  phoneNumber: string | null,
  school: string | null,
  schoolYear: string | null,
  preferredUniversity: string | null,
  preferredCarrer: string | null,
  deleted: boolean,
}

export { INSTITUTIONS_STUDENTS_UI, InstitutionUser, InstitutionSessionUser };
