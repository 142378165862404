import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { Typography, Grid, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Bomb } from '../types';
import useStyles from '../styles';

type BombDetailHeaderProps = {
  bomb: Bomb;
};

function BombDetailHeader({ bomb }: BombDetailHeaderProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        <Button
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          ml={1}
          variant="h3"
          fontWeight='bold'
        >
          Ver bomba
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Sede: </strong><span style={{ whiteSpace: 'nowrap' }}>{bomb.locationName}</span></>}
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Programa: </strong><span>{bomb.programName}</span></>}
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Habilidad desde: </strong><span>{bomb.startHability}</span></>}
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.textBodyColor} ${classes.responsiveTypography}`}
        >
          {<><strong>Habilidad hasta: </strong><span>{bomb.endHability}</span></>}
        </Typography>
      </Grid>
    </Grid >
  );
}

BombDetailHeader.propTypes = {
  className: PropTypes.string,
};

export default BombDetailHeader;
