import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Button, Container, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import useStyles from './styles';

function InstitutionUserDetailHeader(
  { testName, competence }: { testName: string, competence: string },
) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container className={classes.titleContainer}>
      <Button className={classes.backButton} onClick={() => history.goBack()}>
          <ArrowBackIosIcon />
        </Button>
      <Typography
        variant="h4"
        className={classes.testName}
      >
        <span style={{ whiteSpace: 'nowrap' }}>{testName} - {competence}</span>
      </Typography>
    </Container>
  );
}

InstitutionUserDetailHeader.propTypes = {
  className: PropTypes.string,
};

export default InstitutionUserDetailHeader;
