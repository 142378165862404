import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';
import PostRegister from './views/Register/PostRegister';

import UserListView from './views/Admin/Users/UserListView';
import { ChildrenProps, RouteConfig } from './types';
import UpsertView from './views/Admin/Users/UpsertView';
import {
  ACADEMIC, ADMINISTRATOR, REPRESENTATIVE, SECRETARY, STUDENT, TEACHER,
} from './utils/user_types';
import Unauthorized from './views/Unauthorized';
import ProgramListView from './views/Program/ProgramListView';
import TestListView from './views/Test/TestListView';
import RepresentativeListView from './views/Representative/RepresentativeListView';
import TeacherListView from './views/Teacher/TeacherListView';
import StudentListView from './views/Student/StudentListView';
import QuestionListView from './views/Question/QuestionListView';
import ClassListView from './views/Class/ClassListView';
import BombListView from './views/Bomb/BombListView';
import SectionListView from './views/Section/SectionListView';
import TestQuestionsView from './views/Test/TestQuestionsView';
import StudentDetailView from './views/Student/StudentDetailView';
import ContractUpsertView from './views/Student/StudentDetailView/Views/ContractView/ContractUpsertView';
import SectionsAssociationsView from './views/Student/StudentDetailView/Views/SectionsView/SectionsAssociationsView';
import SectionDetailView from './views/Section/SectionDetailView';
import ProgramDetailView from './views/Program/ProgramDetailView';
import MassiveListView from './views/Massive/MassiveListView';
import MassiveDetailView from './views/Massive/MassiveDetailView';
import TestDetailView from './views/Student/StudentDetailView/Views/TestsView/TestDetailView';
import ClassAssitanceView from './views/Class/ClassAssistanceVIew';
import StudentTestsListView from './views/Section/SectionDetailView/Components/TestsView/StudentTestsListView';
import StudentAssistancesListView from './views/Section/SectionDetailView/Components/AssistancesView/StudentAssistancesListView';
import BombDetailView from './views/Bomb/BombDetailView';
import DownloadsView from './views/Downloads/DownloadsView';
import InstitutionsListView from './views/Institutions/InstitutionsListView';
import InstitutionSessionView from './views/Institutions/InstitutionSessionView';
import LoginInstitutionsView from './views/LoginInstitutions';
import InstitutionUserProfile from './views/InstitutionUserProfile';
import InstitutionAuthGuard from './components/Auth/InstitutionAuthGuard';
import InstitutionUserTest from './views/InstitutionUserTest';
import InstitutionUserTestResult from './views/InstitutionUserTestResult';
import InstitutionStudentsView from './views/Institutions/InstitutionStudents';
import InstitutionSessionResultsView from './views/Institutions/InstitutionSessionResults';
import InstitutionUserTestAttempt from './views/InstitutionUserTest/InstitutionUserTestAttempt';
import InstitutionSessionTestView from './views/Institutions/InstitutionSessionTest';
import InstitutionTestInProgressGuard from './components/Auth/InstitutionTestInProgressGuard';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  {
    id: 4,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 5,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 6,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    id: 7,
    exact: true,
    path: '/postRegister',
    component: () => <PostRegister />,
  },
  {
    id: 8,
    exact: true,
    path: '/sin-autorizacion',
    guard: (props: ChildrenProps) => <AuthGuard {...props} />,
    component: () => <Unauthorized />,
  },
  {
    id: 10,
    path: '/administracion',
    guard: (props: ChildrenProps) => <AuthGuard {...props} admittedRoles={[ADMINISTRATOR]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/administracion',
        component: () => <Redirect to="/administracion/usuarios" />,
      },
      {
        id: 12,
        exact: true,
        path: '/administracion/usuarios',
        component: () => <UserListView />,
      },
      {
        id: 13,
        exact: true,
        path: '/administracion/usuarios/crear',
        component: () => <UpsertView />,
      },
      {
        id: 14,
        exact: true,
        path: '/administracion/usuarios/:id/editar',
        component: () => <UpsertView />,
      },
      {
        id: 15,
        component: () => <Redirect to="/administracion/usuarios" />,
      },
    ],
  },
  {
    id: 20,
    path: '/programas',
    guard: (
      props: ChildrenProps,
    ) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 21,
        exact: true,
        path: '/programas',
        component: () => <ProgramListView />,
      },
      {
        id: 22,
        exact: true,
        path: '/programas/:id',
        component: () => <ProgramDetailView />,
      },
    ],
  },
  {
    id: 30,
    path: '/secciones',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 31,
        exact: true,
        path: '/secciones',
        component: () => <SectionListView />,
      },
      {
        id: 32,
        exact: true,
        path: '/secciones/:id',
        component: () => <SectionDetailView />,
      },
      {
        id: 33,
        exact: true,
        path: '/secciones/:sectionId/alumno/:studentId/guias',
        component: () => <StudentTestsListView />,
      },
      {
        id: 34,
        exact: true,
        path: '/secciones/:sectionId/alumno/:studentId/asistencia',
        component: () => <StudentAssistancesListView />,
      },
    ],
  },
  {
    id: 40,
    path: '/apoderados',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 41,
        exact: true,
        path: '/apoderados',
        component: () => <RepresentativeListView />,
      },
    ],
  },
  {
    id: 50,
    path: '/profesores',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 51,
        exact: true,
        path: '/profesores',
        component: () => <TeacherListView />,
      },
    ],
  },
  {
    id: 60,
    path: '/alumnos',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
    admittedRoles={[ADMINISTRATOR, REPRESENTATIVE, TEACHER, ACADEMIC, SECRETARY, STUDENT]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 61,
        exact: true,
        path: '/alumnos',
        component: () => <StudentListView />,
      },
      {
        id: 62,
        exact: true,
        path: '/alumnos/:id',
        component: () => <StudentDetailView />,
      },
      {
        id: 63,
        exact: true,
        path: '/alumnos/:id/contrato/nuevo',
        component: () => <ContractUpsertView />,
      },
      {
        id: 64,
        exact: true,
        path: '/alumnos/:id/contrato/:contractId/editar',
        component: () => <ContractUpsertView />,
      },
      {
        id: 65,
        exact: true,
        path: '/alumnos/:id/programa/:programId/asociar',
        component: () => <SectionsAssociationsView />,
      },
      {
        id: 66,
        exact: true,
        path: '/alumnos/:id/guia/:testId',
        component: () => <TestDetailView />,
      },
    ],
  },
  {
    id: 70,
    path: '/guias',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 71,
        exact: true,
        path: '/guias',
        component: () => <TestListView />,
      },
      {
        id: 72,
        exact: true,
        path: '/guias/:id',
        component: () => <TestQuestionsView />,
      },
    ],
  },
  {
    id: 80,
    path: '/preguntas',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 81,
        exact: true,
        path: '/preguntas',
        component: () => <QuestionListView />,
      },
    ],
  },
  {
    id: 90,
    path: '/clases',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 91,
        exact: true,
        path: '/clases',
        component: () => <ClassListView />,
      },
      {
        id: 92,
        exact: true,
        path: '/clases/:id',
        component: () => <ClassAssitanceView />,
      },
    ],
  },
  {
    id: 100,
    path: '/bombas',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 101,
        exact: true,
        path: '/bombas',
        component: () => <BombListView />,
      },
      {
        id: 102,
        exact: true,
        path: '/bombas/:id',
        component: () => <BombDetailView />,
      },
    ],
  },
  {
    id: 110,
    path: '/masivos',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 111,
        exact: true,
        path: '/masivos',
        component: () => <MassiveListView />,
      },
      {
        id: 112,
        exact: true,
        path: '/masivos/:id',
        component: () => <MassiveDetailView />,
      },
    ],
  },
  {
    id: 120,
    path: '/descargas',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 111,
        exact: true,
        path: '/descargas',
        component: () => <DownloadsView />,
      },
    ],
  },
  {
    id: 130,
    path: '/instituciones',
    guard: (props: ChildrenProps) => <AuthGuard {...props}
      admittedRoles={[ADMINISTRATOR, TEACHER, ACADEMIC, SECRETARY]} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 131,
        exact: true,
        path: '/instituciones',
        component: () => <InstitutionsListView />,
      },
      {
        id: 132,
        exact: true,
        path: '/instituciones/:institutionId',
        component: () => <InstitutionSessionView />,
      },
      {
        id: 133,
        exact: true,
        path: '/instituciones/:institutionId/jornada/:institutionSessionId/alumnos',
        component: () => <InstitutionStudentsView />,
      },
      {
        id: 134,
        exact: true,
        path: '/instituciones/:institutionId/jornada/:institutionSessionId/resultados',
        component: () => <InstitutionSessionResultsView />,
      },
      {
        id: 135,
        exact: true,
        path: '/instituciones/:institutionId/jornada/:institutionSessionId/resultados-ensayo/:institutionSessionTestId',
        component: () => <InstitutionSessionTestView />,
      },
    ],
  },
  {
    id: 140,
    exact: true,
    path: '/institucion/:institutionUrlExtension/login',
    component: () => <LoginInstitutionsView />,
  },
  {
    id: 150,
    path: '/institucion/:institutionUrlExtension/ensayos',
    guard: (props: ChildrenProps) => <InstitutionAuthGuard {...props} />,
    layout: DashboardLayout,
    routes: [
      {
        id: 151,
        exact: true,
        path: '/institucion/:institutionUrlExtension/ensayos',
        guard: (props: ChildrenProps) => <InstitutionTestInProgressGuard {...props} />,
        component: () => <InstitutionUserProfile />,
      },
      {
        id: 152,
        exact: true,
        path: '/institucion/:institutionUrlExtension/ensayos/:institutionSessionUserTestId',
        guard: (props: ChildrenProps) => <InstitutionTestInProgressGuard {...props} />,
        component: () => <InstitutionUserTest />,
      },
      {
        id: 153,
        exact: true,
        path: '/institucion/:institutionUrlExtension/ensayos/:institutionSessionUserTestId/evaluacion',
        component: () => <InstitutionUserTestAttempt />,
      },
      {
        id: 154,
        exact: true,
        path: '/institucion/:institutionUrlExtension/ensayos/:institutionSessionUserTestId/resultado',
        component: () => <InstitutionUserTestResult />,
      },
    ],
  },
  {
    id: 160,
    path: '/institucion/:institutionUrlExtension/administracion',
    guard: (props: ChildrenProps) => (
      <InstitutionAuthGuard {...props} admittedRoles={[ADMINISTRATOR]}/>
    ),
    layout: DashboardLayout,
    routes: [
      {
        id: 161,
        exact: true,
        path: '/institucion/:institutionUrlExtension/administracion',
        component: () => <InstitutionSessionView />,
      },
      {
        id: 162,
        exact: true,
        path: '/institucion/:institutionUrlExtension/administracion/jornada/:institutionSessionId/resultados',
        component: () => <InstitutionSessionResultsView />,
      },
      {
        id: 163,
        exact: true,
        path: '/institucion/:institutionUrlExtension/administracion/jornada/:institutionSessionId/alumnos',
        component: () => <InstitutionStudentsView />,
      },
      {
        id: 164,
        exact: true,
        path: '/institucion/:institutionUrlExtension/administracion/jornada/:institutionSessionId/resultados-ensayo/:institutionSessionTestId',
        component: () => <InstitutionSessionTestView />,
      },
    ],
  },
];

const renderRoutes = (routes: RouteConfig[]) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route: RouteConfig, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component as React.ElementType;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
