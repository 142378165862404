import {
  ClassesAssistancesFiltersPayload,
  MassivesFiltersPayload,
  ProgramsFiltersPayload,
  SectionFiltersPayload,
  TestsFiltersPayload,
} from '../types';

export const SET_SECTION_FILTERS = '@filters/set-section-filters';
export const SET_PROGRAMS_FILTERS = '@filters/set-programs-filters';
export const SET_TESTS_FILTERS = '@filters/set-tests-filters';
export const SET_CLASSES_ASSISTANCES_FILTERS = '@filters/set-classes-assistances-filters';
export const SET_MASSIVES_FILTERS = '@filters/set-massives-filters';

export const setSectionFilters = (filtersData: SectionFiltersPayload) => ({
  type: SET_SECTION_FILTERS,
  payload: {
    ...filtersData,
  },
});

export const setProgramsFilters = (filtersData: ProgramsFiltersPayload) => ({
  type: SET_PROGRAMS_FILTERS,
  payload: {
    ...filtersData,
  },
});

export const setTestsFilters = (filtersData: TestsFiltersPayload) => ({
  type: SET_TESTS_FILTERS,
  payload: {
    ...filtersData,
  },
});

export const setClassesAssistancesFilters = (filtersData: ClassesAssistancesFiltersPayload) => ({
  type: SET_CLASSES_ASSISTANCES_FILTERS,
  payload: filtersData,
});

export const setMassivesFilters = (filtersData: MassivesFiltersPayload) => ({
  type: SET_MASSIVES_FILTERS,
  payload: {
    ...filtersData,
  },
});
