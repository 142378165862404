import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';

import { AxiosError } from 'axios';
import { getInstitutionInfoByUrlExtension } from '../../requests/api/institutions';

import { formatDateToWeekDay } from '../../utils/helpers';
import handleApiResponse from '../../utils/handleApiResponse';

import Page from '../../components/Layout/Page';
import Logo from '../../components/Layout/Logo';
import LoginForm from './LoginForm';

import useStyles from './styles';
import UserInformationForm from './UserInformationForm';
import { Institution, InstitutionUser } from './types';

function LoginInstitutionsView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { institutionUrlExtension } = useParams<{ institutionUrlExtension: string }>();

  const initialValues = {
    id: null,
    role: '',
    rut: '',
    password: '',
    name: null,
    lastname: null,
    email: null,
    phoneNumber: null,
    school: null,
    schoolYear: null,
    preferredUniversity: null,
    preferredCarrer: null,
    deleted: false,
  };

  const [institutionUser, setInstitutionUser] = useState<InstitutionUser>(initialValues);

  const [institution, setInstitution] = useState<Institution>({
    id: null,
    name: '',
    urlExtension: institutionUrlExtension,
    logoUrl: '',
    institutionSessions: [{
      startDate: null,
      endDate: null,
      resultsDate: null,
    }],
  });

  useEffect(() => {
    const getInstitutionInfo = async () => {
      try {
        const response = await getInstitutionInfoByUrlExtension({ institutionUrlExtension });
        if (response.success) setInstitution(response.institution);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (institutionUrlExtension && !institution.id) {
      getInstitutionInfo();
    }
  }, []);

  if (!institution.id) {
    return (
      <Page className={classes.noInstitutionRoot} title="Ingreso">
        <Container className={classes.imageContainer}>
          <Box className={classes.blueBox} />
          <Box className={classes.infoContainer}>
            <Box className={classes.noInstitutionMessageContainer}>
              <Logo />
              <Typography className={classes.noInstitutionMessage}>
                La institución a la que intenta acceder no existe.
                <br/>
                Por favor, revise la URL e intente nuevamente.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Page>
    );
  }

  return (
    <Page className={classes.root} title="Ingreso">
      <Container className={classes.imageContainer}>
        <Box className={classes.blueBox} />
        <Box className={classes.infoContainer}>
          <Box className={classes.loginTitle}>
            Bienvenido a la plataforma
            <br /> de rendición de ensayos de <br />
            <span>{institution.name}</span>
          </Box>
          <Box>
            <Typography className={classes.loginParagraph}>
              En la UAI queremos apoyar el proceso de orientación y preparación para la Prueba de
              Acceso a la Educación Superior (PAES) de los estudiantes, para un ingreso
              exitoso a la Universidad en Admisión {institution.institutionSessions[0].startDate
              ? (new Date(institution.institutionSessions[0].startDate).getFullYear() + 1) : null}.
            </Typography>
            <Typography className={classes.loginParagraph}>
              Estimado(a) estudiante:
            </Typography>
            <Typography className={classes.loginParagraph}>
              En este sitio podrás revisar el resultado del Ensayo PAES rendido el
              {` ${formatDateToWeekDay(institution.institutionSessions[0].resultsDate)} `}
              durante la actividad &quot;Universidad Abierta UAI&quot;.
            </Typography>
            <Typography className={classes.loginParagraph}>
              👉🏻 Desde el {`${formatDateToWeekDay(institution.institutionSessions[0].startDate)} `}
              hasta el {formatDateToWeekDay(institution.institutionSessions[0].endDate)}.
              <br />
              👉🏻 Entrega de resultados con puntaje, preguntas correctas, incorrectas, y omitidas
              mediante la visualización de un solucionario.
            </Typography>
            <Typography className={classes.loginParagraph}>
              ¡Mucho éxito y esperamos contar con tu presencia en nuestras próximas actividades!
            </Typography>
          </Box>
        </Box>
      </Container>
      {/* Renders the login form */}
      {institutionUser && !institutionUser.id ? (
        <Container className={classes.formContainer}>
          <Box className={classes.logosContainer}>
            <Logo />
            <img alt="Institution logo" src={institution.logoUrl} />
          </Box>
          <Box className={classes.inputsContainer}>
            <Typography className={classes.loginMessage}>
              Inicia sesión para utilizar la plataforma
            </Typography>
            <LoginForm
              institutionId={institution.id}
              urlExtension={institutionUrlExtension}
              setInstitutionUser={setInstitutionUser}
            />
          </Box>
        </Container>
      ) : null}
      {/* Renders the institution user information form */}
      {institutionUser && institutionUser.id && !institutionUser.name && !institutionUser.email ? (
        <Container className={classes.formContainer}>
          <Box className={classes.inputsContainer}>
            <Typography className={classes.institutionUserMessage}>
              Ingresa tu información académica
            </Typography>
            <UserInformationForm
              institutionUserId={institutionUser.id}
              initialValues={initialValues}
              setInstitutionUser={setInstitutionUser}
              urlExtension={institutionUrlExtension}
            />
          </Box>
        </Container>
      ) : null}
    </Page>
  );
}

export default LoginInstitutionsView;
