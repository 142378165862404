import { REPRESENTATIVE } from '../../../../../utils/user_types';

export type SummaryDataResponse = {
  programId: string;
  programName: string;
  numberOfTests: number;
  numberOfDeliveries: number;
  correctAnswers: number;
  wrongAnswers: number;
  omittedAnswers: number;
};

export type DetailsDataResponse = {
  id: number,
  testName: string;
  programName: string;
  programId: string;
  subjectName: string;
  numberOfQuestions: number;
  correctAnswers: number;
  wrongAnswers: number;
  lastUpdated: Date | null;
  status: string;
  solutionaryUrl: string;
  isActive: boolean;
};

const showTranslatedStatus = (statusToTranslate: string) => {
  if (statusToTranslate === 'answered') return 'Respondida';
  if (statusToTranslate === 'saved') return 'Respuestas guardadas';
  return 'No respondida';
};

export const SUMMARY_TABLE_UI = [
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Número de guías', key: 'numberOfTests', valueType: 'string',
  },
  {
    label: 'Número de entregas', key: 'numberOfDeliveries', valueType: 'string',
  },
  {
    label: 'Preguntas correctas', key: 'correctAnswers', valueType: 'string',
  },
  {
    label: 'Preguntas incorrectas', key: 'wrongAnswers', valueType: 'string',
  },
  {
    label: 'Preguntas omitidas', key: 'omittedAnswers', valueType: 'string',
  },
];

export const TESTS_TABLE_UI = [
  {
    label: 'Nombre', key: 'testName', valueType: 'string',
  },
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Número de preguntas', key: 'numberOfQuestions', valueType: 'string',
  },
  {
    label: 'Respuestas correctas', key: 'correctAnswers', valueType: 'string',
  },
  {
    label: 'Respuestas incorrectas', key: 'wrongAnswers', valueType: 'string',
  },
  {
    label: 'Respuestas omitidas', key: 'omittedAnswers', valueType: 'string',
  },
  {
    label: 'Fecha actualización respuestas', key: 'lastUpdated', valueType: 'shortDate',
  },
  {
    label: 'Estado', key: 'status', valueType: 'custom', functionToApply: showTranslatedStatus,
  },
  {
    label: 'Ver solucionario', key: 'seeSolution', valueType: 'other', excludedRoles: [REPRESENTATIVE],
  },
  {
    label: 'Respuestas', key: 'actions', valueType: 'other', excludedRoles: [REPRESENTATIVE],
  },
];
