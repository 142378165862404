import { Box } from '@mui/material';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface HistogramProps {
  graphData: {
    categories: string[],
    data: number[],
  }
}

export const Histogram = ({ graphData }: HistogramProps) => {
  const tripleColumnChartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Puntajes',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: graphData.categories,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Frecuencia',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:12px;"><strong>Puntaje:</strong> {point.key}</span><table>',
      pointFormat: '<tr><td style="padding:0">'
        + '<span style="font-size:12px;"><b>{series.name}:</b></span></td>'
        + '<td style="padding:0;">{point.y}</td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        borderColor: '#303030',
        pointPadding: 0,
        borderWidth: 1,
        groupPadding: 0,
        shadow: false,
      },
    },
    series: [{
      name: 'Cantidad alumnos',
      data: graphData.data,

    }],
  };
  return (
    <Box style={{ width: '50%' }}>
      <HighchartsReact highcharts={Highcharts} options={tripleColumnChartOptions} />
    </Box>
  );
};
