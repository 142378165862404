import React, { Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import {
  Typography,
  Grid,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Add as AddIcon,
} from '@mui/icons-material';
import CustomButton from '../../../../components/General/CustomButton';
import useStyles from '../styles';

function ProgramDetailHeader({
  programName,
  subjectName,
  numberStudents,
  setModalAssociateTest,
}: {
  programName: string;
  subjectName: string;
  numberStudents: number;
  setModalAssociateTest: Dispatch<SetStateAction<{ open: boolean; id: string; }>>,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        <Button
          className={classes.backButton}
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          variant="h3"
          fontWeight='bold'
        >
          Ver guías
        </Typography>
        <Typography
          ml='30px'
          variant="h4"
          className={classes.textBodyColor}
        >
          {<><strong>Programa: </strong><span>{programName}</span></>}
        </Typography>
        <Typography
          ml='30px'
          variant="h4"
          className={classes.textBodyColor}
        >
          {<><strong>Asignatura: </strong><span>{subjectName}</span></>}
        </Typography>
        <Typography
          ml='30px'
          variant="h4"
          className={classes.textBodyColor}
        >
          {<><strong>Número de alumnos: </strong><span>{numberStudents}</span></>}
        </Typography>
      </Grid>
      <Grid item display="flex" alignItems="center">
        <CustomButton
          key='associate-guide'
          icon={<AddIcon />}
          text="Asociar guía"
          colorType="tertiary"
          onClick={() => setModalAssociateTest({ id: '', open: true })}
        />
      </Grid>
    </Grid >
  );
}

ProgramDetailHeader.propTypes = {
  className: PropTypes.string,
  programName: PropTypes.string.isRequired,
  subjectName: PropTypes.string.isRequired,
  numberStudents: PropTypes.number.isRequired,
  setModalAssociateTest: PropTypes.func.isRequired,
};

export default ProgramDetailHeader;
