import React from 'react';
import {
  Box, Card, CardContent, IconButton, Modal, Theme, Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CustomButton from '../../../../components/General/CustomButton';

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modal: {
    maxHeight: '920px',
    maxWidth: '890px',
    width: '100%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalRestore: {
    maxHeight: '290px',
    maxWidth: '690px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitleAndIcon: {
    display: 'flex',
  },
  modalRestoreBody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  modalRestoreButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
}));

function RestoreModal({
  modalRestore,
  setModalRestore,
  handleCloseModal,
  restoreFunction,
}: {
  modalRestore: { id: string, open: boolean },
  setModalRestore: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>,
  handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => void,
  restoreFunction: (id: string) => void,
}) {
  const classes = useStyles();
  return (
    <Modal
      open={modalRestore.open}
      onClose={() => handleCloseModal(setModalRestore)}
    >
      <Box className={classes.modalContainer}>
        <Card className={`${classes.modal} ${classes.modalRestore}`}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon} >
                <RestoreFromTrashIcon />
                <Typography
                  variant="h3"
                  fontWeight='bold'
                >
                  {'Restaurar alumno'}
                </Typography>
              </Box>
              <IconButton onClick={() => handleCloseModal(setModalRestore)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.modalRestoreBody}>
              <Typography
                variant='body1'
                textAlign='center'
              >
                Has elegido restaurar este alumno. ¿Estás seguro que deseas hacerlo?
              </Typography>
            </Box>
            <Box className={classes.modalRestoreButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={() => handleCloseModal(setModalRestore)} key={'CancelButtonRestore'} icon={<CloseIcon />} />
              <CustomButton text='Restaurar' colorType='primary' onClick={() => restoreFunction(modalRestore.id)} key={'AcceptButtonRestore'} icon={<RestoreFromTrashIcon />} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default RestoreModal;
