export type TestData = {
  testName: string,
  number: number,
  numberOfQuestions: number,
  correctAnswers: string,
  wrongAnswers: string,
  omittedAnswers: string,
  lastUpdates: string | null,
  deliveryStatus: string;
};

export type StudentStatistics = {
  correctAnswersAverage: number,
  omittedAnswersAverage: number,
  wrongAnswersAverage: number,
  testsSubmitted: string,
};

export const TESTS_TABLE_UI = [
  {
    label: 'Número guía', key: 'number', valueType: 'string',
  },
  {
    label: 'Nombre guía', key: 'name', valueType: 'string',
  },
  {
    label: 'Tipo guía', key: 'type', valueType: 'string',
  },
  {
    label: 'Número de preguntas', key: 'numberOfQuestions', valueType: 'string',
  },
  {
    label: 'Respuestas correctas', key: 'correctAnswers', valueType: 'string',
  },
  {
    label: 'Respuestas incorrectas', key: 'wrongAnswers', valueType: 'string',
  },
  {
    label: 'Respuestas omitidas', key: 'omittedAnswers', valueType: 'string',
  },
  {
    label: 'Fecha actualización respuestas', key: 'lastUpdated', valueType: 'shortDate',
  },
  {
    label: 'Estado', key: 'status', valueType: 'string',
  },
];
