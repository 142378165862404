import React from 'react';
import {
  Modal,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import CustomButton from '../../../../components/General/CustomButton';
import useStyles from '../styles';
import { Question } from '../types';

const ConfirmChangesModal = ({
  modalConfirmEditInformation,
  handleCloseModal,
  handleEditFunction,
}: {
  modalConfirmEditInformation: {
    open: boolean;
    numberOfDiffs: number;
    questions: Question[];
  };
  handleCloseModal: () => void;
  handleEditFunction: () => void;
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={modalConfirmEditInformation.open}
      onClose={handleCloseModal}
    >
      <Box className={classes.modalContainer}>
        <Card className={classes.modal}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon}>
                <Typography variant='h4' color='primary' ml={1}>Confirmar acción</Typography>
              </Box>

              <IconButton onClick={handleCloseModal}>
                <CloseIcon color='primary' />
              </IconButton>
            </Box>
            <Box className={classes.modalTextfieldsContainer}>
              <Typography>{`Hemos encontrado ${modalConfirmEditInformation.numberOfDiffs} líneas a cambiar. Si esto es correcto, favor apretar "Confirmar cambio"`}</Typography>
            </Box>
            <Box className={classes.modalButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={() => handleCloseModal()} key={'CancelButtonAddProgram'} icon={<CloseIcon />} />
              <CustomButton text='Confirmar cambio' colorType='primary' onClick={() => handleEditFunction()} key={'AcceptButtonEditChange'} icon={<CheckIcon />} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default ConfirmChangesModal;
