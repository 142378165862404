import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.main,
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  backButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
  textBodyColor: {
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
