import React, { useEffect, useState } from 'react';
import {
  Box, ClassNameMap, Divider, IconButton, Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  MailOutline as MailOutlineIcon,
} from '@mui/icons-material';
import { useHistory, useParams } from 'react-router';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import useStyles from '../../styles';
import { CustomSelectTextField } from '../../../../../components/General/CustomTextfields';
import CustomButton from '../../../../../components/General/CustomButton';
import Programs from './Components/Programs';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import {
  deleteDocument,
  getStudentContractsByStudentId,
  postDownloadDocument,
  postGenerateContractDocument,
  postSendDocument,
  postUploadAnnexDocument,
} from '../../../../../requests/api/students';
import { Contract, StudentProgram, StudentProgramTableData } from '../../types';
import { formatCcy, getDateOnlyFormat } from '../../../../../utils/helpers';
import { REPRESENTATIVE, STUDENT } from '../../../../../utils/user_types';
import { ReduxState } from '../../../../../types';
import CircularProgressComponent from '../../../../../components/Loading/CircularProgressComponent';
import Documents from './Components/Documents';
import {
  ContractDocument,
  DB_TO_UI_CONTRACT,
  LIST_DOCUMENTS_UI,
  LIST_PROGRAMS_UI,
} from './types';
import DeleteModal from '../../../../../components/General/DeleteModal';
import UploadModal from '../../../../../components/General/UploadModal';

const generateDocumentButton = (
  classes: ClassNameMap<string>,
  contractDocumentId: string,
  contractId: string,
  isAlterable: boolean,
  handleGenerateDocument: (contractId: string, contractDocumentId: string) => void,
) => <Box>
    <IconButton
      disabled={!isAlterable} onClick={() => handleGenerateDocument(contractId, contractDocumentId)}
    >
      <AddCircleOutlineIcon className={isAlterable ? classes.icons : classes.disabledIcons} />
    </IconButton>
  </Box>;

const downloadDocumentButton = (
  classes: ClassNameMap<string>,
  pdfUrl: string | undefined,
  handleDownloadDocument: (pdfUrl: string) => void,
) => (
  <IconButton disabled={!pdfUrl} onClick={() => handleDownloadDocument(pdfUrl as string)}>
    <DownloadIcon className={pdfUrl ? classes.icons : classes.disabledIcons} />
  </IconButton>
);

const sendDocumentButton = (
  classes: ClassNameMap<string>,
  studentId: string,
  contractDocumentId: string,
  documentUrl: string | undefined,
  handleSendDocument: (studentId: string, contractDocumentId: string, documentUrl: string) => void,
) => <Box>
    <IconButton onClick={() => handleSendDocument(
      studentId, contractDocumentId, documentUrl as string,
    )}>
      <MailOutlineIcon className={classes.icons} />
    </IconButton>
  </Box>;

const deleteDocumentButton = (
  classes: ClassNameMap<string>,
  contractDocumentId: string,
  isAlterable: boolean,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (
  <IconButton
    disabled={!isAlterable}
    onClick={() => openDeleteModal({ id: contractDocumentId, open: true })}
  >
    <DeleteIcon className={isAlterable ? classes.icons : classes.disabledIcons} />
  </IconButton>
);

const READ_ONLY_USERS = [STUDENT, REPRESENTATIVE];

function ContractListView() {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoading, setShowLoading] = useState(true);
  const [allContracts, setAllContracts] = useState<Contract[]>();
  const [allContractsUI, setAllContractsUI] = useState([]);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState({ id: '', open: false });
  const [uploadAnnexModal, setUploadAnnexModal] = useState({ id: '', open: false });
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);
  const [updateTable, setUpdateTable] = useState(false);
  const [selectedContract, setSelectedContract] = useState<Contract | undefined>();
  const [
    availableStudentPrograms, setAvailableStudentPrograms,
  ] = useState<StudentProgramTableData[]>([]);
  const [tutor, setTutor] = useState<string>('Nombre Apellido (Falta crear)');

  const account = useSelector((state: ReduxState) => state.account);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllStudentContracts = async () => {
      try {
        const { studentContracts } = await getStudentContractsByStudentId(id, source.token);
        setTutor(studentContracts.tutor);
        const auxContractDocuments = studentContracts.contracts[0]?.contractDocuments
          .map((elem: ContractDocument) => {
            const isAlterable = elem.status !== 'sent';
            return {
              id: elem.id,
              createdAt: elem.createdAt,
              contractType: DB_TO_UI_CONTRACT[elem.contractType as keyof typeof DB_TO_UI_CONTRACT],
              comment: elem.comment,
              status: DB_TO_UI_CONTRACT[elem.status as keyof typeof DB_TO_UI_CONTRACT],
              generateDocument: generateDocumentButton(
                classes, elem.id, elem.contractId, elem.contractType !== 'annex' && isAlterable, handleGenerateDocument,
              ),
              downloadDocument: downloadDocumentButton(
                classes, elem.pdfUrl, handleDownloadDocument,
              ),
              sendDocument: sendDocumentButton(
                classes,
                id,
                elem.id,
                elem.pdfUrl,
                handleSendDocument,
              ),
              deleteDocument: deleteDocumentButton(
                classes,
                elem.id,
                elem.contractType !== 'contract' && isAlterable,
                setDeleteDocumentModal,
              ),
            };
          });

        setAllContractsUI(auxContractDocuments);
        setAllContracts(studentContracts.contracts);

        if (studentContracts.contracts?.length > 0) {
          setSelectedContract(studentContracts.contracts[0]);
        }
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllStudentContracts();
    return () => {
      source.cancel();
    };
  }, [id, updateTable]);

  useEffect(() => {
    if (selectedContract) {
      const auxAvailableStudentProgram = selectedContract.studentPrograms.map((
        elem: StudentProgram,
      ) => {
        const dateRange = `${getDateOnlyFormat(elem.program.startDate, '/', true)} - ${getDateOnlyFormat(elem.program.endDate, '/', true)}`;
        return {
          id: elem.id,
          subjectName: elem.program.subject.name,
          programName: elem.program.name,
          dateRange,
          programFee: elem.program.fee,
          programFeeDiscount: elem.feeDiscount,
          comment: elem.comment,
          finalProgramPrice: elem.program.fee - elem.feeDiscount,
        };
      });
      setAvailableStudentPrograms(auxAvailableStudentProgram);
    }
  }, [selectedContract]);

  const handleContractChange = (e: React.ChangeEvent<HTMLInputElement> | Date) => {
    const selectedId = (e as React.ChangeEvent<HTMLInputElement>).target.value;
    const auxSelectedContract = allContracts?.filter((elem) => elem.id === selectedId);
    setSelectedContract(auxSelectedContract ? auxSelectedContract[0] : undefined);
  };

  // Safeguard - no debiesen poder entrar
  if ((account?.user && account.user!.userType) === STUDENT) {
    return <h2>Alumnos no tienen acceso a esta sección</h2>;
  }

  const handleGenerateDocument = async (contractId: string, contractDocumentId: string) => {
    // Grab all information from the student and representative and generate a pdf document
    try {
      const response = await postGenerateContractDocument({ contractId, contractDocumentId });
      handleApiResponse(enqueueSnackbar, response, true);
      setUpdateTable((prevState) => !prevState);
    } catch (error) {
      const e = error as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleDownloadDocument = async (urlToDownload: string) => {
    try {
      const { signedUrl } = await postDownloadDocument(urlToDownload);

      const link = document.createElement('a');
      link.href = signedUrl;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleSendDocument = async (
    studentId: string, contractDocumentId: string, documentUrl: string,
  ) => {
    try {
      const response = await postSendDocument(studentId, contractDocumentId, documentUrl);
      handleApiResponse(enqueueSnackbar, response, true);
      setUpdateTable((prevState) => !prevState);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleDeleteDocument = async (contractDocumentId: string) => {
    try {
      const response = await deleteDocument(contractDocumentId);
      handleApiResponse(enqueueSnackbar, response, true);
      setUpdateTable((prevState) => !prevState);
      setDeleteDocumentModal({ id: '', open: false });
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleEditContract = () => {
    const contractDocuments = selectedContract?.contractDocuments;
    if (contractDocuments?.filter((elem) => elem.contractType === 'annex').every((elem) => elem.status === 'sent')) {
      history.push(`/alumnos/${id}/contrato/${selectedContract?.id}/editar`);
    } else {
      enqueueSnackbar('Debes aprobar todos los anexos antes de hacer nuevas modificaciones', { variant: 'error' });
    }
  };

  const handleCloseUploadModal = () => {
    setFileToUpload(undefined);
    setUploadAnnexModal({ id: '', open: false });
  };

  const handleUploadAnnex = async () => {
    setShowLoading(true);
    try {
      const response = await postUploadAnnexDocument(
        (fileToUpload as File),
        (selectedContract as Contract).id,
      );
      handleCloseUploadModal();
      handleApiResponse(enqueueSnackbar, response, true);
      setUpdateTable((prevState) => !prevState);
      setShowLoading(false);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const calculateContractDiscount = (): { price: number, discount: number } => {
    let price = 0;
    let discount = 0;

    if (!selectedContract) return { price, discount };

    price = selectedContract.tuition;

    if (selectedContract?.tuitionDiscount) {
      price *= (1 - selectedContract?.tuitionDiscount / 100);
    }

    if (price < 0) price = 0;

    discount = selectedContract.tuition - price;

    return { price, discount };
  };

  const calculateProgramDiscount = (): { price: number, discount: number } => {
    const programPrice = availableStudentPrograms.reduce(
      (acc, val) => acc + val.finalProgramPrice, 0,
    );

    let price = programPrice;

    if (selectedContract?.feeDiscount) {
      price *= (1 - selectedContract?.feeDiscount / 100);
    }
    if (selectedContract?.feeAdditionalDiscount) {
      price -= selectedContract.feeAdditionalDiscount;
    }
    if (selectedContract?.feePaymentTypeDiscount) {
      price *= (1 - selectedContract.feePaymentTypeDiscount / 100);
    }

    if (price < 0) price = 0;

    const discount = programPrice - price;

    return { price, discount };
  };

  return (
    <Box className={classes.contractViewContainer}>
      {showLoading
        ? <CircularProgressComponent />
        : <>
          <Box className={classes.contractViewHeaderContainer}>
            <Box className={classes.contractViewHeaderSelectTextfieldContainer}>
              {selectedContract && <CustomSelectTextField
                keyField='contract'
                name='Contrato'
                onChangeFunction={handleContractChange}
                selectFields={allContracts?.map((elem) => ({ id: elem.id, name: elem.statusName }))
                  ?? []}
                value={selectedContract.id}
                valueToRetrieveFromSelect='id'
              />}
              {!selectedContract && <CustomSelectTextField
                disabled={true}
                keyField='contract'
                name='Contrato'
                selectFields={[{ id: '0', name: 'Sin contratos' }]}
                value={'Sin contratos'}
              />}
            </Box>
            {/* Negation of readOnlyUsers, as I do not want to show them this part */}
            {!READ_ONLY_USERS.includes(account.user!.userType || STUDENT)
              && <Box className={classes.contractViewButtonsContainer}>
                <CustomButton text='Editar contrato' colorType='tertiary' onClick={handleEditContract} key={'EditContract'} icon={<EditIcon />} disabled={!selectedContract} />
                <CustomButton text='Crear nuevo contrato' colorType='tertiary' onClick={() => history.push(`/alumnos/${id}/contrato/nuevo`)} key={'AddContract'} icon={<AddIcon />} />
              </Box>}
          </Box>
          {allContracts && allContracts.length > 0
            ? <>
              <Box className={classes.contractViewDetailsHeaderContainer}>
                <Divider sx={{ marginBottom: '30px' }} />
                <Box className={classes.contractViewDetailsHeaderContent}>
                  <Box className={classes.contractViewDetailsHeaderIndividualTypography}>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='primary'
                      textAlign='center'
                    >
                      Rango de fechas:
                    </Typography>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='textPrimary'
                      textAlign='center'
                    >
                      {selectedContract && `${new Date(selectedContract.startDate).toLocaleDateString('es-ES', { month: 'long' })} ${new Date(selectedContract.startDate).getFullYear()} - ${new Date(selectedContract.endDate).toLocaleDateString('es-ES', { month: 'long' })} ${new Date(selectedContract.endDate).getFullYear()}`}
                    </Typography>
                  </Box>
                  <Box className={classes.contractViewDetailsHeaderIndividualTypography}>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='primary'
                      textAlign='center'
                    >
                      Sostenedor:
                    </Typography>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='textPrimary'
                      textAlign='center'
                    >
                      {tutor}
                    </Typography>
                  </Box>
                  <Box className={classes.contractViewDetailsHeaderIndividualTypography}>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='primary'
                      textAlign='center'
                    >
                      Descuento matrícula:
                    </Typography>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='textPrimary'
                      textAlign='center'
                    >
                      {formatCcy(calculateContractDiscount().discount, '.')}
                    </Typography>
                  </Box>
                  <Box className={classes.contractViewDetailsHeaderIndividualTypography}>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='primary'
                      textAlign='center'
                    >
                      Descuento arancel:
                    </Typography>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='textPrimary'
                      textAlign='center'
                    >
                      {formatCcy(calculateProgramDiscount().discount, '.')}
                    </Typography>
                  </Box>
                  <Box className={classes.contractViewDetailsHeaderIndividualTypography}>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='primary'
                      textAlign='center'
                    >
                      Valor final matrícula:
                    </Typography>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='textPrimary'
                      textAlign='center'
                    >
                      {formatCcy(calculateContractDiscount().price, '.')}
                    </Typography>
                  </Box>
                  <Box className={classes.contractViewDetailsHeaderIndividualTypography}>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='primary'
                      textAlign='center'
                    >
                      Valor final arancel:
                    </Typography>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='textPrimary'
                      textAlign='center'
                    >
                      {formatCcy(calculateProgramDiscount().price, '.')}
                    </Typography>
                  </Box>
                  <Box className={classes.contractViewDetailsHeaderIndividualTypography}>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='primary'
                      textAlign='center'
                    >
                      Estado:
                    </Typography>
                    <Typography
                      fontWeight='bold'
                      variant='h5'
                      color='textPrimary'
                      textAlign='center'
                    >
                      Vigente
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ marginTop: '30px' }} />
              </Box>
              <Documents
                headers={LIST_DOCUMENTS_UI}
                data={{
                  values: allContractsUI ?? [],
                  count: allContractsUI?.length ?? 0,
                }}
                userType={account.user!.userType}
                handleUploadAnnex={
                  () => setUploadAnnexModal({ id: uploadAnnexModal.id, open: true })
                }
              />
              <Programs
                headers={LIST_PROGRAMS_UI}
                data={{
                  values: availableStudentPrograms,
                  count: availableStudentPrograms?.length ?? 0,
                }}
              />
            </>
            : <Box
              textAlign='center'
              marginTop='45px'
            >
              <Typography
                variant='h1'
                color='primary'
              >
                No hay contratos disponibles
              </Typography>

            </Box>
          }
        </>}
      <DeleteModal
        title="documento"
        modalDelete={deleteDocumentModal}
        setModalDelete={setDeleteDocumentModal}
        handleCloseModal={() => setDeleteDocumentModal({ id: '', open: false })}
        deleteFunction={handleDeleteDocument}
      />
      <UploadModal
        header='Subir anexo'
        warningMessage='Se subira un anexo bajo este contrato.'
        handleCloseModal={handleCloseUploadModal}
        modalDelete={uploadAnnexModal}
        setModalDelete={setUploadAnnexModal}
        file={fileToUpload}
        handleFileChange={(f: File) => { setFileToUpload(f); }}
        uploadFunction={handleUploadAnnex}
        loading={showLoading}
      />
    </Box>
  );
}

export default ContractListView;
