import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
    },
  },
  testContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  instructionsBox: {
    width: '58.4vw',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    padding: '15px 20px',
    alignSelf: 'center',
    fontSize: 'clamp(14px, 1.1vw, 24px)',
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
  },
  list: {
    listStyleType: 'circle',
    padding: '10px 18px',
    fontSize: 'clamp(10px, 0.9vw, 20px)',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.main,
    width: '84.4vw',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  backButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    '& .MuiBox-root button:last-child': {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '& .MuiBox-root': {
        justifyContent: 'space-between',
      },
      '& button:first-child': {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
      '& .MuiBox-root button': {
        width: '45%',
        marginRight: theme.spacing(1),
      },
      '& .MuiBox-root button:last-child': {
        marginRight: 0,
      },
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    '& button': {
      fontSize: 'clamp(11px, 0.6vw, 25px)',
      lineHeight: '1.3vw',
      width: 'clamp(100px, 18.5vw, 400px)',
    },
  },
  tableContainer: {
    [theme.breakpoints.down('md')]: {
      '& td': {
        padding: '5px',
      },
    },
  },
  testName: {
    fontSize: 'clamp(20px, 1.3vw, 40px)',
    fontWeight: 700,
    lineHeight: '30.48px',
    color: '#2C82B3',
    textAlign: 'left',
    marginLeft: 0,
  },
  tablePaperContainer: {
    width: '20vw',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  tableQuestionAnswers: {
    height: '45vh',
    overflowY: 'scroll',
    [theme.breakpoints.up('lg')]: {
      height: '55vh',
    },
    [theme.breakpoints.up('md')]: {
      height: '70vh',
    },
  },
  tableCellPrimaryBackgroundColor: {
    backgroundColor: theme.palette.background.main,
  },
  tableRowAnswerBase: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(242, 172, 50, 0.30)',
    },
  },
  tableRowAnswerNotAnswered: {
    backgroundColor: 'rgb(242, 172, 50, 0.15)',
  },
  testInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      alignItems: 'normal',
      flexDirection: 'column',
      marginBottom: 0,
    },
  },
  testInformation: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  displayInfoBox: {
    marginRight: '55px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '10px',
      '& > :first-child': {
        marginRight: '1px',
      },
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
  questionContainer: {
    height: '45vh',
    overflowY: 'scroll',
    backgroundColor: 'white',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      marginTop: '20px',
      height: 'fit-content',
    },
  },
  questionAndButtonsContainer: {
    display: 'fles',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  questionsContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  currentQuestion: {
    backgroundColor: 'rgb(242, 172, 50, 0.60)',
    '& .MuiTableCell-root': {
      fontWeight: 600,
    },
  },
}));

export default useStyles;
