import { CancelToken } from 'axios';
import { RequestsPerPage } from '../types';
import axios from '../../utils/axios';
import { TeacherData } from '../../views/Teacher/TeacherListView/types';

const getTeachersPerPage = async ({
  page, limit, searchValue, sort,
}: RequestsPerPage, cancelToken?: CancelToken) => {
  const response = await axios.get(`/api/teachers?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`, { cancelToken });

  return response.data;
};

const postCreateTeacher = async ({
  rut,
  phoneNumber,
  birthDate,
  moduleFee,
  name,
  lastname,
  email,
}: TeacherData) => {
  const response = await axios.post('/api/teachers', {
    rut,
    phoneNumber,
    birthDate,
    moduleFee,
    name,
    lastname,
    email,
  });

  return response.data;
};

const putUpdateTeacher = async ({
  id,
  phoneNumber,
  moduleFee,
  rut,
  birthDate,
  name,
  lastname,
}: {
  id: string;
  phoneNumber: string;
  moduleFee: string;
  rut: string,
  birthDate: Date,
  name: string,
  lastname: string,
}) => {
  const response = await axios.put(`/api/teachers/${id}`, {
    phoneNumber,
    moduleFee,
    rut,
    birthDate,
    name,
    lastname,
  });

  return response.data;
};

const deleteTeacher = async (teacherId: string) => {
  const response = await axios.delete(`/api/teachers/${teacherId}`);

  return response.data;
};

const teachersCSVDownload = async () => {
  const response = await axios.get('/api/download/teachers');

  return response.data;
};

export {
  getTeachersPerPage,
  postCreateTeacher,
  putUpdateTeacher,
  deleteTeacher,
  teachersCSVDownload,
};
