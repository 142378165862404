import { Dispatch } from 'redux';
import { InstitutionUser } from '../types';
import AppActions from './appActions';

export const INSTITUTION_LOGIN_REQUEST = '@account/institution-login-request';
export const INSTITUTION_LOGIN_SUCCESS = '@account/institution-login-success';
export const INSTITUTION_SILENT_LOGIN = '@account/institution-silent-login';
export const INSTITUTION_LOGOUT = '@account/institution-logout';

export const institutionLoginSuccess = (
  institutionUser: InstitutionUser,
) => async (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: INSTITUTION_LOGIN_SUCCESS,
    payload: {
      institutionUser,
    },
  });
};

export const setInstitutionUserData = (
  institutionUser: InstitutionUser,
) => (dispatch: Dispatch<AppActions>) => {
  dispatch({
    type: INSTITUTION_SILENT_LOGIN,
    payload: {
      institutionUser,
    },
  });
};
