import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { Box, Typography } from '@mui/material';

import { getSectionStatistics } from '../../../../requests/api/sections';
import handleApiResponse from '../../../../utils/handleApiResponse';
import useStyles from '../styles';

interface SectionStatistics {
  classesNumber: number
  attendanceAverage: number
  latenessAverage: number
  absenteeismAverage: number
  testsNumber: number
  averageSubmissions: number
  averageGood: number
  averageBad: number
  omittedGuidesAverage: number
}

const SectionDetailInformation = ({ sectionId }: { sectionId: string }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [statistics, setStatistics] = useState<SectionStatistics>({
    classesNumber: 0,
    attendanceAverage: 0,
    latenessAverage: 0,
    absenteeismAverage: 0,
    testsNumber: 0,
    averageSubmissions: 0,
    averageGood: 0,
    averageBad: 0,
    omittedGuidesAverage: 0,
  });

  useEffect(() => {
    const getClassesFromSection = async () => {
      try {
        const { sectionStatistics } = await getSectionStatistics(sectionId);
        setStatistics(sectionStatistics);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getClassesFromSection();
  }, []);

  return (
    <Box className={classes.sectionDetailAllInfoContainer}>
      <Box className={classes.sectionDetailInfoContainer}>
        <Typography
          className={classes.sectionDetailTypographyMainTitle}
        >
          Asistencia
        </Typography>
        <Box className={classes.sectionDetailTypographyContainer}>
          <Typography className={classes.sectionDetailTypographySectionTitle}>
            Número de clases:
          </Typography>
          <Typography className={classes.sectionDetailTypographyText}>
            {statistics.classesNumber}
          </Typography>
        </Box>
        <Box className={classes.sectionDetailTypographyMultipleRowsContainer}>
          <Box className={classes.sectionDetailTypographyContainer}>
            <Typography className={classes.sectionDetailTypographySectionTitle}>
              Promedio atraso:
            </Typography>
            <Typography className={classes.sectionDetailTypographyText}>
              {statistics.latenessAverage}%
            </Typography>
          </Box>
          <Box className={classes.sectionDetailTypographyContainer}>
            <Typography className={classes.sectionDetailTypographySectionTitle}>
              Promedio ausencia:
            </Typography>
            <Typography className={classes.sectionDetailTypographyText}>
              {statistics.absenteeismAverage}%
            </Typography>
          </Box>
          <Box className={classes.sectionDetailTypographyContainer}>
            <Typography className={classes.sectionDetailTypographySectionTitle}>
              Promedio de asistencia:
            </Typography>
            <Typography className={classes.sectionDetailTypographyText}>
              {statistics.attendanceAverage}%
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.sectionDetailInfoContainer}>
        <Typography
          className={classes.sectionDetailTypographyMainTitle}
        >
          Guías
        </Typography>
        <Box className={classes.sectionDetailTypographyMultipleRowsContainer}>
          <Box className={classes.sectionDetailTypographyContainer}>
            <Typography className={classes.sectionDetailTypographySectionTitle}>
              Número de guías:
            </Typography>
            <Typography className={classes.sectionDetailTypographyText}>
              {statistics.testsNumber}
            </Typography>
          </Box>
          <Box className={classes.sectionDetailTypographyContainer}>
            <Typography className={classes.sectionDetailTypographySectionTitle}>
              Promedio de entregas:
            </Typography>
            <Typography className={classes.sectionDetailTypographyText}>
              {statistics.averageSubmissions}%
            </Typography>
          </Box>
        </Box>

        <Box className={classes.sectionDetailTypographyMultipleRowsContainer}>
          <Box className={classes.sectionDetailTypographyContainer}>
            <Typography className={classes.sectionDetailTypographySectionTitle}>
              Promedio buenas:
            </Typography>
            <Typography className={classes.sectionDetailTypographyText}>
              {statistics.averageGood}%
            </Typography>
          </Box>
          <Box className={classes.sectionDetailTypographyContainer}>
            <Typography className={classes.sectionDetailTypographySectionTitle}>
              Promedio malas:
            </Typography>
            <Typography className={classes.sectionDetailTypographyText}>
              {statistics.averageBad}%
            </Typography>
          </Box>
          <Box className={classes.sectionDetailTypographyContainer}>
            <Typography className={classes.sectionDetailTypographySectionTitle}>
              Promedio omitidas:
            </Typography>
            <Typography className={classes.sectionDetailTypographyText}>
              {statistics.omittedGuidesAverage}%
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionDetailInformation;
