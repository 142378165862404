import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.main,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  responsiveTypography: {
    marginLeft: '30px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginTop: '5px',
      width: '100%',
    },
  },
  backButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
  textBodyColor: {
    color: theme.palette.text.primary,
  },
  bombDetailNavbar: {
    height: '60px',
    width: '100%',
    display: 'flex',
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.primary.main,
    marginTop: '35px',
  },
  navBarDetailContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    borderRadius: '5px 5px 0px 0px',
    width: '14.29%',
    textAlign: 'center',
    padding: '0px 12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  navbarDetailNavItem: {
    margin: '0px 4px',
  },
  navbarDetailNavItemActive: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  navbarDetailNavItemInactive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.gray,
  },
}));

export default useStyles;
