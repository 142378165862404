import React, { SyntheticEvent, useEffect, useState } from 'react';
import UpsertModal from '../../../../components/General/UpsertModal';
import { StudentData } from '../types';

function InsertStudentModal(
  {
    modalAdd,
    setModalAdd,
    handleCloseModal,
    modalValues,
    handleFieldChange,
    itemsUI,
    addFunction,
  }: {
    modalAdd: { id: string, open: boolean },
    setModalAdd: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>,
    handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>) => void,
    modalValues: StudentData,
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File, source: string) => void,
    itemsUI: {
      label: string;
      key: string;
      showOnTable: boolean;
      showOnModal: boolean;
      typeField: string;
      selectFields?: object[];
      valueType: string;
    }[],
    addFunction: () => void,
  },
) {
  const [upsertFlag, setUpsertFlag] = useState(false);

  useEffect(() => {
    if (upsertFlag) {
      addFunction();
      setUpsertFlag(false);
    }
  }, [upsertFlag]);

  return (
    <UpsertModal<StudentData>
      title={'alumno'}
      modalAddEdit={modalAdd}
      setModalAddEdit={setModalAdd}
      handleCloseModal={handleCloseModal}
      modalValues={modalValues}
      handleFieldChange={handleFieldChange}
      itemsUI={itemsUI}
      handleAddFunction={() => setUpsertFlag(true)}
      handleEditFunction={() => setUpsertFlag(true)}
    />
  );
}

export default InsertStudentModal;
