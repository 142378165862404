import React from 'react';
import {
  Button, Grid, TextField, Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { useHistory } from 'react-router';
import useStyles from '../styles';

const MassiveDetailHeader = ({
  minQuestions,
  maxQuestions,
  creationDate,
  programName,
}: Record<string, string>) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        <Button
          className={classes.icons}
          onClick={() => history.goBack()}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          ml={1}
          variant="h3"
          fontWeight='bold'
        >
          Ver masivos
        </Typography>
      </Grid>
      <Grid item display="flex" alignItems="center">
        <TextField
          variant='outlined'
          value={minQuestions}
          sx={{ marginRight: '10px' }}
          label='Mínimo número preguntas'
        />
        <TextField
          variant='outlined'
          value={maxQuestions}
          sx={{ marginRight: '10px' }}
          label='Máximo número preguntas'
        />
        <MobileDatePicker
          readOnly
          value={new Date(creationDate)}
          sx={{ marginRight: '10px' }}
          label="Fecha creación"
        />
        <TextField
          variant='outlined'
          value={programName}
          label='Nombre programa'
        />
      </Grid>
    </Grid>
  );
};

export default MassiveDetailHeader;
