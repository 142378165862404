import React, { useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';

import { teachersCSVDownload } from '../../../requests/api/teachers';
import {
  studentAssistancesCSVDownload,
  studentTestsCSVDownload,
  studentTestsResultsCSVDownload,
  studentsCSVDownload,
} from '../../../requests/api/students';
import { testsPerformanceCSVDownload } from '../../../requests/api/test';

import Page from '../../../components/Layout/Page';
import Header from '../../../components/Layout/Header';
import CustomTable from '../../../components/General/CustomTable';

import {
  ASSISTANCES_TABLE_UI,
  RESULTS_TABLE_UI,
  STUDENTS_TABLE_UI,
  SUBMITTED_TABLE_UI,
  TEACHERS_TABLE_UI,
  TESTS_TABLE_UI,
  exampleValues,
} from './types';

const downloadTables = [{
  title: 'Listado de profesores',
  columns: TEACHERS_TABLE_UI,
  buttonFn: teachersCSVDownload,
}, {
  title: 'Listado de alumnos',
  columns: STUDENTS_TABLE_UI,
  buttonFn: studentsCSVDownload,
}, {
  title: 'Listado de asistencias',
  columns: ASSISTANCES_TABLE_UI,
  buttonFn: studentAssistancesCSVDownload,
}, {
  title: 'Listado de entregas',
  columns: SUBMITTED_TABLE_UI,
  buttonFn: studentTestsCSVDownload,
}, {
  title: 'Listado de resultados',
  columns: RESULTS_TABLE_UI,
  buttonFn: studentTestsResultsCSVDownload,
}, {
  title: 'Rendimiento de guías y ensayos',
  columns: TESTS_TABLE_UI,
  buttonFn: testsPerformanceCSVDownload,
}];

function DownloadsView() {
  const [downloading, setDownloading] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = async (func: () => Promise<{ file: string }>, filename: string) => {
    setLoading(true);
    setDownloading(filename);
    const { file } = await func();

    const uri = `data:text/csv;charset=utf-8,\uFEFF${file}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = uri;
    downloadLink.download = `${filename}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    setLoading(false);
    setDownloading('');
  };

  return (
    <Page title="Descargas">
      <Header
        title="Descargas"
        icon={<DownloadIcon />}
      />
      {downloadTables.map((table, index) => (
        <Box key={index} mt={3}>
          <Box mb={2}>
            <Header
            title={table.title}
            buttons={[
              {
                text: 'Descargar',
                icon: downloading === table.title
                  ? <CircularProgress size={20} /> : <DownloadIcon />,
                onClick: () => handleDownload(table.buttonFn, table.title),
                disabled: loading,
              },
            ]}
            />
          </Box>
          <CustomTable
            headers={table.columns}
            data={{ values: exampleValues, count: 0 }}
          />
        </Box>
      ))}
    </Page>
  );
}

export default DownloadsView;
