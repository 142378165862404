import { RequestsPerPage } from '../types';
import axios from '../../utils/axios';
import { SectionData } from '../../views/Section/SectionListView/types';

const getSectionsPerPage = async ({
  page, limit, searchValue, sort, teacherId, subjectId, programId,
}: RequestsPerPage & { teacherId: string | undefined }) => {
  let url = `/api/sections?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`;
  if (teacherId) {
    url += `&teacherId=${teacherId}`;
  }

  if (subjectId) {
    url += `&subjectId=${subjectId}`;
  }

  if (programId) {
    url += `&programId=${programId}`;
  }

  const response = await axios.get(url);

  return response.data;
};

const postCreateSection = async ({
  programId,
  locationId,
  classroom,
  studentsCapacity,
  timeSlotIds,
  teacherInfo,
  mipreuId,
}: SectionData) => {
  const response = await axios.post('/api/sections', {
    programId,
    locationId,
    classroom,
    studentsCapacity,
    timeSlotIds,
    teacherInfo,
    mipreuId,
  });

  return response.data;
};

const putUpdateSection = async ({
  id,
  programId,
  locationId,
  classroom,
  studentsCapacity,
  timeSlotIds,
  teacherInfo,
  mipreuId,
}: SectionData) => {
  const response = await axios.put(`/api/sections/${id}`, {
    programId,
    locationId,
    classroom,
    studentsCapacity,
    timeSlotIds,
    teacherInfo,
    mipreuId,
  });

  return response.data;
};

const deleteSection = async (sectionId: string) => {
  const response = await axios.delete(`/api/sections/${sectionId}`);

  return response.data;
};

const getTimeSlots = async () => {
  const response = await axios.get('/api/sections/get/time-slots');

  return response.data;
};

const getLocations = async () => {
  const response = await axios.get('/api/sections/get/locations');

  return response.data;
};

const getPrograms = async (subjectId: string | number | undefined) => {
  const response = await axios.get(`/api/sections/get/programs/${subjectId || 'all'}`);

  return response.data;
};

const getTeachers = async () => {
  const response = await axios.get('/api/sections/get/teachers');

  return response.data;
};

const getStudentsInSection = async (sectionId: string) => {
  const response = await axios.get(`/api/students-section/${sectionId}`);

  return response.data;
};

const getSectionStatistics = async (sectionId: string) => {
  const response = await axios.get(`/api/sections/${sectionId}/get/statistics`);

  return response.data;
};

const getStudentTestsInformationBySectionId = async (studentId: string, sectionId: string) => {
  const response = await axios.get(`/api/students-tests/${studentId}/section/${sectionId}`);

  return response.data;
};

const getStudentAssistanceInformationBySectionId = async (studentId: string, sectionId: string) => {
  const response = await axios.get(`/api/students-assistances/${studentId}/section/${sectionId}`);

  return response.data;
};

const getAllSections = async () => {
  const response = await axios.get('/api/get/sections');

  return response.data;
};

export {
  deleteSection,
  getSectionsPerPage,
  postCreateSection,
  putUpdateSection,
  getTimeSlots,
  getLocations,
  getPrograms,
  getTeachers,
  getStudentsInSection,
  getSectionStatistics,
  getStudentTestsInformationBySectionId,
  getStudentAssistanceInformationBySectionId,
  getAllSections,
};
