import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { ClassNameMap } from '@mui/styles';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import Page from '../../../../../components/Layout/Page';
import ContractUpsertHeader from './Components/ContractUpsertHeader';
import Programs from './Components/Programs';
import ContractDiscounts from './Components/ContractDiscounts';
import { ReduxState, StudentContract, TableStudentPrograms } from '../../../../../types';
import useStyles from '../../styles';
import { resetContract, setAddProgramModalValues, setNewContract } from '../../../../../actions/studentContractActions';
import { getContractById, postContract, putContract } from '../../../../../requests/api/students';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import { StudentProgram, StudentProgramTableData } from '../../types';
import CustomButton from '../../../../../components/General/CustomButton';
import { getDateOnlyFormat } from '../../../../../utils/helpers';

const buttonActions = (
  classes: ClassNameMap<string>,
  programId: string,
  editProgram: ({ programId }: { programId: string }) => void,
  removeProgram: ({ programId }: { programId: string }) => void,
) => (<Box>
  <IconButton onClick={() => editProgram({ programId })}>
    <EditIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => removeProgram({ programId })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

const UPSERT_PROGRAMS_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Rango de fechas', key: 'dateRange', valueType: 'string',
  },
  {
    label: 'Arancel original', key: 'programFee', valueType: 'ccy',
  },
  {
    label: 'Descuento', key: 'programFeeDiscount', valueType: 'ccy',
  },
  {
    label: 'Comentario', key: 'comment', valueType: 'string',
  },
  {
    label: 'Arancel final', key: 'finalProgramPrice', valueType: 'ccy',
  },
  {
    label: 'Acciones', key: 'actions', valueType: 'other',
  },
];

function ContractUpsertView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: studentId, contractId } = useParams<{ id: string, contractId?: string }>();
  const {
    contract,
    contractAddProgramModal,
  } = useSelector((state: ReduxState) => state.studentContract);
  const { enqueueSnackbar } = useSnackbar();

  const [studentProgramsTable, setStudentProgramsTable] = useState<StudentProgramTableData[]>([]);
  const [eraseProgramsTrigger, setEraseProgramsTrigger] = useState(false);

  const editProgram = (programId: string) => {
    const filteredContract = contract && contract[studentId]
      && contract[studentId].selectedStudentPrograms && contract[studentId].selectedStudentPrograms!
      .filter((elem) => elem.programId === programId);

    dispatch(setAddProgramModalValues({
      open: true,
      values: {
        subjectId: filteredContract![0].subjectId,
        selectedProgramId: filteredContract![0].programId,
        feeDiscount: filteredContract![0].programFeeDiscount,
        comment: filteredContract![0].comment,
        selectedProgramIdToEdit: filteredContract![0].programId,
      },
    }));
  };

  const removeProgram = (programId: string) => {
    // If I am already removing something from the table, it means it already exist a contract
    // and contract[studentId]
    const auxContractInfo = {
      ...contract![studentId],
      selectedStudentPrograms: contract![studentId].selectedStudentPrograms!
        .filter((elem) => elem.programId !== programId),
    };
    dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    setEraseProgramsTrigger((prevState) => !prevState);
  };

  useEffect(() => {
    const getContract = async () => {
      try {
        const { contract: relevantContract } = await getContractById(contractId!);
        const contractDocument = relevantContract
          .contractDocuments.find((elem: StudentContract) => (elem.contractId as string)
            .toString() === contractId);

        const auxStudentContract: StudentContract = {
          contractId,
          studentId,
          contractTuition: relevantContract.tuition,
          contractTuitionDiscount: relevantContract.tuitionDiscount,
          feeAdditionalDiscount: relevantContract.feeAdditionalDiscount,
          comment: (contractDocument && contractDocument.comment) ?? '',
          startDate: new Date(relevantContract.startDate),
          endDate: new Date(relevantContract.endDate),
          tuitionAgreement: relevantContract.tuitionAgreement,
          tuitionPaymentType: { id: relevantContract.tuitionPaymentType, name: '' },
          feeDiscountType: relevantContract.feeDiscountType,
          feeDiscount: relevantContract.feeDiscount,
          feeAgreementDiscount: relevantContract.feeDiscountType === 'agreement' ? relevantContract.feeDiscount : '0',
          feePaymentType: { id: relevantContract.feePaymentType, name: '' },
          feePaymentTypeDiscount: relevantContract.feePaymentTypeDiscount,
          installments: relevantContract.contractPayments,
          disabledDiscount: relevantContract.feePaymentTypeDiscount === 0,
          disabledProgramsDiscount: relevantContract.studentPrograms.length >= 2
            && relevantContract.feeDiscountType !== 'agreement'
            && relevantContract.feeDiscount === 0,
        };

        if (relevantContract.studentPrograms) {
          const auxProgramsToAdd = relevantContract.studentPrograms.map((elem: StudentProgram) => ({
            programId: elem.programId,
            subjectId: elem.program.subject.id,
            subjectName: elem.program.subject.name,
            programName: elem.program.name,
            programFee: elem.program.fee,
            programFeeDiscount: elem.feeDiscount,
            comment: elem.comment,
            programStartDate: elem.program.startDate,
            programEndDate: elem.program.endDate,
          }));
          auxStudentContract.selectedStudentPrograms = auxProgramsToAdd;
        }

        dispatch(setNewContract({ studentId, studentContract: auxStudentContract }));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    const initializeReduxNewContract = () => {
      dispatch(setNewContract({
        studentId,
        studentContract: {
          studentId,
          contractTuition: '0',
          contractTuitionDiscount: '0',
          feeAgreementDiscount: '0',
          feeAdditionalDiscount: '0',
          startDate: new Date(),
          endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
          tuitionAgreement: null,
          tuitionPaymentType: { id: '', name: '' },
          feeDiscountType: '',
          feeDiscount: '0',
          feePaymentType: { id: '', name: '' },
          feePaymentTypeDiscount: '',
          installments: [],
          selectedStudentPrograms: [],
          comment: '',
          disabledDiscount: false,
          disabledProgramsDiscount: false,
        },
      }));
    };

    if (contractId) {
      getContract();
    } else {
      initializeReduxNewContract();
    }
  }, [contractId]);

  useEffect(() => {
    if (contract && contract[studentId] && contract[studentId].selectedStudentPrograms) {
      const auxSelectedStudentPrograms = contract[studentId].selectedStudentPrograms!.map((
        elem: TableStudentPrograms,
      ) => {
        const programFee = elem.programFee ? parseInt(elem.programFee, 10) : 0;
        const programFeeDiscount = elem.programFeeDiscount
          ? parseInt(elem.programFeeDiscount, 10)
          : 0;
        const dateRange = `${getDateOnlyFormat(elem.programStartDate, '/', true)} - ${getDateOnlyFormat(elem.programEndDate, '/', true)}`;
        return {
          subjectName: elem.subjectName,
          programName: elem.programName,
          dateRange,
          programFee,
          programFeeDiscount,
          comment: elem.comment,
          finalProgramPrice: programFee
            - (programFeeDiscount ?? 0),
          actions: buttonActions(
            classes,
            studentId,
            () => editProgram(elem.programId),
            () => removeProgram(elem.programId),
          ),
        };
      });
      setStudentProgramsTable(auxSelectedStudentPrograms);
    }
  }, [contract, contractAddProgramModal.open, eraseProgramsTrigger]);

  const handleAddEditContract = async () => {
    try {
      const auxContract = contract![studentId] as StudentContract;

      let totalFee = 0;
      let totalInstallmentsAmount = 0;
      if (auxContract.feePaymentType!.id === 'check' || auxContract.feePaymentType!.id === 'monthly') {
        // Calculate the total amount from installments
        const installments = auxContract.installments || [];
        totalInstallmentsAmount = installments.reduce(
          (sum, installment) => sum + (typeof installment.amount === 'string' ? Number(installment.amount) : installment.amount), 0,
        );

        // Calculate the total fee from selectedStudentPrograms
        const selectedStudentPrograms = auxContract.selectedStudentPrograms || [];
        totalFee = selectedStudentPrograms.reduce((sum, program) => {
          const programFee = parseFloat(program.programFee) || 0;
          const programFeeDiscount = parseFloat(program.programFeeDiscount) || 0;
          return sum + (programFee - programFeeDiscount);
        }, 0);
      }
      // AMOUNTS
      const tuition = parseInt(auxContract.contractTuition!, 10);

      // DISCOUNTS
      const tuitionDiscount = parseInt(auxContract.contractTuitionDiscount || '0', 10);
      const feeAdditionalDiscount = parseInt(auxContract.feeAdditionalDiscount || '0', 10);

      // The agreement discount takes precedence over the program discount.
      // In other words, if there is an agreement discount, the program discount does not apply.
      let feeDiscountType = null;
      let feeDiscount = 0;
      if (auxContract.feeAgreementDiscount && auxContract.feeAgreementDiscount !== '0') {
        feeDiscountType = 'agreement';
        feeDiscount = parseInt(auxContract.feeAgreementDiscount, 10);
      } else if (auxContract.selectedStudentPrograms!.length >= 2
          && !auxContract.disabledProgramsDiscount) {
        feeDiscountType = 'programs';
        feeDiscount = auxContract.selectedStudentPrograms!.length === 2 ? 5 : 10;
      }

      if (feeDiscount > 0) totalFee *= ((100 - feeDiscount) / 100);

      const payWithInstallments = auxContract.feePaymentType!.id === 'check' || auxContract.feePaymentType!.id === 'monthly';
      // Validate that the sum of installments matches the total fee
      if (payWithInstallments && totalInstallmentsAmount !== (totalFee - feeAdditionalDiscount)) {
        enqueueSnackbar('El valor final del arancel no es igual a la suma de las cuotas.', {
          variant: 'error',
        });
        return;
      }

      let installmentsDiscount = false;
      const installments = auxContract.installments || [];
      // If the payment method for the fee is 'Cheque', the payment is made in a single installment,
      // and if the installment is due today, a 3% discount is applied.
      if (installments.length === 1) {
        const today = new Date();
        const date = new Date(installments[0].dueDate);
        date.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0); // Normalize today's date to midnight for accurate comparison.

        installmentsDiscount = auxContract.feePaymentType!.id === 'check' && date <= today;
      }

      const feePaymentTypeDiscount = (
        ['transfer', 'debit', 'credit'].includes(auxContract.feePaymentType!.id)
        || installmentsDiscount
      ) && !auxContract.disabledDiscount ? 3 : 0;

      // INFO
      const tuitionPaymentType = auxContract.tuitionPaymentType!.id || '';
      const feePaymentType = auxContract.feePaymentType!.id || '';
      const studentProgramInfo = auxContract.selectedStudentPrograms?.map((elem) => ({
        programId: elem.programId,
        feeDiscount: parseInt(elem.programFeeDiscount || '0', 10),
        comment: elem.comment,
      })) || [];

      const contractData = {
        studentId,
        startDate: auxContract.startDate!,
        endDate: auxContract.endDate!,
        tuition,
        tuitionAgreement: auxContract.tuitionAgreement || null,
        tuitionDiscount,
        tuitionPaymentType,
        feeDiscountType,
        feeDiscount,
        feeAdditionalDiscount,
        feePaymentType,
        feePaymentTypeDiscount,
        studentProgramInfo,
        installments,
        comment: auxContract.comment,
      };

      if (contractId) {
        await putContract({ contractId, ...contractData });
      } else {
        const response = await postContract(contractData);
        handleApiResponse(enqueueSnackbar, response, true);
      }
      dispatch(resetContract({ studentId }));
      history.replace(`/alumnos/${studentId}`, { activeTab: 0 });
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  return (
    <Page
      title={`Alumnos | ${contractId ? 'Editar' : 'Nuevo'} contrato`}
    >
      <ContractUpsertHeader />
      <Divider sx={{ marginTop: '30px' }} />
      <Programs
        headers={UPSERT_PROGRAMS_UI}
        data={{
          values: studentProgramsTable,
          count: studentProgramsTable.length,
        }}
      />
      <ContractDiscounts programsLength={studentProgramsTable.length} />
      <Box className={classes.contractUpsertButtonContainer}>
        <CustomButton
          text={contractId ? 'Confirmar edición contrato' : 'Confirmar nuevo contrato'}
          colorType='tertiary'
          onClick={handleAddEditContract}
          key={'AddEditContractButton'}
          icon={contractId ? <EditIcon /> : <AddIcon />}
        />
      </Box>
    </Page>
  );
}

export default ContractUpsertView;
