import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, ClassNameMap, IconButton } from '@mui/material';
import { Add as AddIcon, Download as DownloadIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import CustomButton from '../../../../../components/General/CustomButton';
import CustomTable from '../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../components/General/EmptyTable';
import { downloadTest, getFeedbacksByStudentId, postFeedbacks } from '../../../../../requests/api/students';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import useStyles from '../../styles';
import FeedbackMassiveModal from './Modals/FeedbackMassiveModal';
import { Program } from '../../../../Program/ProgramListView/types';
import {
  REPRESENTATIVE,
  SECRETARY,
  STUDENT,
  TEACHER,
} from '../../../../../utils/user_types';
import { ReduxState } from '../../../../../types';
import CircularProgressComponent from '../../../../../components/Loading/CircularProgressComponent';

type StudentFeedback = {
  createdAt: Date,
  minQuestions: number,
  maxQuestions: number,
  comment: string,
  feedbackQuestionsCount: number,
  massiveId?: string,
  questionsPdfUrl: string,
  solutionsPdfUrl: string,
  program: {
    id: string,
    name: string,
    subject: {
      id: string,
      name: string,
    }
  }
};

const FEEDBACKS_VIEW_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Fecha creación', key: 'createdAt', valueType: 'shortDate',
  },
  {
    label: 'ID Masivo', key: 'massiveId', valueType: 'string',
  },
  {
    label: 'Número preguntas', key: 'numberOfQuestions', valueType: 'string',
  },
  {
    label: 'Mínimo preguntas', key: 'minQuestions', valueType: 'string',
  },
  {
    label: 'Máximo preguntas', key: 'maxQuestions', valueType: 'string',
  },
  {
    label: 'Comentario', key: 'comment', valueType: 'string',
  },
  {
    label: 'Descargar preguntas', key: 'downloadQuestions', valueType: 'other',
  },
  {
    label: 'Descargar respuestas', key: 'downloadAnswers', valueType: 'other',
  },
];

const FEEDBACKS_VIEW_MODAL_UI = [
  {
    label: 'Programa', key: 'programId', typeField: 'autocomplete', selectFields: [], valueToRetrieveFromSelect: 'id',
  },
  {
    label: 'Mínimo de preguntas', key: 'minQuestions', typeField: 'number',
  },
  {
    label: 'Máximo de preguntas', key: 'maxQuestions', typeField: 'number',
  },
];

const buttonActions = (
  classes: ClassNameMap<string>,
  urlToDownload: string,
  handleDownload: (urlToDownload: string) => Promise<void>,
) => (<Box>
  <IconButton disabled={!urlToDownload} onClick={() => handleDownload(urlToDownload)}>
    <DownloadIcon className={urlToDownload ? classes.icons : classes.disabledIcons} />
  </IconButton>
</Box>);

const excludedRoles = [TEACHER, STUDENT, REPRESENTATIVE, SECRETARY];

function FeedbacksListView() {
  const classes = useStyles();
  const [allFeedbacks, setAllFeedbacks] = useState<{ count: number, values: [] }>(
    { count: 0, values: [] },
  );
  const { enqueueSnackbar } = useSnackbar();
  const { id: studentId } = useParams<{ id: string; }>();
  const [modalAddFeedback, setModalAddFeedback] = useState({ id: '', open: false });
  const [updateTable, setUpdateTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [modalValues, setModalValues] = useState<{
    name?: string,
    minQuestions: number,
    maxQuestions: number | undefined,
    programId: string,
    selectedTestsIds: string[],
  }>({
    minQuestions: 0,
    maxQuestions: undefined,
    programId: '',
    selectedTestsIds: [],
  });
  const { user } = useSelector((state: ReduxState) => state.account);

  const handleDownload = async (urlToDownload: string) => {
    try {
      const { signedUrl } = await downloadTest(urlToDownload);

      // Creating link to download file
      const link = document.createElement('a');
      link.href = signedUrl;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getInitialInformationFromStudent = async () => {
      // Getting the feedbacks
      try {
        const { studentFeedbacks } = await getFeedbacksByStudentId(studentId, source.token);
        const auxStudentFeedbacks = studentFeedbacks.map((elem: StudentFeedback) => ({
          subjectName: elem.program.subject.name,
          programName: elem.program.name,
          createdAt: elem.createdAt,
          numberOfQuestions: elem.feedbackQuestionsCount,
          minQuestions: elem.minQuestions,
          maxQuestions: elem.maxQuestions,
          comment: elem.comment,
          massiveId: elem.massiveId ?? '-',
          downloadQuestions: buttonActions(classes, elem.questionsPdfUrl, handleDownload),
          downloadAnswers: buttonActions(classes, elem.solutionsPdfUrl, handleDownload),
        }));
        setAllFeedbacks({ count: auxStudentFeedbacks.length, values: auxStudentFeedbacks });
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getInitialInformationFromStudent();
    return () => {
      source.cancel();
    };
  }, [updateTable, studentId]);

  const handleCloseModal = () => {
    setModalValues({
      minQuestions: 0,
      maxQuestions: undefined,
      programId: '',
      selectedTestsIds: [],
    });
    setModalAddFeedback({ id: '', open: false });
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string, value?: string | object | null,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (!(e instanceof File)) {
      const { value: targetValue } = (e as React.ChangeEvent<HTMLInputElement>).target;
      if (source === 'checkbox') {
        if (modalValues.selectedTestsIds.includes(targetValue)) {
          setModalValues(
            (prevState) => ({
              ...prevState,
              selectedTestsIds: prevState.selectedTestsIds
                .filter((elem) => elem !== targetValue),
            }),
          );
        } else {
          setModalValues(
            (prevState) => ({
              ...prevState,
              selectedTestsIds: [...prevState.selectedTestsIds, targetValue],
            }),
          );
        }
      } else if (source === 'programId') {
        setModalValues(
          (prevState) => ({ ...prevState, [source]: (value as Program)?.id ?? '' }),
        );
      } else {
        setModalValues(
          (prevState) => ({ ...prevState, [source]: (e.target as HTMLInputElement).value }),
        );
      }
    }
  };

  const handleAddFunction = async (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!modalValues.maxQuestions
      || Number(modalValues.maxQuestions) < Number(modalValues.minQuestions)) {
      enqueueSnackbar('Máximo de preguntas no puede estar vacío ni ser menor que mínimo de preguntas.', { variant: 'error' });
      return;
    }

    try {
      setLoading(true);
      const response = await postFeedbacks({
        studentId,
        minQuestions: modalValues.minQuestions,
        maxQuestions: modalValues.maxQuestions,
        programId: modalValues.programId,
        selectedTestsIds: modalValues.selectedTestsIds,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal();
      setUpdateTable((prevState) => !prevState);
      setLoading(false);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
      setLoading(false);
    }
  };

  return (
    <>
      {showLoading
        ? <CircularProgressComponent />
        : <Box className={classes.videoListViewContainer}>
          <Box className={classes.videoListViewHeader}>
            {user && !excludedRoles.includes(user!.userType) && <CustomButton text={'Crear feedback'} colorType='tertiary' onClick={() => setModalAddFeedback({ ...modalAddFeedback, open: true })} key={'AcceptButtonAddFeedback'} icon={<AddIcon />} />}
          </Box>
          <Box className={classes.videoListViewTableContainer}>
            {allFeedbacks.count !== 0
              && <CustomTable
                headers={FEEDBACKS_VIEW_UI}
                data={allFeedbacks}
              />}
            {allFeedbacks.count === 0 && <EmptyTable />}
          </Box>
        </Box>}
      <FeedbackMassiveModal
        title={'feedback'}
        modalAddEdit={modalAddFeedback}
        setModalAddEdit={setModalAddFeedback}
        itemsUI={FEEDBACKS_VIEW_MODAL_UI}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        handleFieldChange={handleFieldChange}
        handleAddFunction={handleAddFunction}
      />
    </>
  );
}

export default FeedbacksListView;
