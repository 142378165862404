export type AssistanceData = {
  date: Date,
  programName: string,
  startTime: string,
  teacher: string,
  assistance: string,
  classId: number,
  studentId: number,
  studentClassId: number,
  studentName: string,
  studentLastName: string,
  studentRut: string,
  mipreuId: string,
  class: AssistanceDataIncludes,
};

type AssistanceDataIncludes = {
  id: string,
  sectionTimeSlot: {
    id: string,
    section: {
      id: string,
      sectionTeachers: AssistanceDataSectionTeacher[]
    }
  }
};

export type AssistanceDataSectionTeacher = {
  id: string,
  teacher: {
    id: string,
    user: {
      name: string,
      last: string
    }
  }
};

export type StudentAssistanceStatistics = {
  arrivedLateAverage: number,
  assistance: string,
};

export const ASSISTANCE_OPTIONS = [{ name: 'Presente', value: 'present' }, { name: 'Ausente', value: 'absent' }, { name: 'Atrasado', value: 'late' }];
