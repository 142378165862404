import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import handleApiResponse from '../../../utils/handleApiResponse';
import { getPrograms, getSubjects } from '../../../requests/api/bombs';

import {
  CustomAutocompleteTextField,
  CustomNumericTextField,
  CustomSelectTextField,
  CustomTextField,
} from '../../../components/General/CustomTextfields';
import CustomButton from '../../../components/General/CustomButton';

import useStyles from './styles';
import { getTests } from '../../../requests/api/massive';

type ProgramTest = {
  deliverDate: Date,
  number: number,
  numberOfDeliveries: number,
  test: {
    id: string,
    name: string,
  }
};

export default function AddBombModal({
  title,
  modalAddEdit,
  setModalAddEdit,
  handleCloseModal,
  modalValues,
  handleFieldChange,
  itemsUI,
  handleAddFunction,
}: {
  title: string,
  modalAddEdit: { id: string, open: boolean },
  setModalAddEdit: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>,
  handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => void,
  modalValues: {
    startHability: number,
    endHability: number,
    programId: string,
    subjectId: string,
    selectedTestsIds: string[],
  },
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string, value?: string | object | null,
  ) => void,
  itemsUI: {
    label: string;
    key: string;
    showOnTable?: boolean;
    showOnModal?: boolean;
    typeField: string;
    selectFields?: object[];
    valueType?: string;
    valueToRetrieveFromSelect?: string;
    keyNameInObjectSent?: string;
    disabled?: boolean;
  }[],
  handleAddFunction: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [modalUI, setModalUI] = useState(itemsUI);
  const [allTests, setAllTests] = useState({ count: 0, values: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllSubjectsInformation = async () => {
      try {
        const { subjects } = await getSubjects();
        setModalUI(itemsUI.map((elem) => {
          if (elem.label === 'Asignatura') {
            elem.selectFields = subjects;
          }
          return elem;
        }));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (modalAddEdit.open) {
      getAllSubjectsInformation();
    }
  }, [modalAddEdit.open]);

  useEffect(() => {
    const getAllProgramsInformation = async () => {
      try {
        const { programs } = await getPrograms(modalValues.subjectId);
        setModalUI(itemsUI.map((elem) => {
          if (elem.label === 'Programa') {
            elem.selectFields = programs;
          }
          return elem;
        }));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (modalAddEdit.open && modalValues.subjectId !== '') {
      getAllProgramsInformation();
    }
  }, [modalValues.subjectId, modalAddEdit.open]);

  useEffect(() => {
    const getAllTestsInformation = async () => {
      // Getting the programs and questions associated with the program.
      try {
        const { tests } = await getTests(
          modalValues.programId !== '' ? modalValues.programId : '0',
        );
        setAllTests({ count: tests.length, values: tests });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    if (modalAddEdit.open && modalValues.programId !== '') {
      getAllTestsInformation();
    }
  }, [modalValues.programId, modalAddEdit.open]);

  return (
    <Modal
      open={modalAddEdit.open}
      onClose={() => handleCloseModal(setModalAddEdit)}
    >
      <Box className={classes.modalContainer}>
        <Card className={classes.modal}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              {/* TITLE */}
              <Box className={classes.modalTitleAndIcon}>
                {modalAddEdit.id !== '' ? <EditIcon color='primary' /> : <AddIcon color='primary' />}
                <Typography variant='h3' color='primary' fontWeight='bold'>{modalAddEdit.id !== '' ? `Editar ${title}` : `Crear ${title}`}</Typography>
              </Box>

              <IconButton onClick={() => handleCloseModal(setModalAddEdit)}>
                <CloseIcon color='primary' />
              </IconButton>
            </Box>
            <Box className={classes.modalTextfieldsContainer}>
              {modalUI
                .map((elem) => {
                  let key;
                  if (elem.keyNameInObjectSent) {
                    key = elem.keyNameInObjectSent;
                  } else {
                    key = elem.key;
                  }

                  return (
                    <Box key={key.toString()} className={classes.modalIndividualTextfield}>
                      {elem.typeField === 'select' && <CustomSelectTextField
                        disabled={elem.disabled}
                        valueToRetrieveFromSelect={elem.valueToRetrieveFromSelect}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key as keyof typeof modalValues] as string}
                        onChangeFunction={handleFieldChange}
                        selectFields={elem.selectFields as Array<{ id: string; name: string; }>}
                      />}
                      {elem.typeField === 'text' && <CustomTextField
                        disabled={elem.disabled}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key as keyof typeof modalValues] as string}
                        onChangeFunction={handleFieldChange}
                      />}
                      {elem.typeField === 'number' && <CustomNumericTextField
                        disabled={elem.disabled}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key as keyof typeof modalValues] as string}
                        onChangeFunction={handleFieldChange}
                      />}
                      {elem.typeField === 'autocomplete' && <CustomAutocompleteTextField
                        disabled={elem.disabled}
                        valueToRetrieveFromSelect={elem.valueToRetrieveFromSelect}
                        keyField={key.toString()}
                        name={elem.label}
                        value={modalValues[key as keyof typeof modalValues] as string}
                        onChangeFunction={handleFieldChange}
                        selectFields={elem.selectFields as Array<{ id: string; name: string; }>}
                      />}
                    </Box>
                  );
                })}
            </Box>
            <Typography variant='h3' color='textPrimary' fontWeight='bold' mt={3} mb={3}>Guías</Typography>
            {modalValues.programId !== ''
              && <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Número</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Fecha programada</TableCell>
                      <TableCell>N° entregas</TableCell>
                      <TableCell>Seleccionar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allTests.values.map((row: ProgramTest, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.number}</TableCell>
                        <TableCell>{row.test.name}</TableCell>
                        <TableCell>{row.deliverDate}</TableCell>
                        <TableCell>{row.numberOfDeliveries}</TableCell>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={modalValues.selectedTestsIds.includes(`${row.test.id}`)}
                            onChange={(e) => {
                              e.target.value = row.test.id;
                              handleFieldChange(e, 'checkbox');
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
            {modalValues.programId === ''
              && <Typography fontWeight='bold'>
                Ningún programa se ha seleccionado
              </Typography>}
            <Box className={classes.modalButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={() => handleCloseModal(setModalAddEdit)} key={'CancelButtonAddProgram'} icon={<CloseIcon />} />
              {<CustomButton
                text={`Crear ${title}`}
                colorType='primary'
                onClick={() => handleAddFunction(setLoading)}
                key={`AcceptButtonAdd${title}`}
                icon={<AddIcon />}
                loading={loading}
              />}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
