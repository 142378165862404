import { Box, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../../../styles';
import CustomTable from '../../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../../components/General/EmptyTable';
import { SummaryDataResponse } from '../types';

const SummaryTable = ({
  data, headers,
}: {
  data: { count: number; values: SummaryDataResponse[] },
  headers: { label: string; key: string; valueType: string; }[]
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.contractViewProgramsContainer}>
      <Box className={classes.contractViewProgramsTitleButtonContainer}>
        <Box className={classes.contractViewProgramsTitleContainer}>
          <Typography
            variant='h3'
            color='textPrimary'
            fontWeight='bold'
          >
            Resumen
          </Typography>
        </Box>
      </Box>
      <Box className={classes.contractViewProgramsTableContainer}>
        {data.count !== 0
          && <CustomTable
            headers={headers}
            data={data}
          />}
        {data.count === 0 && <EmptyTable />}
      </Box>
    </Box>
  );
};

export default SummaryTable;
