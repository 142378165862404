import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { formatCcy, formatDate, getDateOnlyFormat } from '../../utils/helpers';

export const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#5BC2D5 !important',
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #5BC2D5',
      },
      color: '#5BC2D5',
    },
  },
})(Tooltip);

type TableColumnHeader = {
  label: string;
  key: string;
  valueType: string;
  excludedRoles?: string[];
  functionToApply?: (...args: any[]) => JSX.Element[]
};

type TableValues = { [key: string]: any }[];

type TableData = {
  values: TableValues;
  count: number;
};

type TableParameters = {
  page: number;
  rowsPerPage: number;
};

type CustomTableProps = {
  headers: TableColumnHeader[];
  data: TableData;
  userType?: string;
  tableParameters?: TableParameters,
  setTableParameters?: React.Dispatch<React.SetStateAction<TableParameters>>,
  reduxTableParameters?: TableParameters,
  setReduxTableParameters?: any,
};

function CustomTable({
  data, headers, userType, tableParameters, setTableParameters,
  reduxTableParameters, setReduxTableParameters,
}: CustomTableProps) {
  const dispatch = useDispatch();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    if (setTableParameters) {
      setTableParameters((prevState) => ({ ...prevState, page: newPage }));
    }

    if (setReduxTableParameters) {
      dispatch(setReduxTableParameters({ ...reduxTableParameters, page: newPage }));
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setTableParameters) {
      setTableParameters({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    }

    if (setReduxTableParameters) {
      dispatch(setReduxTableParameters({
        ...reduxTableParameters, rowsPerPage: parseInt(event.target.value, 10), page: 0,
      }));
    }
  };

  const tooltipMessage = (deliveryStatus: string, status: string) => {
    if (deliveryStatus === 'advanced' && status === 'Respondida') {
      return 'Esta guía fue entregada antes de tiempo por lo que no será considerada aún en las entregas totales.';
    } if (deliveryStatus === 'advanced' && status === 'No respondida') {
      return 'Está guía aún está dentro del plazo de entrega, por lo que no será considerada aún en las entregas totales.';
    }
    return '';
  };

  const tableFooter = (parameters: TableParameters | undefined) => (parameters ? <TableFooter>
    <TableRow>
      <TableCell>
        Total: {data.count}
      </TableCell>
      <TablePagination
        count={data.count}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
        page={parameters.page}
        rowsPerPage={parameters.rowsPerPage}
        labelRowsPerPage="Datos por página:"
        labelDisplayedRows={() => { }}
      />
    </TableRow>
  </TableFooter> : null);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => {
              if (header.excludedRoles) {
                if (userType && header.excludedRoles.includes(userType)) {
                  return undefined;
                }
              }
              return (
                <TableCell key={index}>{header.label}</TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.values.map((row, rowIndex) => (
            <CustomTooltip
              arrow
              key={rowIndex}
              title={tooltipMessage(row.deliveryStatus, row.status)}
            >
              <TableRow
                sx={row.deliveryStatus === 'advanced' ? { backgroundColor: '#5BC2D533' } : undefined}
              >
                {headers.map((header: TableColumnHeader, headerIndex) => {
                  let cellValue = row[header.key];

                  if (header.excludedRoles) {
                    if (userType && header.excludedRoles.includes(userType)) {
                      return undefined;
                    }
                  }

                  if (header.valueType === 'date') {
                    cellValue = formatDate(cellValue);
                  }

                  if (header.valueType === 'shortDate') {
                    if (cellValue) {
                      cellValue = getDateOnlyFormat(cellValue, '/', true);
                    } else {
                      cellValue = '-';
                    }
                  }

                  if (header.valueType === 'ccy') {
                    cellValue = formatCcy(cellValue, '.');
                  }

                  if (header.valueType === 'custom' && header.functionToApply) {
                    cellValue = header.functionToApply(cellValue, rowIndex);
                  }
                  return (
                    <TableCell key={headerIndex} className={row.rowStyles || null}>
                      {cellValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            </CustomTooltip>
          ))}
        </TableBody>
        {tableFooter(tableParameters || reduxTableParameters)}

      </Table>
    </TableContainer>
  );
}

export default CustomTable;
