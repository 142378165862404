import React, { SyntheticEvent, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import {
  CustomSectionData,
  DropdownTeacherInfo,
  SectionData,
  SpecificDropdownTeacherInfo,
  SpecificTimeSlot,
  Teacher,
  TimeSlot,
} from '../types';
import UpsertModal from '../../../../components/General/UpsertModal';
import CustomTimeSlotComponent from '../Components/CustomTimeSlotComponent';
import CustomTeacherComponent from '../Components/CustomTeacherComponent';

function UpsertSectionModal(
  {
    allItems,
    modalAddEdit,
    setModalAddEdit,
    handleCloseModal,
    modalValues,
    setModalValues,
    handleFieldChange,
    itemsUI,
    addFunction,
    editFunction,
  }: {
    allItems: { items: Array<CustomSectionData>, totalItems: number },
    modalAddEdit: { id: string, open: boolean },
    setModalAddEdit: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>,
    handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
      id: string;
      open: boolean;
    }>>) => void,
    modalValues: SectionData,
    setModalValues: React.Dispatch<React.SetStateAction<SectionData>>,
    handleFieldChange: (e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File, source: string) => void,
    itemsUI: {
      label: string;
      key: string;
      showOnTable: boolean;
      showOnModal: boolean;
      typeField: string;
      selectFields?: object[];
      valueType: string;
    }[],
    addFunction: () => void,
    editFunction: (id: string) => void,
  },
) {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertFlag, setUpsertFlag] = useState(false);
  const [assignedTimeSlots, setAssignedTimeSlots] = useState<TimeSlot[]>([]);
  const [specificTimeSlot, setSpecificTimeSlot] = useState<SpecificTimeSlot>({
    isBeingModified: false,
    id: '',
    startTime: '',
    endTime: '',
    weekDay: '',
  });
  const [assignedTeachers, setAssignedTeachers] = useState<DropdownTeacherInfo[]>([]);
  const [specificTeacher, setSpecificTeacher] = useState<SpecificDropdownTeacherInfo>({
    isBeingModified: false,
    id: '',
    fullName: '',
    role: '',
  });

  useEffect(() => {
    if (upsertFlag && modalAddEdit.id) {
      editFunction(modalAddEdit.id);
      setUpsertFlag(false);
    } else if (upsertFlag) {
      addFunction();
      setUpsertFlag(false);
    }
  }, [upsertFlag, modalAddEdit.id]);

  useEffect(() => {
    if (modalAddEdit.id !== '') {
      const auxSelectedSection = allItems.items
        .filter((elem) => elem.id === modalAddEdit.id);
      const selectedSection = auxSelectedSection[0];

      setModalValues({
        id: selectedSection.id,
        name: selectedSection.programName,
        subjectId: selectedSection.subjectId,
        programId: selectedSection.programId,
        locationId: selectedSection.locationId,
        classroom: selectedSection.classroom,
        studentsCapacity: selectedSection.studentsCapacity,
        mipreuId: selectedSection.mipreuId,
        timeSlotIds: selectedSection.timeSlotIds,
      });
      if (selectedSection.sectionTimeSlots) {
        const filteredSectionSlots = selectedSection.sectionTimeSlots
          .filter((elem) => selectedSection.id === elem.sectionId)
          .map((elem) => elem.timeSlot);
        setAssignedTimeSlots(filteredSectionSlots);
      }

      if (selectedSection.sectionTeachers) {
        const teacherInfo = selectedSection.sectionTeachers.map((elem) => ({
          id: elem.teacherId,
          role: elem.comment ?? '',
        }));
        setModalValues((prevState) => ({ ...prevState, teacherInfo }));
        setAssignedTeachers(teacherInfo);
      }
    }
  }, [modalAddEdit.id]);

  useEffect(() => {
    // reset valores dentro de los modales
    if (modalAddEdit.open === false) {
      setAssignedTimeSlots([]);
      setSpecificTimeSlot({
        isBeingModified: false,
        id: '',
        startTime: '',
        endTime: '',
        weekDay: '',
      });
      setAssignedTeachers([]);
      setSpecificTeacher({
        isBeingModified: false,
        id: '',
        fullName: '',
        role: '',
      });
    }
  }, [modalAddEdit.open]);

  // Chequeo solo por startTime. Si startTime esta completo, endTime también
  const allFieldsFilled = () => (
    (!specificTimeSlot.isBeingModified || (specificTimeSlot.isBeingModified && (specificTimeSlot.weekDay.trim() !== '' && specificTimeSlot.startTime.trim() !== '')))
    && assignedTeachers.every((elem) => `${elem.fullName}`.trim() !== '' && elem.role.trim() !== '')
    && (!specificTeacher.isBeingModified || (specificTeacher.isBeingModified && ((specificTeacher.fullName ? specificTeacher.fullName.trim() : '') !== '' && specificTeacher.role.trim() !== '')))
  );

  const handleAddEditASection = () => {
    const areFieldsFilled = allFieldsFilled();
    if (!areFieldsFilled) {
      enqueueSnackbar('Rellene todos los campos habilitados', {
        variant: 'error',
      });
    } else {
      const allTimeSlotsIds = assignedTimeSlots.map((elem) => elem.id);
      if (specificTimeSlot.isBeingModified) {
        const actualTimeSlotId = specificTimeSlot.id;
        setModalValues((prevState) => ({
          ...prevState, timeSlotIds: [...allTimeSlotsIds, actualTimeSlotId],
        }));
      } else {
        setModalValues((prevState) => ({
          ...prevState, timeSlotIds: [...allTimeSlotsIds],
        }));
      }

      const allTeacherInfo = assignedTeachers.map((elem) => ({ id: elem.id, role: elem.role }));
      if (specificTeacher.isBeingModified) {
        const actualTeacherInfo = { id: specificTeacher.id, role: specificTeacher.role };
        setModalValues((prevState) => ({
          ...prevState, teacherInfo: [...allTeacherInfo, actualTeacherInfo],
        }));
      } else {
        setModalValues((prevState) => ({
          ...prevState, teacherInfo: [...allTeacherInfo],
        }));
      }
      setUpsertFlag(true);
    }
  };

  const allTeachers = itemsUI.filter((elem) => elem.label === 'Profesor')[0].selectFields as Teacher[];

  return (
    <UpsertModal<SectionData>
      title={'sección'}
      modalAddEdit={modalAddEdit}
      setModalAddEdit={setModalAddEdit}
      handleCloseModal={handleCloseModal}
      modalValues={modalValues}
      handleFieldChange={handleFieldChange}
      itemsUI={itemsUI}
      handleAddFunction={handleAddEditASection}
      handleEditFunction={handleAddEditASection}
    >
      <CustomTimeSlotComponent
        assignedTimeSlots={assignedTimeSlots}
        setAssignedTimeSlots={setAssignedTimeSlots}
        specificTimeSlot={specificTimeSlot}
        setSpecificTimeSlot={setSpecificTimeSlot}
      />
      <CustomTeacherComponent
        allTeachers={allTeachers || []}
        assignedTeachers={assignedTeachers}
        setAssignedTeachers={setAssignedTeachers}
        specificTeacher={specificTeacher}
        setSpecificTeacher={setSpecificTeacher}
      />
    </UpsertModal>
  );
}

export default UpsertSectionModal;
