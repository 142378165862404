export const BOMB_TOTALS_UI = [
  {
    label: 'Habilidad', key: 'skill', valueType: 'string',
  },
  {
    label: 'Total', key: 'total', valueType: 'string',
  },
];

export type TableColumnHeader = {
  label: string;
  key: string;
  valueType: string;
};

export interface BombSkills {
  skill: number;
  total: number;
}
