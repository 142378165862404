import React from 'react';
import {
  Box,
  MenuItem,
  Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField,
} from '@mui/material';

import { ASSISTANCE_OPTIONS, AssistanceData, AssistanceDataSectionTeacher } from '../types';

import EmptyTable from '../../../../../../components/General/EmptyTable';
import { getDateOnlyFormat } from '../../../../../../utils/helpers';

const StudentAssistancesTable = (
  { data, handleAssistanceChange }:
  { data: { count: number; values: AssistanceData[] }, handleAssistanceChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    elemAssistance: AssistanceData,
  ) => void },
) => (
    <Box>
      {data.count !== 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Programa</TableCell>
                <TableCell>Hora</TableCell>
                <TableCell>Profesor</TableCell>
                <TableCell>Asistencia</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.values.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>{getDateOnlyFormat(row.date, '/', true)}</TableCell>
                  <TableCell>{row.programName}</TableCell>
                  <TableCell>{row.startTime.slice(0, 5)}</TableCell>
                  <TableCell>{row.class.sectionTimeSlot.section?.sectionTeachers.map(
                    ({ teacher }: AssistanceDataSectionTeacher, index: number) => `${index >= 1 ? ', ' : ''}${teacher.user.name} ${teacher.user.last}`,
                  ) ?? '-'}</TableCell>
                  <TableCell>
                    <TextField
                      select
                      fullWidth
                      label="Asistencia"
                      value={row.assistance}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => handleAssistanceChange(event, row)}
                      sx={{ maxWidth: 200 }}
                    >
                      {ASSISTANCE_OPTIONS.map((option) => (
                        <MenuItem key={option.name} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {data.count === 0 && <EmptyTable />}
    </Box>
);

export default StudentAssistancesTable;
