import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from '@mui/icons-material';
import {
  Box,
  ClassNameMap,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import {
  deleteSection,
  getLocations,
  getPrograms,
  getSectionsPerPage,
  getTeachers,
  postCreateSection,
  putUpdateSection,
} from '../../../requests/api/sections';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import useStyles from './styles';
import {
  LocationsData, SECTIONS_UI, SectionData, SectionTeacher, Teacher,
} from './types';
import DeleteModal from '../../../components/General/DeleteModal';
import UpsertSectionModal from './Modals/UpsertSectionModal';
import { ProgramData, SubjectData } from '../../Program/ProgramListView/types';
import { ReduxState } from '../../../types';
import { SECRETARY, TEACHER } from '../../../utils/user_types';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import { setSectionFilters } from '../../../actions/filtersActions';
import { getProgramsPerPage } from '../../../requests/api/programs';
import { getSubjects } from '../../../requests/api/subjects';

const buttonActions = (
  classes: ClassNameMap<string>,
  sectionId: string,
  mipreuId: number,
  userType: string,
  openEditModal: ({ open, id }: { open: boolean, id: string }) => void,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
  handleRedirectSectionDetail: (sectionId: string, mipreuId: number) => void,
) => (<Box>
  <IconButton onClick={() => handleRedirectSectionDetail(sectionId, mipreuId)}>
    <RemoveRedEyeIcon className={classes.icons} />
  </IconButton>
  {userType !== SECRETARY && userType !== TEACHER && <>
    <IconButton onClick={() => openEditModal({ id: sectionId, open: true })}>
      <EditIcon className={classes.icons} />
    </IconButton>
    <IconButton onClick={() => openDeleteModal({ id: sectionId, open: true })}>
      <DeleteIcon className={classes.icons} />
    </IconButton></>}
</Box>);

function SectionListView() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state: ReduxState) => state.account);
  const { sectionFilters } = useSelector((state: ReduxState) => state.filters);
  const [allSections, setAllSections] = useState({
    items: [],
    totalItems: 0,
  });
  const [sectionOptions, setSectionOptions] = useState<{ id: string, name: string }[]>([]);
  const [modalAddEditASection, setModalAddEditASection] = useState(
    { id: '', open: false },
  );
  const [modalDeleteASection, setModalDeleteASection] = useState({ id: '', open: false });
  const [modalValues, setModalValues] = useState<SectionData>({
    id: '',
    name: '',
    subjectId: '',
    programId: '',
    locationId: '',
    classroom: '',
    studentsCapacity: '0/0',
    mipreuId: undefined,
    timeSlotIds: [],
    teacherInfo: [],
  });
  const [allPrograms, setAllPrograms] = useState<{ id: string, name: string }[]>([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState({
    sections: false,
    programs: false,
    otherInfo: false,
  });
  const [sectionsUI, setSectionsUI] = useState(SECTIONS_UI);

  const handleRedirectSectionDetail = (sectionId: string, mipreuId: number) => {
    history.push({ pathname: `/secciones/${sectionId}`, state: { mipreuId } });
  };

  const isTeacher = account?.user && account.user!.userType === TEACHER;

  // Clear filter values when leaving the view.
  useEffect(() => () => {
    dispatch(setSectionFilters({
      Programa: '',
      Asignatura: '',
      rowsPerPage: 5,
      page: 0,
      Seccion: '',
    }));
  }, []);

  useEffect(() => {
    const getAllSections = async () => {
      try {
        let teacherId;
        if (isTeacher) {
          teacherId = account?.user?.userTypeId;
        }
        const { sections, totalSections } = await getSectionsPerPage({
          page: sectionFilters.page,
          limit: sectionFilters.rowsPerPage,
          searchValue: sectionFilters.Seccion,
          sort: 'mipreuId|DESC',
          teacherId,
          programId: sectionFilters.Programa,
        });

        const sectionsAndActions = sections.map((elem: SectionData) => {
          const teachersName = elem.sectionTeachers && elem.sectionTeachers
            .filter((sectionTeacher: SectionTeacher) => sectionTeacher.sectionId === elem.id)
            .map((sectionTeacher: SectionTeacher) => sectionTeacher.teacher.user.fullName);
          return {
            ...elem,
            subjectName: elem.program && elem.program.subject.name,
            programName: elem.program && elem.program.name,
            locationName: elem.location && elem.location.name,
            studentsCapacityStatus: `${elem.studentsCount}/${elem.studentsCapacity}`,
            teacherName: teachersName ?? '',
            actions: buttonActions(
              classes,
              elem.id!,
              elem?.mipreuId ?? 0,
              (account && account.user && account.user.userType) ?? '',
              setModalAddEditASection,
              setModalDeleteASection,
              handleRedirectSectionDetail,
            ),
          };
        });
        setAllSections({ items: sectionsAndActions, totalItems: totalSections });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setHasLoaded((prevState) => ({ ...prevState, sections: true }));
      }
    };

    getAllSections();

    const getProgramValuesForFields = async () => {
      // Get programs
      try {
        const { programs } = await getProgramsPerPage({
          teacherId: account?.user?.userTypeId,
          page: 0,
          limit: 1000,
          searchValue: '',
          sort: 'name|ASC',
        });

        setAllPrograms(programs.map((p: ProgramData) => ({ id: p.id, name: p.name })));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getProgramValuesForFields();

    const getSeccionValuesForFields = async () => {
      // Get sections
      try {
        const { sections } = await getSectionsPerPage({
          teacherId: account?.user?.userTypeId,
          page: 0,
          limit: 1000,
          searchValue: '',
          sort: 'name|ASC',
          programId: sectionFilters.Programa,
        });

        setSectionOptions(sections.map((p: SectionData) => ({ id: p.mipreuId, name: p.mipreuId })));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getSeccionValuesForFields();
  }, [
    sectionFilters.page,
    sectionFilters.rowsPerPage,
    sectionFilters.Seccion,
    sectionFilters.Programa,
    updateTable,
  ]);

  useEffect(() => {
    const getProgramValuesForFields = async () => {
      let programInfo: ProgramData[];
      // Get programs
      try {
        const { programs } = await getPrograms(modalValues.subjectId);
        programInfo = programs;

        const auxSectionsUI = sectionsUI.map((elem) => {
          if (elem.label === 'Programa') {
            elem.selectFields = programInfo;
          }

          return elem;
        });
        setSectionsUI(auxSectionsUI);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setHasLoaded((prevState) => ({ ...prevState, programs: true }));
      }
    };

    getProgramValuesForFields();
  }, [modalValues.subjectId]);

  useEffect(() => {
    // I need to do it on a separate call, as I need ALL the information,
    // not only the one available for the created sections
    const getSelectValuesForFields = async () => {
      let subjectsInfo: SubjectData[];
      let locationsInfo: LocationsData[];
      let teachersInfo: Teacher[];
      // Get subjects
      try {
        const { subjects } = await getSubjects();
        subjectsInfo = subjects;
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      // Get Locations
      try {
        const { locations } = await getLocations();
        locationsInfo = locations;
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      // Get teachers
      try {
        const { teachers } = await getTeachers();
        teachersInfo = teachers;
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      const auxSectionsUI = sectionsUI.map((elem) => {
        if (elem.label === 'Asignatura') {
          elem.selectFields = subjectsInfo;
        }

        if (elem.label === 'Sede') {
          elem.selectFields = locationsInfo;
        }

        if (elem.label === 'Profesor') {
          elem.selectFields = teachersInfo;
        }

        return elem;
      });
      setSectionsUI(auxSectionsUI);
      setHasLoaded((prevState) => ({ ...prevState, otherInfo: true }));
    };

    getSelectValuesForFields();
  }, [modalAddEditASection.open]);

  useEffect(() => {
    if (Object.values(hasLoaded).every((elem) => elem === true)) {
      setShowLoading(false);
    }
  }, [
    hasLoaded.programs, hasLoaded.otherInfo, hasLoaded.sections,
  ]);

  const handleCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setModalValues({
      id: '',
      programId: '',
      locationId: '',
      classroom: '',
      studentsCapacity: '0/0',
      mipreuId: undefined,
      timeSlotIds: [],
      teacherInfo: [],
    });
    setUpdateTable(!updateTable);
  };

  const addASection = async () => {
    try {
      const response = await postCreateSection({
        programId: modalValues.programId,
        locationId: modalValues.locationId,
        classroom: modalValues.classroom,
        studentsCapacity: modalValues.studentsCapacity,
        timeSlotIds: modalValues.timeSlotIds,
        teacherInfo: modalValues.teacherInfo,
        mipreuId: modalValues.mipreuId,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalAddEditASection);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const editASection = async (sectionId: string) => {
    try {
      const response = await putUpdateSection({
        id: sectionId,
        programId: modalValues.programId,
        locationId: modalValues.locationId,
        classroom: modalValues.classroom,
        studentsCapacity: modalValues.studentsCapacity,
        timeSlotIds: modalValues.timeSlotIds,
        teacherInfo: modalValues.teacherInfo,
        mipreuId: modalValues.mipreuId,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalAddEditASection);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const deleteASection = async (sectionId: string) => {
    try {
      const response = await deleteSection(sectionId);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalDeleteASection);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File, source: string,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (e instanceof File) {
      // No use of file here. Just type handling
    } else if (source === 'programId') {
      setModalValues((prevState) => ({
        ...prevState, [source]: (e.target as HTMLInputElement).value,
      }));
    } else {
      setModalValues(
        (prevState) => ({ ...prevState, [source]: (e.target as HTMLInputElement).value }),
      );
    }
  };

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (!value) {
      dispatch(setSectionFilters({ ...sectionFilters, [source]: '' }));
    } else {
      let extraPropsToUpdate = {};
      if (source === 'Programa') extraPropsToUpdate = { Seccion: '' };

      dispatch(setSectionFilters({
        ...sectionFilters,
        [source]: (value as { name: string, id: string }).id,
        ...extraPropsToUpdate,
      }));
    }

    if (sectionFilters.page !== 0) {
      dispatch(setSectionFilters({ ...sectionFilters, page: 0 }));
    }
  };

  return (
    <Page
      title="Secciones"
    >
      <Header
        title='Secciones'
        icon={<SupervisorAccountIcon />}
        buttons={(account && account.user && ![
          SECRETARY, TEACHER].includes(account.user.userType) && !showLoading)
          ? [{ text: 'Crear sección', icon: <AddIcon />, onClick: () => setModalAddEditASection((prevState) => ({ ...prevState, open: true })) }] : undefined}
        autocompletes={!showLoading ? [
          {
            name: 'Programa',
            value: sectionFilters.Programa,
            options: [{ name: 'Todos', id: '' }, ...allPrograms],
            onChange: handleFilterChange,
            keyField: 'Programa',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
          {
            name: 'Sección',
            value: sectionFilters.Seccion,
            options: [{ name: 'Todos', id: '' }, ...sectionOptions],
            onChange: handleFilterChange,
            keyField: 'Seccion',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
        ] : undefined}
      />
      {showLoading ? <CircularProgressComponent /> : <Box
        mt={3}
      >
        {allSections.totalItems !== 0
          && <CustomTable
            headers={sectionsUI.filter((elem) => elem.showOnTable)}
            data={{ values: allSections.items, count: allSections.totalItems }}
            reduxTableParameters={sectionFilters}
            setReduxTableParameters={setSectionFilters}
          />}
        {allSections.totalItems === 0 && <EmptyTable />}
      </Box>}

      <UpsertSectionModal
        allItems={allSections}
        modalAddEdit={modalAddEditASection}
        setModalAddEdit={setModalAddEditASection}
        handleCloseModal={handleCloseModal}
        modalValues={modalValues}
        setModalValues={setModalValues}
        handleFieldChange={handleFieldChange}
        itemsUI={sectionsUI}
        addFunction={addASection}
        editFunction={editASection}
      />
      <DeleteModal
        title="sección"
        modalDelete={modalDeleteASection}
        setModalDelete={setModalDeleteASection}
        handleCloseModal={handleCloseModal}
        deleteFunction={deleteASection}
      />
    </Page>
  );
}

export default SectionListView;
