import { CancelToken } from 'axios';
import { RequestsPerPage } from '../types';
import axios from '../../utils/axios';
import {
  RowData as RepresentativeData,
} from '../../views/Representative/RepresentativeListView/types';

const getRepresentativesPerPage = async ({
  page, limit, searchValue, sort,
}: RequestsPerPage, cancelToken?: CancelToken) => {
  const response = await axios.get('/api/representatives', {
    params: {
      page,
      limit,
      searchValue,
      sort,
    },
    cancelToken,
  });

  return response.data;
};

const postCreateRepresentative = async ({
  rut,
  phoneNumber,
  name,
  lastname,
  email,
  birthDate,
  nationality,
  maritalStatus,
  profession,
  address,
  commune,
}: RepresentativeData) => {
  const response = await axios.post('/api/representatives', {
    rut,
    phoneNumber,
    name,
    lastname,
    email,
    birthDate,
    nationality,
    maritalStatus,
    profession,
    address,
    commune,
  });

  return response.data;
};

const putUpdateRepresentative = async ({
  id,
  rut,
  phoneNumber,
  name,
  lastname,
  birthDate,
  nationality,
  maritalStatus,
  profession,
  address,
  commune,
}: {
  id: string,
  rut: string,
  phoneNumber: string,
  name: string,
  lastname: string,
  birthDate: Date | string,
  nationality: string,
  maritalStatus: string,
  profession: string,
  address: string,
  commune: string,
}) => {
  const response = await axios.put(`/api/representatives/${id}`, {
    rut,
    phoneNumber,
    name,
    lastname,
    birthDate,
    nationality,
    maritalStatus,
    profession,
    address,
    commune,
  });

  return response.data;
};

const deleteRepresentative = async (id: string) => {
  const response = await axios.delete(`/api/representatives/${id}`);
  return response.data;
};

export {
  getRepresentativesPerPage,
  postCreateRepresentative,
  putUpdateRepresentative,
  deleteRepresentative,
};
