import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  disableTableCells: {
    color: 'rgb(172,172,172)',
    backgroundColor: 'rgb(211,211,211, 0.2)',
  },
}));

export default useStyles;
