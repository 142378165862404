import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Add as AddIcon, ArrowBackIos as ArrowBackIosIcon, Edit as EditIcon } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router';
import { CustomTextField } from '../../../../../../components/General/CustomTextfields';
import useStyles from '../../../styles';
import CustomButton from '../../../../../../components/General/CustomButton';

interface LocationState {
  studentName: string;
  programName: string;
  studentId: string;
}

function SectionsAssociationHeader({
  sectionToSearch,
  setSectionToSearch,
  handleUpsertProgramSection,
  isEditSection,
}: {
  sectionToSearch: string,
  setSectionToSearch: (valueToSet: string) => void,
  handleUpsertProgramSection: () => void,
  isEditSection: boolean,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<LocationState>();

  const studentName = (location.state && location.state.studentName) ? `${location.state.studentName}` : '';
  const programName = (location.state && location.state.programName) ? `${location.state.programName}` : '';
  const studentId = (location.state && location.state.studentId) ? `${location.state.studentId}` : '';

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
    >
      <Grid item className={classes.contractUpsertHeaderTitleContainer}>
        <Button
          className={classes.contractUpsertHeaderBackButton}
          onClick={() => history.replace(`/alumnos/${studentId}`, { activeTab: 1 })}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          ml={1}
          variant="h3"
          fontWeight='bold'
          display='flex'
          alignItems='center'
        >
          {`${studentName} `}
        </Typography>
        <Typography
          ml={1}
          variant="h3"
          display='flex'
          alignItems='center'
        >
          {`- ${programName}`}
        </Typography>
      </Grid>
      <Grid item display="flex" alignItems="center" >
        <Grid item marginRight='30px' >
          <CustomTextField
            keyField='section'
            name='Buscar seccion'
            onChangeFunction={(
              e: React.ChangeEvent<HTMLInputElement> | Date,
            ) => setSectionToSearch((e as React.ChangeEvent<HTMLInputElement>).target.value)}
            value={sectionToSearch}
          />
        </Grid>
        <Grid item>
          <CustomButton
            colorType='tertiary'
            text={isEditSection ? 'Confirmar edición' : 'Inscribir sección'}
            icon={ isEditSection ? <EditIcon /> : <AddIcon />}
            onClick={handleUpsertProgramSection}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SectionsAssociationHeader;
