export const INSTITUTION_USER_TEST_RESULT = [
  {
    label: 'Número de pregunta', key: 'number', typeField: 'text', valueType: 'string',
  },
  {
    label: 'Respuesta ingresada', key: 'userAnswer', typeField: 'text', valueType: 'string',
  },
  {
    label: 'Respuesta correcta', key: 'correctAnswer', typeField: 'text', valueType: 'string',
  },
  {
    label: 'Descargar solución', key: 'solutionButton', valueType: 'other',
  },
];

export type QuestionRow = {
  number: number,
  userAnswer: string,
  correctAnswer: string,
  solution: string,
  solutionButton?: JSX.Element,
  correct: boolean,
  incorrect: boolean,
  omitted: boolean,
};

export type QuestionRows = QuestionRow[];

export type Questions = {
  rows: QuestionRows,
  count: number,
};

export interface InstitutionSessionUserTestResult {
  test: {
    name: string,
    durationMinutes: number,
    pdfUrl: string,
    solutionUrl: string,
    subject: { name: string },
    answers: {
      correct: number,
      incorrect: number,
      omitted: number,
    },
    questions: Questions,
  },
  competence: string,
}
