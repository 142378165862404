import React from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { DropdownTeacherInfo, SpecificDropdownTeacherInfo, Teacher } from '../types';
import useStyles from '../styles';

type TeacherComponentProps = {
  allTeachers: Teacher[],
  assignedTeachers: DropdownTeacherInfo[],
  setAssignedTeachers: React.Dispatch<React.SetStateAction<Array<DropdownTeacherInfo>>>,
  specificTeacher: SpecificDropdownTeacherInfo,
  setSpecificTeacher: React.Dispatch<React.SetStateAction<SpecificDropdownTeacherInfo>>,
};

function CustomTeacherComponent({
  allTeachers,
  assignedTeachers,
  setAssignedTeachers,
  specificTeacher,
  setSpecificTeacher,
}: TeacherComponentProps) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const removeCurrentTeacher = (teacherId: string) => {
    const auxAssignedTeacher = assignedTeachers.filter((elem) => `${elem.id}` !== `${teacherId}`);
    setAssignedTeachers(auxAssignedTeacher);
  };

  const removeNewTeacher = () => {
    setSpecificTeacher({
      isBeingModified: false,
      id: '',
      fullName: '',
      role: '',
    });
  };

  const handleAddingNewTeacher = () => {
    if (specificTeacher.fullName) {
      setAssignedTeachers((prevState) => [...prevState, {
        id: specificTeacher.id,
        fullName: specificTeacher.fullName,
        role: specificTeacher.role,
      }]);
      setSpecificTeacher({
        isBeingModified: true,
        id: '',
        fullName: '',
        role: '',
      });
    } else {
      setSpecificTeacher((prevState) => ({ ...prevState, isBeingModified: true }));
    }
  };

  const handleTeacherChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    source: string,
  ) => {
    if (source === 'role') {
      setSpecificTeacher((prevState) => ({ ...prevState, role: e.target.value }));
    } else {
      if (assignedTeachers.some((elem) => elem.id.toString() === e.target.value.toString())) {
        enqueueSnackbar('El mismo profesor no puede ser asignado a la misma seccion más de una vez', { variant: 'error' });
        return;
      }

      // Get the times from the id
      const auxTeacher = allTeachers.filter((elem) => `${elem.id}` === e.target.value.toString());
      setSpecificTeacher((prevState) => ({
        ...prevState,
        id: auxTeacher[0].id,
        fullName: auxTeacher[0].user ? auxTeacher[0].user.fullName : '',
      }));
    }
  };

  const teachersUI = ({
    id, role,
  }: { id: string; role: string; }) => {
    let deleteFunction;
    const isTeacherAlreadySelected = assignedTeachers.some((elem) => elem.id === id);
    if (isTeacherAlreadySelected) {
      deleteFunction = () => removeCurrentTeacher(id);
    } else {
      deleteFunction = () => removeNewTeacher();
    }
    return (
      <TableRow key={`row-item-teacher-${id}`}>
        <TableCell>
          <TextField
            disabled={isTeacherAlreadySelected}
            key={`Nombre-Apellido-${id}`}
            select
            fullWidth
            name="Nombre Apellido"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTeacherChange(e, 'fullName')}
          >
            {allTeachers.map((option) => (
              <MenuItem key={`seleccion-nombre-apellido-${option.id}`} value={option.id}>
                {`${option.user ? option.user.fullName : ''}`}
              </MenuItem>))}
          </TextField>
        </TableCell>
        <TableCell>
          <TextField
            disabled={isTeacherAlreadySelected}
            key={`Rol-${id}`}
            fullWidth
            name="Rol"
            value={role}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTeacherChange(e, 'role')}
          />
        </TableCell>
        <TableCell >
          <IconButton onClick={deleteFunction}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <Typography variant='h4' fontWeight='bold' mt={2} mb={3}>Profesores</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.colWidth}>Nombre Apellido</TableCell>
            <TableCell className={classes.colWidth}>Rol</TableCell>
            <TableCell className={classes.iconColWidth} />
          </TableRow>
        </TableHead>
        <TableBody>
          {assignedTeachers.length > 0
            && assignedTeachers.map((elem: { id: string, role: string }) => teachersUI({
              id: elem.id,
              role: elem.role,
            }))
          }
          {specificTeacher.isBeingModified && teachersUI({
            id: specificTeacher.id ?? '',
            role: specificTeacher.role ?? '',
          })}
          <TableRow>
            <TableCell colSpan={3}>
              <IconButton onClick={handleAddingNewTeacher}>
                <AddIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default CustomTeacherComponent;
