import {
  Box,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import React from 'react';
import useStyles from '../../../styles';

const HeaderSummaryAnswers = ({ correctAnswers }: { correctAnswers: string }) => {
  const classes = useStyles();
  return (
    <Box mt={2} mb={2} display='flex' width='100%' justifyContent="space-between" alignItems="center">
      <Box>
        <Typography
          variant="h4"
          className={classes.textBodyColor}
        >
          {<><strong>Respuestas correctas: </strong><span>{correctAnswers}</span></>}
        </Typography>
      </Box>
      <Box>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox readOnly checked={true} disableRipple />
            }
            label="Respuesta correcta"
          />
          <FormControlLabel
            control={
              <Checkbox readOnly checked={true} color='error' disableRipple />
            }
            label="Respuesta incorrecta"
          />
          <FormControlLabel
            control={
              <Checkbox readOnly checked={true} color='secondary' disableRipple />
            }
            label="Clave"
          />
        </FormGroup>
      </Box>
    </Box>
  );
};

export default HeaderSummaryAnswers;
