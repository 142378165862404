type Program = {
  id?: string;
  subjectId: number;
  name: string;
  level: ProgramLevel;
  classYear: ProgramClassYear;
  startDate: Date;
  endDate: Date;
  active: boolean;
  fee: number;
  programContents?: {
    number: string;
    name: string;
    url: string;
  }[];
};

type ProgramContentData = {
  id?: string;
  programId?: string;
  number: string;
  name: string;
  url: string;
};

type ProgramData = {
  id?: string;
  subjectId: string;
  name: string | undefined;
  level: string | undefined;
  classYear: string | undefined;
  startDate: Date;
  endDate: Date;
  fee: string;
  subjectName?: string;
  active: string;
  programContents?: Array<ProgramContentData>;
};

type SubjectData = {
  id: string;
  name: string;
};

enum ProgramLevel {
  basico = 'Normal',
  medio = 'Intermedio',
  avanzado = 'Avanzado',
}

enum ProgramClassYear {
  cuartoMedio = 'IV Medio',
  terceroMedio = 'III Medio',
  graduado = 'Egresados',
}

const levelSelectFields = [
  { id: 0, name: ProgramLevel.basico },
  { id: 1, name: ProgramLevel.medio },
  { id: 2, name: ProgramLevel.avanzado }];
const classYearSelectFields = [
  { id: 0, name: ProgramClassYear.terceroMedio },
  { id: 1, name: ProgramClassYear.cuartoMedio },
  { id: 2, name: ProgramClassYear.graduado }];
const activeSelectFields = [
  { id: 0, name: 'Activo' },
  { id: 1, name: 'Inactivo' },
];
const PROGRAMS_UI = [
  {
    label: 'ID', key: 'id', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Asignatura', key: 'subjectName', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: [], valueType: 'string',
  },
  {
    label: 'Nombre', key: 'name', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Nivel', key: 'level', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: levelSelectFields, valueType: 'string',
  },
  {
    label: 'Curso', key: 'classYear', showOnTable: true, showOnModal: true, typeField: 'select', selectFields: classYearSelectFields, valueType: 'string',
  },
  {
    label: 'Fecha inicio', key: 'startDate', showOnTable: true, showOnModal: true, typeField: 'date', valueType: 'date',
  },
  {
    label: 'Fecha fin', key: 'endDate', showOnTable: true, showOnModal: true, typeField: 'date', valueType: 'date',
  },
  {
    label: 'Nº de alumnos', key: 'studentsCount', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Arancel', key: 'fee', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'ccy',
  },
  {
    label: 'Acciones', key: 'actions', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
  {
    label: 'Activo', key: 'active', showOnTable: false, showOnModal: true, typeField: 'select', selectFields: activeSelectFields, valueType: 'string',
  },
];

export {
  Program,
  ProgramContentData,
  ProgramData,
  SubjectData,
  ProgramLevel,
  ProgramClassYear,
  PROGRAMS_UI,
};
