import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { format } from 'rut.js';

import { Container } from '@mui/system';
import { InstitutionUser } from '../../types';

import useStyles from './styles';

type InstitutionUserDetailHeaderProps = {
  student: InstitutionUser;
};

function InstitutionUserDetailHeader({
  student,
}: InstitutionUserDetailHeaderProps) {
  const classes = useStyles();

  return (
    <Container className={classes.titleContainer}>
      <Typography
        variant="h4"
        className={classes.studentName}
      >
        <span style={{ whiteSpace: 'nowrap' }}>{student.name} {student.lastname}</span>
      </Typography>
      <Typography
        variant="h4"
        className={classes.studentInfo}
      >
        {<><strong>Rut: </strong><span style={{ whiteSpace: 'nowrap' }}>{format(student.rut)}</span></>}
      </Typography>
      <Typography
        variant="h4"
        className={classes.studentInfo}
      >
        {<><strong>Teléfono: </strong><span>{student.phoneNumber}</span></>}
      </Typography>
      <Typography
        variant="h4"
        className={classes.studentInfo}
      >
        {<><strong>Email: </strong><span>{student.email}</span></>}
      </Typography>
    </Container>
  );
}

InstitutionUserDetailHeader.propTypes = {
  className: PropTypes.string,
};

export default InstitutionUserDetailHeader;
