import {
  ACADEMIC, ADMINISTRATOR, REPRESENTATIVE, SECRETARY, STUDENT, TEACHER,
} from '../utils/user_types';

const fallbackRoutes:{ [key: string]: string } = {
  [ADMINISTRATOR]: '/programas',
  [STUDENT]: '/alumnos',
  [REPRESENTATIVE]: '/alumnos',
  [ACADEMIC]: '/programas',
  [TEACHER]: '/programas',
  [SECRETARY]: '/programas',
};

export default fallbackRoutes;
