import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Box, ClassNameMap, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import Page from '../../../components/Layout/Page';
import ProgramDetailHeader from './Components/ProgramDetailHeader';
import CustomTable from '../../../components/General/CustomTable';
import EmptyTable from '../../../components/General/EmptyTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import {
  deleteProgramTest,
  getProgramTestOverallInfo,
  getProgramTestsByProgramId,
  getQuestionAnswersSummary,
  getTestsBySubjectId,
  putProgramTests,
} from '../../../requests/api/programs';
import useStyles from './styles';
import DeleteModal from '../../../components/General/DeleteModal';
import UpsertModal from '../../../components/General/UpsertModal';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';

type ProgramsTableType = {
  testNumber: string,
  testName: string,
  deliverDate: Date | string,
  numberDeliveries: Array<number>,
  averageScore: Array<number>,
  actions: JSX.Element
};

type ProgramTest = {
  id: string,
  programId: string,
  testId: string,
  number: string,
  deliverDate: Date | string,
  isActive: boolean,
  test: {
    id: string,
    programId: string,
    name: string,
    numberOfQuestions: number,
  }
  program: {
    id: string,
    programId: string,

  }
};

const showFractionNumber = (array: number[]) => [<span key={Math.round(Math.random() * 100)}>{`${array[0]}/${array[1]}`}</span>];

const TEST_PROGRAMS_TABLE_UI = [{
  label: 'Número', key: 'testNumber', valueType: 'string',
},
{
  label: 'Nombre', key: 'testName', valueType: 'string',
},
{
  label: 'Fecha entrega', key: 'deliverDate', valueType: 'string',
},
{
  label: 'Número de entregas', key: 'numberDeliveries', valueType: 'custom', functionToApply: showFractionNumber,
},
{
  label: 'Buenas promedio', key: 'averageScore', valueType: 'custom', functionToApply: showFractionNumber,
},
{
  label: 'Malas promedio', key: 'incorrectAvgScore', valueType: 'custom', functionToApply: showFractionNumber,
},
{
  label: 'Omitidas promedio', key: 'omittedAvgScore', valueType: 'custom', functionToApply: showFractionNumber,
},
{
  label: 'Acciones', key: 'actions', valueType: 'other',
},
];

const PROGRAM_TESTS_MODAL_UI = [{
  label: 'Número', key: 'testNumber', typeField: 'number', showOnModal: true,
},
{
  label: 'Guía', key: 'testId', typeField: 'autocomplete', valueToRetrieveFromSelect: 'id', selectFields: [], showOnModal: true,
},
{
  label: 'Fecha programada', key: 'deliverDate', typeField: 'date', showOnModal: true,
},
{
  label: 'Estado Guia', key: 'isActive', typeField: 'switch', showOnModal: true,
},
];

const buttonActions = (
  classes: ClassNameMap<string>,
  programTestId: string,
  openEditModal: ({ open, id }: { open: boolean, id: string }) => void,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
) => (<Box>
  <IconButton onClick={() => openEditModal({ id: programTestId, open: true })}>
    <EditIcon className={classes.icons} />
  </IconButton>
  <IconButton onClick={() => openDeleteModal({ id: programTestId, open: true })}>
    <DeleteIcon className={classes.icons} />
  </IconButton>
</Box>);

const ProgramDetailView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: programId } = useParams<{ id: string }>();
  const classes = useStyles();
  const [allProgramTests, setAllProgramTests] = useState<ProgramTest[]>([]);
  const [programTestsTableInfo, setProgramTestsTableInfo] = useState<ProgramsTableType[]>([]);
  const [programInfo, setProgramInfo] = useState({
    programName: '',
    subjectName: '',
    numberStudents: 0,
  });
  const [questionAnswersInfo, setQuestionAnswersInfo] = useState<{
    [key: string]: {
      numberOfDeliveries: number;
      numberOfQuestions: number;
      averageOfCorrectAnswers: number;
      averageOfIncorrectAnswers: number;
      averageOfOmittedAnswers: number;
    }
  }>({});
  const [modalAssociateTest, setModalAssociateTest] = useState({
    open: false,
    id: '',
  });
  const [modalDeleteProgramTest, setModalDeleteProgramTest] = useState({
    open: false,
    id: '',
  });

  const [showLoading, setShowLoading] = useState(true);
  const [programTestsModalUI, setProgramTestsModalUI] = useState(PROGRAM_TESTS_MODAL_UI);
  const [modalValues, setModalValues] = useState<{
    name: '',
    testNumber: string,
    testId: string,
    programTestId: string,
    deliverDate: string,
    numberOfDeliveries: string,
    isActive: boolean,
  }>({
    name: '',
    programTestId: '',
    testNumber: '',
    testId: '',
    deliverDate: '',
    numberOfDeliveries: '',
    isActive: true,
  });
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const handleCloseModal = () => {
    setModalAssociateTest({
      open: false,
      id: '',
    });
    setModalValues({
      name: '',
      testNumber: '',
      programTestId: '',
      testId: '',
      deliverDate: '',
      numberOfDeliveries: '',
      isActive: true,
    });
    setUpdateTrigger(!updateTrigger);
  };

  const handleCloseDeleteModal = () => {
    setModalDeleteProgramTest({
      open: false,
      id: '',
    });
    setUpdateTrigger(!updateTrigger);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date | File,
    source: string, value?: { name: string, id: string } | null,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (e) {
      if (value && source === 'testId') {
        setModalValues(
          (prevState) => ({
            ...prevState, testId: value.id,
          }),
        );
      } else if (source === 'isActive') {
        setModalValues(
          (prevState) => ({
            ...prevState,
            isActive: (e as React.ChangeEvent<HTMLInputElement>).target.checked,
          }),
        );
      } else {
        setModalValues(
          (prevState) => ({
            ...prevState,
            [source]: (e as React.ChangeEvent<HTMLInputElement>).target.value,
          }),
        );
      }
    }
  };

  const getAllQuestionsSummary = async () => getQuestionAnswersSummary(programId);

  useEffect(() => {
    const getAllInitialInformation = async () => {
      try {
        await getAllProgramTestsByProgramId();
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      // Get information of the program and number of students
      let subjectId = '';
      try {
        const {
          programInfo: dbProgramInfo,
          totalStudentsNumber,
        } = await getProgramTestOverallInfo(programId);

        subjectId = dbProgramInfo.subject.id;
        setProgramInfo({
          programName: dbProgramInfo.name,
          subjectName: dbProgramInfo.subject.name,
          numberStudents: totalStudentsNumber,
        });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      // Question summary retrieval
      try {
        const { questionAnswerSummary } = await getAllQuestionsSummary();
        setQuestionAnswersInfo(questionAnswerSummary);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      // Get all the tests for the modal
      try {
        const { allTests } = await getTestsBySubjectId(subjectId);
        programTestsModalUI.map((elem) => {
          if (elem.label === 'Guía') {
            elem.selectFields = allTests;
          }
          return elem;
        });
        setProgramTestsModalUI(programTestsModalUI);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      setShowLoading(false);
    };

    getAllInitialInformation();
  }, []);

  const handleUpsertProgramTest = async () => {
    try {
      const response = await putProgramTests({
        programId,
        programTestId: modalValues.programTestId !== '' ? modalValues.programTestId : '0',
        testId: modalValues.testId,
        number: modalValues.testNumber,
        deliverDate: modalValues.deliverDate,
        isActive: modalValues.isActive,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal();
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleDeleteFunction = async () => {
    try {
      const response = await deleteProgramTest(modalDeleteProgramTest.id);
      handleApiResponse(enqueueSnackbar, response, true);
      setModalDeleteProgramTest({
        open: false,
        id: '',
      });
      setUpdateTrigger((prevState) => !prevState);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  useEffect(() => {
    updateTableFunction();
  }, [programInfo, allProgramTests, questionAnswersInfo]);

  useEffect(() => {
    if (modalAssociateTest.id !== '') {
      // Case we are creating a new record
      if (modalAssociateTest.id === '0') {
        const maxValue = allProgramTests?.length > 0 ? (Math.max(...allProgramTests.map((elem) => parseInt(elem.number, 10))) + 1).toString() : '';
        setModalValues({
          ...modalValues,
          testNumber: maxValue,
        });
        return;
      }

      const auxValues = allProgramTests.find((elem) => elem.id
        === modalAssociateTest.id);

      setModalValues({
        ...modalValues,
        programTestId: modalAssociateTest.id,
        testNumber: auxValues?.number ?? '0',
        testId: auxValues?.testId ?? '',
        deliverDate: (typeof auxValues?.deliverDate === 'string') ? auxValues.deliverDate : auxValues?.deliverDate?.toISOString() ?? '',
        isActive: auxValues?.isActive ?? false,
      });
    }
  }, [modalAssociateTest.id]);

  const getAllProgramTestsByProgramId = async () => {
    // Get information of the programTests for table
    try {
      const { programTests } = await getProgramTestsByProgramId(programId);
      setAllProgramTests(programTests);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const updateTableFunction = () => {
    const auxProgramTests = allProgramTests.map((
      elem: ProgramTest,
    ) => ({
      testNumber: elem.number,
      testName: elem.test.name,
      deliverDate: elem.deliverDate,
      numberDeliveries: [
        questionAnswersInfo[elem.testId]?.numberOfDeliveries ?? 0,
        programInfo.numberStudents,
      ],
      averageScore: [
        questionAnswersInfo[elem.testId]?.averageOfCorrectAnswers
          ? Math.round(questionAnswersInfo[elem.testId].averageOfCorrectAnswers)
          : 0,
        questionAnswersInfo[elem.testId]?.numberOfQuestions] ?? 0,
      incorrectAvgScore: [
        questionAnswersInfo[elem.testId]?.averageOfIncorrectAnswers
          ? Math.round(questionAnswersInfo[elem.testId].averageOfIncorrectAnswers)
          : 0,
        questionAnswersInfo[elem.testId]?.numberOfQuestions] ?? 0,
      omittedAvgScore: [
        questionAnswersInfo[elem.testId]?.averageOfOmittedAnswers
          ? Math.round(questionAnswersInfo[elem.testId].averageOfOmittedAnswers)
          : 0,
        questionAnswersInfo[elem.testId]?.numberOfQuestions] ?? 0,
      actions: buttonActions(
        classes,
        elem.id,
        setModalAssociateTest,
        setModalDeleteProgramTest,
      ),
    }));
    setProgramTestsTableInfo(auxProgramTests);
  };

  useEffect(() => {
    const updateTable = async () => {
      try {
        await getAllProgramTestsByProgramId();
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      // Question summary retrieval
      try {
        const { questionAnswerSummary } = await getAllQuestionsSummary();
        setQuestionAnswersInfo(questionAnswerSummary);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      updateTableFunction();
    };

    updateTable();
  }, [updateTrigger]);

  return (
    <Page
      title="Programas | Asociar"
    >
      {showLoading ? <CircularProgressComponent /> : <>
        <ProgramDetailHeader
          numberStudents={programInfo.numberStudents ?? 0}
          programName={programInfo.programName ?? ''}
          subjectName={programInfo.subjectName ?? ''}
          setModalAssociateTest={setModalAssociateTest}
        />
        <Box
          mt={3}
        >
          {programTestsTableInfo.length !== 0
            && <CustomTable
              headers={TEST_PROGRAMS_TABLE_UI}
              data={{ values: programTestsTableInfo, count: programTestsTableInfo.length }}
            />}
          {programTestsTableInfo.length === 0 && <EmptyTable />}
        </Box>
      </>}
      <UpsertModal
        handleAddFunction={handleUpsertProgramTest}
        handleCloseModal={handleCloseModal}
        handleEditFunction={handleUpsertProgramTest}
        handleFieldChange={handleFieldChange}
        itemsUI={programTestsModalUI}
        modalAddEdit={modalAssociateTest}
        setModalAddEdit={() => { }}
        modalValues={modalValues}
        title='asociación'
      />
      <DeleteModal
        deleteFunction={handleDeleteFunction}
        handleCloseModal={handleCloseDeleteModal}
        modalDelete={modalDeleteProgramTest}
        setModalDelete={setModalDeleteProgramTest}
        title='asociación guía'
        message='Estás a punto de eliminar esta asociacion entre la guía y el programa. ¿Estas seguro de quere continuar?'
      />
    </Page>
  );
};

export default ProgramDetailView;
