import { SyntheticEvent } from 'react';
import { UserData } from '../../../requests/types';
import { COMMUNES, NATIONALITIES, MARITAL_STATUS } from '../../types';

type RowData = {
  id?: string;
  userId?: string;
  rut: string;
  phoneNumber: string;
  name: string;
  lastname: string;
  email: string;
  user?: UserData;
  birthDate: Date | string;
  nationality: string;
  maritalStatus: string;
  profession: string;
  address: string;
  commune: string;
};

type InsertModalTypes = {
  modalAdd: { id: string, open: boolean },
  setModalAdd: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>,
  handleCloseModal: (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => void,
  modalValues: RowData,
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date | File,
    source: string
  ) => void,
  itemsUI: {
    label: string;
    key: string;
    showOnTable: boolean;
    showOnModal: boolean;
    typeField: string;
    selectFields?: object[];
    valueType: string;
  }[],
  addFunction: () => void,
};

const ITEMS_UI = [
  {
    label: 'Nombre', key: 'name', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Apellidos', key: 'lastname', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Rut', key: 'rut', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Teléfono', key: 'phoneNumber', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Email', key: 'email', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Fecha de nacimiento', key: 'birthDate', showOnTable: true, showOnModal: true, typeField: 'date', valueType: 'shortDate',
  },
  {
    label: 'Nacionalidad', key: 'nationality', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: NATIONALITIES,
  },
  {
    label: 'Estado civil', key: 'maritalStatus', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: MARITAL_STATUS,
  },
  {
    label: 'Profesión', key: 'profession', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Dirección', key: 'address', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Comuna', key: 'commune', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: COMMUNES,
  },
  {
    label: 'Ver más', key: 'actions', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
];

const ITEMS_EDIT_UI = [
  {
    label: 'Nombre', key: 'name', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Apellidos', key: 'lastname', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Rut', key: 'rut', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Teléfono', key: 'phoneNumber', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Email', key: 'email', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string', disabled: true,
  },
  {
    label: 'Fecha de nacimiento', key: 'birthDate', showOnTable: true, showOnModal: true, typeField: 'date', valueType: 'shortDate',
  },
  {
    label: 'Nacionalidad', key: 'nationality', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: NATIONALITIES,
  },
  {
    label: 'Estado civil', key: 'maritalStatus', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: MARITAL_STATUS,
  },
  {
    label: 'Profesión', key: 'profession', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Dirección', key: 'address', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Comuna', key: 'commune', showOnTable: true, showOnModal: true, typeField: 'autocomplete', valueType: 'string', selectFields: COMMUNES,
  },
  {
    label: 'Ver más', key: 'actions', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
];

export {
  RowData,
  InsertModalTypes,
  ITEMS_UI,
  ITEMS_EDIT_UI,
};
