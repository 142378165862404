import produce, { Draft } from 'immer';
import {
  ClassesAssistancesFiltersPayload,
  CustomActionFilterState,
  FiltersState,
  MassivesFiltersPayload,
  ProgramsFiltersPayload,
  SectionFiltersPayload,
  TestsFiltersPayload,
} from '../types';
import {
  SET_CLASSES_ASSISTANCES_FILTERS,
  SET_MASSIVES_FILTERS,
  SET_PROGRAMS_FILTERS,
  SET_SECTION_FILTERS,
  SET_TESTS_FILTERS,
} from '../actions/filtersActions';

const iniState: FiltersState = {
  sectionFilters: {
    Programa: '',
    Asignatura: '',
    rowsPerPage: 5,
    page: 0,
    Seccion: '',
  },
  programsFilters: {
    subjectId: '',
    rowsPerPage: 5,
    page: 0,
    searchValue: '',
  },
  testsFilters: {
    subjectId: '',
    rowsPerPage: 5,
    page: 0,
    searchValue: '',
  },
  classesAssistancesFilters: {
    registered: '',
    searchValue: '',
    page: 0,
    rowsPerPage: 5,
    Seccion: '',
  },
  massivesFilters: {
    sectionId: '',
    programName: 'Todos',
    searchValue: '',
    page: 0,
    rowsPerPage: 5,
  },
};

const filtersReducer = (
  state = iniState,
  action: CustomActionFilterState,
): FiltersState => {
  switch (action.type) {
    case SET_SECTION_FILTERS: {
      return produce(state, (draft: Draft<FiltersState>) => {
        draft.sectionFilters = { ...(action.payload as SectionFiltersPayload) };
      });
    }

    case SET_PROGRAMS_FILTERS: {
      return produce(state, (draft: Draft<FiltersState>) => {
        draft.programsFilters = { ...(action.payload as ProgramsFiltersPayload) };
      });
    }

    case SET_TESTS_FILTERS: {
      return produce(state, (draft: Draft<FiltersState>) => {
        draft.testsFilters = { ...(action.payload as TestsFiltersPayload) };
      });
    }

    case SET_CLASSES_ASSISTANCES_FILTERS: {
      return produce(state, (draft: Draft<FiltersState>) => {
        draft.classesAssistancesFilters = {
          ...(action.payload as ClassesAssistancesFiltersPayload),
        };
      });
    }

    case SET_MASSIVES_FILTERS: {
      return produce(state, (draft: Draft<FiltersState>) => {
        draft.massivesFilters = { ...(action.payload as MassivesFiltersPayload) };
      });
    }

    default: {
      return state;
    }
  }
};

export default filtersReducer;
