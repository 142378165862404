import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  noInstitutionRoot: {
    display: 'flex',
    height: '100%',
    width: '100%',
    '& div': {
      width: '100vw',
      maxWidth: '100vw',
    },
  },
  noInstitutionMessage: {
    fontSize: 'clamp(16px, 1.3vw, 50px)',
    textAlign: 'center',
  },
  noInstitutionMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 'clamp(200px, 21vw, 500px)',
      marginBottom: '40px',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '10px',
      borderRadius: '5px',
    },
  },
  infoContainer: {
    position: 'absolute',
    width: 'clamp(300px, 37vw, 800px)',
    color: '#FFF',
    '& p': {
      fontWeight: 400,
      lineHeight: '24.08px',
    },
  },
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    '& a': {
      textAlign: 'center',
      display: 'block',
      color: theme.palette.primary.white,
      textDecoration: 'none',
      fontSize: '14px',
    },
  },
  imageContainer: {
    position: 'relative',
    width: '55vw',
    minHeight: '100%',
    backgroundImage: 'url(https://s3-alpha-sig.figma.com/img/3770/8755/7341b3586e2937eb71e0732ca2901214?Expires=1719187200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CZl6W676W9yFVJDupC-Z-aPyI8mdcHPcKm5V9h2IGOAoWFASDMIdOZF2xEH2MbglDiyQhReb74gXvLJiSRoqZXHIYmQWoc6LARx7B6B2A0cfAcwv0Xe3XNHli0qvCUFhEzKbtY7tb6TfIuWiApDG8w4riTzuluEZXsIkl-09aq40W~yMul~stnY6vxW8T43TDYNzHomqi3xWrqSV0lrrCvhucLaooI~-b-Wo8kM~HVQu4Au0vx0hGWWqYlySghcmSRr4psw5wIqwexSeOORWRay86~Mh~1h7s6fwJhuDn1wvPysgb3zQzjWJZHgWO0X9hVDboekcibfOgt-VQcQp0Q__)',
    backgroundSize: 'cover',
    backgroundPosition: '-200px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blueBox: {
    opacity: 0.9,
    backgroundColor: '#2C82B3',
    width: '100%',
    height: '100%',
  },
  loginTitle: {
    fontSize: 'clamp(30px, 2vw, 50px)',
    fontWeight: 400,
    lineHeight: 'clamp(35px, 3vw, 55px)',
    textAlign: 'center',
    marginBottom: '50px',
    '& span': {
      color: '#F2AC32',
    },
  },
  loginParagraph: {
    marginTop: '10px',
  },
  formContainer: {
    width: '45vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputsContainer: {
    width: 'clamp(200px, 22vw, 500px)',
    '& .MuiTextField-root': {
      marginTop: '10px',
    },
    '& .MuiAutocomplete-root': {
      marginBottom: '12px',
    },
  },
  loginMessage: {
    color: '#E94745',
    fontSize: 'clamp(12px, 1vw, 16px)',
    fontWeight: 400,
    lineHeight: '19.26px',
    textAlign: 'center',
    marginBottom: '10px',
  },
  institutionUserMessage: {
    fontSize: 'clamp(20px, 1.6vw, 60vw)',
    fontWeight: 400,
    lineHeight: 'clamp(28px, 3vw, 68px)',
    textAlign: 'center',
    color: '#2C82B3',
  },
  logosContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 'clamp(200px, 21vw, 500px)',
      marginBottom: '40px',
      mixBlendMode: 'multiply',
    },
  },
  resetPassword: {
    display: 'flex',
    textTransform: 'none',
    color: theme.palette.text.primary,
    margin: '1rem auto 0px auto',
  },
}));

export default useStyles;
