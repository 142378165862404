import React, { useEffect, useState } from 'react';
import UpsertModal from '../../../../components/General/UpsertModal';
import { InsertModalTypes, RowData } from '../types';

const TITLE = 'apoderados';

function InsertModal(
  {
    modalAdd,
    setModalAdd,
    handleCloseModal,
    modalValues,
    handleFieldChange,
    itemsUI,
    addFunction,
  }: InsertModalTypes,
) {
  const [upsertFlag, setUpsertFlag] = useState(false);

  useEffect(() => {
    if (upsertFlag) {
      addFunction();
      setUpsertFlag(false);
    }
  }, [upsertFlag]);

  return (
    <UpsertModal<RowData>
      title={TITLE}
      modalAddEdit={modalAdd}
      setModalAddEdit={setModalAdd}
      handleCloseModal={handleCloseModal}
      modalValues={modalValues}
      handleFieldChange={handleFieldChange}
      itemsUI={itemsUI}
      handleAddFunction={() => setUpsertFlag(true)}
      handleEditFunction={() => setUpsertFlag(true)}
    />
  );
}

export default InsertModal;
