function formatDate(date: Date | string): string {
  let localDate;
  if (date instanceof Date) {
    // If it's a Date object, convert to UTC
    localDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  } else {
    // If it's a string, assume it's already in a 'YYYY-MM-DD' format and parse as UTC
    const parts = date.split('-');
    localDate = new Date(
      Date.UTC(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10)),
    );
  }

  const formatter = new Intl.DateTimeFormat('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  });

  return formatter.format(localDate);
}

const formatDateToDate = (date: Date | string) => {
  if (!date) {
    return '';
  }
  let localDate;
  if (date instanceof Date) {
    // If it's a Date object, convert to UTC
    localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  } else {
    // If it's a string, assume it's already in a 'YYYY-MM-DD' format and parse as UTC
    const parts = date.split('-');
    localDate = new Date(
      parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10),
    );
  }

  return localDate;
};

const getDateOnlyFormat = (date: string | Date, delimiter = '-', dayFirst?: boolean) => {
  const now = new Date(date);

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  return dayFirst ? `${day}${delimiter}${month}${delimiter}${year}` : `${year}${delimiter}${month}${delimiter}${day}`;
};

const thousandSeparator = (
  num: number | string, separator: string,
) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

const formatCcy = (value: number | string, separator: string) => `$ ${thousandSeparator(Number(value).toFixed(0), separator)}`;

const formatDateToString = (date: string | Date) => {
  if (!date) {
    return '';
  }
  let localDate;
  if (date instanceof Date) {
    localDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  } else {
    const parts = date.split('-');
    localDate = `${parseInt(parts[0], 10)}/${parseInt(parts[1], 10)}/${parseInt(parts[2], 10)}`;
  }

  return localDate;
};

const formatDateToWeekDay = (date: Date | string | null) => {
  if (!date) {
    return '';
  }

  let dateObject;
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }

  const dayName = dateObject.toLocaleString('es-ES', { weekday: 'long' });
  const monthName = dateObject.toLocaleString('es-ES', { month: 'long' });
  const day = dateObject.getDate();

  return `${dayName} ${day} de ${monthName}`;
};

const formatTime = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let result = '';

  if (hours > 0) {
    result += `${hours} ${hours === 1 ? 'hora' : 'horas'}`;
  }

  if (minutes > 0) {
    if (hours > 0) {
      result += ' y ';
    }
    result += `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;
  }

  return result;
};

const parseSecondsToTime = (time: number) => {
  if (!time) {
    return ('00:00:00');
  }
  const hour = Math.trunc(time / 3600);
  const minutes = Math.trunc((time - hour * 3600) / 60);
  const seconds = (time - minutes * 60 - hour * 3600);
  return (`0${hour}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`);
};

export {
  thousandSeparator,
  formatCcy,
  formatDate,
  formatDateToDate,
  getDateOnlyFormat,
  formatDateToString,
  formatDateToWeekDay,
  formatTime,
  parseSecondsToTime,
};
