import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.main,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  responsiveTypography: {
    marginLeft: '30px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginTop: '5px',
      width: '100%',
    },
  },
  backButton: {
    color: theme.palette.primary.red,
    width: 24,
    minWidth: 24,
  },
  textBodyColor: {
    color: theme.palette.text.primary,
  },
  classAssistanceViewTableContainer: {
    marginTop: '30px',
  },
}));

export default useStyles;
