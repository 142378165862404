import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import CustomTable from '../../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../../components/General/EmptyTable';
import CustomButton from '../../../../../../components/General/CustomButton';

import { REPRESENTATIVE, STUDENT } from '../../../../../../utils/user_types';
import useStyles from '../../../styles';

function Documents({
  headers,
  data,
  userType,
  handleUploadAnnex,
}: {
  headers: { label: string, key: string, valueType: string, }[]
  data: { values: any[], count: number },
  userType: string | undefined,
  handleUploadAnnex: () => void,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.contractViewProgramsContainer}>
      <Box className={classes.contractViewContractDocumentsTitleButtonContainer}>
        <Box className={classes.contractViewProgramsTitleContainer}>
          <Typography
            variant='h3'
            color='textPrimary'
            fontWeight='bold'
          >
            Documentos
          </Typography>
        </Box>
        {userType === REPRESENTATIVE || userType === STUDENT ? null : (
          <Box>
            <CustomButton text='Añadir anexo' colorType='tertiary' onClick={handleUploadAnnex} key={'UploadAnex'} icon={<AddIcon />}/>
          </Box>
        )}
      </Box>
      <Box className={classes.contractViewProgramsTableContainer}>
        {data.count !== 0
          && <CustomTable
            headers={headers}
            data={data}
            userType={userType}
          />}
        {data.count === 0 && <EmptyTable />}
      </Box>
    </Box>
  );
}

export default Documents;
