import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import {
  Box,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { format } from 'rut.js';
import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import ClassAssistanceHeader from './Components/ClassAssistanceHeader';
import EmptyTable from '../../../components/General/EmptyTable';
import handleApiResponse from '../../../utils/handleApiResponse';
import {
  deleteAssistanceStudentClasses,
  getClassByClassId,
  getStudentsInClassPerPage,
  postStudentClasses,
  putStudentClasses,
} from '../../../requests/api/classes';
import useStyles from '../ClassListView/styles';
import { StudentClass } from '../ClassListView/types';
import { ReduxState } from '../../../types';
import { ADMINISTRATOR, TEACHER } from '../../../utils/user_types';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';

type ProgramInfo = {
  mipreuId: string,
  programName: string,
  date: string,
  time: string,
  lastAssistanceUpdate: Date | undefined,
  teacherNames: string[],
};

type StudentFormatted = {
  id: string;
  classId: string;
  studentId: string;
  attended: boolean;
  arrivedLate: boolean;
  assistance: string;
  studentClassId?: string;
  studentRut: string;
  studentName: string;
  studentLastname: string;
};

const ASSISTANCE_OPTIONS_UI = [{ name: 'Presente', value: 'present' }, { name: 'Ausente', value: 'absent' }, { name: 'Atrasado', value: 'late' }];

const ClassAssitanceView = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [allStudentsInClass, setAllStudentsInClass] = useState<
  { totalItems: number, items: StudentFormatted[] }>({
    totalItems: 0,
    items: [],
  });
  const [classInformation, setClassInformation] = useState<ProgramInfo>({
    programName: '',
    date: '',
    time: '',
    mipreuId: '',
    lastAssistanceUpdate: undefined,
    teacherNames: [],
  });
  const [tableParameters, setTableParameters] = useState({
    page: 0,
    rowsPerPage: 1000,
  });
  const [hasChanged, setHasChanged] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const account = useSelector((state: ReduxState) => state.account);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    if (setTableParameters) {
      setTableParameters((prevState) => ({ ...prevState, page: newPage }));
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setTableParameters) {
      setTableParameters({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    }
  };

  useEffect(() => {
    const getAllInitialInformation = async () => {
      let response;
      try {
        response = await getClassByClassId(
          { classId: id },
        );
        const { classInfo } = response;
        setClassInformation({
          programName: classInfo.sectionTimeSlot.section.program.name,
          date: classInfo.date,
          time: `${classInfo.sectionTimeSlot.timeSlot.startTime.slice(0, 5)} hrs - ${classInfo.sectionTimeSlot.timeSlot.endTime.slice(0, 5)} hrs`,
          mipreuId: classInfo.sectionTimeSlot.section.mipreuId,
          lastAssistanceUpdate: classInfo.lastAssistanceUpdate,
          teacherNames: classInfo.sectionTimeSlot.section.sectionTeachers.map((elem: { teacher: { user: { name: string, lastname: string } } }) => `${elem.teacher.user.name} ${elem.teacher.user.lastname}`),
        });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }

      try {
        const { studentsInClass, totalStudentsInClass } = await getStudentsInClassPerPage({
          page: tableParameters.page,
          limit: tableParameters.rowsPerPage,
          sort: 'id|DESC',
          classId: id,
          lastAssistanceUpdate: response.classInfo.lastAssistanceUpdate,
        });

        const auxStudentClass = studentsInClass.map((elem: StudentClass) => {
          // check if the value is null or undefined
          let assistance;
          if (elem.attended == null) {
            assistance = 'present';
          } else if (elem.attended) {
            if (elem.arrivedLate) {
              assistance = 'late';
            } else {
              assistance = 'present';
            }
          } else {
            assistance = 'absent';
          }

          const elementToReturn = {
            ...elem,
            attended: elem.attended || true,
            arrivedLate: elem.arrivedLate || false,
            assistance,
          };

          if (elem.studentClassId) {
            elementToReturn.studentClassId = elem.studentClassId;
          }

          return elementToReturn;
        });

        setAllStudentsInClass({ items: auxStudentClass, totalItems: totalStudentsInClass });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setShowLoading(false);
      }
    };

    getAllInitialInformation();
  }, []);

  const handleAssistanceSubmission = async () => {
    try {
      const isNewAssistance = !classInformation.lastAssistanceUpdate;
      let response;
      if (isNewAssistance) {
        response = await postStudentClasses({
          studentClasses: allStudentsInClass.items.map((
            elem,
          ) => ({
            studentId: elem.studentId,
            studentName: elem.studentName,
            studentLastname: elem.studentLastname,
            teacherNames: classInformation.teacherNames,
            studentRut: elem.studentRut,
            classId: elem.classId,
            attended: elem.assistance !== 'absent',
            arrivedLate: elem.assistance === 'late',
          })),
          classDetails: {
            date: classInformation.date,
            program: classInformation.programName,
            section: classInformation.mipreuId,
          },
        });
      } else {
        response = await putStudentClasses({
          studentClasses: allStudentsInClass.items.map((
            elem,
          ) => ({
            studentClassId: elem.studentClassId as string,
            studentId: elem.studentId,
            studentName: elem.studentName,
            studentLastname: elem.studentLastname,
            studentRut: elem.studentRut,
            teacherNames: classInformation.teacherNames,
            classId: elem.classId,
            attended: elem.assistance !== 'absent',
            arrivedLate: elem.assistance === 'late',
          })),
          classDetails: {
            date: classInformation.date,
            program: classInformation.programName,
            section: classInformation.mipreuId,
          },
        });
      }

      if (response.success) {
        handleApiResponse(enqueueSnackbar, response, true);
        history.goBack();
      }
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleAssistanceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    elemAssistance: StudentFormatted,
  ) => {
    setHasChanged(true);
    setAllStudentsInClass((prevState) => {
      const updatedItems = [...prevState.items];
      const indexElement = updatedItems
        .findIndex((elem) => elem.studentId === elemAssistance.studentId);
      updatedItems[indexElement] = { ...elemAssistance, assistance: event.target.value };
      return {
        ...prevState,
        items: updatedItems,
      };
    });
  };

  const handleDelete = async () => {
    try {
      const response = await deleteAssistanceStudentClasses(id);

      if (response.success) {
        handleApiResponse(enqueueSnackbar, response, true);
        history.goBack();
      }
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const showSelectAssistance = account?.user
    && (account.user!.userType === ADMINISTRATOR
      || account.user!.userType === TEACHER);

  return (
    <Page
      title={'Clases | Asistencia'}
    >
      {showLoading && <CircularProgressComponent />}
      {!showLoading && <>
        <ClassAssistanceHeader
          mipreuId={classInformation.mipreuId}
          programName={classInformation.programName}
          date={classInformation.date}
          time={classInformation.time}
          lastAssistanceUpdate={classInformation.lastAssistanceUpdate}
          hasChanged={hasChanged}
          handleAssistance={handleAssistanceSubmission}
          handleDelete={handleDelete}
        />
        {allStudentsInClass.totalItems !== 0
          && <Box className={classes.classAssistanceViewTableContainer}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Rut</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Apellido</TableCell>
                    <TableCell>Asistencia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allStudentsInClass.items.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell>{format(row.studentRut)}</TableCell>
                      <TableCell>{row.studentName}</TableCell>
                      <TableCell>{row.studentLastname}</TableCell>
                      <TableCell>
                        {showSelectAssistance
                          && <TextField
                            label="Asistencia"
                            select
                            defaultValue={'present'}
                            value={row.assistance || 'present'}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => handleAssistanceChange(event, row)}
                            fullWidth
                            sx={{
                              maxWidth: 200,
                            }}
                          >
                            {ASSISTANCE_OPTIONS_UI.map((option) => (
                              <MenuItem key={option.name} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>}
                        {!showSelectAssistance
                          && (ASSISTANCE_OPTIONS_UI
                            .find((elem) => elem.value === row.assistance)?.name || 'Presente')}

                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>
                      Total: {allStudentsInClass.totalItems}
                    </TableCell>
                    <TablePagination
                      count={allStudentsInClass.totalItems}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={[1000]}
                      page={tableParameters.page}
                      rowsPerPage={tableParameters.rowsPerPage}
                      labelRowsPerPage="Datos por página:"
                      labelDisplayedRows={() => { }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>

            </TableContainer>
          </Box>}
        {allStudentsInClass.totalItems === 0
          && <Box className={classes.classAssistanceViewTableContainer}><EmptyTable /></Box>}
      </>}

    </Page>
  );
};

export default ClassAssitanceView;
