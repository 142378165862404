import { EditUserData, RequestsPerPage } from '../types';
import axios from '../../utils/axios';

const getUserDetail = async (id: number | string) => {
  const response = await axios.get(`/api/users/${id}`);

  return response.data;
};

const editUserData = async ({ userId, newData }: EditUserData) => {
  const response = await axios.put(`/api/users/${userId}`, newData);

  return response.data;
};

const deleteUsers = async (userIds: number[] | string[]) => {
  const response = await axios.delete(`/api/users/${userIds}`);

  return response.data;
};

const getUserById = async (userId: number | string) => {
  const response = await axios.get(`/api/users/${userId}`);

  return response.data;
};

const getUsersPerPage = async ({
  page, limit, searchValue, sort,
}: RequestsPerPage) => {
  const response = await axios.get(`/api/users?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`);

  return response.data;
};

export {
  getUserDetail,
  editUserData,
  deleteUsers,
  getUserById,
  getUsersPerPage,
};
