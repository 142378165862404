import React from 'react';
import { Redirect } from 'react-router';

import { ChildrenProps } from '../../types';
import LocalTestInformation from '../../services/institutionUserTestService';

function InstitutionAuthGuard({ children }: ChildrenProps): JSX.Element {
  const testInProgress = LocalTestInformation.getTestInProgress();

  if (testInProgress) {
    return <Redirect to={`/institucion/${testInProgress.institutionUrlExtension}/ensayos/${testInProgress.institutionSessionUserTestId}/evaluacion`} />;
  }

  return <>{children}</>;
}

export default InstitutionAuthGuard;
