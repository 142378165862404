import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  SvgIcon,
  Typography,
} from '@mui/material';
import {
  PlusCircle as PlusCircleIcon,
} from 'react-feather';

import { FormattedMessage } from 'react-intl';
import useStyles from './styles';

function Header() {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          <FormattedMessage
            id="admin.listView.header.title"
          />
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          href="/administracion/usuarios/crear"
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <PlusCircleIcon />
          </SvgIcon>
          <FormattedMessage
            id="admin.listView.header.button.addUser"
          />
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
