/* eslint-disable react/prop-types */
import {
  Autocomplete,
  Box, Card, CardContent, IconButton, Modal, TextField, Typography,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  FamilyRestroom as FamilyRestroomIcon,
} from '@mui/icons-material';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import useStyles from '../../../styles';
import CustomButton from '../../../../../../components/General/CustomButton';
import { CustomSelectTextField } from '../../../../../../components/General/CustomTextfields';
import handleApiResponse from '../../../../../../utils/handleApiResponse';
import { getRepresentativesPerPage } from '../../../../../../requests/api/representatives';
import { RELATION_TO_STUDENT_OPTIONS, RELATION_UI_TO_DB_EQ, Representative } from '../types';
import { postRepresentativeStudentAssociation } from '../../../../../../requests/api/students';

const AssociateRepresentativeModal = ({
  studentId,
  openAssociateModal,
  handleCloseAssociateModal,
  representativesAlreadyAssociated,
  openAddModal,
  setOpenAddModal,
}: {
  studentId: string,
  openAssociateModal: boolean,
  handleCloseAssociateModal: () => void,
  representativesAlreadyAssociated: string[],
  openAddModal: { id: string, open: boolean },
  setOpenAddModal: React.Dispatch<React.SetStateAction<{ id: string, open: boolean }>>,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [allRepresentatives, setAllRepresentatives] = useState<{ id: string, name: string }[]>([]);
  const [selectedValues, setSelectedValues] = useState({
    representative: { id: '', name: '' },
    relationToStudent: '',
  });

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllRepresentatives = async () => {
      try {
        const representatives = await getRepresentativesPerPage({
          page: 0, limit: 0, searchValue: '', sort: '',
        }, source.token);

        if (representatives && representatives.success) {
          const auxRepresentatives = representatives.rows
            .filter((elem: Representative) => !representativesAlreadyAssociated.includes(elem.id))
            .map((elem: Representative) => ({
              id: elem.id,
              name: `${elem.user.name} ${elem.user.lastname}`,
            }));
          setAllRepresentatives([{ id: 0, name: '+ Anadir otro apoderado' }, ...auxRepresentatives]);
        }
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllRepresentatives();
    return () => {
      source.cancel();
    };
  }, [openAddModal.open, representativesAlreadyAssociated.length]);

  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (value) {
      if ((value as { id: number }).id === 0) {
        setOpenAddModal({ id: '', open: true });
      } else {
        setSelectedValues((prevState) => ({ ...prevState, [source]: value }));
      }
    } else {
      setSelectedValues({
        representative: { id: '', name: '' },
        relationToStudent: '',
      });
    }
  };

  const handleAddAssociation = async () => {
    try {
      const response = await postRepresentativeStudentAssociation({
        studentId,
        representativeId: (selectedValues.representative as unknown as Representative).id,
        relationToStudent: RELATION_UI_TO_DB_EQ.get(selectedValues.relationToStudent) as string,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      onCloseModal();
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const onCloseModal = () => {
    setSelectedValues({
      representative: { id: '', name: '' },
      relationToStudent: '',
    });
    handleCloseAssociateModal();
  };

  return (
    <Modal
      open={openAssociateModal}
      onClose={onCloseModal}
    >
      <Box className={classes.modalContainer}>
        <Card className={classes.modal}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon}>
                <FamilyRestroomIcon color='primary' />
                <Typography variant='h4' color='primary' fontWeight='bold'>Asignar apoderado al alumno</Typography>
              </Box>
              <IconButton onClick={onCloseModal}>
                <CloseIcon color='primary' />
              </IconButton>
            </Box>
            <Box className={classes.modalTextfieldsContainer}>
              <Box className={classes.modalIndividualTextfield}>
                <Autocomplete
                  key={'Apoderado-representative'}
                  options={allRepresentatives}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  value={allRepresentatives
                    .find((option1) => (option1.id === selectedValues.representative.id)) || null}
                  onChange={(e: SyntheticEvent<Element, Event>, v) => {
                    handleValueChange(e, 'representative', v);
                  }}
                  renderOption={(props, option, { index }) => (
                    <li
                      {...props}
                      className={index === 0
                        ? `${props.className} ${classes.autocompleteAddRepresentative}`
                        : props.className
                      }>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Apoderado'}
                      variant="outlined"
                      color="secondary"
                    />
                  )}
                />
              </Box>
              <Box className={classes.modalIndividualTextfield}>
                <CustomSelectTextField
                  value={selectedValues.relationToStudent}
                  keyField='relationToStudent'
                  selectFields={RELATION_TO_STUDENT_OPTIONS}
                  name="Parentesco"
                  onChangeFunction={(e: React.ChangeEvent<HTMLInputElement> | Date) => {
                    setSelectedValues((prevState) => ({
                      ...prevState,
                      relationToStudent: (e as React.ChangeEvent<HTMLInputElement>).target.value,
                    }));
                  }}
                />
              </Box>
            </Box>
            <Box className={classes.modalButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={onCloseModal} key={'CancelButtonAssociateModal'} icon={<CloseIcon />} />
              <CustomButton disabled={selectedValues.representative.id === '' || selectedValues.relationToStudent === ''} text='Asignar apoderado' colorType='primary' onClick={handleAddAssociation} key={'AcceptButtonAssociateModal'} icon={<CheckIcon />} />
            </Box>
          </CardContent>
        </Card >
      </Box >
    </Modal >
  );
};

export default AssociateRepresentativeModal;
