export type Question = {
  category: string,
  topic: string,
  difficulty: string,
  correctAnswer: string,
  questionImageUrl: string,
  solutionImageUrl: string,
};

type QuestionsData = {
  id: string,
  number: string,
  question: Question,
};

const TEST_QUESTIONS_UI = [
  {
    label: 'Número', key: 'questionNumber', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'number',
  },
  {
    label: 'Clasificación', key: 'category', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'number',
  },
  {
    label: 'Tema', key: 'topic', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Dificultad', key: 'difficulty', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Clave', key: 'correctAnswer', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Clave', key: 'correctAnswer', showOnTable: false, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Pregunta', key: 'questionImageUrl', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Solución', key: 'solutionImageUrl', showOnTable: true, showOnModal: false, typeField: 'text', valueType: 'string',
  },
];

export {
  QuestionsData,
  TEST_QUESTIONS_UI,
};
