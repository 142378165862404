import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const wrapperWithoutNavbar = {
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 84,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapperWithoutNavbar: {
    ...wrapperWithoutNavbar,
  },
  wrapper: {
    ...wrapperWithoutNavbar,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

export default useStyles;
