import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import { CombinedStateType } from '../types';
import studentContractReducer from './studentContractReducer';
import filtersReducer from './filtersReducer';
import institutionAccountReducer from './institutionAccountReducer';
import institutionUserTestReducer from './institutionUserTestReducer';

const rootReducer: Reducer<CombinedStateType> = combineReducers({
  account: accountReducer,
  form: formReducer,
  filters: filtersReducer,
  studentContract: studentContractReducer,
  institutionAccount: institutionAccountReducer,
  institutionUserTest: institutionUserTestReducer,
});

export default rootReducer;
