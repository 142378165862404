import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  School as SchoolIcon,
} from '@mui/icons-material';
import {
  Box,
  ClassNameMap,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../components/Layout/Header';
import Page from '../../../components/Layout/Page';
import CustomTable from '../../../components/General/CustomTable';
import {
  deleteProgram,
  getProgramsPerPage,
  postCreateProgram,
  putUpdateProgram,
} from '../../../requests/api/programs';
import handleApiResponse from '../../../utils/handleApiResponse';
import EmptyTable from '../../../components/General/EmptyTable';

import { getSubjects } from '../../../requests/api/subjects';
import DeleteModal from '../../../components/General/DeleteModal';
import {
  PROGRAMS_UI, ProgramClassYear, ProgramContentData, ProgramData, ProgramLevel, SubjectData,
} from './types';
import useStyles from './styles';
import UpsertProgramModal from './Modals/UpsertProgramModal';
import { ReduxState } from '../../../types';
import { SECRETARY } from '../../../utils/user_types';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';
import { setProgramsFilters } from '../../../actions/filtersActions';

const buttonActions = (
  classes: ClassNameMap<string>,
  programId: string,
  userType: string,
  openEditModal: ({ open, id }: { open: boolean, id: string }) => void,
  openDeleteModal: ({ open, id }: { open: boolean, id: string }) => void,
  handleRedirectProgramTests: (programId: string) => void,
) => (<Box>
  <IconButton onClick={() => handleRedirectProgramTests(programId)}>
    <FileCopyIcon className={classes.icons} />
  </IconButton>
  {userType !== SECRETARY && <>
    <IconButton onClick={() => openEditModal({ id: programId, open: true })}>
      <EditIcon className={classes.icons} />
    </IconButton>
    <IconButton onClick={() => openDeleteModal({ id: programId, open: true })}>
      <DeleteIcon className={classes.icons} />
    </IconButton>
  </>}
</Box>);

function ProgramListView() {
  const classes = useStyles();
  const history = useHistory();
  const [allPrograms, setAllPrograms] = useState({
    items: [],
    totalItems: 0,
  });
  const [subjectObject, setSubjectObject] = useState<SubjectData>();
  const [programsUI, setProgramsUI] = useState(PROGRAMS_UI);
  const [modalAddEditAProgram, setModalAddEditAProgram] = useState(
    { id: '', open: false },
  );
  const [modalDeleteAProgram, setModalDeleteAProgram] = useState({ id: '', open: false });
  const [modalValues, setModalValues] = useState<ProgramData>({
    subjectId: '',
    subjectName: '',
    name: '',
    level: '',
    classYear: '',
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now() + 24 * 60 * 60),
    fee: '',
    active: '',
    programContents: undefined,
  });
  const [allSubjects, setAllSubjects] = useState<SubjectData[]>([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [hasLoaded, setHasLoaded] = useState({ subjects: false, programs: false });
  const [showLoading, setShowLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state: ReduxState) => state.account);
  const { programsFilters } = useSelector((state: ReduxState) => state.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getAllSubjects = async () => {
      try {
        const { subjects } = await getSubjects(source.token);
        const auxProgramsUI = programsUI.map((elem) => {
          if (elem.label === 'Asignatura') {
            elem.selectFields = subjects;
          }
          return elem;
        });
        setProgramsUI(auxProgramsUI);
        setSubjectObject(subjects.reduce((
          acc: SubjectData,
          val: SubjectData,
        ) => ({ ...acc, [val.id]: val.name }), {}));
        setAllSubjects(subjects.map((s: SubjectData) => ({ id: s.id, name: s.name })));
        setHasLoaded((prevState) => ({ ...prevState, subjects: true }));
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getAllSubjects();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const getAllPrograms = async () => {
      try {
        const { programs, totalPrograms } = await getProgramsPerPage({
          page: programsFilters.page, limit: programsFilters.rowsPerPage, searchValue: programsFilters.searchValue, sort: 'id|DESC', subjectId: programsFilters.subjectId,
        });

        const programsAndActions = programs.map((elem: ProgramData) => ({
          ...elem,
          subjectName: subjectObject && subjectObject[elem.subjectId as keyof SubjectData],
          actions: buttonActions(
            classes,
            elem.id!,
            (account && account.user && account.user.userType) ?? '',
            setModalAddEditAProgram,
            setModalDeleteAProgram,
            handleRedirectProgramTests,
          ),
        }));
        setAllPrograms({ items: programsAndActions, totalItems: totalPrograms });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      } finally {
        setHasLoaded((prevState) => ({ ...prevState, programs: true }));
      }
    };

    getAllPrograms();
  }, [
    programsFilters.page,
    programsFilters.rowsPerPage,
    programsFilters.searchValue,
    subjectObject,
    updateTable,
    programsFilters,
  ]);

  useEffect(() => {
    if (Object.values(hasLoaded).every((elem) => elem === true)) {
      setShowLoading(false);
    }
  }, [
    hasLoaded.programs, hasLoaded.subjects,
  ]);

  const handleCloseModal = (setFunction: React.Dispatch<React.SetStateAction<{
    id: string;
    open: boolean;
  }>>) => {
    setFunction(() => ({ id: '', open: false }));
    setModalValues({
      subjectId: '',
      subjectName: '',
      name: '',
      level: '',
      classYear: '',
      startDate: new Date(Date.now()),
      endDate: new Date(Date.now() + 24 * 60 * 60),
      fee: '',
      active: '',
      programContents: undefined,
    });
    setUpdateTable(!updateTable);
  };

  const addAProgram = async () => {
    try {
      let auxSubjectId;
      if (subjectObject) {
        auxSubjectId = Object
          .entries(subjectObject)
          .filter((elem) => elem[1] === modalValues.subjectName);
      } else {
        throw Error('Materias no estan definidas');
      }

      let localProgramContents: ProgramContentData[] = [];
      if (modalValues.programContents && modalValues.programContents.length > 0) {
        localProgramContents = modalValues.programContents.map((elem) => ({
          number: elem.number,
          name: elem.name,
          url: elem.url,
        }));
      }

      const response = await postCreateProgram({
        subjectId: auxSubjectId[0] && parseInt(auxSubjectId[0][0], 10),
        name: modalValues.name as string,
        level: modalValues.level as ProgramLevel,
        classYear: modalValues.classYear as ProgramClassYear,
        startDate: modalValues.startDate,
        endDate: modalValues.endDate,
        active: modalValues.active === 'Activo',
        fee: parseInt(modalValues.fee, 10),
        programContents: localProgramContents,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalAddEditAProgram);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const editAProgram = async (programId: string) => {
    try {
      let auxSubjectId;
      if (subjectObject) {
        auxSubjectId = Object
          .entries(subjectObject)
          .filter((elem) => elem[1] === modalValues.subjectName);
      } else {
        throw Error('Materias no estan definidas');
      }

      let localProgramContents = modalValues.programContents ?? undefined;
      if (modalValues.programContents && modalValues.programContents.length > 0) {
        localProgramContents = modalValues.programContents.map((elem) => ({
          number: elem.number,
          name: elem.name,
          url: elem.url,
        }));
      }

      const response = await putUpdateProgram({
        id: programId,
        subjectId: parseInt(auxSubjectId[0][0], 10),
        name: modalValues.name as string,
        level: modalValues.level as ProgramLevel,
        classYear: modalValues.classYear as ProgramClassYear,
        startDate: modalValues.startDate,
        endDate: modalValues.endDate,
        active: modalValues.active === 'Activo',
        fee: parseInt(modalValues.fee, 10),
        programContents: localProgramContents,
      });
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalAddEditAProgram);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const deleteAProgram = async (programId: string) => {
    try {
      const response = await deleteProgram(programId);
      handleApiResponse(enqueueSnackbar, response, true);
      handleCloseModal(setModalDeleteAProgram);
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<Element, Event> | Date | File, source: string,
  ) => {
    if (e instanceof Date) {
      setModalValues((prevState) => ({ ...prevState, [source]: e }));
    } else if (!(e instanceof File)) {
      setModalValues(
        (prevState) => ({ ...prevState, [source]: (e.target as HTMLInputElement).value }),
      );
    }
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setProgramsFilters({ ...programsFilters, searchValue: e.target.value }));
    if (programsFilters.page !== 0) {
      dispatch(setProgramsFilters({ ...programsFilters, page: 0 }));
    }
  };

  const handleRedirectProgramTests = (sectionId: string) => {
    history.push(`/programas/${sectionId}`);
  };

  const handleFilterChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    if (!value) {
      dispatch(setProgramsFilters({ ...programsFilters, subjectId: '' }));
    } else {
      const localValue = value as { name: string, id: string };
      dispatch(setProgramsFilters({ ...programsFilters, subjectId: localValue.id }));
    }

    if (programsFilters.page !== 0) {
      dispatch(setProgramsFilters({ ...programsFilters, page: 0 }));
    }
  };

  return (
    <Page
      title="Programas"
    >
      <Header
        title='Programas'
        icon={<SchoolIcon />}
        buttons={(account && account.user && account.user.userType !== SECRETARY && !showLoading)
          ? [{ text: 'Crear programa', icon: <AddIcon />, onClick: () => setModalAddEditAProgram((prevState) => ({ ...prevState, open: true })) }] : undefined}
        search={!showLoading ? { text: 'Buscar un programa...', value: programsFilters.searchValue, onChangeSearchValue: handleChangeSearchValue } : undefined}
        autocompletes={!showLoading ? [
          {
            name: 'Asignatura',
            value: programsFilters.subjectId,
            onChange: handleFilterChange,
            options: [{ name: 'Todas', id: '' }, ...allSubjects],
            keyField: 'subjectId',
            valueToRetrieveFromSelect: 'id',
            size: 'small',
          },
        ] : undefined}
      />
      {showLoading && <CircularProgressComponent />}
      {!showLoading && <>
        <Box
          mt={3}
        >
          {allPrograms.totalItems !== 0
            && <CustomTable
              headers={programsUI.filter((elem) => elem.showOnTable)}
              data={{ values: allPrograms.items, count: allPrograms.totalItems }}
              reduxTableParameters={programsFilters}
              setReduxTableParameters={setProgramsFilters}
            />}
          {allPrograms.totalItems === 0 && <EmptyTable />}
        </Box>
        <UpsertProgramModal
          allItems={allPrograms}
          modalAddEdit={modalAddEditAProgram}
          setModalAddEdit={setModalAddEditAProgram}
          handleCloseModal={handleCloseModal}
          modalValues={modalValues}
          setModalValues={setModalValues}
          handleFieldChange={handleFieldChange}
          itemsUI={programsUI}
          addFunction={addAProgram}
          editFunction={editAProgram}
        />
        <DeleteModal
          title={'programa'}
          modalDelete={modalDeleteAProgram}
          setModalDelete={setModalDeleteAProgram}
          handleCloseModal={handleCloseModal}
          deleteFunction={deleteAProgram}
        />
      </>}
    </Page>
  );
}

export default ProgramListView;
