import React from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Typography,
  Grid,
  Button,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Edit as EditIcon } from '@mui/icons-material';
import CustomButton from '../../../../../../components/General/CustomButton';
import useStyles from '../../../styles';

function StudentTestDetailHeader({
  status,
  testName,
  programName,
  handleSaveAnswers,
  handleSubmitTest,
}
: {
  status: boolean,
  testName: string,
  programName: string,
  handleSaveAnswers: () => void,
  handleSubmitTest: () => void,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { id: studentId } = useParams<{ id: string }>();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
      spacing={3}
    >
      <Grid item className={classes.titleContainer}>
        <Button
          className={classes.backButton}
          onClick={() => history.replace(`/alumnos/${studentId}`, { activeTab: 2 })}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          variant="h3"
          fontWeight='bold'
        >
          {testName}
        </Typography>
        <Typography
          ml='30px'
          variant="h4"
          className={classes.textBodyColor}
        >
          {<><strong>Programa: </strong><span>{programName}</span></>}
        </Typography>
      </Grid>
      {!status && <Grid item display="flex" alignItems="center">
        <CustomButton
          icon={<EditIcon />}
          text="Guardar respuestas"
          colorType="tertiary"
          onClick={handleSaveAnswers}
        />
        <CustomButton
          icon={<EditIcon />}
          text="Entregar guía"
          colorType="tertiary"
          onClick={handleSubmitTest}
        />
      </Grid>}

    </Grid >
  );
}

export default StudentTestDetailHeader;
