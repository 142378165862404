import {
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import SummaryTable from './Components/SummaryTable';
import AssistancesTable from './Components/AssistancesTable';
import { AssistanceDetailObj, AssistanceSummaryObj, SUMMARY_TABLE_UI } from './types';
import { getAssistancesInformationByStudentId } from '../../../../../requests/api/students';
import CircularProgressComponent from '../../../../../components/Loading/CircularProgressComponent';

function AssistancesListView() {
  const { enqueueSnackbar } = useSnackbar();
  const { id: studentId } = useParams<{ id: string; }>();
  const [showLoading, setShowLoading] = useState(true);
  const [summaryData, setSummaryData] = useState<{
    count: number;
    values: AssistanceSummaryObj[]
  }>({ count: 0, values: [] });
  const [assistancesData, setAssistancesData] = useState<{
    count: number;
    values: AssistanceDetailObj[]
  }>({ count: 0, values: [] });
  const [programId, setProgramId] = useState('');
  const [allPrograms, setAllPrograms] = useState<{ id: string, name: string }[]>([]);
  const [filteredData, setFilteredData] = useState<{
    count: number,
    values: AssistanceDetailObj[]
  }>({ count: 0, values: [] });
  const [updateOnNewInformation, setUpateOnNewInformation] = useState(false);

  useEffect(() => {
    // filter data
    let filtered = assistancesData;
    if (programId) {
      const filteredByProgram = assistancesData.values.filter(
        (v: AssistanceDetailObj) => v.programId === programId,
      );

      filtered = { count: filteredByProgram.length, values: filteredByProgram };
    }

    setFilteredData(filtered);
  }, [assistancesData, programId]);

  // useEffect to get set student programs from detailsData
  useEffect(() => {
    const getStudentPrograms = async () => {
      try {
        const programs = assistancesData.values
          .map((v: AssistanceDetailObj) => ({ id: v.programId, name: v.programName }))
          .reduce((acc: { id: string; name: string }[], current: { id: string; name: string }) => {
            const duplicate = acc.find(
              (item) => item.id === current.id,
            );
            if (!duplicate) {
              acc.push(current);
            }
            return acc;
          }, []);
        setAllPrograms(programs);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getStudentPrograms();
  }, [assistancesData]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getAllAssistancesInformationFromStudent = async () => {
      try {
        const {
          assistancesSummary, assistancesDetail,
        }: {
          assistancesSummary: AssistanceSummaryObj[],
          assistancesDetail: AssistanceDetailObj[],
        } = await getAssistancesInformationByStudentId(studentId, source.token);

        const formattedAssistancesSummary = assistancesSummary.map((elem) => {
          let attended = '0';
          let arrivedLate = '0';
          let absent = '0';
          if (elem.numberOfClasses) {
            attended = `${elem.attended} (${((elem.attended as number / elem.numberOfClasses) * 100).toFixed(0)}%)`;
            arrivedLate = `${elem.arrivedLate} (${((elem.arrivedLate as number / elem.numberOfClasses) * 100).toFixed(0)}%)`;
            absent = `${elem.absent} (${((elem.absent as number / elem.numberOfClasses) * 100).toFixed(0)}%)`;
          }

          return {
            ...elem,
            attended,
            arrivedLate,
            absent,
          };
        });

        setSummaryData({
          count: formattedAssistancesSummary.length,
          values: formattedAssistancesSummary,
        });
        setAssistancesData({ count: assistancesDetail.length, values: assistancesDetail });
        setShowLoading(false);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllAssistancesInformationFromStudent();
    return () => {
      source.cancel();
    };
  }, [updateOnNewInformation, studentId]);

  return (<>
    {showLoading
      ? <CircularProgressComponent />
      : <Box>
        <SummaryTable data={summaryData} headers={SUMMARY_TABLE_UI} />
        <AssistancesTable
          data={filteredData}
          programId={programId}
          setProgramId={setProgramId}
          allPrograms={allPrograms}
          setUpateOnNewInformation={setUpateOnNewInformation}
        />
      </Box>}
  </>

  );
}

export default AssistancesListView;
