import { REPRESENTATIVE, STUDENT } from '../../../../../utils/user_types';

const LIST_PROGRAMS_UI = [
  {
    label: 'Asignatura', key: 'subjectName', valueType: 'string',
  },
  {
    label: 'Programa', key: 'programName', valueType: 'string',
  },
  {
    label: 'Rango de fechas', key: 'dateRange', valueType: 'string',
  },
  {
    label: 'Arancel original', key: 'programFee', valueType: 'ccy',
  },
  {
    label: 'Descuento', key: 'programFeeDiscount', valueType: 'ccy',
  },
  {
    label: 'Comentario', key: 'comment', valueType: 'string',
  },
  {
    label: 'Arancel final', key: 'finalProgramPrice', valueType: 'ccy',
  },
];

const LIST_DOCUMENTS_UI = [
  {
    label: 'ID', key: 'id', valueType: 'string',
  },
  {
    label: 'Fecha creación', key: 'createdAt', valueType: 'shortDate',
  },
  {
    label: 'Tipo', key: 'contractType', valueType: 'string',
  },
  {
    label: 'Comentarios', key: 'comment', valueType: 'string',
  },
  {
    label: 'Estado', key: 'status', valueType: 'string',
  },
  {
    label: 'Generar documento', key: 'generateDocument', valueType: 'other', excludedRoles: [REPRESENTATIVE, STUDENT],
  },
  {
    label: 'Descargar', key: 'downloadDocument', valueType: 'other',
  },
  {
    label: 'Enviar documento', key: 'sendDocument', valueType: 'other', excludedRoles: [REPRESENTATIVE, STUDENT],
  },
  {
    label: 'Eliminar', key: 'deleteDocument', valueType: 'other', excludedRoles: [REPRESENTATIVE, STUDENT],
  },
];

const DB_TO_UI_CONTRACT = {
  contract: 'Contrato',
  annex: 'Anexo',
  toGenerate: 'Por generar',
  sent: 'Enviado',
  draft: 'Borrador',
};

type ContractDocument = {
  id: string,
  createdAt: Date,
  contractType: string,
  type: string,
  comment: string | undefined,
  pdfUrl: string | undefined,
  status: string,
  contractId: string,
};

export {
  LIST_PROGRAMS_UI,
  LIST_DOCUMENTS_UI,
  DB_TO_UI_CONTRACT,
  ContractDocument,
};
