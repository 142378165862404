import React from 'react';
import { Box } from '@mui/material';

import { TestData } from '../types';

import CustomTable from '../../../../../../components/General/CustomTable';
import EmptyTable from '../../../../../../components/General/EmptyTable';

const StudentTestsTable = ({
  data, headers,
}: {
  data: { count: number; values: TestData[] },
  headers: { label: string; key: string; valueType: string; }[]
}) => (
    <Box>
      {data.count !== 0 && <CustomTable headers={headers} data={data} />}
      {data.count === 0 && <EmptyTable />}
    </Box>
);

export default StudentTestsTable;
