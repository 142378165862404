export const SECTION_BOMBS_UI = [
  {
    label: 'Habilidad', key: 'skill', valueType: 'string',
  },
  {
    label: 'Sección', key: 'mipreuId', valueType: 'string',
  },
  {
    label: 'Total', key: 'total', valueType: 'string',
  },
];

export type TableColumnHeader = {
  label: string;
  key: string;
  valueType: string;
};

export type SectionBombSkill = {
  skill: string;
  mipreuId: number;
  total: number;
};
