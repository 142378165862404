import React from 'react';
import {
  Box, Card, CardContent, IconButton, Modal, Theme, Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CustomButton from './CustomButton';

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  modal: {
    maxHeight: '920px',
    maxWidth: '890px',
    width: '100%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  modalDelete: {
    maxHeight: '290px',
    maxWidth: '690px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitleAndIcon: {
    display: 'flex',
  },
  modalDeleteBody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  modalDeleteButtonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
}));

function CustomModal({
  title,
  modal,
  setModal,
  confirmFunction,
  message,
}: {
  title: string,
  modal: boolean,
  setModal: React.Dispatch<React.SetStateAction<boolean>>,
  confirmFunction: () => void,
  message?: string;
}) {
  const classes = useStyles();
  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
    >
      <Box className={classes.modalContainer}>
        <Card className={`${classes.modal} ${classes.modalDelete}`}>
          <CardContent className={classes.modalContent}>
            <Box className={classes.modalTitleContainer}>
              <Box className={classes.modalTitleAndIcon} >
                <Typography
                  variant="h3"
                  fontWeight='bold'
                >
                  {`${title}`}
                </Typography>
              </Box>
              <IconButton onClick={() => setModal(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={classes.modalDeleteBody}>
              {message && <Typography
                variant='body1'
                textAlign='center'
              >
                {message}
              </Typography>}
            </Box>
            <Box className={classes.modalDeleteButtonsContainer}>
              <CustomButton text='Cancelar' colorType='tertiary' onClick={() => setModal(false)} key={`CancelButtonDelete${title}`} icon={<CloseIcon />} />
              <CustomButton text='Confirmar' colorType='primary' onClick={confirmFunction} key={`AcceptButtonDelete${title}`} icon={<CheckIcon />} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
}

export default CustomModal;
