import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  testContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.background.main,
    width: '84.4vw',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    flexWrap: 'wrap',
    '& button': {
      fontSize: 'clamp(11px, 0.6vw, 25px)',
      lineHeight: '1.3vw',
      width: '200px',
    },
  },
  testName: {
    fontSize: 'clamp(20px, 1.3vw, 40px)',
    fontWeight: 700,
    lineHeight: '30.48px',
    color: '#2C82B3',
    textAlign: 'center',
    marginLeft: 0,
  },
  answersWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  answerCont: {
    width: '100px',
    textAlign: 'center',
    margin: '0 30px',
  },
  answerType: {
    fontWeight: 'bold',
  },
  answerNumber: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: theme.palette.primary.red,
  },
  incorrectRow: {
    backgroundColor: theme.palette.primary.red,
    color: 'white',
  },
  omittedRow: {
    backgroundColor: 'rgba(242, 172, 50, 0.1)',
  },
  tableCont: {
    margin: '0 auto',
    marginTop: '20px',
    width: '100%',
    maxWidth: '998px',
    height: '520px',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
}));

export default useStyles;
