import React, {
  useState,
  useEffect,
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Container } from '@mui/material';
import PropTypes from 'prop-types';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import Page from '../../../../components/Layout/Page';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import UserUpsertForm from './UserUpsertForm';
import Header from './Header';

import { getUserDetail } from '../../../../requests/api/users';

import useStyles from './styles';
import { MatchParams } from '../../../types';
import handleApiResponse from '../../../../utils/handleApiResponse';

function UpsertView(props: RouteComponentProps<MatchParams>) {
  const classes = useStyles();
  const intl = useIntl();
  const isMountedRef = useIsMountedRef();
  const [user, setUser] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getUser = async (relUserId: string) => {
      try {
        const response = await getUserDetail(relUserId);

        if (response.success && isMountedRef.current) {
          const auxUser = response.user;
          setUser(auxUser);
        }
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
        setNotFound(true);
      }
    };

    const userId = props.match.params.id;
    if (userId) {
      getUser(userId);
    }
  }, []);

  if (notFound) {
    return <Redirect to="/administracion/usuarios" />;
  }

  return (
    <Page
      className={classes.root}
      // TODO: Actualizar el título
      title={`Template Title | ${props.match.params.id ? intl.messages['admin.upsert.page.title.editUser'] : intl.messages['admin.upsert.page.title.createUser']}`}
    >
      <Container maxWidth={false}>
        <Header isInsertRoute={!!props.match.params.id} />
        <UserUpsertForm user={user} />
      </Container>
    </Page>
  );
}

UpsertView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(UpsertView);
