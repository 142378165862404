import { CancelToken } from 'axios';
import axios from '../../utils/axios';

const getSubjects = async (cancelToken?: CancelToken) => {
  const response = await axios.get('/api/subjects', { cancelToken });

  return response.data;
};

export {
  getSubjects,
};
