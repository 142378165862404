import React from 'react';
import { useLocation, useParams } from 'react-router';
import Page from '../../../components/Layout/Page';
import MassiveDetailHeader from './Components/MassiveDetailHeader';
import StudentsTable from './Components/StudentsTable';
import TestsTable from './Components/TestsTable';
import { useAxiosFetch } from '../../../hooks/useAxiosFetch';

type LocationState = {
  programId: string,
  sectionId: string,
  programName: string,
  minQuestions: string,
  maxQuestions: string,
  creationDate: string,
};

type AllData = {
  allData: {
    testInfo: {
      id: number,
      programId: number,
      testId: number,
      number: number,
      deliverDate: Date,
      numberOfDeliveries: number,
      testName: string
    }[],
    studentInfo: {
      feedbackId: number;
      massiveId: number;
      rut: string;
      studentName: string;
      status: 'Creado' | 'En proceso';
      comment?: string;
      numberOfQuestions: number;
      questionsPdfUrl?: string;
      solutionsPdfUrl?: string;
    }[],
  } | null,
  loading: boolean,
};

function MassiveDetailView() {
  const location = useLocation<LocationState>();
  const { id: massiveId } = useParams<{ id: string }>();

  // get all the information for both tables in one endpoint
  const { allData } = useAxiosFetch({
    url: `/api/massives-details/${massiveId}/program/${location.state.programId}/section/${location.state.sectionId}`,
    keyToRetrieve: 'massives',
  }) as AllData;

  return (
    <Page
      title='Masivos | Detalle'
    >
      <MassiveDetailHeader
        minQuestions={location.state.minQuestions}
        maxQuestions={location.state.maxQuestions}
        creationDate={location.state.creationDate}
        programName={location.state.programName}
      />
      <TestsTable data={{
        values: allData?.testInfo ?? [],
        count: allData?.testInfo.length ?? 0,
      }} />
      <StudentsTable data={{
        values: allData?.studentInfo ?? [],
        count: allData?.studentInfo.length ?? 0,
      }} />
    </Page>
  );
}

export default MassiveDetailView;
