import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import Page from '../../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getUsersPerPage } from '../../../../requests/api/users';
import handleApiResponse from '../../../../utils/handleApiResponse';

import useStyles from './styles';

function UserListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState([]);
  const [countUsers, setCountUsers] = useState(0);
  const [page, setPage] = useState('0');
  const [limit, setLimit] = useState<string>('10');
  const [query, setQuery] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);
  const [sort, setSort] = useState('updated_at|DESC');

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await getUsersPerPage({
          page, limit, searchValue: query, sort,
        });
        setUsers(response.users);
        setCountUsers(response.totalUsers);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };
    getUsers();
  }, [sort, limit, page, toggleSearch]);

  return (
    <Page
      className={classes.root}
      // TODO: Actualizar el título
      title="Example Template | Users"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            users={users}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            query={query}
            setQuery={setQuery}
            sort={sort}
            setSort={setSort}
            toggleSearch={toggleSearch}
            setToggleSearch={setToggleSearch}
            countUsers={countUsers}
          />
        </Box>
      </Container>
    </Page>
  );
}

export default UserListView;
