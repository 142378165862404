import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { useHistory } from 'react-router';
import useStyles from '../styles';

function SectionDetailHeader({
  mipreuId,
} : {
  mipreuId: string,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems='center'
    >
      <Grid item className={classes.sectionDetailTitleContainer}>
        <Button
          className={classes.sectionDetailHeaderBackButton}
          onClick={() => history.push('/secciones')}
        >
          <ArrowBackIosIcon />
        </Button>
        <Typography
          ml={1}
          variant="h3"
          fontWeight='bold'
          display='flex'
          alignItems='center'
        >
          {`ID Sección: ${mipreuId}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SectionDetailHeader;
