import {
  LoginRequest, UpdatePasswordRequest, UserData, UserVerificationRequest,
} from '../types';
import axios from '../../utils/axios';

const postLogin = async ({
  email, password,
}: LoginRequest) => {
  const response = await axios.post('/api/login', {
    email,
    password,
  });

  return response.data;
};

const postRegister = async ({
  name, lastname, email, userType,
}: UserData) => {
  const response = await axios.post('/api/register', {
    name, lastname, email, userType,
  });

  return response.data;
};

// RECOVER PASSWORD
const postRecoverPassword = async (email: string) => {
  const response = await axios.post('/api/recover-password', {
    email,
  });

  return response.data;
};

// UPDATE PASSWORD
const updatePassword = async ({ token, password, confirmPassword }: UpdatePasswordRequest) => {
  const response = await axios.post('/api/reset-password', {
    token,
    password,
    confirmPassword,
  });

  return response.data;
};

// USER VERIFICATION
const userVerification = async ({ token, password, confirmPassword }: UserVerificationRequest) => {
  const response = await axios.post('/api/user-verification', {
    token,
    password,
    confirmPassword,
  });

  return response.data;
};

export {
  postLogin,
  postRegister,
  postRecoverPassword,
  updatePassword,
  userVerification,
};
