import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { Divider, Box } from '@mui/material';

import { setNewContract } from '../../../../../../actions/studentContractActions';
import { ReduxState } from '../../../../../../types';
import { Installment } from '../../../types';
import { thousandSeparator } from '../../../../../../utils/helpers';

import ContractSection from './ContractSection';
import ContractPaymentMethodSection from './ContractPaymentMethodSection';

const agreementOptions = [
  { id: 'Embajador', name: 'Embajador' },
  { id: 'Ex-alumno', name: 'Ex-alumno' },
  { id: 'Colegio convenio', name: 'Colegio convenio' },
  { id: 'Segundo hermano', name: 'Segundo hermano' },
  { id: 'Otro', name: 'Otro' },
];

const tuitionPaymentTypeOptions = [
  { id: 'transfer', name: 'Transferencia' },
  { id: 'credit', name: 'Crédito' },
  { id: 'debit', name: 'Débito' },
  { id: 'check', name: 'Cheque' },
];

const feePaymentTypeOptions = [
  { id: 'transfer', name: 'Transferencia' },
  { id: 'credit', name: 'Crédito' },
  { id: 'debit', name: 'Débito' },
  { id: 'check', name: 'Cheque' },
  { id: 'monthly', name: 'Mensualidad' },
];

function ContractDiscounts({ programsLength }: { programsLength: number }) {
  const { enqueueSnackbar } = useSnackbar();
  const { id: studentId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { contract } = useSelector((state: ReduxState) => state.studentContract);

  const [contractFields, setContractFields] = useState<{
    tuitionAgreement: string,
    tuitionPaymentType: { id: string, name: string },
    feePaymentType: { id: string, name: string },
    installments: number,
    applyCuotasDiscount: boolean,
  }>({
    tuitionAgreement: '',
    tuitionPaymentType: { id: '', name: '' },
    feePaymentType: { id: '', name: '' },
    installments: 1,
    applyCuotasDiscount: false,
  });

  const [installments, setInstallments] = useState<Installment[]>([]);
  const [updateInstallments, setUpdateInstallments] = useState<boolean>(false);

  // Get contract data and set default values.
  useEffect(() => {
    if (!contract || !contract[studentId]) return;
    // Set default tuition value if not available.
    if (contract[studentId].contractTuition === '0' || !contract[studentId].contractTuition) {
      const auxContractInfo = {
        ...contract[studentId],
        contractTuition: '190000',
      };
      dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    }

    setContractFields({
      tuitionAgreement: contract[studentId].tuitionAgreement || '',
      tuitionPaymentType: tuitionPaymentTypeOptions.find((paymentMethod) => paymentMethod.id === contract[studentId].tuitionPaymentType?.id) || { id: '', name: '' },
      feePaymentType: feePaymentTypeOptions.find((paymentMethod) => paymentMethod.id === contract[studentId].feePaymentType?.id) || { id: '', name: '' },
      installments: contract[studentId].installments?.length || 1,
      applyCuotasDiscount: false,
    });

    // Initialize installments with sequential numbers,
    // executed only when the contract is first loaded.
    if (contract[studentId].installments && installments.length === 0) {
      const newInstallments = (contract[studentId].installments || []).map(
        (installment, index) => ({
          ...installment,
          number: index + 1,
        }),
      );

      setInstallments(newInstallments);
    }
  }, [contract]);

  // Update contract fields based on changes.
  useEffect(() => {
    const discountValue = (
      contract && contract[studentId] && contract[studentId].tuitionAgreement
    ) || (
      contract && contract[studentId] && contract[studentId].contractTuitionDiscount === '0' && contract[studentId].tuitionAgreement
    )
      ? '50'
      : (contract && contract[studentId] && `${contract[studentId].contractTuitionDiscount}`) || '';

    if (contract) {
      const auxContractInfo = {
        ...contract[studentId],
        contractTuitionDiscount: discountValue,
      };
      dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    }
  }, [contractFields.tuitionAgreement]);

  useEffect(() => {
    if (installments.length === 0) return;

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to midnight for accurate comparison.
    const installmentsDiscount = new Date(installments[0].dueDate).getTime() <= today.getTime() && contractFields.feePaymentType.id === 'check';

    if (contract && contract[studentId]) {
      const auxContractInfo = {
        ...contract[studentId],
        installments,
      };
      dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    } else {
      dispatch(setNewContract({
        studentId,
        studentContract: { installments },
      }));
    }

    setContractFields({
      ...contractFields,
      applyCuotasDiscount: contractFields.installments === 1 && installmentsDiscount,
    });
  }, [installments]);

  useEffect(() => {
    const totalFee = finalFee().valueWithDiscounts;
    const baseInstallmentValue = Math.ceil(totalFee / installments.length);

    const updatedInstallments = installments.map((installment, index) => {
      let installmentAmount = baseInstallmentValue;

      if (index === installments.length - 1) {
        // Adjust the last installment to account for any rounding discrepancies.
        installmentAmount = totalFee - (baseInstallmentValue * (installments.length - 1));
      }

      return {
        number: installment.number,
        bank: installment.bank,
        accountNumber: installment.accountNumber,
        documentNumber: contractFields.feePaymentType.id === 'monthly' ? null : installment.documentNumber,
        dueDate: installment.dueDate,
        amount: installmentAmount,
      };
    });

    setInstallments(updatedInstallments);
  }, [updateInstallments, programsLength]);

  // Function creates the initial installments fields with only the amount value.
  const createInstallmentsFields = (installmentsNumber: number) => {
    const totalFee = finalFee().valueWithDiscounts;
    const baseInstallmentValue = Math.ceil(totalFee / installmentsNumber);

    const oldInstallments = installments.slice(0, installmentsNumber).map((installment, index) => {
      let newAmount = baseInstallmentValue;
      if (index === installmentsNumber - 1) {
        newAmount = totalFee - (baseInstallmentValue * (installmentsNumber - 1));
      }
      return {
        ...installment,
        documentNumber: contractFields.feePaymentType.id === 'monthly' ? null : installment.documentNumber,
        amount: newAmount,
      };
    });

    const newInstallmentsLength = installmentsNumber - oldInstallments.length;

    const newInstallments = Array.from({ length: newInstallmentsLength }, (_, i) => {
      let installmentAmount = baseInstallmentValue;

      if (oldInstallments.length + i === installmentsNumber - 1) {
        // Adjust the last installment to account for any rounding discrepancies.
        installmentAmount = totalFee - (baseInstallmentValue * (installmentsNumber - 1));
      }
      return {
        number: oldInstallments.length + i + 1,
        bank: '',
        accountNumber: '',
        documentNumber: null,
        dueDate: '',
        amount: installmentAmount,
      };
    });

    const updatedInstallments = [
      ...oldInstallments,
      ...newInstallments,
    ];

    setInstallments(updatedInstallments);
    return updatedInstallments;
  };

  const handleDisableDiscount = (disabled: boolean, discountName: string) => {
    if (contract && contract[studentId]) {
      const auxContractInfo = {
        ...contract[studentId],
        [discountName]: disabled,
      };
      dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    } else {
      dispatch(setNewContract({
        studentId,
        studentContract: { [discountName]: disabled },
      }));
    }
  };

  // Handle field change for input fields.
  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | Date | File, source: string,
  ) => {
    const event = e as React.ChangeEvent<HTMLInputElement>;
    let { value } = event.target;

    if ((value === '' && (event.nativeEvent as InputEvent).inputType === 'deleteContentBackward')
      || (source === 'installments' && value.includes('cuota'))) {
      value = '0';
    }

    if (source === 'feeAdditionalDiscount') value = value.replace(/\./g, '');

    const regexPattern = /^[0-9]+\.?([0-9]{1,2})?$/;
    if (!regexPattern.test(value)) {
      return;
    }

    if ((source === 'feeAgreementDiscount' || source === 'contractTuitionDiscount') && Number(value) > 100) {
      enqueueSnackbar('Valor de descuento debe ser entre 0 y 100', { variant: 'error' });
      return;
    }

    if (source === 'feeAdditionalDiscount' || source === 'feeAgreementDiscount') setUpdateInstallments((prevState) => !prevState);

    const finalValue = `${Number(value)}`;

    if (contract && contract[studentId]) {
      const auxContractInfo = {
        ...contract[studentId],
        [source]: finalValue,
      };
      dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    } else {
      dispatch(setNewContract({
        studentId,
        studentContract: { [source]: finalValue },
      }));
    }
  };

  // Handle select change for dropdown fields.
  const handleSelectChange = (
    e: React.ChangeEvent<HTMLInputElement> | SyntheticEvent<Element, Event> | Date,
    source: string,
    value?: string | object | null,
  ) => {
    let newValue: string | object = '';
    let installmentsInfo = installments;
    let extraProps: { [key: string]: string | number | boolean } = {};

    if (value) {
      const { name } = value as { id: number, name: string };
      newValue = name;

      if (source === 'feePaymentType') {
        extraProps = {
          disabledDiscount: false,
        };

        if (newValue === 'Cheque' || newValue === 'Mensualidad') {
          const newInstallments = createInstallmentsFields(
            Number(contractFields.installments) || 1,
          );
          installmentsInfo = newInstallments;
        } else {
          installmentsInfo = [];
          setInstallments([]);
        }
        newValue = value;
      }

      if (source === 'tuitionPaymentType') newValue = value;
    }

    if (source === 'installments' && 'target' in e && 'value' in e.target) {
      const inst = e.target.value;
      setContractFields({ ...contractFields, [source]: Number(inst) });
      createInstallmentsFields(Number(inst));
      newValue = inst;
    }

    const installmentsValue = source === 'installments' ? Number(newValue) : contractFields.installments;

    setContractFields({
      ...contractFields,
      [source]: newValue,
      installments: source === 'feePaymentType' && installmentsValue === 0 ? 1 : installmentsValue,
    });

    if (contract && contract[studentId]) {
      const auxContractInfo = {
        ...contract[studentId],
        [source]: newValue,
        installments: installmentsInfo,
        ...extraProps,
      };
      dispatch(setNewContract({ studentId, studentContract: auxContractInfo }));
    } else {
      dispatch(setNewContract({
        studentId,
        studentContract: { [source]: newValue, installments: installmentsInfo, ...extraProps },
      }));
    }
  };

  // Calculate program discount.
  const programDiscount = () => {
    const programs = contract?.[studentId]?.selectedStudentPrograms || [];
    if (programs?.length >= 3) return '3 programas - 10% de descuento';
    if (programs?.length === 2) return '2 programas - 5% de descuento';
    return '0';
  };

  // TUITION
  const tuitionValue = () => {
    if (!contract || !contract[studentId]) {
      return {
        valueWithDiscounts: 0,
        discount: false,
        valueWithoutDiscounts: 0,
      };
    }

    const { contractTuition, contractTuitionDiscount } = contract[studentId];

    const tuition = parseInt(contractTuition ?? '0', 10) || 0;
    const discount = parseInt(contractTuitionDiscount ?? '0', 10) || 0;

    const discountedTuition = tuition * (1 - discount / 100);

    return {
      valueWithDiscounts: discountedTuition,
      discount: discount !== 0,
      valueWithoutDiscounts: tuition,
    };
  };

  // FEE
  const feeValue = () => {
    if (!contract || !contract[studentId]) {
      return {
        valueWithDiscounts: 0,
        discount: false,
        valueWithoutDiscounts: 0,
      };
    }

    const { selectedStudentPrograms } = contract[studentId];

    let totalFeeSumWithDiscount = 0;
    let programsDiscount = 0;
    if (selectedStudentPrograms) {
      totalFeeSumWithDiscount = selectedStudentPrograms!
        .reduce((acc, val) => acc + parseInt(val.programFee, 10)
          - (parseInt(val.programFeeDiscount, 10) ?? 0), 0);

      // Calculate program discount based on the number of selected programs.
      if (contract[studentId]?.feeAgreementDiscount === '0' && !contract[studentId]?.disabledProgramsDiscount) {
        if (selectedStudentPrograms!.length === 2) {
          programsDiscount = 5;
        } else if (selectedStudentPrograms!.length >= 3) {
          programsDiscount = 10;
        }
      }
    }

    const extraPercentageDiscount = parseInt(contract[studentId]?.feeAgreementDiscount ?? '0', 10) + programsDiscount;
    const extraDiscount = parseInt(contract[studentId]?.feeAdditionalDiscount ?? '0', 10);

    return {
      valueWithDiscounts: (
        totalFeeSumWithDiscount * (1 - extraPercentageDiscount / 100)
      ) - extraDiscount,
      discount: (extraPercentageDiscount || extraDiscount) > 0,
      valueWithoutDiscounts: totalFeeSumWithDiscount,
    };
  };

  const finalFee = () => {
    if (!contract || !contract[studentId]) {
      return {
        valueWithDiscounts: 0,
        discount: false,
        valueWithoutDiscounts: 0,
      };
    }

    let paymentMethodDiscount = 1;
    let installmentsDiscount = 0;
    let applyDiscount = false;
    const today = new Date();

    if (!contract[studentId].disabledDiscount) {
      if (contractFields.feePaymentType.id === 'check' && installments.length > 0 && installments[0].dueDate) {
        const dueDate = new Date(installments[0].dueDate);

        dueDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        applyDiscount = contractFields.installments === 1 && dueDate <= today;

        if (applyDiscount) installmentsDiscount = 3;
      }

      paymentMethodDiscount = contract[studentId].feePaymentType
      && ['transfer', 'debit', 'credit'].includes(contract[studentId].feePaymentType!.id) ? 0.97 : 1;
    }

    const discountedFee = feeValue().valueWithDiscounts * (1 - installmentsDiscount / 100);

    return {
      valueWithDiscounts: discountedFee * paymentMethodDiscount,
      discount: (applyDiscount || paymentMethodDiscount === 0.97)
        && !contract[studentId]?.disabledDiscount,
      valueWithoutDiscounts: feeValue().valueWithDiscounts,
    };
  };

  return (
    <Box>
      <Divider sx={{ marginBottom: '30px' }} />
      {/* Matrícula */}
      <ContractSection
        title="Matrícula"
        fields={[
          {
            keyField: 'contractTuition', fieldType: 'textInput', name: 'Valor matrícula', value: (contract && contract[studentId]?.contractTuition) ?? '0',
          },
          {
            keyField: 'tuitionAgreement', fieldType: 'select', name: 'Convenio', value: (contract && contract[studentId]?.tuitionAgreement) ?? contractFields.tuitionAgreement, options: agreementOptions,
          },
          {
            keyField: 'contractTuitionDiscount', fieldType: 'textInput', name: 'Descuento (opcional)', value: (contract && contract[studentId]?.contractTuitionDiscount) ?? '0',
          }]}
        values={tuitionValue()}
        handleFieldChange={handleFieldChange}
        handleSelectChange={handleSelectChange}
      />
      {/* Arancel */}
      <ContractSection
        title="Arancel"
        fields={[
          {
            keyField: 'programsDiscount', fieldType: 'textInput', name: 'Descuento por N° de programas', value: programDiscount(), disabled: true, noApply: !!(contract && contract[studentId]?.feeAgreementDiscount !== '0'),
          },
          {
            keyField: 'feeAgreementDiscount', fieldType: 'textInput', name: '% descuento por convenio', value: (contract && contract[studentId]?.feeAgreementDiscount) ?? '',
          },
          {
            keyField: 'feeAdditionalDiscount', fieldType: 'textInput', name: 'Descuento adicional (opcional)', value: thousandSeparator((contract && contract[studentId]?.feeAdditionalDiscount) ?? '', '.'),
          },
        ]}
        values={feeValue()}
        handleFieldChange={handleFieldChange}
        disabledProgramsDiscount={(contract && contract[studentId]?.disabledProgramsDiscount)
          ?? false}
        handleDisableDiscount={handleDisableDiscount}
      />
      {/* Método de pago matrícula */}
      <ContractPaymentMethodSection
        title="Método de pago matrícula"
        payment="matrícula"
        fields={[{ keyField: 'tuitionPaymentType', name: 'Forma de pago' }]}
        options={tuitionPaymentTypeOptions}
        selectedValue={contractFields.tuitionPaymentType.id}
        handleSelectChange={handleSelectChange}
        showInstallments={false}
        values={tuitionValue()}
        final
      />
      {/* Método de pago arancel */}
      <ContractPaymentMethodSection
        title="Método de pago arancel"
        payment="arancel"
        fields={[{ keyField: 'feePaymentType', name: 'Forma de pago' }]}
        options={feePaymentTypeOptions}
        selectedValue={contractFields.feePaymentType.id}
        installments={installments}
        setInstallments={setInstallments}
        showInstallments={contractFields.feePaymentType.id === 'check' || contractFields.feePaymentType.id === 'monthly'}
        installmentsNumber={contractFields.installments}
        values={finalFee()}
        applyDiscount={contractFields.applyCuotasDiscount}
        handleSelectChange={handleSelectChange}
        handleFieldChange={handleFieldChange}
        setUpdateInstallments={setUpdateInstallments}
        disabledDiscount={(contract && contract[studentId]?.disabledDiscount) ?? false}
        handleDisableDiscount={handleDisableDiscount}
      />
    </Box>
  );
}

export default ContractDiscounts;
