import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import { postRecoverPassword } from '../../requests/api/auth';
import handleApiResponse from '../../utils/handleApiResponse';

import useStyles from './styles';
import { ClassNameProps } from '../types';
import authService from '../../services/authService';
import { loginSuccess } from '../../actions/accountActions';

function LoginForm({ className, ...rest }: ClassNameProps) {
  const classes = useStyles();
  const intl = useIntl();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = async (email: string) => {
    if (!email) {
      handleApiResponse(enqueueSnackbar, {
        message: 'Debe ingresar un email.',
      }, false);
      return;
    }

    try {
      const response = await postRecoverPassword(email);
      handleApiResponse(enqueueSnackbar, response, true);
    } catch (error) {
      const e = error as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  return (
    <Formik

      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().matches(new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i), intl.messages['login.email.invalidFormat'] as string).required(intl.messages['login.email.required'] as string),
        password: Yup.string().max(255).required(intl.messages['login.password.required'] as string),
      })}
      onSubmit={async (values, {
        setSubmitting,
      }) => {
        try {
          const response = await authService.loginWithEmailAndPassword(
            values.email, values.password,
          );
          if (response.success) {
            dispatch(loginSuccess(response.user));
          }
        } catch (err) {
          const e = err as AxiosError;
          handleApiResponse(enqueueSnackbar, e, false);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            color="secondary"
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label={intl.messages['login.email']}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            color="secondary"
            helperText={touched.password && errors.password}
            label={intl.messages['login.password']}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              <FormattedMessage
                id="login.login"
              />
            </Button>
          </Box>
          <Box mt={2}>
            <Divider />
          </Box>
          {/* Comentar hasta aqui */}
          <Button
            className={classes.resetPassword}
            onClick={() => handleResetPassword(values.email)}
          >
            <FormattedMessage
              id="login.forgottenPassword"
            />
          </Button>
        </form>
      )
      }
    </Formik >
  );
}

export default LoginForm;
