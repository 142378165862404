import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    color: theme.palette.primary.red,
    fontSize: '26px',
  },
  disabledIcons: {
    color: theme.palette.action.disabled,
    fontSize: '26px',
  },
}));

export default useStyles;
