import {
  Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router';
import StudentTestDetailHeader from './Components/TestDetailHeader';
import Page from '../../../../../components/Layout/Page';
import EmptyTable from '../../../../../components/General/EmptyTable';
import handleApiResponse from '../../../../../utils/handleApiResponse';
import { getTestsQuestionsByStudentId, postStudentAnswers, putStudentSavedAnswers } from '../../../../../requests/api/students';
import HeaderSummaryAnswers from './Components/HeaderSummaryAnswers';

interface LocationState {
  status: boolean;
  testName: string,
  programName: string,
}

type Test = {
  id: number,
  name: string,
  studentTests: {
    id: number,
    answered: boolean,
    saved: boolean,
  }[]
};

type TestQuestions = {
  id: string,
  questionNumber: string,
  studentTestQuestions: {
    isCorrect: boolean;
    answer: string;
  }[],
  question: {
    correctAnswer: string,
  }
};

type AcceptedColorValues = 'primary' | 'secondary' | 'success' | 'error';

type AcceptedTestAnswerKeys = 'answerA' | 'answerB' | 'answerC' | 'answerD' | 'answerE';

type AnswerDetails = { color: AcceptedColorValues, checked: boolean };

type TestAnswer = {
  testQuestionId: string,
  questionNumber: string,
  answerA: AnswerDetails,
  answerB: AnswerDetails,
  answerC: AnswerDetails,
  answerD: AnswerDetails,
  answerE: AnswerDetails,
};

type TestAnswersState = {
  count: number,
  values: TestAnswer[]
};

const testStatusLogic = (studentTest: { answered: boolean, saved: boolean }[] | undefined) => {
  if (!studentTest || studentTest.length === 0) return { isAnswered: false, isSaved: false };
  const auxStudentTest = studentTest[0];
  if (auxStudentTest.answered) return { isAnswered: true, isSaved: false };
  if (auxStudentTest.saved) return { isAnswered: false, isSaved: true };

  return { isAnswered: false, isSaved: false };
};

const TestDetailView = () => {
  const [testAnswers, setTestAnswers] = useState<TestAnswersState>({ count: 0, values: [] });
  const [answerInformation, setAnswerInformation] = useState({
    isAnswered: false,
    isSaved: false,
  });
  const history = useHistory();
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const { id: studentId, testId } = useParams<{ id: string, testId: string }>();
  const location = useLocation<LocationState>();
  const testName = location.state ? location.state.testName : 'S/I';
  const programName = location.state ? location.state.programName : 'S/I';
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getAllTestsQuestionsFromStudent = async () => {
      try {
        const { testQuestions, answeredInformation }: {
          testQuestions: TestQuestions[],
          answeredInformation: Test,
        } = await getTestsQuestionsByStudentId(
          studentId, testId,
        );

        let auxTestsQuestions = [];

        const testStatus = testStatusLogic(answeredInformation.studentTests);

        setAnswerInformation(testStatus);

        if (testStatus.isAnswered) {
          auxTestsQuestions = testQuestions.map((elem) => ({
            testQuestionId: elem.id,
            questionNumber: elem.questionNumber,
            answerA: showAnswerLogic(elem, 'A'),
            answerB: showAnswerLogic(elem, 'B'),
            answerC: showAnswerLogic(elem, 'C'),
            answerD: showAnswerLogic(elem, 'D'),
            answerE: showAnswerLogic(elem, 'E'),
          }));
          setCorrectAnswers(testQuestions.reduce((acc, val) => acc
            + (val.studentTestQuestions[0].isCorrect ? 1 : 0), 0));
        } else if (testStatus.isSaved) {
          auxTestsQuestions = testQuestions.map((elem) => ({
            testQuestionId: elem.id,
            questionNumber: elem.questionNumber,
            answerA: { color: 'primary' as AcceptedColorValues, checked: elem.studentTestQuestions[0].answer === 'A' },
            answerB: { color: 'primary' as AcceptedColorValues, checked: elem.studentTestQuestions[0].answer === 'B' },
            answerC: { color: 'primary' as AcceptedColorValues, checked: elem.studentTestQuestions[0].answer === 'C' },
            answerD: { color: 'primary' as AcceptedColorValues, checked: elem.studentTestQuestions[0].answer === 'D' },
            answerE: { color: 'primary' as AcceptedColorValues, checked: elem.studentTestQuestions[0].answer === 'E' },
          }));
        } else {
          auxTestsQuestions = testQuestions.map((elem) => ({
            testQuestionId: elem.id,
            questionNumber: elem.questionNumber,
            answerA: { color: 'primary' as AcceptedColorValues, checked: false },
            answerB: { color: 'primary' as AcceptedColorValues, checked: false },
            answerC: { color: 'primary' as AcceptedColorValues, checked: false },
            answerD: { color: 'primary' as AcceptedColorValues, checked: false },
            answerE: { color: 'primary' as AcceptedColorValues, checked: false },
          }));
        }

        setTestAnswers({
          count: auxTestsQuestions.length,
          values: auxTestsQuestions,
        });
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
    };

    getAllTestsQuestionsFromStudent();
  }, []);

  // Using specfic letters instead of isCorrect, as omitted cases are managed by answer 'O' and
  // using isCorrect may lead to errors in this categorization
  const showAnswerLogic = (
    testAnswer: TestQuestions, checboxAnswer: string,
  ) => {
    if (testAnswer.studentTestQuestions[0].answer === checboxAnswer
      && testAnswer.question.correctAnswer === checboxAnswer) {
      return { color: 'primary' as AcceptedColorValues, checked: true };
    } if (testAnswer.studentTestQuestions[0].answer === checboxAnswer
      && testAnswer.question.correctAnswer !== checboxAnswer) {
      return { color: 'error' as AcceptedColorValues, checked: true };
    } if (testAnswer.studentTestQuestions[0].answer !== checboxAnswer
      && testAnswer.question.correctAnswer === checboxAnswer) {
      return { color: 'secondary' as AcceptedColorValues, checked: true };
    }
    return { color: 'primary' as AcceptedColorValues, checked: false };
  };

  const handleAnswerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
    answerKey: AcceptedTestAnswerKeys,
    isAnswered: boolean,
  ) => {
    if (isAnswered) {
      return;
    }
    const updatedTestAnswers: TestAnswer[] = [...testAnswers.values];
    if (Object.values(updatedTestAnswers[rowIndex]).some((elem) => elem)) {
      updatedTestAnswers[rowIndex] = {
        ...updatedTestAnswers[rowIndex],
        answerA: { ...updatedTestAnswers[rowIndex].answerA, checked: false },
        answerB: { ...updatedTestAnswers[rowIndex].answerB, checked: false },
        answerC: { ...updatedTestAnswers[rowIndex].answerC, checked: false },
        answerD: { ...updatedTestAnswers[rowIndex].answerD, checked: false },
        answerE: { ...updatedTestAnswers[rowIndex].answerE, checked: false },
      };
    }
    updatedTestAnswers[rowIndex][answerKey] = {
      color: updatedTestAnswers[rowIndex][answerKey].color,
      checked: e.target.checked,
    } as AnswerDetails;

    setTestAnswers((prevState) => ({ ...prevState, values: updatedTestAnswers }));
  };

  const formatAnswersToSend = (answers: TestAnswer[]) => answers.map((elem) => {
    let answer;
    const auxStudentAnswer = Object.entries(elem).find((
      [, value]: [string, AnswerDetails | string],
    ) => (value as AnswerDetails).checked === true);
    if (!auxStudentAnswer) {
      answer = 'O';
    } else {
      answer = auxStudentAnswer[0].slice(-1);
    }
    return {
      testQuestionId: elem.testQuestionId,
      questionNumber: elem.questionNumber,
      answer,
    };
  });

  const handleSaveAnswers = async () => {
    try {
      const response = await putStudentSavedAnswers({
        studentId,
        testId,
        answers: formatAnswersToSend(testAnswers.values),
      });
      handleApiResponse(enqueueSnackbar, response, true);
      history.replace({ pathname: `/alumnos/${studentId}`, state: { activeTab: 2 } });
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };

  const handleSubmitTest = async () => {
    try {
      const response = await postStudentAnswers({
        studentId,
        testId,
        answers: formatAnswersToSend(testAnswers.values),
      });
      handleApiResponse(enqueueSnackbar, response, true);
      history.replace({ pathname: `/alumnos/${studentId}`, state: { activeTab: 2 } });
    } catch (err) {
      const e = err as AxiosError;
      handleApiResponse(enqueueSnackbar, e, false);
    }
  };
  return (
    <Page
      title="Alumnos | Detalle guia"
    >
      <StudentTestDetailHeader
        status={answerInformation.isAnswered}
        testName={testName}
        programName={programName}
        handleSaveAnswers={handleSaveAnswers}
        handleSubmitTest={handleSubmitTest}
      />
      {answerInformation.isAnswered && <HeaderSummaryAnswers correctAnswers={testAnswers ? `${correctAnswers}/${testAnswers.count}` : '-'} />}
      <Box mt={3}>

        {testAnswers.count !== 0
          && <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Número preguntas</TableCell>
                  <TableCell>A</TableCell>
                  <TableCell>B</TableCell>
                  <TableCell>C</TableCell>
                  <TableCell>D</TableCell>
                  <TableCell>E</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {testAnswers.values.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell>{row.questionNumber}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.answerA.checked}
                        color={row.answerA.color}
                        onChange={(e) => handleAnswerChange(e, rowIndex, 'answerA', answerInformation.isAnswered)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.answerB.checked}
                        color={row.answerB.color}
                        onChange={(e) => handleAnswerChange(e, rowIndex, 'answerB', answerInformation.isAnswered)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.answerC.checked}
                        color={row.answerC.color}
                        onChange={(e) => handleAnswerChange(e, rowIndex, 'answerC', answerInformation.isAnswered)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.answerD.checked}
                        color={row.answerD.color}
                        onChange={(e) => handleAnswerChange(e, rowIndex, 'answerD', answerInformation.isAnswered)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={row.answerE.checked}
                        color={row.answerE.color}
                        onChange={(e) => handleAnswerChange(e, rowIndex, 'answerE', answerInformation.isAnswered)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {testAnswers.count === 0 && <EmptyTable />}

      </Box>
    </Page>

  );
};

export default TestDetailView;
