const ADMINISTRATOR = 'administrator';
const TEACHER = 'teacher';
const ACADEMIC = 'academic';
const STUDENT = 'student';
const REPRESENTATIVE = 'representative';
const SECRETARY = 'secretary';

export {
  ADMINISTRATOR,
  TEACHER,
  ACADEMIC,
  STUDENT,
  REPRESENTATIVE,
  SECRETARY,
};
