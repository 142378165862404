import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import handleApiResponse from '../../../utils/handleApiResponse';
import { getBombById } from '../../../requests/api/bombs';

import Page from '../../../components/Layout/Page';
import BombDetailHeader from './Components/BombDetailHeader';
import BombDetailNavbar from './Components/BombDetailNavbar';
import CircularProgressComponent from '../../../components/Loading/CircularProgressComponent';

import TotalsListView from './Views/TotalsView/TotalsListView';
import SectionsListView from './Views/SectionsView/SectionsListView';
import StudentsListView from './Views/StudentsView/StudentsView';

import { Bomb } from './types';

interface LocationState {
  activeTab: string;
}

function BombDetailView() {
  const location = useLocation<LocationState>();

  const { enqueueSnackbar } = useSnackbar();
  const { id: bombId } = useParams<{ id: string }>();

  const [activeTab, setActiveTab] = useState(0);
  const [showLoading, setShowLoading] = useState(true);
  const [bombDetail, setBombDetail] = useState<Bomb>({
    id: 0,
    locationName: '',
    programName: '',
    startHability: 0,
    endHability: 0,
  });

  useEffect(() => {
    const getBombInfo = async () => {
      try {
        const { bombInfo } = await getBombById(bombId);

        setBombDetail(bombInfo);
      } catch (err) {
        const e = err as AxiosError;
        handleApiResponse(enqueueSnackbar, e, false);
      }
      setShowLoading(false);
    };
    getBombInfo();
  }, [bombId]);

  useEffect(() => {
    if (location?.state?.activeTab) {
      setActiveTab(parseInt(location.state.activeTab, 10));
    }
  }, [location]);

  const tabToShow = () => {
    switch (activeTab) {
      case 0:
        return <TotalsListView />;
      case 1:
        return <SectionsListView />;
      case 2:
        return <StudentsListView />;
      default:
        return <TotalsListView />;
    }
  };

  return (
    <Page title="Bombas">
      {showLoading
        ? <CircularProgressComponent />
        : (
          <>
            <BombDetailHeader bomb={bombDetail} />
            <BombDetailNavbar activeTab={activeTab} setActiveTab={setActiveTab} />
            {tabToShow()}
          </>
        )}
    </Page>
  );
}

export default BombDetailView;
