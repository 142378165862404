import React from 'react';
import Page from '../../../components/Layout/Page';

function QuestionListView() {
  return (
    <Page
    title='Preguntas'
    >
      <h1>Page Under Construction</h1>
    </Page>
  );
}

export default QuestionListView;
