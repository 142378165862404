import { Installment } from '../StudentDetailView/types';

type UserData = {
  name: string;
  lastname: string;
  email: string;
};

type StudentData = {
  id?: string;
  userId?: string;
  rut: string;
  phoneNumber: string;
  name: string;
  lastname: string;
  email: string;
  deleted?: boolean;
  user?: UserData;
  studentId?: string;
  onlineEnabled: boolean,
};

type StudentProgramInfo = {
  programId: string;
  feeDiscount: number;
  comment: string;
};

type PostContractData = {
  studentId: string;
  startDate: Date;
  endDate: Date;
  tuition: number;
  tuitionDiscount: number;
  tuitionAgreement: string | null;
  tuitionPaymentType: string;
  feeDiscountType: string | null;
  feeDiscount: number;
  feeAdditionalDiscount: number | null;
  feePaymentType: string;
  feePaymentTypeDiscount: number;
  studentProgramInfo: StudentProgramInfo[];
  comment?: string;
  installments: Installment[];
};

type PutContractData = PostContractData & {
  contractId: string;
};

const STUDENTS_UI = [
  {
    label: 'Nombre', key: 'name', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Apellidos', key: 'lastname', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Rut', key: 'rut', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Teléfono', key: 'phoneNumber', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: 'Email', key: 'email', showOnTable: true, showOnModal: true, typeField: 'text', valueType: 'string',
  },
  {
    label: '¿Guías online?', key: 'onlineEnabled', showOnTable: true, showOnModal: true, typeField: 'switch', valueType: 'other',
  },
  {
    label: 'Acciones', key: 'actions', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
  {
    label: 'Ver más', key: 'seeMore', showOnTable: true, showOnModal: false, typeField: 'other', valueType: 'string',
  },
];

export {
  StudentData,
  STUDENTS_UI,
  PostContractData,
  PutContractData,
  StudentProgramInfo,
};
