import { CancelToken } from 'axios';
import axios from '../../utils/axios';
import { PostTestData, PutTestName } from '../../views/Test/TestListView/types';
import { RequestTestQuestions, RequestsPerPage } from '../types';
import { Question } from '../../views/Test/TestQuestionsView/types';

const getTestsPerPage = async ({
  page, limit, searchValue, sort, subjectId,
}: RequestsPerPage, cancelToken?: CancelToken) => {
  let url = `/api/tests?page=${page}&limit=${limit}&searchValue=${searchValue}&sort=${sort}`;

  if (subjectId) {
    url += `&subjectId=${subjectId}`;
  }

  const response = await axios.get(url, { cancelToken });
  return response.data;
};

const getTestQuestions = async ({
  page, limit, sort, testId,
}: RequestTestQuestions) => {
  const response = await axios.get(`/api/tests/${testId}?page=${page}&limit=${limit}&sort=${sort}`);

  return response.data;
};

const postCreateTest = async (data: PostTestData, file: File | null) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }
  formData.append('json', JSON.stringify(data));
  const response = await axios.post('/api/tests', formData);

  return response.data;
};

const putEditTest = async (data: PutTestName, id: string) => {
  const response = await axios.put(`/api/tests/${id}`, data);

  return response.data;
};

const deleteTest = async (testId: string) => {
  const response = await axios.delete(`/api/tests/${testId}`);

  return response.data;
};

const postUploadQuestionImages = async (file: File, testId: string | number) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }
  const response = await axios.post(`/api/tests/${testId}/upload-images/questions`, formData);

  return response.data;
};

const postUploadsolutionImages = async (file: File, testId: string | number) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }
  const response = await axios.post(`/api/tests/${testId}/upload-images/solutions`, formData);

  return response.data;
};

const postUploadSolutionary = async (file: File, testId: string | number) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }
  const response = await axios.post(`/api/tests/${testId}/upload-solutionary`, formData);

  return response.data;
};

const postUploadTestPDF = async (file: File, testId: string | number) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }
  const response = await axios.post(`/api/tests/${testId}/upload-test`, formData);

  return response.data;
};

const postUploadOptimarkAnswers = async (testId: string | number) => {
  const response = await axios.post(`/api/optimark/manual/${testId}`);

  return response.data;
};

const postUploadInstitutionOptimarkAnswers = async (testId: string | number) => {
  const response = await axios.post(`/api/institution/optimark/manual/${testId}`);

  return response.data;
};

const getOptimarkTests = async (cancelToken: CancelToken) => {
  const response = await axios.get('/api/optimark/tests', { cancelToken });

  return response.data;
};

const putTest = async (questions: Question[], testId: string) => {
  const response = await axios.put('/api/tests', {
    questions,
    testId,
  });

  return response.data;
};

const checkExcelFileToUpload = async (file: File, testId: string, numberOfQuestions: string) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }
  formData.append('json', JSON.stringify({ testId, numberOfQuestions }));
  const response = await axios.post('/api/tests/check', formData);

  return response.data;
};

const updateEditedTestScores = async (file: File, testId: string, numberOfQuestions: string) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }
  formData.append('json', JSON.stringify({ testId, numberOfQuestions }));
  const response = await axios.post('/api/tests/scores', formData);

  return response.data;
};

const testsPerformanceCSVDownload = async () => {
  const response = await axios.get('/api/download/tests-performance');

  return response.data;
};

export {
  postCreateTest,
  putEditTest,
  deleteTest,
  getTestsPerPage,
  getTestQuestions,
  postUploadQuestionImages,
  postUploadsolutionImages,
  postUploadTestPDF,
  postUploadOptimarkAnswers,
  postUploadInstitutionOptimarkAnswers,
  postUploadSolutionary,
  getOptimarkTests,
  testsPerformanceCSVDownload,
  putTest,
  checkExcelFileToUpload,
  updateEditedTestScores,
};
