import { UserData } from '../../../../../requests/types';

export const RELATION_TO_STUDENT_OPTIONS = [{
  id: '1', name: 'Sostenedor',
}, {
  id: '2', name: 'Representante',
}];

export const RELATION_DB_TO_UI_EQ = new Map([['sustainer', 'Sostenedor'], ['representative', 'Representante']]);
export const RELATION_UI_TO_DB_EQ = new Map([['Sostenedor', 'sustainer'], ['Representante', 'representative']]);
export type StudentRepresentativeData = {
  id?: string;
  userId?: string;
  rut: string;
  phoneNumber: string;
  name: string;
  lastname: string;
  email: string;
  user?: UserData;
  relationToStudent: string;
  birthDate: Date | string;
  nationality: string;
  maritalStatus: string;
  profession: string;
  address: string;
  commune: string;
};

type RepresentativeUser = {
  id: number;
  name: string;
  lastname: string;
  email: string;
};

export type Representative = {
  id: string;
  rut: string;
  phoneNumber: string;
  user: RepresentativeUser;
  birthDate: Date | string;
  nationality: string;
  maritalStatus: string;
  profession: string;
  address: string;
  commune: string;
};

export type StudentRepresentative = {
  id: string;
  studentId: string;
  representativeId: string;
  relationToStudent: string;
  deleted: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
  representative: Representative;
};
