import React from 'react';
import {
  Grid, Typography, Theme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
  },
  key: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  value: {
    paddingLeft: 5,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
}));

const InfoDisplay = ({ info }: { info: object }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.wrapper}>
      {Object.entries(info).map(([key, value], index) => (
        <Grid item key={index} className={classes.cell}>
          <Typography className={classes.key}>
            {key}:
          </Typography>
          <Typography className={classes.value}>
            {value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default InfoDisplay;
